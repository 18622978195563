import React from 'react'
import heroSectionImg from '../../../../assets/images/heroSectionImg.png'
import SectionCard from '../../../UI/Cards/SectionCard/SectionCard'

const AboutHeroes = () => {
	return (
		<SectionCard
			isReversed={true}
			img={heroSectionImg}
			imgSize='lg'
			title={'Heroes'}
			list={['Join Tribe', 'Create a Cause', 'Start changing the world ']}
			btnText='Become a Hero'
			btnUrl='/register'>
			<p>When you create a project through Tribe - a Cause - you become a Hero, and can begin seeking Angels.</p>
		</SectionCard>
	)
}

export default AboutHeroes
