import React, { Component, Fragment } from "react";
import User from "../../DataModels/User";
import { requestHandler } from "../../utils/requestHandler";

import Navbar from "../../components/layouts/Navbar";
import Sidebar from "../../components/layouts/Sidebar/Sidebar";
import ContentBox from "../../components/layouts/ContentBox/ContentBox";

class KYCSupport extends Component {
	state = {
		user: {},
		name: "",
		email: "",
		phone: "",
		DOB: "",
		socialMediaAccounts: "",
		bankAccountDetails: "",
		address: "",
		profession: "",
		workEmail: "",
		employer: "",
		governmentID: "",
	};

	async componentDidMount() {
		const apiRes = await User.ReloadUser();
		console.log(apiRes);
		this.setState({
			user: apiRes,
			name: apiRes.firstName + " " + apiRes.lastName,
			email: apiRes.email,
			address:
				apiRes.shippingAddresses.length !== 0
					? apiRes.shippingAddresses[0].street
					: "",
		});
	}

	onInputChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	onSubmitHandler = () => {
		const kycSupportObj = { ...this.state };
		delete kycSupportObj.user;
		kycSupportObj.bankAccountDetails = {
			bankAccountDetails: kycSupportObj.bankAccountDetails,
		};
		kycSupportObj.address = {
			addressLine1: kycSupportObj.address,
		};

		requestHandler("postKycSupport", kycSupportObj)
			.then((apiRes) => {
				if (apiRes.status === 200) {
					alert(apiRes.message);
				} else {
					alert("Something went wrong!");
				}
			})
			.catch((err) => {
				alert("Server side error occured.");
			});
	};

	render() {
		console.log("KYE: ", this.state.user.kycStatus);
		return (
			<Fragment>
				<Navbar />
				<Sidebar />
				<ContentBox title="KYC Support">
					<div className="container mt-5">
						<div className="row">
							<div className="col">
								{this.state.user.kycStatus === "rejected" ? (
									<div>
										Your previous request for KYC was
										Rejected.
									</div>
								) : null}
								{this.state.user ? (
									this.state.user.kycStatus == null ||
									this.state.user.kycStatus == "rejected" ? (
										<Fragment>
											<div class="form-group">
												<label for="exampleInputEmail1">
													Name
												</label>
												<input
													type="text"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="Enter name"
													name="name"
													value={this.state.name}
													onChange={
														this.onInputChange
													}
												/>
											</div>
											<div class="form-group">
												<label for="exampleInputEmail1">
													Personal Email
												</label>
												<input
													type="email"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="Enter email"
													name="email"
													value={this.state.email}
													onChange={
														this.onInputChange
													}
												/>
											</div>
											<div class="form-group">
												<label for="exampleInputEmail1">
													Phone Number
												</label>
												<input
													type="number"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="Enter phone number"
													name="phone"
													value={this.state.phone}
													onChange={
														this.onInputChange
													}
												/>
											</div>
											<div class="form-group">
												<label for="exampleInputEmail1">
													Date of Birth
												</label>
												<input
													type="date"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													name="DOB"
													value={this.state.DOB}
													onChange={
														this.onInputChange
													}
												/>
											</div>
											<div class="form-group">
												<label for="exampleInputEmail1">
													Social Media Accounts
												</label>
												<input
													type="text"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="Enter account URL"
													name="socialMediaAccounts"
													value={
														this.state
															.socialMediaAccounts
													}
													onChange={
														this.onInputChange
													}
												/>
											</div>
											<div class="form-group">
												<label for="exampleInputEmail1">
													Bank Account Details
												</label>
												<input
													type="text"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="Enter bank account details"
													name="bankAccountDetails"
													value={
														this.state
															.bankAccountDetails
													}
													onChange={
														this.onInputChange
													}
												/>
											</div>
											<div class="form-group">
												<label for="exampleInputEmail1">
													Home Address
												</label>
												<input
													type="text"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="Enter home address"
													name="address"
													value={this.state.address}
													onChange={
														this.onInputChange
													}
												/>
											</div>
											<div class="form-group">
												<label for="exampleInputEmail1">
													Profession
												</label>
												<input
													type="text"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="Enter profession"
													name="profession"
													value={
														this.state.profession
													}
													onChange={
														this.onInputChange
													}
												/>
											</div>
											<div class="form-group">
												<label for="exampleInputEmail1">
													Work Email
												</label>
												<input
													type="email"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="Enter work email"
													name="workEmail"
													value={this.state.workEmail}
													onChange={
														this.onInputChange
													}
												/>
											</div>
											<div class="form-group">
												<label for="exampleInputEmail1">
													Employer Name
												</label>
												<input
													type="text"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="Enter employer name"
													name="employer"
													value={this.state.employer}
													onChange={
														this.onInputChange
													}
												/>
											</div>
											<div class="form-group">
												<label for="exampleInputEmail1">
													Government ID
												</label>
												<input
													type="text"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="Enter Government ID"
													name="governmentID"
													value={
														this.state.governmentID
													}
													onChange={
														this.onInputChange
													}
												/>
											</div>
											<div className="mt-4 mb-5">
												<button
													className="btn btn-primary"
													onClick={
														this.onSubmitHandler
													}
												>
													Submit
												</button>
											</div>{" "}
										</Fragment>
									) : this.state.user.kycStatus ==
									  "pending" ? (
										<div>
											Your KYC Request is already
											submitted and waiting to be
											approved!
										</div>
									) : this.state.user.kycStatus ===
									  "approved" ? (
										<div>
											Your KYC is <b>Approved</b>. No
											further action required.
											<br />
											<br />
											<br />
											Your Compliance Score is:{" "}
											<span className="h3">
												{
													this.state.user
														.complianceScore
												}
											</span>
										</div>
									) : null
								) : null}
							</div>
						</div>
					</div>
				</ContentBox>
			</Fragment>
		);
	}
}

export default KYCSupport;
