import React from "react";
import "./ContentSection.css";
import PrimaryCard from "../../UI/Cards/PrimaryCard/PrimaryCard";

const ContentSection = (props) => {
    return (
        <section className="ContentSection">
            <div className="container">
                <div className="row mb-4">
                    <div className="col-12 col-sm-6 col-lg-4 mb-4 mx-auto">
                        <PrimaryCard />
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4 mb-4 mx-auto">
                        <PrimaryCard />
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4 mb-4 mx-auto">
                        <PrimaryCard />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContentSection;
