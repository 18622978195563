import React from 'react'
import MainContent from "../../../../components/Sections/MainContent/MainContent";
import LabelledInput from "../../../../components/UI/Inputs/LabelledInput/LabelledInput";
import PrimaryBtn from "../../../../components/UI/Buttons/PrimaryBtn/PrimaryBtn";
import PrimaryTitle from "../../../../components/UI/PrimaryTitle/PrimaryTitle";
import User from '../../../../DataModels/User';
import { Redirect } from 'react-router-dom';

export default class ForgotPasswordEmailRequest extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			email: "",
			redirect: null
		}
	}

	onSubmit = () => {
		const { forgotPasswordEmail } = this.state
		User.forgotPasswordEmailRequest(forgotPasswordEmail)
			.then(result => {
				console.log("RESULT: ", result)
				if (result.status === 200) {
					alert("Reset password link has been send to your email")
					if (this.props.onSuccess) {
						this.props.onSuccess()
					}
					if (this.props.redirect) {
						this.setState({
							redirect: this.props.redirect
						})
					}
				} else {
					alert(result.message)
				}
			}, e => {
				console.log("ERROR: ", e)
			})
	}

	render() {
		const { redirect } = this.state
		if (redirect) {
			return (<Redirect to={redirect} />)
		}
		return (
			<div>
				<div className="my-4">
					<MainContent
						topTitle="Forgot Password?"
						titleCenter={true}
					></MainContent>
				</div>

				<div className="container">
					<div className="row">
						<div className="col-12 mx-auto">
							<div className="form-wrapper">
								<form>
									<PrimaryTitle title="Enter Details" />
									<LabelledInput
										label="Email Address Here"
										isBold={true}
										isRequired={true}
										input={{
											onChange: (item) => {
												this.setState({
													forgotPasswordEmail: item.target.value
												})
											},
											type: "email",
											autoFocus: true,
											required: true,
										}}
									/>
									<PrimaryBtn
										type="button"
										size="md"
										color="yellow"
										onClick={this.onSubmit}
									>
										Reset Password
									</PrimaryBtn>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
