import React from "react";
import "./TribePrimaryBtn.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faGoogle } from "@fortawesome/free-brands-svg-icons";

const TribePrimaryBtn = (props) => {
	const { type } = props;
	const styles = {};

	switch (type) {
		case "success":
			styles.backgroundColor = "green";
			break;
		case "newBtn":
			styles.backgroundColor = "#e27a27";
			break;
		case "white":
			styles.backgroundColor = "#fff";
			styles.color = "#000";
			break;
		case "dark":
			styles.backgroundColor = "#212529";
			break;
		case "hero":
			styles.backgroundColor = "#EB982D";
			break;
		case "angel":
			styles.backgroundColor = "#3B9190";
			break;
		case "facebook":
			styles.backgroundColor = "#4267B2";
			break;
		case "google":
			styles.backgroundColor = "#CC504B";
			break;
		default:
			styles.backgroundColor = "#000";
	}

	if (type === "facebook" || type === "google") {
		return (
			<button
				{...props}
				style={styles}
				className="TribePrimaryBtn applyFlex"
			>
				<span className="icon">
					<FontAwesomeIcon
						icon={type === "facebook" ? faFacebookF : faGoogle}
					/>
				</span>
				<span className="text">{props.children}</span>
			</button>
		);
	} else {
		return (
			<button {...props} style={styles} className="TribePrimaryBtn">
				{props.children}
			</button>
		);
	}
};

export default TribePrimaryBtn;
