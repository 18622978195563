import React from "react";
import "./TribeHeaderLogo.css";
import { Link } from "react-router-dom";
import Logo from "../../../assets/images/tribelogo.png";

const TribeHeaderLogo = () => (
    <div className="TribeHeaderLogo">
        <Link to="/">
            <div className="Logo">
                <img src={Logo} alt="Logo" />
            </div>
        </Link>
    </div>
);

export default TribeHeaderLogo;
