import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import "./App.css";
import $ from "jquery"
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.min.js';
import "hover.css/css/hover-min.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import AdminRoute from "./components/AdminRoute";
import FullPageLoader from "./components/FullPageLoader/FullPageLoader";
// Pages
import ProtectedRoute from "./components/ProtectedRoute";
import ReloadUser from "./components/ReloadUser";
import { I18Provider } from "./i18n";
import AddReward from "./pages/AddReward/AddReward";
import AdminAllDonations from "./pages/AdminAllDonations/AdminAllDonations";
import AdminCausesView from "./pages/AdminCausesView/AdminCausesView";
import AdminChats from "./pages/AdminChats";
import AdminContactUsList from "./pages/AdminContactUsList/AdminContactUsList";
import AdminDashboard from "./pages/AdminDashboard/AdminDashboard";
import AdminKycRequests from "./pages/AdminKycRequests/AdminKycRequests";
import AdminLogin from "./pages/AdminLogin/AdminLogin";
import AdminPendingUpdates from "./pages/AdminPendingUpdates/AdminPendingUpdates";
import AdminRefundRequests from "./pages/AdminRefundRequests/AdminRefundRequests";
import AdminReports from "./pages/AdminReports/AdminReports";
import AdminTransfers from "./pages/AdminTransfers/AdminTransfers";
import AdminUsersView from "./pages/AdminUsersView/AdminUsersView";
import AllCampaigns from "./pages/AllCampaigns/AllCampaigns";
import Register from "./pages/auth/Register";
import ResetPassword from "./pages/auth/ResetPassword";
import VarifyEmail from "./pages/auth/VarifyEmail";
import CharityDetails from "./pages/Charity/CharityDetails";
import CreateCampaignUpdate from "./pages/Charity/CreateCampaignUpdate";
import CharityDonate from "./pages/Charity/Donate";
import EditCharity from "./pages/Charity/EditCharity";
import ManageCampaign from "./pages/Charity/ManageCharity";
import ManageEnterprise from "./pages/Charity/ManageEnterprise";
import Chat from "./pages/Chat";
import ContactHeroes from "./pages/ContactHeroes";
import CreateCampaign from "./pages/createCharity/CreateCharity";
import CreateEnterprise from "./pages/CreateEnterprise/CreateEnterprise";
import Dashboard from "./pages/Dashboard";
import EditEnterprise from "./pages/EditEnterprise/EditEnterprise";
import EnterpriseDetails from "./pages/EnterpriseDetails/EnterpriseDetails";
import About from "./pages/IndexPages/About/About";
import ForgotPassword from "./pages/IndexPages/Auth/ForgotPassword/Index";
import LoginMobile from "./pages/IndexPages/Auth/LoginMobile/LoginMobile";
import SignupMobile from "./pages/IndexPages/Auth/SignupMobile/SignupMobile";
import ContactPage from "./pages/IndexPages/ContactPage/ContactPage";
import Faqs from "./pages/IndexPages/Faqs/Faqs";
import FindCause from "./pages/IndexPages/FindCause/FindCause";
import Home from "./pages/IndexPages/Home/Home";
import HowItWorks from "./pages/IndexPages/HowItWorks/HowItWorks";
import Legal from "./pages/IndexPages/Legal/Legal";
import News from "./pages/IndexPages/News/News";
import KYCSupport from "./pages/KYCSupport/KYCSupport";
import MyDonations from "./pages/MyDonations/MyDonations";
import MyPayments from "./pages/MyPayments/MyPayments";
import MyRewards from "./pages/MyRewards/MyRewards";
import Profile from "./pages/Profile";
import RewardBuy from "./pages/RewardBuy/RewardBuy";
import Settings from "./pages/settings/Settings";
import TransferRequests from "./pages/TransferRequests/TransferRequests";
import DashboardNew from "./pages/DashboardPages/Dashboard/Dashboard";
import NotFound from "./pages/NotFound/NotFound";
import Donations from "./pages/DashboardPages/Angel/Donations/Donations";
import RewardTraking from "./pages/DashboardPages/Angel/RewardTraking/RewardTraking";
import CreateCause from "./pages/DashboardPages/Hero/CreateCause/CreateCause";

class App extends Component {
	state = {
		loading: true,
	};

	componentDidMount() {
		window.addEventListener("load", () => {
			setTimeout(() => {
				this.setState({ loading: false });
			}, 1000);
		});
	}

	render() {
		const { isAuthenticated, isVerifying, user, dashboardMode } =
			this.props;
		const isAngel = dashboardMode === "angel";
		const isAdmin =
			isAuthenticated && user && user.userType === "admin" ? true : false;

		if (this.state.loading) {
			return <FullPageLoader loaded={this.state.loading} />;
		}

		return (
			<I18Provider locale={this.props.language}>
				<Switch>
					<Route exact path="/" component={Home} />

					<Route
						exact
						path="/dashboard-new"
						component={DashboardNew}
					/>

					<Route
						exact
						path="/angel-donations"
						component={Donations}
					/>

					<Route
						exact
						path="/angel-donations?refunds=true"
						component={Donations}
					/>

					<Route
						exact
						path="/angel-rewards"
						component={RewardTraking}
					/>

					<Route
						exact
						path="/hero-createcause"
						component={CreateCause}
					/>

					<Route exact path="/about" component={About} />

					<Route exact path="/news" component={News} />

					<Route exact path="/contact" component={ContactPage} />

					<Route exact path="/faqs" component={Faqs} />

					<Route exact path="/legal" component={Legal} />

					<Route exact path="/login" component={LoginMobile} />

					<Route exact path="/join" component={SignupMobile} />

					<Route exact path="/findcause" component={FindCause} />

					<Route exact path="/howitworks" component={HowItWorks} />

					<Route
						exact
						path="/charity/details/:id"
						component={CharityDetails}
					/>

					<Route
						exact
						path="/charity/details"
						component={() => <Redirect to="/" />}
					/>
					<Route
						exact
						path="/charity/donate/:id"
						component={CharityDonate}
					/>
					<Route
						exact
						path="/charity/donate"
						component={() => <Redirect to="/" />}
					/>
					<Route
						exact
						path="/enterprise/details/:id"
						component={EnterpriseDetails}
					/>
					<Route
						exact
						path="/reward/buy/:enterpriseId/:rewardId"
						component={RewardBuy}
					/>

					<Route
						exact
						path="/allcampaigns"
						component={AllCampaigns}
					/>

					<ProtectedRoute
						exact
						path="/"
						component={Dashboard}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<ProtectedRoute
						exact
						path="/dashboard"
						component={isAngel ? Dashboard : Dashboard}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<ProtectedRoute
						exact
						path="/contact-heroes"
						component={ContactHeroes}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<ProtectedRoute
						exact
						path="/mypayments"
						component={MyPayments}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<ProtectedRoute
						exact
						path="/profile"
						component={Profile}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<ProtectedRoute
						exact
						path="/settings/:view"
						component={Settings}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<ProtectedRoute
						exact
						path="/settings"
						component={Settings}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<ProtectedRoute
						exact
						path="/transfers"
						component={TransferRequests}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<ProtectedRoute
						exact
						path="/kyc-support"
						component={KYCSupport}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<ProtectedRoute
						exact
						path="/charity/manage"
						component={ManageCampaign}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<ProtectedRoute
						exact
						path="/charity/create"
						component={CreateCampaign}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<ProtectedRoute
						exact
						path="/enterprise/manage"
						component={ManageEnterprise}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<ProtectedRoute
						exact
						path="/enterprise/create"
						component={CreateEnterprise}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<ProtectedRoute
						exact
						path="/enterprise/edit/:id"
						component={EditEnterprise}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<ProtectedRoute
						exact
						path="/enterprise/addreward/:id"
						component={AddReward}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<ProtectedRoute
						exact
						path="/my-donations"
						component={MyDonations}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<ProtectedRoute
						exact
						path="/my-rewards"
						component={MyRewards}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<ProtectedRoute
						exact
						path="/charity/details/:id"
						component={CharityDetails}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<ProtectedRoute
						exact
						path="/charity/edit/:id"
						component={EditCharity}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<ProtectedRoute
						exact
						path="/campaign/update/:id"
						component={CreateCampaignUpdate}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<ProtectedRoute
						exact
						path="/chat"
						component={Chat}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<ProtectedRoute
						exact
						path="/chat/:id"
						component={Chat}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<ProtectedRoute
						exact
						path="/reload-user"
						component={ReloadUser}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<AdminRoute
						exact
						path="/admin"
						component={AdminDashboard}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<AdminRoute
						exact
						path="/admin/dashboard"
						component={AdminDashboard}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<AdminRoute
						exact
						path="/admin/users"
						component={AdminUsersView}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<AdminRoute
						exact
						path="/admin/user/:id"
						component={Profile}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<AdminRoute
						exact
						path="/admin/users/:userstatus"
						component={AdminUsersView}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<AdminRoute
						exact
						path="/admin/causes"
						component={AdminCausesView}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<AdminRoute
						exact
						path="/admin/causes/:causestatus"
						component={AdminCausesView}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<AdminRoute
						exact
						path="/admin/chats"
						component={AdminChats}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<AdminRoute
						exact
						path="/admin/chats/:chatId"
						component={AdminChats}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<AdminRoute
						exact
						path="/admin/donations"
						component={AdminAllDonations}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<AdminRoute
						exact
						path="/admin/pending-updates"
						component={AdminPendingUpdates}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<AdminRoute
						exact
						path="/admin/refund-requests"
						component={AdminRefundRequests}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<AdminRoute
						exact
						path="/admin/kyc-requests"
						component={AdminKycRequests}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<AdminRoute
						exact
						path="/admin/kyc-requests/:status"
						component={AdminKycRequests}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<AdminRoute
						exact
						path="/admin/contact-us"
						component={AdminContactUsList}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<AdminRoute
						exact
						path="/admin/reports"
						component={AdminReports}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<AdminRoute
						exact
						path="/admin/transfers"
						component={AdminTransfers}
						isAuthenticated={isAuthenticated}
						isAdmin={isAdmin}
						isVerifying={isVerifying}
					/>

					<Route path="/resetpassword" component={ForgotPassword} />
					<Route path="/register" component={Register} />
					<Route path="/admin/login" component={AdminLogin} />
					<Route path="/profile/:id" component={Profile} />
					<Route
						exact
						path="/verifyemail/:token"
						component={VarifyEmail}
					/>
					<Route
						exact
						path="/updatepassword/:token"
						component={ResetPassword}
					/>
					<Route component={NotFound} />
				</Switch>
			</I18Provider>
		);
	}
}

function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		isVerifying: state.auth.isVerifying,
		user: state.auth.user,
		language: state.language.language,
		dashboardMode: state.dashboard.mode,
	};
}

export default connect(mapStateToProps)(App);
