import React, { Component } from "react";
import "./TrendingProjects.css";
import PropTypes from "prop-types";
import sweetalert from "sweetalert";
import { requestHandler } from "../../../../utils/requestHandler";
import Slider from "react-slick";
import SectionHeader from "../../../layouts/SectionHeader/SectionHeader";
import ProjectCard from "../../../UI/Cards/ProjectCard/ProjectCard";
import SliderArrow from "../../../UI/SliderArrow/SliderArrow";
import BgShape from "../../../layouts/BgShape/BgShape";

class TrendingProjects extends Component {
	state = {
		charities: [],
	};

	componentDidMount() {
		this.getCharities();
	}

	getCharities = () => {
		return requestHandler("getCharityCard")
			.then((res) => {
				if (res.status === 200) {
					this.setState({ charities: res.data });
				}
			})
			.catch((err) => {
				console.log(err);
				alert("Server side error occured.");
				sweetalert(
					"Error",
					"Error occured while fetching charities.",
					"error"
				);
			});
	};

	getSliderSettings = () => {
		return {
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow: 3,
			slidesToScroll: 1,
			nextArrow: <SliderArrow direction="right" fromBottom="-15" />,
			prevArrow: <SliderArrow direction="left" fromBottom="-15" />,
			adaptiveHeight: true,
			responsive: [
				{
					breakpoint: 1299,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
					},
				},
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
			],
		};
	};

	render() {
		const { charities } = this.state;
		const sliderSettings = this.getSliderSettings();

		return (
			<section className="TrendingProjects mt-5">
				<div className="header">
					<SectionHeader
						title="Trending projects"
						text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci eleifend vitae elit faucibus. Duis at morbi viverra ac consequat augue elementum."
						btnText="View all"
					/>
				</div>
				<div className="TrendingProjects-body">
					<div className="container">
						<div className="row">
							<div className="col mx-auto">
								<Slider {...sliderSettings}>
									{charities ? (
										charities.map((charity) => (
											<ProjectCard charity={charity} />
										))
									) : (
										<h5 className="text-center">
											No charities found
										</h5>
									)}
								</Slider>
							</div>
						</div>
					</div>
				</div>
				{this.props.showBgShape ? (
					<BgShape side="right" fromTop={-400} />
				) : null}
			</section>
		);
	}
}

TrendingProjects.propTypes = {
	showBgShape: PropTypes.bool.isRequired,
};

export default TrendingProjects;
