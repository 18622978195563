import React from "react";
import "./PrimaryTitle.css";

const PrimaryTitle = (props) => {
    const subTitle = props.subTitle ? (
        <h5 className="sub-title">{props.subTitle}</h5>
    ) : null;

    const ImgBox = props.img ? (
        <div className="img-box">
            <img src={props.img} alt="TitleImg" />
        </div>
    ) : null;

    return (
        <div className={props.img ? "PrimaryTitle WithImg" : "PrimaryTitle"}>
            {ImgBox}
            <div className="title-box">
                <h2 className="title">{props.title}</h2>
                {subTitle}
            </div>
        </div>
    );
};

export default PrimaryTitle;
