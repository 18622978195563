import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import Report from "../../../DataModels/Report";

class ReportModal extends PureComponent {
	state = {
		email: "",
		message: "",
	}

	onInputChange = (item) => {
		this.setState({
			[item.target.id]: item.target.value,
		});
	};

	submitForm = (e) => {
		e.preventDefault();
		this.props.toggleReportModal(false);

		const data = {
			reporterEmail: this.state.email,
			reporterMessage: this.state.message,
			reportType: this.props.reportType,
			reportedId: this.props.reportedId,
			campaignId: this.props.campaignId
		};

		Report.addReport(data)
			.then((res) => {
				if (res.status === 200) {
					alert(res.message);
				} else {
					alert(res.error);
				}
			})
			.catch((err) => {
				console.log(err);
				alert("Erro while submitting a report");
			})
	}

	render() {
		const { email, message } = this.state;
		const { showModal, toggleReportModal, isAuthenticated, user } = this.props;

		if (isAuthenticated) {
			this.setState({ email: user.email });
		}

		return (
			<Modal show={showModal} onHide={() => toggleReportModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Report</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={this.submitForm}>
						<div className="form-group">
							<label htmlFor="email">Email Address</label>
							<input type="email" className="form-control" id="email" placeholder="Enter email" value={email} onChange={this.onInputChange} disabled={isAuthenticated ? true : false} />
						</div>
						<div className="form-group">
							<label htmlFor="message">Why do you want to report this?</label>
							<textarea className="form-control" id="message" rows="3" value={message} onChange={this.onInputChange}></textarea>
						</div>
						<button type="submit" className="btn btn-primary">Submit</button>
					</form>
				</Modal.Body>
			</Modal>
		);
	}
}

function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		user: state.auth.user,
	};
}

export default connect(mapStateToProps)(ReportModal);
