import React from 'react';
import "./AddressCard.css";

const AddressCard = (props) => (
	<div className={props.selected ? "CampaignCard mx-auto bg-secondary p-3" : "CampaignCard mx-auto p-3"}>
		<div className="head">
			<p className="text">Full Name: {props.fullname}</p>
			<p className="text">Country: {props.country}</p>
			<p className="text">Street: {props.street}</p>
			<p className="text">City: {props.city}</p>
			<p className="text">Postal Code: {props.postalCode}</p>
			<p className="text">Phone Number: {props.phoneNumber}</p>
		</div>
	</div>
);

export default AddressCard;