import React, { Fragment } from 'react'
// import { productStagesMessages } from '../../utils/templates'
import PrimaryInputField from '../UI/Inputs/PrimaryAuthInput/PrimaryAuthInput'

export default class ProductStageSelector extends React.Component {

	state = {
		conceptIdea: '',
		attachments: []
	}

	updateProductStage = (currentStage) => {
		const productStage = Object.assign({}, this.props.productStage) // { ...this.props.productStage, currentStage }
		productStage.currentStage = currentStage
		if (this.attachmentRef) this.attachmentRef.files = null;
		if (!productStage[currentStage]) {
			if (currentStage === 'concept') {
				productStage[currentStage] = {
					idea: '',
					attachments: [],
					approvalState: 'pending'
				}
			} else if (currentStage === 'prototype') {
				productStage[currentStage] = {
					feedback: '',
					attachments: [],
					approvalState: 'pending'
				}
			} else if (currentStage === 'production') {
				productStage[currentStage] = {
					description: '',
					attachments: [],
					approvalState: 'pending'
				}
			} else if (currentStage === 'shipping') {
				productStage[currentStage] = {
					starting: '',
					attachments: [],
					approvalState: 'pending'
				}
			}
		}
		this.props.onChangeProductStageUpdate(productStage)
	}

	onFileInputChange = (event) => {
		const productStage = Object.assign({}, this.props.productStage) // { ...this.props.productStage }
		productStage[productStage.currentStage].attachment = event.target.files
		this.props.onChangeProductStageUpdate(productStage)
	};

	onInputChange = (item) => {
		const productStage = Object.assign({}, this.props.productStage) // { ...this.props.productStage }
		productStage[productStage.currentStage][item.target.id] = item.target.value
		this.props.onChangeProductStageUpdate(productStage)
	};

	renderStageDetails = () => {
		const { productStage } = this.props
		const currentStageObj = productStage ? productStage[productStage.currentStage] : {}
		if (currentStageObj && currentStageObj.approvalState === 'approveda') {
			return <div className="bg-success pl-1">
				<b> This project stage has been approved by admin. No action required. </b>
			</div>
		}
		return <div>
			Please fill these fields for your current project stage to be approved.<br /><br />
			{productStage && productStage.currentStage === 'concept' ?
				<Fragment>
					<label htmlFor="idea">Idea</label>
					<PrimaryInputField
						id='idea'
						onChange={this.onInputChange}
						value={currentStageObj.idea} />
				</Fragment> : null}
			{productStage && productStage.currentStage === 'prototype' ?
				<Fragment>
					<label htmlFor="feedback">feedback</label>
					<PrimaryInputField
						id='feedback'
						onChange={this.onInputChange}
						value={currentStageObj.feedback} />
				</Fragment> : null}
			{productStage && productStage.currentStage === 'production' ?
				<Fragment>
					<label htmlFor="description">description</label>
					<PrimaryInputField
						id='description'
						onChange={this.onInputChange}
						value={currentStageObj.description} />
				</Fragment> : null}
			{productStage && productStage.currentStage === 'shipping' ?
				<Fragment>
					<label htmlFor="starting">starting</label>
					<PrimaryInputField
						id='starting'
						onChange={this.onInputChange}
						value={currentStageObj.starting} />
				</Fragment> : null} <br /><br />
			<div>
				<input
					ref={(ref) => { this.attachmentRef = ref }}
					type='file'
					id='attachment'
					onChange={this.onFileInputChange}
					multiple
				/>
			</div>
		</div>
	}

	render() {
		const props = this.props
		const currentStage = props.productStage ? props.productStage.currentStage : ''
		return (
			<div className="bg-light border border-secondary p-1" onChange={(e) => this.updateProductStage(e.target.value)}>
				<h5>Select the Stage of your company/product is in</h5>
				<input type="radio" checked={currentStage === 'concept'} value="concept" name="productStage" /> Concept &nbsp;
				<input type="radio" checked={currentStage === 'prototype'} value="prototype" name="productStage" /> Prototype &nbsp;
				<input type="radio" checked={currentStage === 'production'} value="production" name="productStage" /> Production &nbsp;
				<input type="radio" checked={currentStage === 'shipping'} value="shipping" name="productStage" /> Shipping &nbsp;
				<hr />
				{/* <div>
				{productStagesMessages[currentStage]}
			</div><br /> */}
				{this.renderStageDetails()}
			</div>
		)
	}
}