import React from 'react'
import { Button } from 'react-bootstrap'
import User from '../../DataModels/User'

import './EmailWarning.css'

const sendEmailConfirmationLink = () => {
	User.ResendVarificationEmail()
}

const EmailWarning = (props) => {
	const user = JSON.parse(localStorage.getItem('auth'))
	if (user.verified_at) {
		return null
	}
	return (
		<div className="bg-warning warning">
			<span>
				Please confirm your email
			</span>
			<span className="send-email-button">
				<Button onClick={sendEmailConfirmationLink}>
					Resend Email
				</Button>
			</span>
		</div>
	)
}

export default EmailWarning