import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";


import './Chat.css'
import ContentBox from "../../components/layouts/ContentBox/ContentBox";
import Navbar from "../../components/layouts/Navbar";
import Sidebar from "../../components/layouts/Sidebar/Sidebar";
import Conversation from "../../DataModels/Conversation";
import ChatThreadCard from "./ChatThreadCard";
import ChatMessages from "./ChatMessages";

class Chat extends Component {
    state = {
        conversations: [],
        selectedConversation: null,
    };

    componentDidMount() {
        this.loadData()

    }

    loadData = () => {
        this.loadAllConversations()
    }

    loadAllConversations = async () => {
        try {
            let { id: selectedConversation } = this.props.match.params
            const conversations = await Conversation.FetchUserConversations()
            conversations.forEach(conversation => {
                if (conversation._id === selectedConversation) {
                    selectedConversation = conversation
                }
            })
            this.setState({ conversations, selectedConversation })
        } catch (e) {
            console.log({ e })
            alert('Could not fetch conversations at this time')
        }
    }

    setRedirect = (redirect) => {
        this.setState({ redirect })
    }

    onConversationClick = (conversation) => {
        this.setState({ selectedConversation: conversation })
    }

    blockConversation = async (convo) => {
        let conversation = new Conversation(convo._id)
        if (window.confirm('By pressing OK you will block this user from sending you any more messages')) {
            // return alert("The user has been blocked")
            if (await conversation.blockConversation()) {
                this.loadData()
                alert("The user has been blocked")
            } else {
                alert("Something went wrong. Please try again later")
            }
        }
    }

    unblockConversation = async (convo) => {
        let conversation = new Conversation(convo._id);
        if (window.confirm('By pressing OK you will unblock this user. And the conversation will continue')) {
            if (await conversation.unblockConversation()) {
                this.loadData()
                alert("The user has been unblocked")
            } else {
                alert("Something went wrong. Please try again later")
            }
        }
    }

    reportConversation = async (conversationId) => {
        let conversation = new Conversation(conversationId)
        // return;
        if (window.confirm('Are you sure you want to report this conversation to the admin')) {
            if (await conversation.reportConversation()) {
                alert("The conversation has been reported to admin and will be reviewed.")
            } else {
                alert("Something went wrong. Please try again later")
            }
        }
    }

    renderAllConversations = () => {
        const { conversations, selectedConversation } = this.state
        return (
            <div className="bg-light">
                <div className='shift-detail-Actual pt-3 d-flex'>
                    <h2> Conversations </h2>
                </div>
                <div className='' style={{ padding: "10px" }}>
                    {conversations.map(conversation => {
                        return <ChatThreadCard
                            conversation={conversation}
                            selected={conversation._id === selectedConversation}
                            loadChat={() => this.onConversationClick(conversation)} />
                    })}

                </div>
            </div>
        )
    }

    render() {
        let {
            redirect,
            selectedConversation,
        } = this.state;
        if (redirect) {
            return (<Redirect to={redirect} />)
        }
        return (
            <Fragment>
                <Navbar />
                <Sidebar />
                <ContentBox title="Chats">
                    <div className='row vh-100'>
                        <div className="col" md={12}>
                            <div className='overview-main-div '>
                                <div className='overview-left'>
                                    <div className='row'>
                                        <div className='col-3 col-lg-4 col-md-4 col-sm-5 min-vh-100  ml-5'>
                                            {this.renderAllConversations()}
                                        </div>

                                        {selectedConversation ?
                                            <ChatMessages
                                                conversation={selectedConversation}
                                                unblockConversation={() => this.unblockConversation(selectedConversation)}
                                                blockConversation={() => this.blockConversation(selectedConversation)}
                                                reportConversation={() => this.reportConversation(selectedConversation)}
                                            /> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ContentBox>
            </Fragment>
        );
    }
}

export default Chat;
