import React from "react";
import { Modal } from "react-bootstrap";
import ForgotPasswordEmailRequest from "../../../pages/IndexPages/Auth/ForgotPassword/ForgotPasswordEmailRequest";

const PopupModal = (props) => {

	return (
		<Modal show={true} onHide={props.onClose}>
			<Modal.Header closeButton>
				<Modal.Title></Modal.Title>
				<Modal.Body>
					<ForgotPasswordEmailRequest {...props} />
				</Modal.Body>

				<Modal.Footer>
				</Modal.Footer>
			</Modal.Header>
		</Modal>
	);
};

export default PopupModal;
