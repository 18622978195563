import React, { Component } from "react";

class RewardItemCard extends Component {
    state = { item: null, loading: true };

    componentDidMount() {
        // eslint-disable-next-line
        const { itemId, item } = this.props;

        if (item) {
            this.setState({ item, loading: false });
        }
    }

    renderCustomization = (customization) => {
        if (!this.state.item) {
            return null;
        } else {
			const { description, availability } = customization; 
			
			return (
				<div>
					<b>{description}</b>
					<br />
					<div className="ml-4">
						{availability.map((_availibility, index) => (
							<span>
								{_availibility}
								{index === availability.length - 1 ? null : " - "}
							</span>
						))}
					</div>
				</div>
			);
		}
    };

    render() {
        // eslint-disable-next-line
        const { loading, item, _id } = this.state;
        const { selected } = this.props;
        let customizations = item ? this.state.item.customizations : [];
        if (loading) return <span></span>;
        return (
            <div
                className={
                    selected ? "mx-auto p-3 bg-info" : "mx-auto p-3 bg-light"
                }
                style={{ borderStyle: "ridge" }}
                onClick={this.props.onClick}
            >
                Title: <b>{item ? item.title : null}</b> <br />
                Retail Price: <b>{item ? item.retailPrice : null}</b> <br />
                {customizations.length ? "Options:" : null}
                {customizations.map((customization) => {
                    return this.renderCustomization(customization);
                })}
            </div>
        );
    }
}

export default RewardItemCard;
