import React from 'react'
import UserAvatar from '../UserAvatar'

export default class UserCard extends React.Component {
	constructor(p) {
		super(p)
		this.state = {
			name: '',
			noOfCauses: '',
			noOfEnterprises: '',
			location: '',
			refuntPopup: false,
			showMapLocation: false
		}
	}

	componentDidMount() {
		this.loadData()
	}

	loadData = () => {
		// const user = await User.GetUserCard(this.props.userId)
		const { user } = this.props
		this.setState({
			name: `${user.firstName} ${user.lastName}`,
			noOfCauses: user.charities,
			noOfEnterprises: user.enterprises,
			location: `${user.address.city}, ${user.address.country}`,
			locationName: user.address.location,
			lat: user.address.lat,
			lng: user.address.lng,
			avatar: user.avatar
		})
	}

	toggleShowMapModal = () => {
		this.setState({ showMapLocation: !this.state.showMapLocation })
	}

	render() {
		const { user } = this.props
		const name = user ? `${user.firstName} ${user.lastName}` : '',
			noOfCauses = user ? user.charities : '',
			noOfEnterprises = user ? user.enterprises : '',
			location = user
				? `${user.address.city}, ${user.address.country}`
				: '',
			locationName = user ? user.address.location : '',
			lat = user ? user.address.lat : '',
			lng = user ? user.address.lng : '',
			_id = user ? user._id : '',
			avatar = user ? user.avatar : null
		return (
			<div style={{}}>
				<div
					className={'mx-auto bg-light'}
					style={{ borderStyle: 'ridge' }}>
					<div className='row'>
						<div className='col'>
							<div
								style={{
									alignContent: 'center',
									alignItems: 'center',
									alignSelf: 'center',
									margin: '20%'
								}}>
								<UserAvatar
									style={{ width: '150px', height: '150px' }}
									avatar={avatar}
									defaultAvatar={avatar ? false : true}
								/>
							</div>
						</div>
						<div className='col' style={{ marginTop: '5%' }}>
							<div className='head'>
								<p className='text'>Name: {name}</p>
								<p className='text'>
									Hero to {noOfCauses} Causes{' '}
								</p>
								<p className='text'>
									Hero to {noOfEnterprises} Enterprises{' '}
								</p>
								<p className='text'>Location: {location}</p>
								<a href={'/profile/' + _id}>View Profile</a>
								<br />
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
