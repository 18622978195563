import React, { Component, Fragment } from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import { requestHandler } from "../../utils/requestHandler";

class MapContainer extends Component {
    state = {
        mapCenter: {
            lat: 40.854885,
            lng: -88.081807,
        },
        selectedEntity: "users",
        users: [],
        causes: [],
        enterprises: [],
        showingInfoWindow: false,
        activeMarker: {},
        selectedPlace: {},
    };

    componentDidMount() {
        const { mapCenter } = this.state;
        this.fetchUsersCoords(mapCenter);
        this.fetchCausesCoords(mapCenter);
        this.fetchEnterprisesCoords(mapCenter);
    }

    fetchUsersCoords = (coords) => {
        requestHandler("getUsersCoords", {
            latitude: coords.lat,
            longitude: coords.lng,
        }).then((res) => {
            console.log("UsersCoords", res);

            if (res.statusCode === 200) {
                this.setState({ users: res.data });
            }
        });
    };

    fetchCausesCoords = (coords) => {
        requestHandler("getCausesCoords", {
            latitude: coords.lat,
            longitude: coords.lng,
        }).then((res) => {
            console.log("CausesCoords", res);

            if (res.statusCode === 200) {
                this.setState({ causes: res.data });
            }
        });
    };

    fetchEnterprisesCoords = (coords) => {
        requestHandler("getEnterprisesCoords", {
            latitude: coords.lat,
            longitude: coords.lng,
        }).then((res) => {
            console.log("EnterpriseCoords", res);

            if (res.statusCode === 200) {
                this.setState({ enterprises: res.data });
            }
        });
    };

    onMarkerClick = (props, marker, e) => {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true,
        });
    };

    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null,
            });
        }
    };

    render() {
        const mapContainerStyle = {
            width: "80%",
            height: "450px",
        };

        console.log(this.state.enterprises);

        return (
            <div className="MapContainer">
                <Map
                    containerStyle={mapContainerStyle}
                    google={this.props.google}
                    zoom={4}
                    maxZoom={12}
                    center={this.state.mapCenter}
                    onClick={this.onMapClicked}
                >
                    {/* <Marker
                        onClick={this.onMarkerClick}
                        name={"Current location"}
                        position={this.state.mapCenter}
                    />

                    <InfoWindow
                        marker={this.state.activeMarker}
                        visible={this.state.showingInfoWindow}
                    >
                        <div>
                            <h1>{this.state.selectedPlace.name}</h1>
                        </div>
                    </InfoWindow> */}

                    {this.state.enterprises.map((data) => (
                        <Fragment>
                            <Marker
                                onClick={this.onMarkerClick}
                                name={"Current location"}
                                position={{
                                    lat: data.location.coordinates[0],
                                    lng: data.location.coordinates[1],
                                }}
                            />

                            <InfoWindow
                                marker={this.state.activeMarker}
                                visible={this.state.showingInfoWindow}
                            >
                                <div>
                                    <h1>{this.state.selectedPlace.name}</h1>
                                </div>
                            </InfoWindow>
                        </Fragment>
                    ))}
                </Map>
            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyBz3Mxi9q2R8sXrS2bRlb90H8c3UxVvBCg",
})(MapContainer);
