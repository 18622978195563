import React, { Component } from "react";
import "./News.css";
import Navbar from "../../../components/Sections/Navbar/Navbar";
import ContentSection from "../../../components/Sections/ContentSection/ContentSection";
import Footer from "../../../components/Sections/Footer/Footer";
import Title from "../../../components/UI/Typography/Title/Title";
import PrimaryBtn from "../../../components/UI/Buttons/PrimaryBtn/PrimaryBtn";
import BgShape from "../../../components/layouts/BgShape/BgShape";

class News extends Component {
    state = {};

    render() {
        return (
            <main className="NewsPage">
                <BgShape side="right" fromTop={100} />

                <Navbar mode="dark" />

                <section className="NewsPage-Header">
                    <div className="container">
                        <div className="row">
                            <div className="col col-sm-10 col-md-8 col-lg-6">
                                <Title
                                    size="lg"
                                    color="dark"
                                    isResponsive={true}
                                >
                                    The Campfire
                                </Title>
                                <p className="text">
                                    Ah, there you are! Whether you’ve been away
                                    an hour or a year, you’re always welcome
                                    here in your Tribe. Come, join us around the
                                    campfire and share tales of great deeds,
                                    noble Heroes and glorious Angels.
                                </p>
                                <div className="btns-group">
                                    <div className="group group-one">
                                        <PrimaryBtn
                                            type="button"
                                            size="sm"
                                            color="yellow"
                                        >
                                            ALL
                                        </PrimaryBtn>
                                        <PrimaryBtn
                                            type="button"
                                            size="sm"
                                            color="transparentYellow"
                                        >
                                            LOREM
                                        </PrimaryBtn>
                                    </div>
                                    <div className="group group-second">
                                        <PrimaryBtn
                                            type="button"
                                            size="sm"
                                            color="transparentYellow"
                                        >
                                            IPSUM
                                        </PrimaryBtn>
                                        <PrimaryBtn
                                            type="button"
                                            size="sm"
                                            color="transparentYellow"
                                        >
                                            AMET
                                        </PrimaryBtn>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="NewsPage-Body">
                    <ContentSection />
                    <ContentSection />
                    <ContentSection />
                </section>

                <Footer />
            </main>
        );
    }
}

export default News;
