import { LOCALES } from "../../i18n";
import { SET_LANGUAGE } from "../actions";

export default (
	state = {
		language: LOCALES.ENGLISH,
	},
	action
) => {
	switch (action.type) {
		case SET_LANGUAGE:
			return {
				...state,
				language: action.language,
			};
		default:
			return state;
	}
};
