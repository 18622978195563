import React from "react";
import "./LabelledInput.css";

const LabelledInput = (props) => {
	let InputHTML = null;

	switch (props.inputType) {
		case "input":
			InputHTML = <input {...props.input} className="InputField" />;
			break;
		case "textarea":
			InputHTML = (
				<textarea
					{...props.input}
					className="InputField"
					rows="4"
				></textarea>
			);
			break;
		case "select":
			InputHTML = (
				<select {...props.input} className="InputField">
					{props.children}
				</select>
			);
            break;
		default:
			InputHTML = <input {...props.input} className="InputField" />;
	}

	return (
		<div className="LabelledInput">
			{props.label ? (
				<label className="Label">{props.label}</label>
			) : null}
			{InputHTML}
		</div>
	);
};

export default LabelledInput;
