import React, { Component } from "react";
import "./Legal.css";
import NavbarWithBackdrop from "../../../components/Sections/NavbarWithBackdrop/NavbarWithBackdrop";
import TextSection from "../../../components/UI/Typography/TextSection/TextSection";
import Footer from "../../../components/Sections/Footer/Footer";

// Images
import BackdropImg from "../../../assets/images/backdropTest.png";

class Legal extends Component {
    state = {};

    render() {
        return (
            <main className="Legal">
                <NavbarWithBackdrop
                    navbarMode="white"
                    backdropImg={BackdropImg}
                    text="Legal"
                    textColor="white"
                />

                <div className="Legal-body">
                    <TextSection title="Lorem Ipsum" color="dark">
                        <p>
                            Dui duis gravida sed consectetur enim. Iaculis purus
                            a neque odio phasellus habitasse accumsan in magna.
                            Nunc neque, tristique aliquet velit tempus, at sed
                            tempus. Lectus diam in egestas aliquam aliquam
                            vulputate pulvinar elit quam. Integer orci,
                            imperdiet id nibh neque aliquam. Aliquam
                            pellentesque diam.
                        </p>
                    </TextSection>

                    <TextSection title="Lorem Ipsum" color="dark">
                        <p>
                            Dui duis gravida sed consectetur enim. Iaculis purus
                            a neque odio phasellus habitasse accumsan in magna.
                            Nunc neque, tristique aliquet velit tempus, at sed
                            tempus. Lectus diam in egestas aliquam aliquam
                            vulputate pulvinar elit quam. Integer orci,
                            imperdiet id nibh neque aliquam. Aliquam
                            pellentesque diam.
                        </p>
                    </TextSection>

                    <TextSection title="Lorem Ipsum" color="dark">
                        <p>
                            Dui duis gravida sed consectetur enim. Iaculis purus
                            a neque odio phasellus habitasse accumsan in magna.
                            Nunc neque, tristique aliquet velit tempus, at sed
                            tempus. Lectus diam in egestas aliquam aliquam
                            vulputate pulvinar elit quam. Integer orci,
                            imperdiet id nibh neque aliquam. Aliquam
                            pellentesque diam. Dui duis gravida sed consectetur
                            enim. Iaculis purus a neque odio phasellus habitasse
                            accumsan in magna. Nunc neque, tristique aliquet
                            velit tempus, at sed tempus. Lectus diam in egestas
                            aliquam aliquam vulputate pulvinar elit quam.
                            Integer orci, imperdiet id nibh neque aliquam.
                            Aliquam pellentesque diam.
                        </p>
                        <p>
                            Dui duis gravida sed consectetur enim. Iaculis purus
                            a neque odio phasellus habitasse accumsan in magna.
                            Nunc neque, tristique aliquet velit tempus, at sed
                            tempus. Lectus diam in egestas aliquam aliquam
                            vulputate pulvinar elit quam. Integer orci,
                            imperdiet id nibh neque aliquam. Aliquam
                            pellentesque diam.
                        </p>
                    </TextSection>

                    <TextSection title="Lorem Ipsum" color="dark">
                        <p>
                            Dui duis gravida sed consectetur enim. Iaculis purus
                            a neque odio phasellus habitasse accumsan in magna.
                            Nunc neque, tristique aliquet velit tempus, at sed
                            tempus. Lectus diam in egestas aliquam aliquam
                            vulputate pulvinar elit quam. Integer orci,
                            imperdiet id nibh neque aliquam. Aliquam
                            pellentesque diam. Dui duis gravida sed consectetur
                            enim. Iaculis purus a neque odio phasellus habitasse
                            accumsan in magna. Nunc neque, tristique aliquet
                            velit tempus, at sed tempus. Lectus diam in egestas
                            aliquam aliquam vulputate pulvinar elit quam.
                            Integer orci, imperdiet id nibh neque aliquam.
                            Aliquam pellentesque diam.
                        </p>
                        <p>
                            Dui duis gravida sed consectetur enim. Iaculis purus
                            a neque odio phasellus habitasse accumsan in magna.
                            Nunc neque, tristique aliquet velit tempus, at sed
                            tempus. Lectus diam in egestas aliquam aliquam
                            vulputate pulvinar elit quam. Integer orci,
                            imperdiet id nibh neque aliquam. Aliquam
                            pellentesque diam.
                        </p>
                    </TextSection>
                </div>

                <Footer />
            </main>
        );
    }
}

export default Legal;
