import React from 'react'
import { Button } from 'react-bootstrap'

export default function ShowFaqs(props) {
	const { faqs } = props
	if (!faqs) {
		return (null)
	}
	return (
		<div className="bg-muted pb-2 mt-1">
			<h5>FAQs</h5>
			{faqs.map((faq, index) => {
				return renderFaq(faq.question, faq.answer, props.popFaq ? () => props.popFaq(index) : null)
			})}
		</div>
	)
}

function renderFaq(question, answer, deleteFaq) {
	return (
		<div className="bg-light m-2 border border-secondary p-1">
			<div>
				<span>
					<u>QUESTION</u>:&nbsp; <b>{question}</b>
					{deleteFaq ? <Button className="btn-danger ml-4" onClick={deleteFaq}>Delete FAQ</Button> : null}
				</span>
			</div>
			<div>
				<u>ANSWER</u>: &nbsp; &nbsp; {answer}
			</div>
		</div>
	)
}