import React, { Component } from "react";
import { Redirect, Link} from 'react-router-dom';
import { connect } from 'react-redux';

import { receiveLogin } from "../../store/actions";
import { requestHandler } from '../../utils/requestHandler'

import Logo from '../../assets/images/logo_login.png'


class AdminLogin extends Component {
	
	state = {
		email: "",
		password: "",
		errorMessage:''
	};

	onChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};

	signIn = ( ) => {
		const { email, password } = this.state;
		requestHandler('login', {email: email.toLowerCase(), password, userType: 'admin'})
		.then( result => {
			if(result.status === 200) {
				localStorage.setItem('auth',JSON.stringify(result.data));
				this.props.loginUser(result.data);
				this.setState({ redirect: "/admin/dashboard" });
			} else {
				alert(result.message)
			}
		}, error => {
			console.log("ERROR: ", error)
		})
		return;
	}

	render() {
		const { email, password, errorMessage } = this.state;
		const { isAuthenticated } = this.props;
		
		if (isAuthenticated) {
			return <Redirect to="/admin/dashboard" />;
		}
		return (
			<section id="login">
				<div className="container">
					<div className="row">
						<div className="col-10 col-md-8 col-lg-5">
							<div className="wrapper">
								<div>
									<div className="wrap-img">
										<Link to='/'><img src={Logo} alt="logo"></img></Link>
									</div>
								</div>
								<div className="wrap-form">
									<form onSubmit={this.onSubmit}>
										<div className="form-group">
											<input
												type="email"
												name="email"
												className="input-field form-control"
												placeholder="Email"
												value={email}
												onChange={this.onChange}
											/>
										</div>
										<div className="form-group" >
											<input
												type="password"
												name="password"
												className="input-field form-control"
												placeholder="Password"
												value={password}
												onChange={this.onChange}
											/>
										</div>
										{errorMessage.length?<div><span>{errorMessage}</span></div>:null}
										<button
											type="button"
											className="btn btn-block btn-primary"
											onClick={this.signIn}
										>
											Admin Login
										</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

function mapStateToProps(state) {
	return {
	  isAuthenticated: state.auth.isAuthenticated
	};
  }

const mapDispatchToProps= (dispatch )=>({
	loginUser: user => dispatch(receiveLogin(user))

});

export default connect(mapStateToProps,mapDispatchToProps)(AdminLogin);
  