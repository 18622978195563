import React, { Component } from "react";
import "./ContactPage.css";
import ContactModel from "../../../DataModels/ContactUs";

import Navbar from "../../../components/Sections/Navbar/Navbar";
import Contact from "../../../components/Sections/Contact/Contact";
import Footer from "../../../components/Sections/Footer/Footer";
import FluidCard from "../../../components/layouts/FluidCard/FluidCard";

// Images
import bgDummy3 from "../../../assets/images/bgDummy3.png";
import BgShape from "../../../components/layouts/BgShape/BgShape";
import sweetalert from "sweetalert";

class ContactPage extends Component {
	state = {
		name: "",
		email: "",
		category: "",
		description: "",
		phoneNumber: "",
	};

	inputChangeHandler = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	onSubmitHandler = () => {
		const { name, email, category, description, phoneNumber } = this.state;

		if (name && email && category && description && phoneNumber) {
			ContactModel.createContact({
				name,
				email,
				category,
				description,
				phoneNumber,
			})
				.then((response) => {
					if (response.status === 200) {
						sweetalert("Success", response.message, "success");
					}
				})
				.catch((err) => {
					sweetalert("Error", "Server side error occured.", "error");
				});
		} else {
			sweetalert(
				"Error",
				"Kindly fill out the required fields.",
				"error"
			);
		}
	};

	render() {
		return (
			<main className="ContactPage">
				<BgShape side="right" fromTop={100} />
				<Navbar mode="dark" />
				<Contact
					inputs={this.state}
					inputChangeHandler={this.inputChangeHandler}
					onSubmitHandler={this.onSubmitHandler}
				/>
				<div className="ContactPage-FluidCard">
					<FluidCard
						img={bgDummy3}
						headers={["LOREM IPSUM", "AMET DOLOR"]}
						btnText={"Make a Difference"}
					/>
				</div>
				<Footer />
			</main>
		);
	}
}

export default ContactPage;
