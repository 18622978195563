import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { receiveLogout } from "../../store/actions";
import CreateFaqs from "../../components/CreateFaqs";
import ContentBox from "../../components/layouts/ContentBox/ContentBox";
import Navbar from "../../components/layouts/Navbar";
import Sidebar from "../../components/layouts/Sidebar/Sidebar";
import PlacesAutoCompleteInput from "../../components/UI/Inputs/PlacesAutoCompleteInput";
import { generateDefaultCustomUrlFromTitle } from "../../utils/helpers";
import { requestHandler } from "../../utils/requestHandler";
import { templateStoryCause } from "../../utils/templates";
import CharityDetails from "../Charity/CharityDetails";
import "./CreateCharity.css";

class CreateCampaign extends React.Component {  
	state = {
		redirect: null,
		categories: [],
		title: "",
		goal: "",
		shortDesc: "",
		category: { name: "" },
		location: "",
		lat: null,
		lng: null,
		story: templateStoryCause,
		step: 0,
		customUrl: "",
		faqs: [],
	};

	componentDidMount() {
		requestHandler("getCategories").then((result) => {
			console.log({ result });
			console.log({ result });
			this.setState({
				categories: result.data,
				category: result.data[0] ? result.data[0] : { name: "" },
			});
		});
	}

	setLocatoinAutocomplete = (location, latLng) => {
		this.setState({ location });

		if (latLng) {
			const { lat, lng } = latLng;
			this.setState({ lat, lng });
		}
	};

	handleEditorChange = (story) => {
		this.setState({ story });
	};

	onCategoryChange = (e) => {
		this.setState({
			category: this.state.categories[e.target.value],
		});
	};

	onInputChange = (item) => {
		this.setState({
			[item.target.id]: item.target.value,
		});
		if (item.target.id === "title") {
			this.setState({
				customUrl: generateDefaultCustomUrlFromTitle(item.target.value),
			});
		}
	};

	createCharity = (e) => {
		e.preventDefault();
		const { title, goal, category, location, lat, lng, story, customUrl } =
			this.state;
		if (!title.length || !goal.length || !location.length || !story.length)
			return alert("Please fill all fields");
		if (customUrl.length && customUrl.length < 10)
			return alert("Custom URL should be at least 10 characters");

		let data = {
			title,
			goal: Number(goal).toString(),
			story,
			locationName: location,
			content: [],
			categoryId: category._id,
			creatorId: this.props.user._id,
			customUrl,
			location: [lng, lat],
		};

		requestHandler("createCharity", data).then((result) => {
			console.log("RESULT: ", result);
			if (result.status === 200)
				this.setState({ redirect: "/charity/manage" });
		});
	};

	nextStep = (e) => {
		if (e && e.preventDefault) e.preventDefault();
		this.setState({ step: this.state.step + 1 });
	};

	prevStep = () => {
		this.setState({ step: this.state.step - 1 });
	};

	pushFaq = (question, answer) => {
		this.setState({
			faqs: [...this.state.faqs, { question, answer }],
		});
	};

	popFaq = (index) => {
		const faqs = [...this.state.faqs];
		faqs.splice(index, 1);
		this.setState({ faqs });
	};

	renderDefineCategoryGoalAndTitle = () => {
		// STEP 0
		const { category, categories, location } = this.state;
		return (
			<Fragment>
				<Navbar />
				<Sidebar />
				<ContentBox title="Start your Cause">
					<div className="CharityWrapper">
						<form className="mb-4" onSubmit={this.nextStep}>
							<div className="form-group">
								<div>
									Location
									<br />
									<PlacesAutoCompleteInput
										setLocatoinAutocomplete={
											this.setLocatoinAutocomplete
										}
										location={location}
									/>
								</div>
								<div>
									Goal
									<input
										type="text"
										className="form-control"
										id="goal"
										onChange={this.onInputChange}
										value={this.state.goal}
										required
									/>
								</div>

								<div className="mt-4">
									Title
									<input
										type="text"
										className="form-control"
										id="title"
										onChange={this.onInputChange}
										value={this.state.title}
										required
									/>
								</div>

								<div className="mt-4">
									Short Description
									<textarea
										className="form-control"
										rows="2"
										id="shortDesc"
										onChange={this.onInputChange}
										value={this.state.shortDesc}
										required
									></textarea>
								</div>

								<div className="mt-4">
									Please select a category of your Cause
									<select
										className="form-control"
										id="category"
										onChange={this.onCategoryChange}
									>
										{categories.map((singleCategory, i) => (
											<option
												key={i}
												selected={
													singleCategory._id ===
													category._id
												}
												value={i}
											>
												{singleCategory.name}
											</option>
										))}
									</select>
								</div>
							</div>
							<button type="submit" className="btn btn-primary">
								Continue
							</button>
						</form>
					</div>
				</ContentBox>
			</Fragment>
		);
	};

	renderCreateFaqs = () => {
		// STEP 2
		const { faqs } = this.state;
		return (
			<Fragment>
				<Navbar />
				<Sidebar />
				<ContentBox title="Start your Cause">
					<div className="CharityWrapper">
						<form className="mb-4" onSubmit={this.nextStep}>
							<CreateFaqs
								faqs={faqs}
								popFaq={this.popFaq}
								pushFaq={this.pushFaq}
							/>
							<span>
								<button
									className="btn btn-secondary"
									onClick={this.prevStep}
								>
									Go back
								</button>
								&nbsp;
								<button
									type="submit"
									className="btn btn-primary"
								>
									Continue
								</button>
							</span>
						</form>
					</div>
				</ContentBox>
			</Fragment>
		);
	};

	renderCharityDetails = () => {
		const { title, goal, shortDesc, category, faqs, location, lat, lng } =
			this.state;
		return (
			<CharityDetails
				locationName={location}
				latLng={[lat, lng]}
				charityInCreation={true}
				goal={goal}
				title={title}
				shortDesc={shortDesc}
				categoryId={category._id}
				creatorId={this.props.user._id}
				faqs={faqs}
			/>
		);
	};

	render() {
		const { redirect, step } = this.state;

		if (redirect) return <Redirect to="/charity/manage" />;

		if (step === 0) return this.renderDefineCategoryGoalAndTitle();
		else if (step === 1) return this.renderCreateFaqs();
		else if (step === 2) return this.renderCharityDetails();
		else return null;
	}
}

function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		user: state.auth.user,
	};
}

const mapDispatchToProps = (dispatch) => ({
	logoutUser: () => dispatch(receiveLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCampaign);
