import React from "react";
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
} from "react-share";

import { socialMediaShareMessage } from '../utils/templates'

const SocialMediaShare = ({ title, goal, url: propsUrl }) => {
    const content = socialMediaShareMessage.replace(`{{goal}}`, "$" + goal).replace(`{{title}}`, title)
    const url = propsUrl ? propsUrl : window.location.href.split('?')[0]
    return (
        <div>
            <div>
                <h6>
                    &nbsp;
                    Share on
                </h6>
            </div>
            <span>
                &nbsp;&nbsp;&nbsp;
                <FacebookShareButton
                    url={url}
                    quote={content + '\n' + url} >
                    <FacebookIcon size={32} round={true} />
                </FacebookShareButton>
                &nbsp;
                <TwitterShareButton
                    url={url}
                    title={content} >
                    <TwitterIcon size={32} round={true} />
                </TwitterShareButton>
                &nbsp;
                <WhatsappShareButton
                    url={url}
                    title={content}
                    separator={'\n'}>
                    <WhatsappIcon size={32} round={true} />
                </WhatsappShareButton>
                &nbsp;
                <EmailShareButton
                    url={url}
                    subject={'Please support ' + title}
                    body={content} >
                    <EmailIcon size={32} round={true} />
                </EmailShareButton>
                &nbsp;
                <LinkedinShareButton
                    url={url}
                    title={title}
                    summary={content} >
                    <LinkedinIcon size={32} round={true} />
                </LinkedinShareButton>
            </span>
        </div>
    )
}

export default SocialMediaShare