// import configureStore from "../configureStore";
import { requestHandler } from "../utils/requestHandler";

// const store = configureStore();

class ChatMessage {
	constructor(_id, conversationId, senderId, content, type, createdAt) {
		this._id = _id
		this.conversationId = conversationId
		this.senderId = senderId
		this.content = content
		this.type = type
		this.createdAt = createdAt
	}

	static async FetchChatMessages(conversationId) {
		const response = await requestHandler('getChat', { conversationId })
		const messages = []
		if (response.status === 200 && response.data && response.data.length) {
			response.data.forEach(singleMessageData => {
				messages.push(new ChatMessage(
					singleMessageData._id,
					singleMessageData.conversationId,
					singleMessageData.senderId,
					singleMessageData.content,
					singleMessageData.type,
					singleMessageData.createdAt,
				))

			})
		}
		return messages
	}

	async postMessage() {

		const response = await requestHandler('postChat', {
			conversationId: this.conversationId,
			content: this.content,
			type: this.type,
		})
		console.log("Chat pushed: ", response)
		if (response.status === 200) {
			this._id = response.data._id
			this.conversationId = response.data.conversationId
			this.senderId = response.data.senderId
			this.content = response.data.content
			this.type = response.data.type
			this.createdAt = response.data.createdAt
			return this
		} else throw new Error("Message sending failed")
	}
}

export default ChatMessage;
