import React from "react";

import { requestHandler } from "../utils/requestHandler";
import CreateComment from "../components/CreateComment";
import { createDateAndTimeFromISO } from "../utils/helpers";
import CampaignCommentReplies from "./CampaignCommentReplies";

class CampaignComments extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			comments: [],
		};
	}

	componentDidMount() {
		this.loadData();
		// this.loadDummyComments()
	}

	loadDummyComments = () => {
		let comment = {
			userId: "dummyuserid",
			campaignId: "dummycampaignid",
			message: "THE DUMMY COMMENT IS HERE",
			timestamp: new Date().getTime(),
			likes: 500,
			dislikes: 29,
			replies: [],
		};

		this.setState({
			comments: [comment, comment, comment],
		});
	};

	loadData = () => {
		this.loadComments();
	};

	loadComments = () => {
		const { campaignId } = this.props;
		if (!campaignId) return;
		requestHandler("getCampaignComments", { campaignId }).then(
			(response) => {
				if (response.status === 200) {
					this.setState({ comments: response.data });
				}
			},
		);
	};

	renderSingleComment = ({
		createdAt,
		dislikes,
		likes,
		message,
		replies,
		user,
		showReplyBox,
		_id,
	}) => (
			<div
				className='bg-light'
				style={{ margin: "10px", padding: "10px" }}
				key={_id}>
				<div className='d-flex justify-content-between'>
					<div>
						<i>From:</i> <b>{user.firstName + " " + user.lastName}</b>
					</div>
					<div>
						<i>Time:</i>{" "}
						{createDateAndTimeFromISO(createdAt, true, true, false)}
					</div>
				</div>
				<br />
				{message}
				{/* <i>Likes:</i> <b>{likes}</b> <i>Dislikes:</i> <b>{dislikes}</b> */}
			</div>
		);

	renderComments = () => {
		const { comments } = this.state;
		return (
			<div>
				{comments.map((comment) => (
					<div>
						{this.renderSingleComment(comment)}
						<CampaignCommentReplies
							comments={comment.replies}
							commentId={comment._id}
							reloadComments={this.loadComments}
						/>
					</div>
				))}
			</div>
		);
	};

	render() {
		const { campaignId } = this.props;
		return (
			<div>
				<div>
					<CreateComment
						campaignId={campaignId}
						reloadComments={this.loadComments}
					/>
				</div>
				{this.renderComments()}
			</div>
		);
	}
}

export default CampaignComments;
