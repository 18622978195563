import React, { Component, Fragment } from "react";
import Navbar from "../../components/layouts/Navbar";
import AdminSidebar from "../../components/layouts/AdminSidebar/AdminSidebar";
import ContentBox from "../../components/layouts/ContentBox/ContentBox";
import { requestHandler } from "../../utils/requestHandler";
import { Modal, Button } from "react-bootstrap";

class AdminKycRequests extends Component {
	state = {
		show: false,
		records: [],
		selectedRecord: {},
		complianceScore: "",
		message: "",
	};

	componentDidMount() {
		this.getKycRequests();
	}

	componentWillReceiveProps(props) {
		if (props.match.params.status !== this.props.match.params.status)
			this.setState({ users: [] }, this.getKycRequests)
	}

	handleClose = () => {
		this.setState({ show: false });
	};

	onInputChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	getKycRequests = () => {
		let { status } = this.props.match.params
		if (!status || (status !== 'approved' && status !== 'pending' && status !== 'rejected')) {
			status = 'all'
		}
		requestHandler("getKycRequests", status === 'all' ? {} : { status })
			.then((result) => {
				console.log(result);
				if (result.status === 200) {
					this.setState({ records: result.data, lastquery: status });
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	onViewClick = (record) => {
		this.setState({ selectedRecord: record, show: true });
	};

	renderSelectedKyc = () => {
		const { selectedRecord } = this.state;

		if (Object.keys(selectedRecord).length === 0) {
			return null;
		}

		return (
			<div>
				<div class="form-group">
					<label for="exampleInputEmail1">Name</label>
					<input
						type="text"
						class="form-control"
						id="exampleInputEmail1"
						aria-describedby="emailHelp"
						placeholder="Enter name"
						name="name"
						value={selectedRecord.name}
						disabled={true}
					/>
				</div>
				<div class="form-group">
					<label for="exampleInputEmail1">Personal Email</label>
					<input
						type="email"
						class="form-control"
						id="exampleInputEmail1"
						aria-describedby="emailHelp"
						placeholder="Enter email"
						name="email"
						value={selectedRecord.email}
						disabled={true}
					/>
				</div>
				<div class="form-group">
					<label for="exampleInputEmail1">Phone Number</label>
					<input
						type="number"
						class="form-control"
						id="exampleInputEmail1"
						aria-describedby="emailHelp"
						placeholder="Enter phone number"
						name="phone"
						value={selectedRecord.phone}
						disabled={true}
					/>
				</div>
				<div class="form-group">
					<label for="exampleInputEmail1">Date of Birth</label>
					<input
						type="text"
						class="form-control"
						id="exampleInputEmail1"
						aria-describedby="emailHelp"
						name="DOB"
						value={selectedRecord.DOB.split("T")[0]}
						disabled={true}
					/>
				</div>
				{/* <div class="form-group">
					<label for="exampleInputEmail1">
						Social Media Accounts
					</label>
					<input
						type="text"
						class="form-control"
						id="exampleInputEmail1"
						aria-describedby="emailHelp"
						placeholder="Enter account URL"
						name="socialMediaAccounts"
						value={selectedRecord.socialMediaAccounts}
						disabled={true}
					/>
				</div> */}
				<div class="form-group">
					<label for="exampleInputEmail1">Bank Account Details</label>
					<input
						type="text"
						class="form-control"
						id="exampleInputEmail1"
						aria-describedby="emailHelp"
						placeholder="Enter bank account details"
						name="bankAccountDetails"
						value={selectedRecord.bankAccountDetails.accountNo}
						disabled={true}
					/>
				</div>
				<div class="form-group">
					<label for="exampleInputEmail1">Home Address</label>
					<input
						type="text"
						class="form-control"
						id="exampleInputEmail1"
						aria-describedby="emailHelp"
						placeholder="Enter home address"
						name="address"
						value={selectedRecord.address.addressLine1}
						disabled={true}
					/>
				</div>
				<div class="form-group">
					<label for="exampleInputEmail1">Profession</label>
					<input
						type="text"
						class="form-control"
						id="exampleInputEmail1"
						aria-describedby="emailHelp"
						placeholder="Enter profession"
						name="profession"
						value={selectedRecord.profession}
						disabled={true}
					/>
				</div>
				<div class="form-group">
					<label for="exampleInputEmail1">Work Email</label>
					<input
						type="email"
						class="form-control"
						id="exampleInputEmail1"
						aria-describedby="emailHelp"
						placeholder="Enter work email"
						name="workEmail"
						value={selectedRecord.workEmail}
						disabled={true}
					/>
				</div>
				<div class="form-group">
					<label for="exampleInputEmail1">Employer Name</label>
					<input
						type="text"
						class="form-control"
						id="exampleInputEmail1"
						aria-describedby="emailHelp"
						placeholder="Enter employer name"
						name="employer"
						value={selectedRecord.employer}
						disabled={true}
					/>
				</div>
				<div class="form-group">
					<label for="exampleInputEmail1">Government ID</label>
					<input
						type="text"
						class="form-control"
						id="exampleInputEmail1"
						aria-describedby="emailHelp"
						placeholder="Enter Government ID"
						name="governmentID"
						value={selectedRecord.governmentID}
						disabled={true}
					/>
				</div>
				<div className="my-4">
					<hr />
				</div>
				<div class="form-group">
					<label for="exampleInputEmail1">Compliance Score</label>
					<input
						disabled={selectedRecord.status === 'pending' ? false : true}
						type="number"
						class="form-control"
						id="exampleInputEmail1"
						aria-describedby="emailHelp"
						placeholder="Compliance Score"
						name="complianceScore"
						value={this.state.complianceScore}
						onChange={this.onInputChange}
					/>
				</div>
				<div class="form-group">
					<label for="exampleInputEmail1">Message</label>
					<input
						disabled={selectedRecord.status === 'pending' ? false : true}
						type="text"
						class="form-control"
						id="exampleInputEmail1"
						aria-describedby="emailHelp"
						placeholder="Message"
						name="message"
						value={this.state.message}
						onChange={this.onInputChange}
					/>
				</div>
			</div>
		);
	};

	renderViewModel = () => {
		return (
			<Modal show={this.state.show} onHide={this.handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Campaign Update Content</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="m-3">{this.renderSelectedKyc()}</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="danger"
						onClick={() => this.kycSubmitHandler("rejected")}
					>
						Reject
					</Button>
					<Button
						variant="primary"
						onClick={() => this.kycSubmitHandler("approved")}
					>
						Accept
					</Button>
				</Modal.Footer>
			</Modal>
		);
	};

	kycSubmitHandler = (status) => {
		const { complianceScore, message, selectedRecord } = this.state;

		if (complianceScore && message) {
			requestHandler(
				"kycStatusChange",
				{
					status,
					complianceScore,
					message,
				},
				selectedRecord._id
			)
				.then((result) => {
					if (result.status === 200) {
						this.handleClose();
						this.getKycRequests();
						alert(`KYC has been successfully ${status}`);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			alert("Compliance Score and Message are required.");
		}
	};

	render() {
		let { status } = this.props.match.params
		if (!status || (status !== 'approved' && status !== 'pending' && status !== 'rejected')) {
			status = 'all'
		}
		return (
			<Fragment>
				<Navbar />
				<AdminSidebar />
				<ContentBox title={`${status[0].toUpperCase() + status.slice(1)} KYC Requests`}>
					<div className="m-5">
						<table className="table">
							<thead>
								<tr>
									<td>Name</td>
									<td>Email</td>
									<td>Kyc Status</td>
									<td>Actions</td>
								</tr>
							</thead>
							<tbody>
								{this.state.records.map((record) => (
									<tr key={record._id}>
										<td>{record.name}</td>
										<td>{record.email}</td>
										<td>{record.status}</td>
										<td>
											<button
												className="btn btn-primary"
												onClick={() =>
													this.onViewClick(record)
												}
											>
												View Request
											</button>
										</td>
									</tr>
								))}
							</tbody>
						</table>
						{this.renderViewModel()}
					</div>
				</ContentBox>
			</Fragment>
		);
	}
}

export default AdminKycRequests;
