import { LOCALES } from "../locales";

export default {
    [LOCALES.ENGLISH]: {
        "home": "Home",
        "packages": "Packages",
        "portfolio": "Portfolio",
        "events": "Events",
        "blogs": "Blogs",
        "language": "Language",
        "login": "Login",
        "caps-investors": "INVESTORS",
        "caps-million_invested": "MILLION INVESTED",
        "caps-million_profits": "MILLION PROFITS",
        "caps-frames_impacted": "FRAMES IMPACTED",
        "caps-as_seen_on": "AS SEEN ON",
        "become_a_hero": "Become a Hero",
        "become_an_angel": "Become an Angel",
        "how_it_works": "How it works",
        "for": "for",
        "caps-heroes": "HEROES",
        "caps-angels": "ANGELS",
    },
};
