import React, { Component } from "react";
import { connect } from 'react-redux';
import { receiveLogin } from "../../store/actions";

import './social-login-buttons.css'
import User from "../../DataModels/User";
import { Redirect } from "react-router-dom";

class VarifyEmail extends Component {

	constructor(props) {
		super(props)
		this.state = {
			message: "Loading...",
			redirect: null,
		};
		console.log("hERE")
	}

	componentDidMount() {
		this.varifyEmail()
	}

	varifyEmail = () => {
		const { token } = this.props.match.params
		User.varifyEmail(token)
			.then(response => {
				if (!response.message) {
					response.message = "Something went wrong"
				}
				this.redirect(response.message)
			}, err => {
				console.log(err)
				this.redirect("Something went wrong")
			})
	}

	redirect = (message) => {
		this.setState({ message, redirecting: true })
		setTimeout(() => this.setState({ redirect: "/" }), 5000);
	}

	render() {
		const { message, redirect, redirecting } = this.state;
		console.log({ message })
		if (redirect) return (<Redirect to={redirect} />)
		return (
			<section id="login">
				<div className="container">
					<b>{message}</b><br />
					<i>{redirecting ? "You will be redirected to home page" : null}</i>

				</div>
			</section>
		);
	}
}

function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated
	};
}

const mapDispatchToProps = (dispatch) => ({
	loginUser: user => dispatch(receiveLogin(user))

});

export default connect(mapStateToProps, mapDispatchToProps)(VarifyEmail);
