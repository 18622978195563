import React, { Fragment } from "react";
import { connect } from 'react-redux';

import { receiveLogout } from "../../store/actions";

import Navbar from '../../components/layouts/Navbar'
import AdminSidebar from "../../components/layouts/AdminSidebar/AdminSidebar";
import ContentBox from "../../components/layouts/ContentBox/ContentBox";

import { Redirect } from "react-router-dom";


class Navigation extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: { },
            redirect: null,
            message:''
        }
    }

    componentDidMount() {

    }

    render() {  
        const { redirect } = this.state
        if(redirect) return ( <Redirect to={redirect} />)
        return (
            <Fragment>
                <Navbar />
				<AdminSidebar />
                <ContentBox title="Welcome to Admin Dashboard"> </ContentBox>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        user: state.auth.user
    };
}

const mapDispatchToProps = (dispatch) => ({
    logoutUser: () => dispatch(receiveLogout())

});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
