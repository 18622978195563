import React from "react";
import { Button, Modal } from "react-bootstrap";
import LabelledInput from "../../UI/Inputs/LabelledInput/LabelledInput";
import { requestHandler } from "../../../utils/requestHandler";

class AddItem extends React.Component {
    state = {
        title: "",
        retailPrice: "",
        customizations: [],
        inCreationCustomization: {
            description: "",
            availabilityValue: "",
            availabilityList: [],
        },
    };

    resetState = () => {
        this.setState({
            title: "",
            retailPrice: "",
            customizations: [],
            inCreationCustomization: {
                description: "",
                availabilityValue: "",
                availabilityList: [],
            },
        });
    };

    onInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    customizationChangeHandler = (name, value) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                inCreationCustomization: {
                    ...prevState.inCreationCustomization,
                    [name]: value,
                },
            };
        });
    };

    addAvailabilityHandler = () => {
        const { availabilityValue } = this.state.inCreationCustomization;
        const value = availabilityValue;

        if (availabilityValue.trim()) {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    inCreationCustomization: {
                        ...prevState.inCreationCustomization,
                        availabilityValue: "",
                        availabilityList: [
                            ...prevState.inCreationCustomization
                                .availabilityList,
                            value,
                        ],
                    },
                };
            });
        } else {
            alert("Empty Value Error");
        }
    };

    addCustomizationHandler = () => {
        const {
            description,
            availabilityList,
        } = this.state.inCreationCustomization;

        if (description.trim() && availabilityList) {
            const newCustomization = {
                description,
                availability: availabilityList,
            };

            this.setState((prevState) => {
                return {
                    ...prevState,
                    customizations: [
                        ...prevState.customizations,
                        newCustomization,
                    ],
                    inCreationCustomization: {
                        description: "",
                        availabilityValue: "",
                        availabilityList: [],
                    },
                };
            });
        } else {
            alert("Empty Value Error");
        }
    };

    renderAddCustomizations = () => {
        const {
            description,
            availabilityValue,
            availabilityList,
        } = this.state.inCreationCustomization;

        return (
            <div
                style={{
                    border: "1px solid #ccc",
                    margin: "15px 0px",
                    padding: "10px",
                }}
            >
                <br />
                <h6>
                    <b>Add Customizations</b>
                </h6>
                <LabelledInput
                    label="Description"
                    input={{
                        name: "description",
                        value: description,
                        onChange: (e) =>
                            this.customizationChangeHandler(
                                e.target.name,
                                e.target.value
                            ),
                    }}
                />
                <div
                    style={{
                        marginTop: "10px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <LabelledInput
                        label="Availability"
                        input={{
                            name: "availabilityValue",
                            value: availabilityValue,
                            onChange: (e) =>
                                this.customizationChangeHandler(
                                    e.target.name,
                                    e.target.value
                                ),
                        }}
                    />
                    <Button
                        onClick={this.addAvailabilityHandler}
                        style={{ height: "40px", marginTop: "12px" }}
                    >
                        Add
                    </Button>
                </div>

                <ul style={{ margin: "5px 10px" }}>
                    {availabilityList.map((value) => (
                        <li>{value}</li>
                    ))}
                </ul>

                <Button onClick={this.addCustomizationHandler}>
                    Add Customization
                </Button>
            </div>
        );
    };

    renderCustomizations = () => {
        const { customizations } = this.state;
        return (
            <div
                style={{
                    border: "1px solid #ccc",
                    margin: "15px 0px",
                    padding: "10px",
                }}
            >
                <br />
                <h6>
                    <b>Customizations</b>
                </h6>
                {customizations ? (
                    <ul style={{ margin: "5px 10px" }}>
                        {customizations.map((obj) => (
                            <li>
                                <p>
                                    <b>Description:</b> {obj.description}
                                </p>
                                <p>
                                    <b>Availability:</b>{" "}
                                    {obj.availability.join(" - ")}
                                </p>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className="text-danger">No customizations created.</p>
                )}
            </div>
        );
    };

    addItem = () => {
        const { enterpriseId } = this.props;
        const { title, retailPrice, customizations } = this.state;

        if (title.trim() && retailPrice.trim() && customizations) {
            requestHandler("addEnterpriseItem", {
                enterpriseId,
                title,
                retailPrice,
                customizations,
            })
                .then((result) => {
					if (result.status === 200) {
						this.resetState();
						this.props.toggleModal();
						this.props.reloadItems();
					}
                })
                .catch((err) => {
                    console.log(err);
                    alert(err);
                });
        } else {
            alert("Empty value error.");
        }
    };

    render() {
        const { title, retailPrice } = this.state;

        return (
            <Modal
                show={this.props.showModal}
                onHide={this.props.toggleModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>Add New Item</Modal.Header>
                <Modal.Body>
                    <LabelledInput
                        label="Title"
                        input={{
                            name: "title",
                            value: title,
                            onChange: this.onInputChange,
                        }}
                    />
                    <LabelledInput
                        label="Retail Price"
                        input={{
                            name: "retailPrice",
                            value: retailPrice,
                            onChange: this.onInputChange,
                        }}
                    />
                    {this.renderAddCustomizations()}
                    {this.renderCustomizations()}
                    <div className="mt-4">
                        <Button onClick={this.addItem}>Add Item</Button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default AddItem;
