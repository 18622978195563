import React, { Fragment } from 'react'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { receiveLogout } from '../../store/actions'
import AdminSidebar from '../../components/layouts/AdminSidebar/AdminSidebar'
import ContentBox from '../../components/layouts/ContentBox/ContentBox'
import Navbar from '../../components/layouts/Navbar'
import AdminContactUsRespondBack from '../../components/Modals/AdminContactUsRespondBack'
import ContactUsDataModal from '../../DataModels/ContactUs'

class AdminContactUsList extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			contactUs: [],
			selectedRequest: null,
		}
	}

	componentDidMount() {
		this.reloadData()
	}

	reloadData = async () => {
		const contactUs = (await ContactUsDataModal.getRequests()) || []
		this.setState({ contactUs })
	}

	renderTableBody = (contactUs) => {
		return (
			<tbody>
				{contactUs.map((con) => (
					<tr>
						<td>{con.name}</td>
						<td>{con.email}</td>
						<td>{con.category}</td>
						<td>{con.description}</td>
						<td>
							<Button onClick={() => this.setState({ selectedRequest: con })}>Respond Back</Button>
						</td>
						{/* 
						<td>{this.renderChangeUserStatusButton(con)}</td>
                        */}
					</tr>
				))}
			</tbody>
		)
	}

	render() {
		const { redirect, contactUs, selectedRequest } = this.state
		if (redirect) return <Redirect to={redirect} />
		return (
			<Fragment>
				<AdminContactUsRespondBack
					contactUsRequest={selectedRequest}
					onHide={() => this.setState({ selectedRequest: null })}
					show={selectedRequest}
				/>
				<Navbar />
				<AdminSidebar />
				<ContentBox title='Contact Us Requests'>
					<table className='table'>
						<thead>
							<tr>
								<td>From (Name)</td>
								<td>Email</td>
								<td>Category</td>
								<td>Description</td>
							</tr>
						</thead>
						{this.renderTableBody(contactUs)}
					</table>
				</ContentBox>
			</Fragment>
		)
	}
}

function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		user: state.auth.user,
	}
}

const mapDispatchToProps = (dispatch) => ({
	logoutUser: () => dispatch(receiveLogout()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminContactUsList)
