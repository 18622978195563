import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { receiveLogin, receiveLogout } from "../../store/actions";
import CustomInput from "../../components/CustomInput";
import CustomLabel from "../../components/CustomLabel";
import ButtonSuccess from "../../components/layouts/ButtonSuccess";
import Navbar from "../../components/layouts/Navbar";
import Sidebar from "../../components/layouts/Sidebar/Sidebar";
import PlacesAutoCompleteInput from "../../components/UI/Inputs/PlacesAutoCompleteInput";
import UpdateAvatarLink from "../../components/UpdateAvatarLink";
import { requestHandler } from "../../utils/requestHandler";
import "./settings.css";

class Settings extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			redirectTo: null,
			toRender: "default",
			firstName: "",
			lastName: "",
			phone: "",
			address: "",
			city: "",
			state: "",
			country: "",
			postalCode: "",
			acceptPromotionalEmails: false,
			lat: "",
			lng: "",
			location: "",
			password: "",
			confirmPassword: "",
			makeMyLocationPublic: false,
		};
	}

	onChange = (e) => {
		this.setState({ [e.target.id]: e.target.value });
	};

	componentDidMount() {
		const { view } = this.props.match.params;
		this.setState({ toRender: view ? view : "default" });
		if (view === "personalInfoUpdate") this.setPersonalDataInState();
		// this.fetchUserDetails()
	}

	setToRender = (toRender) => {
		if (toRender === "personalInfoUpdate") this.setPersonalDataInState();
		this.setState({
			toRender,
		});
	};

	setPersonalDataInState = () => {
		let data = {};
		let user = JSON.parse(localStorage.getItem("auth"));
		console.log(user);
		data.firstName = user.firstName ? user.firstName : "";
		data.lastName = user.lastName ? user.lastName : "";
		data.acceptPromotionalEmails = user.promotionalEmails
			? user.promotionalEmails
			: false;
		data.phone = user.phone ? user.phone : "";
		data.makeMyLocationPublic = user.publicLocation;
		if (user.address) {
			console.log("Address found");
			data.address = user.address.addressLine1
				? user.address.addressLine1
				: "";
			data.city = user.address.city ? user.address.city : "";
			data.state = user.address.state ? user.address.state : "";
			data.country = user.address.country ? user.address.country : "";
			data.postalCode = user.address.postalCode
				? user.address.postalCode
				: "";
			data.location = user.address.location;
			data.lat = user.address.lat;
			data.lng = user.address.lng;
		} else {
			console.log("Address not found");
			data.address = "";
			data.city = "";
			data.state = "";
			data.country = "";
			data.postalCode = "";
		}
		window.data = data;
		window.user = user;
		console.log({ data, user });
		this.setState({ ...data });
	};

	fetchUserDetails = async () => {
		let result = await requestHandler("getUser", {
			id: this.props.user._id,
		});
		let auth = JSON.parse(await localStorage.getItem("auth"));
		let userNewData = { ...auth, ...result.data[0] };
		await localStorage.setItem("auth", JSON.stringify(userNewData));
		this.props.updateUser(userNewData);
		this.setPersonalDataInState();
		// window.location.reload(false)
	};

	updateProfile = () => {
		let scope = this;
		const {
			firstName,
			lastName,
			address,
			city,
			state,
			country,
			postalCode,
			location,
			lat,
			lng,
			phone,
			acceptPromotionalEmails,
			makeMyLocationPublic,
		} = this.state;
		if (!firstName.length) return alert("Please fill the first name field");
		if (!lastName.length) return alert("Please fill the last name field");
		let dataToUpdate = {
			firstName,
			lastName,
			phone,
			address: {
				addressLine1: address,
				city,
				state,
				country,
				postalCode,
				location,
				lat,
				lng,
			},
			promotionalEmails: acceptPromotionalEmails,
			publicLocation: makeMyLocationPublic,
		};
		console.log(dataToUpdate);
		// call api
		requestHandler("updateProfile", dataToUpdate).then(
			(result) => {
				console.log("HERE result: ", result);
				if (result.status === 200) {
					scope.setState({ toRender: "default" });
					alert("Personal information updated");
					scope.fetchUserDetails();
				} else {
					scope.setState({ toRender: "default" });
					return alert("Something went wrong");
				}
			},
			(error) => {
				console.log("HERE: ", error);
			}
		);
	};

	updatePassword = () => {
		const { oldPassword, newPassword, confirmPassword } = this.state;

		if (
			!oldPassword.length ||
			!newPassword.length ||
			!confirmPassword.length
		) {
			return alert("Please fill all fields");
		}
		if (newPassword !== confirmPassword)
			return alert("Passwords do not match");
		// call api
		requestHandler("updatePassword", {
			oldPassword,
			password: newPassword,
		}).then((result) => {
			if (result.status === 200) {
				alert("Password updated");
				this.setToRender("default");
			}
			if (result.status === 401) alert("Old password is incorrect");
		});
	};

	renderDefault = () => {
		return (
			<div>
				<UpdateAvatarLink />
				<br /> <br />
				<Link
					to="/settings/personalInfoUpdate"
					onClick={() => this.setToRender("personalInfoUpdate")}
				>
					Edit Personal Information
				</Link>{" "}
				<br /> <br />
				<Link
					to="/settings/passwordUpdate"
					onClick={() => this.setToRender("passwordUpdate")}
				>
					Change Passowrd
				</Link>{" "}
				<br /> <br />
				<Link
					to="/settings/paymentMethodUpdate"
					onClick={() => this.setToRender("paymentMethodUpdate")}
				>
					Add/Change Payment Method
				</Link>{" "}
				<br />
				<br />
				<Link to="/my-donations?viewrefund=true">
					Make Refund Request
				</Link>
				<br /> <br />
				<Link to="/kyc-support">KYC Support</Link>
				<br /> <br />
				<Link to="/transfers">Transfer Requests</Link>
			</div>
		);
	};

	setLocatoinAutocomplete = (location, latLng) => {
		this.setState({ location });
		if (latLng) {
			const { lat, lng } = latLng;
			console.log({ location, latLng });
			this.setState({ lat, lng });
		}
	};

	renderPersonalInfoUpdate = () => {
		const {
			firstName,
			lastName,
			phone,
			address,
			city,
			state,
			country,
			postalCode,
			location,
			acceptPromotionalEmails,
			makeMyLocationPublic,
		} = this.state;
		console.log({ makeMyLocationPublic });
		return (
			<div className="options-list">
				<div className="wrap wrap-basic">
					<div className="content">
						<h2 className="title">Personal Information</h2>
						<br />
					</div>
					<div className="form-group">
						<CustomLabel text="First Name *" />
						<CustomInput
							id="firstName"
							placeholder=" first name"
							onChange={this.onChange}
							value={firstName}
						/>
					</div>
					<div className="form-group">
						<CustomLabel text="Last Name *" />
						<CustomInput
							id="lastName"
							placeholder=" last name"
							onChange={this.onChange}
							value={lastName}
						/>
					</div>
					<div className="form-group">
						<CustomLabel text="Phone Number " />
						<CustomInput
							id="phone"
							placeholder=" phone number"
							onChange={this.onChange}
							value={phone}
						/>
					</div>
					<div className="form-group">
						<CustomLabel text="Set Location for Maps" />
						<div>
							<PlacesAutoCompleteInput
								setLocatoinAutocomplete={
									this.setLocatoinAutocomplete
								}
								location={location}
							/>
						</div>
					</div>
					<div className="form-group">
						<CustomLabel text="Address " />
						<CustomInput
							id="address"
							placeholder=" address"
							onChange={this.onChange}
							value={address}
						/>
					</div>
					<div className="form-group">
						<CustomLabel text="City " />
						<CustomInput
							id="city"
							placeholder=" city"
							onChange={this.onChange}
							value={city}
						/>
					</div>
					<div className="form-group">
						<CustomLabel text="State " />
						<CustomInput
							id="state"
							placeholder=" state"
							onChange={this.onChange}
							value={state}
						/>
					</div>
					<div className="form-group">
						<CustomLabel text="Country " />
						<CustomInput
							id="country"
							placeholder=" country"
							onChange={this.onChange}
							value={country}
						/>
					</div>
					<div className="form-group">
						<CustomLabel text="Postal code " />
						<CustomInput
							id="postalCode"
							placeholder=" postal code"
							onChange={this.onChange}
							value={postalCode}
						/>
					</div>
					<div>
						<input
							type="checkbox"
							onChange={() => {}}
							checked={makeMyLocationPublic}
							onClick={() =>
								this.setState({
									makeMyLocationPublic: !makeMyLocationPublic,
								})
							}
						/>
						&nbsp;&nbsp;
						<label>
							Make Location public, for Heroes and Angels to find
							you based on Location
						</label>
					</div>
				</div>
				<div>
					<input
						type="checkbox"
						onChange={() => {}}
						checked={acceptPromotionalEmails}
						onClick={() =>
							this.setState({
								acceptPromotionalEmails:
									!acceptPromotionalEmails,
							})
						}
					/>
					&nbsp;&nbsp;
					<label>I want to receive newsletters via email</label>
				</div>
				<br />
				<div className="d-flex">
					<ButtonSuccess
						text="Update Profile"
						onClick={this.updateProfile}
					/>
				</div>
				<br />
				<Link
					to="/settings/default"
					onClick={() => this.setToRender("default")}
				>
					{"< Go Back"}
				</Link>
			</div>
		);
	};

	renderPasswordUpdate = () => {
		const { oldPassword, newPassword, confirmPassword } = this.state;
		return (
			<div className="options-list">
				<div className="wrap wrap-basic">
					<div className="content">
						<h2 className="title">Update Password</h2>
						<br />
					</div>
					<div className="form-group">
						<CustomLabel text="Old Password *" />
						<CustomInput
							id="oldPassword"
							type="password"
							placeholder=" old password"
							onChange={this.onChange}
							value={oldPassword}
						/>
					</div>
					<div className="form-group">
						<CustomLabel text="Password *" />
						<CustomInput
							id="newPassword"
							type="password"
							placeholder=" new password"
							onChange={this.onChange}
							value={newPassword}
						/>
					</div>
					<div className="form-group">
						<CustomLabel text="Confirm Password *" />
						<CustomInput
							id="confirmPassword"
							type="password"
							placeholder=" re-enter new password"
							onChange={this.onChange}
							value={confirmPassword}
						/>
					</div>
				</div>
				<br />
				<div className="d-flex">
					<ButtonSuccess
						text="Update Password"
						onClick={this.updatePassword}
					/>
				</div>
				<br />
				<Link
					to="/settings/default"
					onClick={() => this.setToRender("default")}
				>
					{"< Go Back"}
				</Link>
			</div>
		);
	};

	renderPaymentMethodUpdate = () => {
		return (
			<div className="options-list">
				<div className="wrap wrap-basic">
					<div className="content">
						<h2 className="title">Update Payment Method</h2>
						<br />
						<h5>To be implemented</h5>
					</div>
					<div className="form-group"></div>
				</div>
				<br />
				<Link
					to="/settings/default"
					onClick={() => this.setToRender("default")}
				>
					{"< Go Back"}
				</Link>
			</div>
		);
	};

	render() {
		const { toRender, redirectTo } = this.state;
		if (redirectTo) return <Redirect to={redirectTo} />;

		return (
			<div>
				<Fragment>
					<Navbar />
					<Sidebar />
				</Fragment>
				<div className="main-div">
					<h1>Settings</h1>
					<section id="register">
						<div className="container">
							<div className="row">
								<div className="mx-auto">
									<div className="wrapper">
										{toRender === "default"
											? this.renderDefault()
											: null}
										{toRender === "personalInfoUpdate"
											? this.renderPersonalInfoUpdate()
											: null}
										{toRender === "passwordUpdate"
											? this.renderPasswordUpdate()
											: null}
										{toRender === "paymentMethodUpdate"
											? this.renderPaymentMethodUpdate()
											: null}
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		user: state.auth.user,
	};
}

const mapDispatchToProps = (dispatch) => ({
	updateUser: (user) => dispatch(receiveLogin(user)),
	logoutUser: () => dispatch(receiveLogout()),
	updateReduxUser: () => dispatch(receiveLogin()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
