import {
	faCubes,
	faLightbulb,
	faTachometerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import "./AdminSidebar.css";

const AdminSidebar = (props) => {
	const campaignDropdown = useRef(null);
	const userDropdown = useRef(null);
	const kycDropdown = useRef(null);

	const dropdownToggleHandler = (dropdownItem) => {
		const classes = ["navbar-list", "navbar-dropdown-list"];
		if (dropdownItem.current.childNodes[1].classList[2] === "hide") {
			classes.push("show");
			dropdownItem.current.childNodes[1].className = classes.join(" ");
		} else {
			classes.push("hide");
			dropdownItem.current.childNodes[1].className = classes.join(" ");
		}
	};

	return (
		<section id="Sidebar">
			<div className="wrapper">
				<ul className="navbar-list">
					<li className="navbar-item">
						<span>
							<FontAwesomeIcon icon={faTachometerAlt} />
							<Link to="/admin/dashboard" className="navbar-link">
								Dashboard
							</Link>
						</span>
					</li>
					<li
						className="navbar-dropdown"
						ref={userDropdown}
						onClick={() => dropdownToggleHandler(userDropdown)}
					>
						<span>
							<FontAwesomeIcon icon={faCubes} />
							<Link className="navbar-link">Users</Link>
						</span>
						<ul className="navbar-list navbar-dropdown-list hide">
							<li className="navbar-item">
								<span>
									<Link
										to="/admin/users"
										className="navbar-link"
									>
										All Users
									</Link>
								</span>
							</li>
							{/* <li className="navbar-item">
                                <span>
                                    <Link to="/admin/users/pending" className="navbar-link">Pending Approval</Link>
                                </span>
                            </li> */}
							<li className="navbar-item">
								<span>
									<Link
										to="/admin/users/inactive"
										className="navbar-link"
									>
										Inactive Users
									</Link>
								</span>
							</li>
						</ul>
					</li>
					<li
						className="navbar-dropdown"
						ref={campaignDropdown}
						onClick={() => dropdownToggleHandler(campaignDropdown)}
					>
						<span>
							<FontAwesomeIcon icon={faLightbulb} />
							<Link className="navbar-link">Causes</Link>
						</span>
						<ul className="navbar-list navbar-dropdown-list hide">
							<li className="navbar-item">
								<span>
									<Link
										to="/admin/causes"
										className="navbar-link"
									>
										All Causes
									</Link>
								</span>
							</li>
							<li className="navbar-item">
								<span>
									<Link
										to="/admin/causes/active"
										className="navbar-link"
									>
										Active
									</Link>
								</span>
							</li>
							<li className="navbar-item">
								<span>
									<Link
										to="/admin/causes/pending"
										className="navbar-link"
									>
										Pending Approval{" "}
									</Link>
								</span>
							</li>
							<li className="navbar-item">
								<span>
									<Link
										to="/admin/causes/rejected"
										className="navbar-link"
									>
										Rejected{" "}
									</Link>
								</span>
							</li>
						</ul>
					</li>
					<li className="navbar-item">
						<span>
							<FontAwesomeIcon icon={faTachometerAlt} />
							<Link to="/admin/donations" className="navbar-link">
								All Donations
							</Link>
						</span>
					</li>
					<li className="navbar-item">
						<span>
							<FontAwesomeIcon icon={faTachometerAlt} />
							<Link
								to="/admin/refund-requests"
								className="navbar-link"
							>
								Refund Requests
							</Link>
						</span>
					</li>
					<li className="navbar-item">
						<span>
							<FontAwesomeIcon icon={faTachometerAlt} />
							<Link
								to="/admin/contact-us"
								className="navbar-link"
							>
								Contact Us Requests
							</Link>
						</span>
					</li>
					<li className="navbar-item">
						<span>
							<FontAwesomeIcon icon={faTachometerAlt} />
							<Link
								to="/admin/reward-purchases"
								className="navbar-link"
							>
								All Reward Purchases
							</Link>
						</span>
					</li>
					<li className="navbar-item">
						<span>
							<FontAwesomeIcon icon={faTachometerAlt} />
							<Link
								to="/admin/pending-updates"
								className="navbar-link"
							>
								Campaign Pending Updates
							</Link>
						</span>
					</li>

					<li
						className="navbar-dropdown"
						ref={kycDropdown}
						onClick={() => dropdownToggleHandler(kycDropdown)}
					>
						<span>
							<FontAwesomeIcon icon={faCubes} />
							<Link className="navbar-link">KYC Requests</Link>
						</span>
						<ul className="navbar-list navbar-dropdown-list hide">
							<li className="navbar-item">
								<span>
									<FontAwesomeIcon icon={faTachometerAlt} />
									<Link
										to="/admin/kyc-requests"
										className="navbar-link"
									>
										All
									</Link>
								</span>
							</li>
							<li className="navbar-item">
								<span>
									<FontAwesomeIcon icon={faTachometerAlt} />
									<Link
										to="/admin/kyc-requests/pending"
										className="navbar-link"
									>
										Pending
									</Link>
								</span>
							</li>
							<li className="navbar-item">
								<span>
									<FontAwesomeIcon icon={faTachometerAlt} />
									<Link
										to="/admin/kyc-requests/approved"
										className="navbar-link"
									>
										Approved
									</Link>
								</span>
							</li>
							<li className="navbar-item">
								<span>
									<FontAwesomeIcon icon={faTachometerAlt} />
									<Link
										to="/admin/kyc-requests/rejected"
										className="navbar-link"
									>
										Rejected
									</Link>
								</span>
							</li>
						</ul>
					</li>

					<li className="navbar-item">
						<span>
							<FontAwesomeIcon icon={faTachometerAlt} />
							<Link to="/admin/reports" className="navbar-link">
								Reports
							</Link>
						</span>
					</li>
					<li className="navbar-item">
						<span>
							<FontAwesomeIcon icon={faTachometerAlt} />
							<Link to="/admin/chats" className="navbar-link">
								Chats
							</Link>
						</span>
					</li>
					<li className="navbar-item">
						<span>
							<FontAwesomeIcon icon={faTachometerAlt} />
							<Link to="/admin/transfers" className="navbar-link">
								Transfer Requests
							</Link>
						</span>
					</li>
					{/* <li className="navbar-dropdown" ref={startupDropdown} onClick={() => dropdownToggleHandler(startupDropdown)}>
                        <span>
                            <FontAwesomeIcon icon={faCubes} />
                            <Link className="navbar-link">Enterprise</Link>
                        </span>
                        <ul className="navbar-list navbar-dropdown-list hide">
                            <li className="navbar-item">
                                <span>
                                    <Link to="/enterprise/create" className="navbar-link">Create New</Link>
                                </span> 
                            </li>
                            <li className="navbar-item">
                                <span>
                                    <Link to="/enterprise/manage" className="navbar-link">Manage</Link>
                                </span>
                            </li>
                        </ul>
                    </li>
                    <li className="navbar-dropdown" ref={donarDropdown} onClick={() => dropdownToggleHandler(donarDropdown)}>
                        <span>
                            <FontAwesomeIcon icon={faFileContract} />
                            <Link className="navbar-link">Donations/Rewards</Link>
                        </span>
                        <ul className="navbar-list navbar-dropdown-list hide">
                            <li className="navbar-item">
                                <span>
                                    <Link to="/my-donations" className="navbar-link">Causes</Link>
                                </span> 
                            </li>
                            <li className="navbar-item">
                                <span>
                                    <Link to="/myrewards" className="navbar-link">Rewards</Link>
                                </span>
                            </li>
                        </ul>
                    </li>
                    <li className="navbar-item">
                        <span>
                            <FontAwesomeIcon icon={faMoneyBillWave} />
                            <Link to="/mypayments" className="navbar-link">My Payments</Link>
                        </span>
                    </li>
                    <li className="navbar-item">
                        <span>
                            <FontAwesomeIcon icon={faCogs} />
                            <Link to="/settings" className="navbar-link">Settings</Link>
                        </span>
                    </li> */}
				</ul>
			</div>
		</section>
	);
};

export default AdminSidebar;
