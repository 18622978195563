import { requestHandler } from "../utils/requestHandler";

export default class Cause {

	static async FetchCause(id) {
		let response = await requestHandler('getCharity', { id })
		// console.log({response})
		if (response.status === 200) {
			return response.data[0]
		}
	}
}