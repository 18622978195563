import React from 'react'
import { Modal } from 'react-bootstrap'

const RefundDonationRequestDetails = (props) => {
	const { type, refId, newAccount } = props
	const renderBody = () => {
		const donation = props.donation || {}
		console.log(donation)
		return (
			<React.Fragment>
				<Modal.Body>
					Campaign Title: <b>{donation.title}</b>
					<br />
					Donar Name: <b>{donation.donarName}</b>
					<br />
					Payment: <b>{donation.payment}</b>
					<br />
					&nbsp;&nbsp; Donation Amount: <b>{donation.donationAmount}</b>
					<br />
					&nbsp;&nbsp; For Platform: <b>{donation.donationForPlatform}</b>
					<br />
				</Modal.Body>
				<Modal.Footer>Attention Required!</Modal.Footer>
			</React.Fragment>
		)
	}

	return (
		<Modal show={props.show} onHide={() => props.onHide(false)}>
			<Modal.Header closeButton>
				<Modal.Title>Refunt Request</Modal.Title>
			</Modal.Header>

			{renderBody()}
		</Modal>
	)
}

export default RefundDonationRequestDetails
