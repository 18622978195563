import React, { useState } from "react";
import "./Topbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faBars } from "@fortawesome/free-solid-svg-icons";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

const Topbar = (props) => {
	const [switchState, setSwitchState] = useState(
		props.dashboardMode === "hero" ? true : false
	);

	const openDashboardSidebar = () => {
		const sliderNavbar = document.getElementById("dashboardSidebar");
		sliderNavbar.style.transform = "scaleX(1)";
	};

	const onSwitchHandler = (checked) => {
		props.setDashboardMode(checked ? "hero" : "angel");
		setSwitchState(checked);
	};

	return (
		<div className={`Topbar ${props.dashboardMode}`}>
			<div className="box box1">
				<button
					className="bars-btn d-block d-xl-none"
					onClick={openDashboardSidebar}
				>
					<FontAwesomeIcon icon={faBars} />
				</button>
			</div>
			<div className="box box2">
				<BootstrapSwitchButton
					checked={switchState}
					onlabel="HERO"
					onstyle="warning"
					offlabel="ANGEL"
					offstyle="primary"
					style="Topbar-SwitchBtn"
					onChange={onSwitchHandler}
				/>
				<div className="language-globe">
					<FontAwesomeIcon icon={faGlobe} />
				</div>
			</div>
		</div>
	);
};

export default Topbar;
