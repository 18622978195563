/*global google*/
import { GoogleApiWrapper, InfoWindow, Map, Marker } from "google-maps-react";
import React, { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ContentBox from "../../components/layouts/ContentBox/ContentBox";
import Navbar from "../../components/layouts/Navbar";
import Sidebar from "../../components/layouts/Sidebar/Sidebar";
import UserCardForMap from "../../components/UserCardForMap/index";
import User from "../../DataModels/User";
import { BACKEND_URL } from "../../utils/constants";
import { requestHandler } from "../../utils/requestHandler";
import CampaignCardForMap from "../../components/CampaignCardForMap/index";

class ContactHeroes extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			markers: [],
			charitiesMarkers: [],
			enterpriseMarkers: [],
			showingUserInfoWindow: false,
			showingCampaignInfoWindow: false,
			activeUserMarker: {},
			activeCampaignMarker: {},
			selectedPlace: {},
			showUsers: true,
			showCauses: true,
			showEnterprises: true,
		};
	}

	onMarkerClick = async (props, marker, e) => {
		const user = await User.GetUserCard(props.user._id);
		const selectedPlace = {
			user: user,
			name: `${props.user.firstName} ${props.user.lastName}`,
			email: props.user.email,
		};

		this.setState({
			selectedPlace: selectedPlace,
			activeUserMarker: marker,
			showingUserInfoWindow: true,
		});
	};

	onCampaignMarkerClick = async (props, marker, e) => {
		const selectedPlace = {
			campaign: props.campaign,
			title: props.campaign.title,
		};

		this.setState({
			selectedPlace: selectedPlace,
			activeCampaignMarker: marker,
			showingCampaignInfoWindow: true,
		});
	};

	onMapClicked = (props) => {
		if (this.state.showingInfoWindow) {
			this.setState({
				showingInfoWindow: false,
				activeMarker: null,
			});
		}
	};

	componentDidMount() {
		this.getUsers();
		this.getCharities();
		this.getEnterprises();
	}

	getUsers = () => {
		let markers = [];
		User.FetchAllUsers().then((allUsers) => {
			for (let user of allUsers) {
				if (!user.publicLocation) {
					continue;
				}
				if (user.location && user.location.coordinates) {
					markers.push({
						user: user,
						locationName: user.address.location,
						lat: user.location.coordinates[1],
						lng: user.location.coordinates[0],
						icon: user.avatarImage
							? `${BACKEND_URL}/${user.avatarImage}`
							: null,
					});
				}
			}
			this.setState({ markers });
		});
	};

	getCharities = () => {
		requestHandler("getCharity", {
			status: "active",
		})
			.then((res) => {
				if (res.status === 200) {
					this.setState({ charitiesMarkers: res.data });
				} else {
					return [];
				}
			})
			.catch((err) => {
				console.log(err);
				alert("Server side error occured.");
			});
	};

	getEnterprises = () => {
		requestHandler("getEnterprise", {
			status: "active",
		})
			.then((res) => {
				if (res.status === 200) {
					this.setState({ enterpriseMarkers: res.data });
				} else {
					return [];
				}
			})
			.catch((err) => {
				console.log(err);
				alert("Server side error occured.");
			});
	};

	handleCheckboxChange = (e) => {
		this.onMapClicked();
		this.setState({
			[e.target.id]: !this.state[e.target.id],
		});
	};

	render() {
		console.log(this.state);
		if (
			!this.props.user.location ||
			!this.props.user.location.coordinates
		) {
			alert("Please set your location in settings first");
			return <Redirect to="/settings/personalInfoUpdate" />;
		}
		let lat = this.props.user.location.coordinates[1],
			lng = this.props.user.location.coordinates[0];
		let {
			redirect,
			markers,
			charitiesMarkers,
			getEnterprises,
			showUsers,
			showCauses,
			showEnterprises,
		} = this.state;
		if (redirect) return <Redirect to={redirect} />;
		charitiesMarkers = showCauses ? charitiesMarkers : [];
		getEnterprises = showEnterprises ? getEnterprises : [];
		markers = showUsers ? markers : [];
		return (
			<Fragment>
				<Navbar />
				<Sidebar />
				<ContentBox title={"Tribe World"}>
					<Row>
						<Col>
							Show Users{" "}
							<input
								type="checkbox"
								id="showUsers"
								checked={showUsers}
								onClick={this.handleCheckboxChange}
							/>
						</Col>
						<Col>
							Show Causes{" "}
							<input
								type="checkbox"
								id="showCauses"
								checked={showCauses}
								onClick={this.handleCheckboxChange}
							/>
						</Col>
						<Col>
							Show Enterprises{" "}
							<input
								type="checkbox"
								id="showEnterprises"
								checked={showEnterprises}
								onClick={this.handleCheckboxChange}
							/>
						</Col>
					</Row>
					<br />
					<Map
						google={this.props.google}
						onClick={this.onMapClicked}
						initialCenter={{ lat, lng }}
						center={{ lat, lng }}
						className={"map"}
						zoom={12}
						minZoom={4}
						maxZoom={22}
					>
						{markers.map((marker, index) => (
							<Marker
								onClick={this.onMarkerClick}
								user={marker.user}
								position={{
									lat: marker.lat,
									lng: marker.lng,
								}}
								key={"checkInMarker" + index}
								name={marker.user.firstName}
								icon={{
									url: marker.icon,
									scaledSize: new google.maps.Size(60, 60),
								}}
							/>
						))}

						{charitiesMarkers
							? charitiesMarkers.map((charity, index) => (
									<Marker
										onClick={this.onCampaignMarkerClick}
										campaign={charity}
										position={{
											lat:
												charity.location.coordinates[1],
											lng:
												charity.location.coordinates[0],
										}}
										key={"checkInMarker" + index}
										name={charity.title}
										icon={{
											url:
												"https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
										}}
									/>
							  ))
							: null}

						{getEnterprises
							? getEnterprises.map((enterprise, index) => (
									<Marker
										onClick={this.onCampaignMarkerClick}
										campaign={enterprise}
										position={{
											lat:
												enterprise.location
													.coordinates[1],
											lng:
												enterprise.location
													.coordinates[0],
										}}
										key={"checkInMarker" + index}
										name={enterprise.title}
										icon={{
											url:
												"https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
										}}
									/>
							  ))
							: null}

						<InfoWindow
							marker={this.state.activeUserMarker}
							visible={this.state.showingUserInfoWindow}
						>
							<div>
								<UserCardForMap
									user={this.state.selectedPlace.user}
								/>
							</div>
						</InfoWindow>
						<InfoWindow
							marker={this.state.activeCampaignMarker}
							visible={this.state.showingCampaignInfoWindow}
						>
							<div>
								<CampaignCardForMap
									campaign={this.state.selectedPlace.campaign}
								/>
							</div>
						</InfoWindow>
					</Map>
				</ContentBox>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		user: state.auth.user,
		dashboardMode: state.dashboard.mode,
	};
}

export default connect(mapStateToProps)(
	GoogleApiWrapper({
		apiKey: "AIzaSyDt9GY0qjMwSFvi-ODbrRJFZg3wCwtZofc",
	})(ContactHeroes)
);
