import React from "react";
import "./HorizontalLine.css";
import PropTypes from "prop-types";

const HorizontalLine = (props) => (
	<div className={`HorizontalLine ${props.color}`}>
		<div className="line"></div>
		<div className="text">or</div>
		<div className="line"></div>
	</div>
);

HorizontalLine.propTypes = {
	color: PropTypes.oneOf(["white", "dark"]),
};

export default HorizontalLine;
