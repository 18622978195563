import React, { Fragment } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";

import "./EditCharity.css";

import { receiveLogout } from "../../store/actions";
import Navbar from "../../components/layouts/Navbar";
import Sidebar from "../../components/layouts/Sidebar/Sidebar";
import ContentBox from "../../components/layouts/ContentBox/ContentBox";
import { requestHandler } from "../../utils/requestHandler";
import { tinyCloudAPIKey } from "../../utils/constants";
import CreateFaqs from "../../components/CreateFaqs";
import PlacesAutoCompleteInput from "../../components/UI/Inputs/PlacesAutoCompleteInput";

class EditCharity extends React.Component {
	state = {
		redirect: null,
		categories: [],
		title: "",
		goal: "",
		category: { name: "" },
		location: "",
		content: [],
		story: "",
		lat: null,
		lng: null,
		loading: true,
		faqs: [],
	};

	componentDidMount() {
		requestHandler("getCategories").then((result) => {
			this.categories = result.data;
			this.loadCharity(result.data);
			this.setState({
				categories: result.data,
			});
		});
	}

	loadCharity = () => {
		let categories = this.categories;
		const { id } = this.props.match.params;
		requestHandler("getCharity", { id }).then(
			(result) => {
				const charity = result.data[0];
				if (charity.creatorId !== this.props.user._id) {
					alert("You cannot edit this charity");
					return this.setState({ redirect: "/dashboard" });
				}
				let category = "";
				categories.forEach((_category) => {
					if (_category._id === charity.categoryId) {
						category = _category;
					}
				});

				this.setState({
					charityId: charity._id,
					goal: charity.goal,
					title: charity.title,
					story: charity.story,
					category,
					content: charity.content,
					location: charity.locationName,
					lat: charity.location.coordinates[1],
					lng: charity.location.coordinates[0],
					loading: false,
					faqs: charity.faqs,
				});
			},
			(err) => {
				console.log("ERROR: ", err);
				alert("Cannot edit the Cause at this moment");
			}
		);
	};

	onCategoryChange = (e) => {
		this.setState({
			category: this.state.categories[e.target.value],
		});
	};

	onInputChange = (item) => {
		this.setState({
			[item.target.id]: item.target.value,
		});
	};

	handleEditorChange = (story) => {
		this.setState({ story: story.length ? story : "<h2>&nbsp;</h2>" });
	};

	editCharity = () => {
		const {
			charityId,
			title,
			goal,
			category,
			location,
			content,
			story,
			faqs,
		} = this.state;
		let categoryId = category._id;

		if (!title.length || !goal.length || !location.length || !story.length)
			return alert("Please fill all fields");

		let data = {
			title,
			goal,
			story,
			content,
			categoryId,
			locationName: location,
			faqs,
			location: [25.197233, 55.274147],
		};
		requestHandler("editCharity", data, charityId).then((result) => {
			if (result.status === 200)
				this.setState({ redirect: "/charity/details/" + charityId });
		});
	};

	setLocatoinAutocomplete = (location, latLng) => {
		this.setState({ location });

		if (latLng) {
			const { lat, lng } = latLng;
			this.setState({ lat, lng });
		}
	};

	pushFaq = (question, answer) => {
		this.setState({
			faqs: [...this.state.faqs, { question, answer }],
		});
	};

	popFaq = (index) => {
		const faqs = [...this.state.faqs];
		faqs.splice(index, 1);
		this.setState({ faqs });
	};

	render() {
		const {
			categories,
			title,
			goal,
			location,
			story,
			redirect,
			category: selectedCategory,
			faqs,
			loading,
		} = this.state;
		if (redirect) return <Redirect to={redirect} />;
		if (loading) return <div>loading...</div>;
		return (
			<Fragment>
				<Navbar />
				<Sidebar />
				<ContentBox title="Edit your Cause">
					<div className="CharityWrapper">
						<form
							className="mb-4"
							onSubmit={(e) => e.preventDefault()}
						>
							<div className="form-group">
								<label htmlFor="title">Title</label>
								<input
									type="text"
									className="form-control"
									id="title"
									onChange={this.onInputChange}
									value={title}
								/>
							</div>

							<div className="form-group">
								<label htmlFor="goal">Goal</label>
								<div className="input-group mb-3">
									<div className="input-group-prepend">
										<span className="input-group-text">
											$
										</span>
									</div>
									<input
										type="number"
										className="form-control"
										id="goal"
										onChange={this.onInputChange}
										value={goal}
									/>
								</div>
							</div>

							<div className="form-group">
								<label htmlFor="category">Category</label>
								<select
									className="form-control"
									id="category"
									onChange={this.onCategoryChange}
								>
									{categories.map((category, i) => (
										<option
											key={i}
											selected={
												category._id ===
												selectedCategory._id
											}
											value={i}
										>
											{category.name}
										</option>
									))}
								</select>
							</div>

							<div className="form-group">
								<label htmlFor="location">Location</label>
								{/* <input type="text" className="form-control" id="location" onChange={this.onInputChange} value={location} /> */}
								<PlacesAutoCompleteInput
									setLocatoinAutocomplete={
										this.setLocatoinAutocomplete
									}
									location={location}
								/>
							</div>

							<div className="form-group">
								<label htmlFor="story">Story</label>
								{/* <textarea className="form-control" id="story" rows="3" onChange={this.onInputChange} value={story}></textarea> */}
								{!loading ? (
									<Editor
										id="story"
										initialValue={
											story.length
												? story
												: "<h2>&nbsp;</h2>"
										}
										apiKey={tinyCloudAPIKey}
										init={{
											height: 500,
											menubar: false,
											plugins: [
												"advlist autolink lists link image charmap print preview anchor",
												"searchreplace visualblocks code fullscreen",
												"insertdatetime media table paste code help wordcount",
											],
											toolbar: `undo redo | formatselect | bold italic | \
                                        alignleft aligncenter alignright alignjustify | \
                                        bullist numlist outdent indent | removeformat | help `,
										}}
										onEditorChange={this.handleEditorChange}
									/>
								) : null}
							</div>
							<CreateFaqs
								faqs={faqs}
								popFaq={this.popFaq}
								pushFaq={this.pushFaq}
							/>
							<button
								type="submit"
								className="btn btn-primary"
								onClick={this.editCharity}
							>
								Submit
							</button>
						</form>
					</div>
				</ContentBox>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		user: state.auth.user,
	};
}

const mapDispatchToProps = (dispatch) => ({
	logoutUser: () => dispatch(receiveLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCharity);
