import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import Cause from '../../../DataModels/Cause'
import InviteFriends from '../../InviteFriends'
import SocialMediaShare from '../../SocialMediaShare'
import PrimaryInputField from '../../UI/Inputs/PrimaryAuthInput/PrimaryAuthInput'
import UpdateAvatarLink from '../../UpdateAvatarLink'
import UserAvatar from '../../UserAvatar'

const CauseCreatedPopup = (props) => {
	const [show, setShow] = useState(true)
	const [cause, setCause] = useState(null)
	const [modalStep, setModalStep] = useState(1)
	const { type, refId, newAccount } = props

	useEffect(() => {
		Cause.FetchCause(refId).then((cause) => {
			setCause(cause)
		})
	}, [refId])

	const title =
		type === 'causecreated'
			? 'Congratulations! You have now become a Tribe Hero by successfully creating the Cause '
			: null
	let causeTitle = ''
	if (cause && cause.title) {
		causeTitle = cause.title
	}
	let causeId = cause && cause._id ? cause._id : null
	console.log(causeId)

	const renderBody = (step) => {
		switch (step) {
			case 1:
				return (
					<React.Fragment>
						<Modal.Body>
							<p>Share you donation with your friends.</p>
							{causeId ? (
								<SocialMediaShare
									title={causeTitle}
									url={
										'https://dev.tribeworld.org/charity/details/' +
										causeId
									}
									goal={
										cause
											? cause.goal
												? cause.goal
												: 0
											: 0
									}
								/>
							) : null}
						</Modal.Body>

						<Modal.Footer>
							<Button
								variant='primary'
								onClick={() => setShow(false)}>
								Close
							</Button>
						</Modal.Footer>
					</React.Fragment>
				)
			case 10:
				return (
					<React.Fragment>
						<Modal.Body>
							<p>Invite your friends to doante.</p>
							<PrimaryInputField
								type='text'
								placeholder='Enter your email'
							/>
							<InviteFriends />
						</Modal.Body>

						<Modal.Footer>
							<Button
								variant='primary'
								onClick={() => setModalStep(1)}>
								Previous
							</Button>
							<Button
								variant='primary'
								onClick={() => setModalStep(3)}>
								Next
							</Button>
						</Modal.Footer>
					</React.Fragment>
				)
			case 2:
				return newAccount ? (
					<React.Fragment>
						<Modal.Body>
							<div className='d-flex align-items-center'>
								<UserAvatar />
							</div>
                            <UpdateAvatarLink />
						</Modal.Body>

						<Modal.Footer>
							<Button
								variant='primary'
								onClick={() => setModalStep(1)}>
								Previous
							</Button>
							<Button variant='primary'>Close</Button>
						</Modal.Footer>
					</React.Fragment>
				) : null
			default:
				return null
		}
	}

	return (
		<Modal show={show} onHide={() => setShow(false)}>
			<Modal.Header closeButton>
				<Modal.Title>
					{title + ' '} <b>{causeTitle}</b>
				</Modal.Title>
			</Modal.Header>

			{renderBody(modalStep)}
		</Modal>
	)
}

export default CauseCreatedPopup
