import React from "react";
import "./CauseForm.css";
import CauseStepOne from "./CauseStepOne/CauseStepOne";

const CauseForm = (props) => {
	return (
		<div className="CauseForm">
			<div className="container">
				<div className="row">
					<div className="col mx-auto">
						<div className="wrapper">
							<CauseStepOne />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CauseForm;
