import React, { Fragment } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import StripeCheckout from 'react-stripe-checkout'

import "../Charity/CharityDetails.css";

import { receiveLogout, receiveLogin } from "../../store/actions";
import Navbar from "../../components/layouts/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUniversity, faWallet } from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../../components/layouts/Sidebar/Sidebar";
import ContentBox from "../../components/layouts/ContentBox/ContentBox";
import { requestHandler } from "../../utils/requestHandler";
import CustomLabel from "../../components/CustomLabel";
import CustomInput from '../../components/CustomInput';
import AddressCardsSection from '../../components/AddressCardsSection/AddressCardsSection'
import { STRIPE_KEY } from "../../utils/constants";
import RewardDetails from "../../components/Modals/RewardDetails";


class EnterpriseBuy extends React.Component {
	state = {
		redirect: null,
		title: "",
		goal: "",
		story: "",
		loading: true,
		fullname: '',
		country: '',
		street: '',
		city: '',
		postalCode: '',
		phoneNumber: '',
		saveShippingDetails: true,
		firstName: '',
		lastName: '',
		email: '',
		donateAnonymously: false,
		step: 1,
		selectedShippingAddress: true,

	};

	componentDidMount() {
		this.loadData();
		this.loadUser();
	}

	loadUser = () => {
		if (this.props.isAuthenticated) {
			const { user } = this.props;
			// console.log("LOGGED IN AS: ", user)
			// let address = {}
			// if(user.address)
			// address = {
			//     street: user.address.street ? user.address.street : '',
			//     city: user.address.city ? user.address.city : '',
			//     country: user.address.country ? user.address.country : '',
			//     postalCode: user.address.postalCode ? user.address.postalCode : '',
			//     state: user.address.state ? user.address.state : '',
			//     phoneNumber: user.primaryPhoneNumber ? user.primaryPhoneNumber : '',
			// }
			this.setState({
				firstName: user.firstName,
				lastName: user.lastName,
				email: user.email,
				// fullname: user.firstName + ' ' + user.lastName,
				// ...address
			})
		}
	}

	loadData = () => {
		const { enterpriseId, rewardId } = this.props.match.params;
		const promises = []
		promises.push(requestHandler("getEnterprise", { id: enterpriseId }).then(
			(result) => {
				const charity = result.data[0];
				this.setState({
					creatorId: charity.creatorId,
					goal: charity.goal,
					title: charity.title,
					story: charity.story,
				});
			},
			(err) => {
				console.log("ERROR: ", err);
			}
		));
		promises.push(requestHandler("getRewards", { id: rewardId })
			.then(result => { this.setState({ reward: result.data[0] }) }));
		Promise.all(promises)
			.then(() => {
				console.log("STATE: ", this.state)
				this.setState({ loading: false })
			})
	}

	onInputChange = (item, resetShippingAddress) => {
		const { selectedShippingAddress } = this.state
		this.setState({
			selectedShippingAddress: resetShippingAddress ? false : selectedShippingAddress,
			[item.target.id]: item.target.value,
		});
	};

	buyReward = async (e) => {
		const { rewardId } = this.props.match.params;
		const { street, city, country, postalCode, phoneNumber, fullname, donateAnonymously, saveShippingDetails, selectedShippingAddress } = this.state
		// create address obj
		const shippingAddress = selectedShippingAddress ? selectedShippingAddress : {
			fullname,
			street,
			city,
			country,
			postalCode,
			phoneNumber,
		}

		// make purchase
		const purchaseDetails = {
			rewardId,
			donateAnonymously,
			shippingDetails: {
				...shippingAddress,
				type: "physical",
			},
		}
		requestHandler('purchaseReward', purchaseDetails)
			.then(response => {
				console.log("RESPONSE FROM PURCHASE: ", response)
				alert("Purchase was successful")
				this.setState({ redirect: "/dashboard" })
				if (saveShippingDetails && !selectedShippingAddress) {
					// post address to account
					this.saveUserShippingAddress(shippingAddress)
				}
			})


	}

	loadUserShippingAddresses = async () => {
		let response = await requestHandler('getUser', { id: this.props.user._id })
		const shippingAddresses = response.data[0].shippingAddresses ? response.data[0].shippingAddresses : []
		return shippingAddresses
	}

	saveUserShippingAddress = async (shippingAddress) => {
		let shippingAddresses = await this.loadUserShippingAddresses()
		shippingAddress._id = parseInt(Math.random() * 10000000000000000)
		shippingAddresses.push(shippingAddress)
		requestHandler("updateProfile", { shippingAddresses })
			.then(response => {
				console.log("RESPONSE FROM UPDATE ADDRESS: ", response)
			})
	}

	registerUser = async (firstName, lastName, email) => {
		let registeringData = { firstName, lastName, email, password: '123123123', service: true, terms: true, promotionalEmails: false }
		let status = false;
		await requestHandler('register', registeringData)
			.then(result => {
				console.log(result)
				if (result.status === 200) {
					status = true
					localStorage.setItem('auth', JSON.stringify(result.data));
					this.props.loginUser(result.data)
				} else {
					alert(result.message)
				}
			}, e => {
				console.log('ERROR: ', e)
			})
		return status
	}

	submitAddressForm = (e) => {
		e.preventDefault();
		this.gotoStep(2)
	}

	renderPurchaseForm = () => {
		const { firstName, lastName, email, selectedShippingAddress } = this.state;
		return (
			<form onSubmit={this.submitAddressForm}>
				<div className="my-5 mx-3">
					<div className="form-group">
						<CustomLabel text="First Name " />
						<CustomInput id='firstName' placeholder=' last name'
							disabled={this.props.isAuthenticated ? true : false}
							onChange={this.onInputChange} value={firstName} />
					</div>
					<div className="form-group">
						<CustomLabel text="Last Name " />
						<CustomInput id='lastName' placeholder=' last name'
							disabled={this.props.isAuthenticated ? true : false}
							onChange={this.onInputChange} value={lastName} />
					</div>
					<div className="form-group">
						<CustomLabel text="Email " />
						<CustomInput id='email' placeholder=' email address'
							disabled={this.props.isAuthenticated ? true : false}
							onChange={this.onInputChange} value={email} />
						{/* {checkingEmailExistenceInSystem ? "Checking..." : emailGoodToGo ? '' : email.length ? 'Email exists. Please Login' : ''} */}

					</div>
					{selectedShippingAddress ?
						<AddressCardsSection
							selectedShippingAddress={selectedShippingAddress}
							setShippingAddress={this.setShippingAddress} />
						: this.renderShippingAddressForm()
					}
				</div>
			</form>
		)
	}

	renderShippingAddressForm = () => {
		const { fullname, country, street, city,
			postalCode, phoneNumber, saveShippingDetails } = this.state;
		return (
			<div className="wrapper">
				<div className='row'>
					<h5 className="font-weight-bold mb-3">
						Shipping Address
                    </h5>
					<button className="ml-5" onClick={() => this.setShippingAddress(true)}>Select from saved addresses</button>
				</div>

				<div className="form-row">
					<div className="col-md-6 mb-3">
						<label htmlFor="fullname">
							Full Name
                        </label>
						<input
							type="text"
							className="form-control"
							id="fullname"
							value={fullname}
							onChange={(e) => this.onInputChange(e, true)}
							required
						/>
					</div>
					<div className="col-md-6 mb-3">
						<label htmlFor="country">Country</label>
						<input
							type="text"
							className="form-control"
							id="country"
							value={country}
							onChange={(e) => this.onInputChange(e, true)}
							required
						/>
					</div>
				</div>
				<div className="form-row">
					<div className="col-md-6 mb-3">
						<label htmlFor="street">
							Street Adress
                        </label>
						<input
							type="text"
							className="form-control"
							id="street"
							value={street}
							onChange={(e) => this.onInputChange(e, true)}
							required
						/>
					</div>
				</div>
				<div className="form-row">
					<div className="col-md-6 mb-3">
						<label htmlFor="city">City</label>
						<input
							type="text"
							className="form-control"
							id="city"
							value={city}
							onChange={(e) => this.onInputChange(e, true)}
							required
						/>
					</div>
					<div className="col-md-6 mb-3">
						<label htmlFor="postalCode">
							Postal Code
                        </label>
						<input
							type="text"
							className="form-control"
							id="postalCode"
							value={postalCode}
							onChange={(e) => this.onInputChange(e, true)}
							required
						/>
					</div>
					<div className="col-md-3 mb-3">
						<label htmlFor="phoneNumber">
							Phone Number
                        </label>
						<input
							type="text"
							className="form-control"
							id="phoneNumber"
							value={phoneNumber}
							onChange={(e) => this.onInputChange(e, true)}
							required
						/>
					</div>
				</div>

				<div className="form-check">
					<input
						className="form-check-input"
						type="checkbox"
						value={saveShippingDetails}
						id="saveShippingDetails"
						checked={saveShippingDetails}
						onChange={() => { }}
						onClick={() => this.setState({ saveShippingDetails: !saveShippingDetails })}
					/>
					<label
						className="form-check-label"
						htmlFor="saveShippingDetails" >
						Save shipping details to profile
                    </label>
				</div>
				<button
					className="btn btn-primary my-3"
					type="submit" >
					Buy Reward
                </button>
			</div>
		)
	}


	initPaypalPayment = (totalPayment) => {
		requestHandler('paypalPayment', { amount: totalPayment.toString() })
			.then(response => {
				if (response.status === 200) {
					window.open(response.approval_url, "_blank")
					this.buyReward()
				}
			}, error => {
				console.log("ERROR: ", error)
			})
	}

	renderStripeButton = (totalPayment) => (
		<div>
			<StripeCheckout
				stripeKey={STRIPE_KEY}
				token={this.handleStripeToken}
				allowRememberMe={false}
				amount={totalPayment * 100}
			/>   <br />
		</div>
	)


	handleStripeToken = async (token) => {
		const { firstName, lastName, email } = this.state
		const { price: totalPayment } = this.state.reward
		if (!this.props.isAuthenticated) {
			await this.registerUser(firstName, lastName, email)
		}

		requestHandler('stripePayment', { stripeToken: token.id, amount: totalPayment.toString() })
			.then(response => {
				if (response.status === 200) {
					this.buyReward()
				}
			}, error => {
				console.log("ERROR: ", error)
			})
	}

	gotoStep = (step) => {
		this.setState({ step })
	}

	renderPaymentForm = () => {
		console.log("REWARD: ", this.state.reward)
		const { price: totalPayment } = this.state.reward
		return (
			<div>
				<h5>Select Payment Method</h5>
				<button
					className="ml-5"
					onClick={() => this.gotoStep(1)}> Go Back </button>
				<ul className="nav bg-light nav-pills rounded nav-fill mb-3" role="tablist">
					<li className="nav-item li-space">
						{this.renderStripeButton(totalPayment)}</li>
					<li className="nav-item li-space">
						<button onClick={() => this.initPaypalPayment(totalPayment)} className="nav-link" data-toggle="pill" href="#nav-tab-paypal">
							<FontAwesomeIcon icon={faWallet} />Paypal</button></li>
					<li className="nav-item li-space">
						<button onClick={() => { }} className="nav-link" data-toggle="pill" href="#nav-tab-bank">
							<FontAwesomeIcon icon={faUniversity} />  Bank Transfer</button></li>
				</ul>
			</div>
		)
	}

	setShippingAddress = (address) => {
		this.setState({ selectedShippingAddress: address })
	}

	render() {
		console.log("SATE: ", this.state)
		const { title, loading, redirect, reward, step } = this.state;
		if (redirect) return <Redirect to={redirect} />;
		return (
			<Fragment>
				<Navbar />
				{this.props.isAuthenticated ? <Sidebar /> : <Sidebar />}
				<ContentBox title="">
					<div className="row">
						<div className="card mx-3">
							<div className="card-header">
								<h4>Purchase summary</h4>
							</div>
							{!loading ?
								<div className="card-body m-2">
									<div className="row">
										<div className="col-sm-6">
											<div className="card">
												<div className="card-body">
													<h5 className="card-title">
														Selected Enterprise Details
                                                    </h5>
													<p className="card-text">
														Title: {title}
													</p>
												</div>
											</div>
										</div>
										<div className="col-sm-6">
											<div className="card">
												<div className="card-body">
													<h5 className="card-title">
														Selected Reward Details
                                                    </h5>
													<p className="card-text">
														Title: {reward.title}<br />
                                                        Description: {reward.description}<br />
                                                        Quantity: {reward.quantity}<br />
                                                        Price: {reward.price}<br />
                                                        Price Retail Price: {reward.retailPrice}<br />
													</p>
													<RewardDetails reward={reward} />
												</div>
											</div>
										</div>
									</div>
								</div>
								: null}
						</div>
					</div>
					{step === 1 && this.renderPurchaseForm()}
					{step === 2 && this.renderPaymentForm()}
				</ContentBox>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		user: state.auth.user,
	};
}

const mapDispatchToProps = (dispatch) => ({
	logoutUser: () => dispatch(receiveLogout()),
	loginUser: user => dispatch(receiveLogin(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(EnterpriseBuy);
