import React from "react";
import "./ResponsiveTable.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import PrimaryBtn from "../Buttons/PrimaryBtn/PrimaryBtn";

const ResponsiveTable = (props) => {
	const renderHeaders = () => (
		<Tr>
			{props.headers.map((item, index) => (
				<Th key={index}>{item}</Th>
			))}
		</Tr>
	);

	const angelDonationsRecords = () => {
		const { records } = props;

		return records.map((donation) => (
			<Tr>
				<Td>{donation.charity.title}</Td>
				<Td>{donation.charity.goal}</Td>
				<Td>{"$" + donation.donationAmount}</Td>
				<Td>{"$" + donation.donationForPlatform}</Td>
				<Td>{"$" + donation.totalPayment}</Td>
				<Td>{donation.createdAt.split("T")[0]}</Td>
				<Td>
					<div className="actions-group">
						<PrimaryBtn
							type="link"
							color="transparentBlue"
							size="md"
							to={"/charity/details/" + donation.charity._id}
						>
							View Cause
						</PrimaryBtn>
						<PrimaryBtn
							type="button"
							color="transparentBlue"
							size="md"
							onClick={() => props.downloadReceiptPDF(donation)}
						>
							Download Receipt
						</PrimaryBtn>
						{props.showRefunds ? (
							<PrimaryBtn
								type="button"
								color={
									donation.refundRequested
										? "disabled"
										: "transparentBlue"
								}
								size="md"
								onClick={() =>
									props.setRefundModal(donation)
								}
								disabled={donation.refundRequested}
							>
								{donation.refundRequested
									? "Refund Reqested"
									: "Request Refund"}
							</PrimaryBtn>
						) : null}
					</div>
				</Td>
			</Tr>
		));
	};

	const angelRewardsRecords = () => {
		const { records } = props;

		return records.map((purchase) => (
			<Tr>
				<Td>{purchase.enterprise.title}</Td>
				<Td>{purchase.rewardDetails.title}</Td>
				<Td>{"$" + purchase.rewardDetails.price}</Td>
				<Td>{purchase.rewardDetails.deliveryDays}</Td>
				<Td>
					<div className="actions-group">
						<PrimaryBtn
							type="link"
							color="transparentBlue"
							size="md"
							to={
								"/enterprise/details/" + purchase.enterprise._id
							}
						>
							View Details
						</PrimaryBtn>
						<PrimaryBtn
							type="button"
							color="transparentBlue"
							size="md"
							onClick={() => {}}
						>
							Tracking
						</PrimaryBtn>
					</div>
				</Td>
			</Tr>
		));
	};

	const renderRecords = () => {
		switch (props.for) {
			case "angelDonations":
				return angelDonationsRecords();
			case "angelRewards":
				return angelRewardsRecords();
			default:
				return null;
		}
	};

	return (
		<Table className={props.dashboardMode}>
			<Thead>{renderHeaders()}</Thead>
			<Tbody>{renderRecords()}</Tbody>
		</Table>
	);
};

ResponsiveTable.propTypes = {
	for: PropTypes.oneOf(["angelDonations", "angelRewards"]).isRequired,
	headers: PropTypes.array.isRequired,
	records: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
	return {
		dashboardMode: state.dashboard.mode,
	};
}

export default connect(mapStateToProps)(ResponsiveTable);
