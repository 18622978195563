import React from "react";
import "./PrimaryCard.css";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import CardDummyImg from "../../../../assets/images/cardDummyImg.png";
import Title from "../../Typography/Title/Title";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

const PrimaryCard = (props) => {
    return (
        <div className="PrimaryCard">
            <div className="PrimaryCard-Head">
                <img src={CardDummyImg} alt="CardHead-Img" />
            </div>
            <div className="PrimaryCard-Body">
                <Title size="md" color="dark">
                    Descriptive NEWS TITLE
                </Title>
                <p className="text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Mauris arcu curabitur nunc, a nisl. Pellentesque interdum
                    sagittis.
                </p>
                <div className="btn-box">
                    <Link className="link-button" to="/">
                        Read More
                        <span className="link-icon">
                            <FontAwesomeIcon icon={faChevronRight} />
                        </span>
                    </Link>
                </div>
            </div>
        </div>
    );
};

PrimaryCard.propTypes = {
    img: PropTypes.any.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
};

export default PrimaryCard;
