import {Button} from "react-bootstrap";
import React, {PureComponent} from "react";
import {Modal} from "react-bootstrap";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import ChatMessage from "../../../DataModels/ChatMessage";
import CustomLabel from "../../CustomLabel";
import Conversation from "../../../DataModels/Conversation";

class ChatDetailsModal extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            chatMessages: []
        }
    }

    loadData = () => {
        const {chat} = this.props
        ChatMessage.FetchChatMessages(chat._id)
            .then((chatMessages) => {
                console.log({chatMessages})
                this.setState({chatMessages})
            })
    }

    componentDidMount() {
        this.loadData()
    }

    componentDidUpdate(props) {
        if (props.chat._id !== this.props.chat._id) {
            this.loadData()
        }
    }

    blockConversation = async () => {
        const conversation = new Conversation(this.props.chat._id)
        if (await conversation.blockConversation()) {
            alert('The conversation has successfully blocked')
            this.props.reloadProps()
            this.props.toggleChatModal(false)
        } else {
            alert('Something went wrong')
        }
    }

    unblockConversation = async () => {
        const conversation = new Conversation(this.props.chat._id)
        if (await conversation.unblockConversation()) {
            alert('The conversation has successfully unblocked')
            this.props.reloadProps()
            this.props.toggleChatModal(false)
        } else {
            alert('Something went wrong')
        }
    }

    renderReceivedMessage = (message, user) => {
        let time = new Date(message.createdAt);
        time = time.getHours() + ":" + time.getMinutes();
        return (
            <div className='main-sender pt-3 '>
                <div className='shift-comment-sender  pt-2 '>
                    <div className='ml-4 '>
                        From:&nbsp;
                        <Link className="text-dark" to={'/profile/' + user._id}>
                            {user.firstName + ' ' + user.lastName}
                        </Link>
                    </div>
                    <div className='ml-2'>
                        <span>{message.content}</span>
                    </div>
                </div>
                <div className='time-span'>
                    <span className=''>{time}</span>
                </div>
            </div>
        );
    };

    renderSentMessage = (message, user) => {
        let time = new Date(message.createdAt);
        time = time.getHours() + ":" + time.getMinutes();
        return (
            <div className='main-receiver pt-3'>
                <div className='shift-comment-receiver  pt-2 '>
                    <div className='ml-4 '>
                        From:&nbsp;
                        <Link className="text-dark" to={'/profile/' + user._id}>
                            {user.firstName + ' ' + user.lastName}
                        </Link>
                    </div>
                    <div className='ml-4 '>
                        <span>{message.content}</span>
                    </div>
                </div>
                <span className='float-right'>{time}</span>
            </div>
        );
    };

    renderChatMessages = () => {
        const {chatMessages} = this.state
        const {userA, userB, chat} = this.props
        console.log({chat})
        return (
            <div>
                {chat.blockedByAdmin ?
                    <Button
                        className='btn btn-secondary'
                        onClick={this.unblockConversation}>
                        Unblock Conversation
                    </Button>
                    : <Button
                        className='btn btn-secondary'
                        onClick={this.blockConversation}>
                        Block Conversation
                    </Button>}
                {
                    chatMessages.map((message) => {
                        if (message.senderId === userA._id) {
                            return this.renderSentMessage(message, userA);
                        } else {
                            return this.renderReceivedMessage(message, userB);
                        }
                    })
                }
            </div>
        )
    }

    render() {
        const {chat, showModal, toggleChatModal, userA, userB} = this.props;
        console.log('Chat to show ', chat)
        return (
            <Modal show={showModal} onHide={() => toggleChatModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Chat History</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CustomLabel text="Participant A: "/> &nbsp;
                    <Link to={'/profile/' + userA._id}>
                        {userA.firstName + ' ' + userA.lastName}
                    </Link>&nbsp;
                    {chat.blockedByUserA ? <span className='text-danger'> has blocked the chat</span> : null}
                    <br/>
                    <CustomLabel text="Participant B"/> &nbsp;
                    <Link to={'/profile/' + userB._id}>
                        {userB.firstName + ' ' + userB.lastName}
                    </Link>&nbsp;
                    {chat.blockedByUserB ? <span className='text-danger'> has blocked the chat</span> : null}
                    <br/>
                    <hr/>
                    {this.renderChatMessages()}
                </Modal.Body>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(ChatDetailsModal);
