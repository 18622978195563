import React, { Fragment } from "react";
import { connect } from 'react-redux';

import { receiveLogout } from "../../store/actions";

import Navbar from '../../components/layouts/Navbar'
import Sidebar from "../../components/layouts/Sidebar/Sidebar";
import ContentBox from "../../components/layouts/ContentBox/ContentBox";
import { requestHandler } from '../../utils/requestHandler';
import { Link } from "react-router-dom";

class MyPayments extends React.Component {
	state = {
		payments: [[], []],
		totalDonationAmount: 0,
		totalPurchaseAmount: 0,
	}

	componentDidMount() {
		this.loadMyPayments()
	}

	loadMyPayments = () => {
		let payments = [];
		let charityDonations = [];
		let rewardPurchases = [];
		let donationsPromise = requestHandler('getMyDonations', {})
			.then(result => {
				console.log("DONATIONS: ", result.data)
				if (result.status === 200)
					charityDonations = result.data
			})
		let rewardsPromise = requestHandler('getMyRewards', {})
			.then(result => {
				console.log("REWARDS: ", result.data)
				if (result.status === 200) {
					rewardPurchases = result.data
				}
			})
		Promise.all([donationsPromise, rewardsPromise])
			.then(() => {
				console.log({})
				payments.push(charityDonations);
				payments.push(rewardPurchases);
				this.setState({ payments }, this.calcStats)
				console.log({ payments })
			})
	}

	calcStats = () => {
		const { payments } = this.state

		let totalDonationAmount = 0
		let totalPurchaseAmount = 0
		payments[0].forEach(donation => {
			totalDonationAmount += Number(donation.totalPayment)
		})

		payments[1].forEach(purchase => {
			totalPurchaseAmount += Number(purchase.rewardDetails.price)
		})

		this.setState({ totalDonationAmount, totalPurchaseAmount })

	}

	renderRewardPurchases = () => {
		const rewardPayments = this.state.payments[1]
		console.log({ rewardPayments })
		return (
			<tbody>
				{rewardPayments.map(payment => (
					<tr>
						<td> {payment.enterprise.title} </td>
						<td> Enterprise </td>
						<td> {'$ ' + payment.rewardDetails.price} </td>
						<td> {payment.createdAt.split('T')[0]} </td>
						<td> <Link to={"/enterprise/details/" + payment.enterprise._id}> View Details </Link>  </td>
					</tr>
				))}
			</tbody>
		)

	}

	renderCharityDonations = () => {
		const charityDonations = this.state.payments[0]

		return (

			<tbody>
				{charityDonations.map(donation => (
					<tr>
						<td> {donation.charity.title} </td>
						<td> Charity </td>
						<td> {'$ ' + donation.totalPayment} </td>
						<td> {donation.createdAt.split('T')[0]} </td>
						<td> <Link to={"/charity/details/" + donation.charity._id}> View Details </Link>  </td>
					</tr>
				))}
			</tbody>
		)

	}

	render() {
		const { totalDonationAmount,
			totalPurchaseAmount } = this.state
		const totalSpending = totalDonationAmount + totalPurchaseAmount;
		return (
			<Fragment>
				<Navbar />
				<Sidebar />
				<ContentBox title="My Payments">
					<div>

						Total Donation amount: {'$ ' + totalDonationAmount}
					</div>
					<div>
						Total Reward purchases amount: {'$ ' + totalPurchaseAmount}
					</div>
					<div>
						Total Spending: {'$ ' + totalSpending}
					</div>
					<br />
					{/* <Campaigns charities={this.state.donations}/> */}
					<table className='table'>
						<thead>
							<tr>
								<td>Campaign Title</td>
								<td>Type</td>
								<td>Amount</td>
								<td>Date of payment</td>
								<td></td>
							</tr>

						</thead>
						{this.renderCharityDonations()}
						{this.renderRewardPurchases()}
					</table>
				</ContentBox>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		user: state.auth.user
	};
}

const mapDispatchToProps = (dispatch) => ({
	logoutUser: () => dispatch(receiveLogout())

});

export default connect(mapStateToProps, mapDispatchToProps)(MyPayments);
