import React, { Component } from "react";
import { Button } from "react-bootstrap";
import AddItem from "../Modals/AddItem";

import RewardItemCard from "../RewardItemCard";
import { requestHandler } from "../../utils/requestHandler";

class EnterpriseRewardItems extends Component {
	state = {
		items: [],
		showAddItemPopup: false,
	};

	componentDidMount() {
		this.fetchEnterpriseRewardItems();
	}

	fetchEnterpriseRewardItems = () => {
		const { enterpriseId } = this.props;
		requestHandler("enterpriseRewardsItems", { enterpriseId })
			.then((result) => {
				console.log("fetchEnterpriseRewardItems", result);
				const items = [...result.data];
				if (result.status === 200) {
					this.setState({ items: items });
				}
			})
			.catch((err) => {
				console.log(err);
				alert("Server Error");
			});
	};

	toggleAddItemPopup = () => {
		this.setState({ showAddItemPopup: !this.state.showAddItemPopup });
	};

	calculateTotalRetailPrice = (selection, items) => {
		let totalRetailPrice = 0;
		items.forEach(item => {
			if (item._id in selection) {
				totalRetailPrice += item.retailPrice * selection[item._id].quantity
			}
		})
		return totalRetailPrice
	}

	onItemClick = (itemId) => {
		const { setSelectedItems, selection: prevSelection } = this.props;
		const { items } = this.state
		if (!setSelectedItems) {
			return;
		}
		let selection = { ...prevSelection };
		if (selection[itemId]) {
			delete selection[itemId];
		} else {
			selection[itemId] = { quantity: 1 };
		}
		let retailPriceTotal = this.calculateTotalRetailPrice(selection, items)
		setSelectedItems(selection, retailPriceTotal);
	};

	setItemQuantity = (itemId, addition) => {
		const { selection: prevSelection } = this.props;
		const { items } = this.state
		const item = prevSelection[itemId];
		item.quantity += addition;
		let selection = null;
		if (item.quantity) {
			selection = {
				...prevSelection,
				[itemId]: item,
			};
		} else {
			selection = { ...prevSelection };
			delete selection[itemId];
		}
		let retailPriceTotal = this.calculateTotalRetailPrice(selection, items)
		this.props.setSelectedItems(selection, retailPriceTotal);
	};

	render() {
		const { items, showAddItemPopup } = this.state;
		let { selection } = this.props;
		if (!selection) {
			selection = {};
		}

		let itemsHTML = (
			<p>
				<b>No items exist</b>
			</p>
		);

		if (items.length !== 0) {
			itemsHTML = (
				<React.Fragment>
					{items.map((item) => (
						<span>
							<RewardItemCard
								selected={selection[item._id]}
								onClick={() => this.onItemClick(item._id)}
								item={item}
							/>

							{selection[item._id] ? (
								<span>
									Quantity
									<Button
										onClick={() =>
											this.setItemQuantity(item._id, -1)
										}
									>
										-
                                    </Button>
									<span className="ml-3 mr-3">
										{selection[item._id].quantity}
									</span>
									<Button
										onClick={() =>
											this.setItemQuantity(item._id, 1)
										}
									>
										+
                                    </Button>
								</span>
							) : null}
						</span>
					))}
				</React.Fragment>
			);
		}

		return (
			<p>
				{this.props.showAddItem ? (
					<AddItem
						enterpriseId={this.props.enterpriseId}
						showModal={showAddItemPopup}
						toggleModal={this.toggleAddItemPopup}
						reloadItems={this.fetchEnterpriseRewardItems}
					/>
				) : null}
				<div className="row">{itemsHTML}</div>
				<Button className="mt-2" onClick={this.toggleAddItemPopup}>
					Add Item
                </Button>
			</p>
		);
	}
}

export default EnterpriseRewardItems;
