import React, { Fragment } from "react";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { Button } from "react-bootstrap";

import "./EnterpriseDetails.css";

import { receiveLogout } from "../../store/actions";
import Navbar from "../../components/layouts/Navbar";
import Sidebar from "../../components/layouts/Sidebar/Sidebar";
import ContentBox from "../../components/layouts/ContentBox/ContentBox";
import RewardTile from "../../components/RewardTile";
import { requestHandler } from "../../utils/requestHandler";
import img from "../../assets/images/Card.jpg";
import SocialMediaShare from "../../components/SocialMediaShare";
// import CreateComment from "../../components/CreateComment";
import CampaignUpdates from "../../components/CampaignUpdates";
import CampaignComments from "../../components/CampaignComments";
import ProductStageDetails from "../../components/ProductStageDetails";
import ShowFaqs from "../../components/ShowFaqs";
import MapLocationViewer from "../../components/Modals/MapLocationViewer";


class EnterpriseDetails extends React.Component {
    state = {
        redirect: null,
        title: "",
        goal: "",
        story: "",
        loading: true,
        rewards: [],
        content: [],
        creatorId: null,
        faqs: [],
        latLng: null,
        locationName: '',
        showMapLocation: false,
    };

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const { id } = this.props.match.params;
        requestHandler("getEnterprise", { id }).then(
            (result) => {
                const _enterprise = result.data[0]
                // console.log(_enterprise.location.coordinates)
                if (_enterprise) {
                    this.setState({
                        creatorId: _enterprise.creatorId,
                        goal: _enterprise.goal,
                        title: _enterprise.title,
                        story: _enterprise.story,
                        shortDescription: _enterprise.shortDescription,
                        content: _enterprise.content,
                        productStage: _enterprise.productStage,
                        locationName: _enterprise.locationName,
                        latLng: _enterprise.location.coordinates,
                        faqs: _enterprise.faqs ? _enterprise.faqs : [],
                    });
                }
            },
            (err) => {
                console.log("ERROR: ", err);
            },
        );

        requestHandler("getRewards", { enterpriseId: id })
            .then((result) => {
                this.setState({ rewards: result.data });
            });
    };

    onInputChange = (item) => {
        this.setState({
            [item.target.id]: item.target.value,
        });
    };

    toggleShowMapModal = () => {
        this.setState({ showMapLocation: !this.state.showMapLocation })
    }

    render() {
        const cardWrapperStyle = {
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
        };

        const { id } = this.props.match.params;
        const {
            title,
            goal,
            story,
            creatorId,
            redirect,
            rewards,
            content,
            shortDescription,
            productStage,
            locationName,
            latLng,
            faqs,
            showMapLocation,
        } = this.state;

        if (redirect) return <Redirect to={redirect} />;

        return (
            <Fragment>
                {	showMapLocation ?
                    <MapLocationViewer
                        show={true}
                        toggleModal={this.toggleShowMapModal}
                        location={{
                            locationName,
                            lat: latLng[0],
                            lng: latLng[1]
                        }}
                    /> : null
                }
                <Navbar />
                {this.props.isAuthenticated ? <Sidebar /> : null}
                <ContentBox style={{ marginBottom: "100px" }} title=''>
                    <div className='row'>
                        <div className='CharityWrapper'>
                            <div className='row' style={{ marginLeft: "20%" }}>
                                <div className='card custom-card'>
                                    <img
                                        className='main-image card-img-top'
                                        src={content.length ? content[0] : img}
                                        alt='main-img'
                                    />
                                    <br />
                                    <SocialMediaShare
                                        title={title}
                                        goal={goal}
                                    />
                                </div>
                                <div className='card custom-card'>
                                    <div className='card-body'>
                                        <h2>{title}</h2>
                                        <p>{shortDescription}</p>
                                        <h5 className='card-title'>Goal</h5>
                                        <p className='card-text'>
                                            {" $ " + goal}
                                        </p>
                                        <h5 className='card-title'>Achieved</h5>
                                        <p className='card-text'>
                                            {" $ 2300 "}
                                        </p>
                                        <br />
                                        <h5 className='card-title'>Location</h5>
                                        <p className='card-text'>
                                            {locationName}
                                        </p>
                                        <Button onClick={() => this.toggleShowMapModal(true)}>Show Map</Button>
                                        <br />
                                        <ProductStageDetails productStage={productStage} />
                                        <ShowFaqs faqs={faqs} />
                                        {creatorId === this.props.user._id ? (
                                            <Link to={"/enterprise/edit/" + id}>
                                                Edit Enterprise
                                            </Link>
                                        ) : null}

                                        <div>
                                            {creatorId ===
                                                this.props.user._id ? (
                                                    <Link
                                                        to={
                                                            "/campaign/update/" + id
                                                        }>
                                                        Post Update
                                                    </Link>
                                                ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='m-5' style={{ marginLeft: "20%" }}>
                        <div className='cards-wrapper' style={cardWrapperStyle}>
                            {rewards.map((reward) => (
                                <RewardTile
                                    reward={reward}
                                    enterpriseId={this.props.match.params.id}
                                    rewardId={reward._id}
                                    key={reward._id}
                                    title={reward.title}
                                    price={reward.price}
                                    retailPrice={reward.retailPrice}
                                    description={reward.description}
                                    quantity={reward.quantity}
                                    deliveryDays={reward.deliveryDays}
                                />
                            ))}
                        </div>
                        {creatorId === this.props.user._id ? (
                            <div className='my-3 mx-2'>
                                <Link
                                    to={"/enterprise/addreward/" + id}
                                    className='btn btn-primary'>
                                    Add Reward
								</Link>
                            </div>
                        ) : null}
                        {/* <div className='d-flex justify-content-sm-between'>
                            <div className='col-8'><CampaignUpdates enterprise={true} campaignId={id} /></div>
                            <div className='col-4'><CampaignComments enterprise={true} campaignId={id} /></div>
                        </div> */}
                    </div>
                    <div className='container-fluid my-3 mx-2'>
                        <div className='row'>
                            <div className='col-12'>
                                <ul
                                    className='nav nav-tabs'
                                    id='myTab'
                                    role='tablist'>
                                    <li
                                        className='nav-item'
                                        role='presentation'>
                                        <a
                                            className='nav-link active'
                                            id='story-tab'
                                            data-toggle='tab'
                                            href='#story'
                                            role='tab'
                                            aria-controls='story'
                                            aria-selected='true'>
                                            Story
										</a>
                                    </li>
                                    <li
                                        className='nav-item'
                                        role='presentation'>
                                        <a
                                            className='nav-link'
                                            id='updates-tab'
                                            data-toggle='tab'
                                            href='#updates'
                                            role='tab'
                                            aria-controls='updates'
                                            aria-selected='false'>
                                            Updates
										</a>
                                    </li>
                                    <li
                                        className='nav-item'
                                        role='presentation'>
                                        <a
                                            className='nav-link'
                                            id='comments-tab'
                                            data-toggle='tab'
                                            href='#comments'
                                            role='tab'
                                            aria-controls='comments'
                                            aria-selected='false'>
                                            Comments
										</a>
                                    </li>
                                </ul>

                                <div
                                    className='tab-content mt-2 mx-4'
                                    id='myTabContent'>
                                    <div
                                        className='tab-pane fade show active'
                                        id='story'
                                        role='tabpanel'
                                        aria-labelledby='story-tab'>
                                        {ReactHtmlParser(story)}
                                    </div>

                                    <div
                                        className='tab-pane fade'
                                        id='updates'
                                        role='tabpanel'
                                        aria-labelledby='updates-tab'>
                                        <CampaignUpdates
                                            enterprise={true}
                                            campaignId={id}
                                        />
                                    </div>

                                    <div
                                        className='tab-pane fade'
                                        id='comments'
                                        role='tabpanel'
                                        aria-labelledby='comments-tab'>
                                        <CampaignComments
                                            enterprise={true}
                                            campaignId={id}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-4">
                                The donations section
                                {
                                    donations.map((donation, index) => {
                                        if(!donation.user) {
                                            donation.user = {
                                                firstName: 'User Deleted',
                                                lastName: 'User Deleted',
                                                email: 'User Deleted',
                                            }
                                        }

                                        return (
                                            <div className="card mb-3" key={index}>
                                                <div className="card-body">
                                                    <h5 className="card-title">{ !donation.donateAnonymously ? donation.user.firstName + ' ' + donation.user.lastName : 'Anonymous' }</h5>
                                                    <h6 className="card-subtitle mb-3">Amount: ${donation.donationAmount}</h6>
                                                    <p className="card-text">{donation.message.length ? donation.message : <>NO MESSAGE</> }</p>
                                                    <h6 className="card-subtitle mb-2 text-muted">Time: {donation.createdAt.split('T')[0]}</h6>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div> */}
                        </div>
                    </div>
                </ContentBox>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        user: state.auth.user,
    };
}

const mapDispatchToProps = (dispatch) => ({
    logoutUser: () => dispatch(receiveLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EnterpriseDetails);
