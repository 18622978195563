import { LOCALES } from "../locales";

export default {
	[LOCALES.GERMAN]: {
		"home": "Zuhause",
		"packages": "Pakete",
		"portfolio": "Portfolio",
		"events": "Veranstaltungen",
		"blogs": "Blogs",
		"language": "Sprache",
		"login": "Anmeldung",
		"caps-investors": "INVESTORINNEN",
		"caps-million_invested": "MILLIONEN INVESTIERT",
		"caps-million_profits": "MILLIONEN GEWINNE",
		"caps-frames_impacted": "RAHMEN BEEINFLUSST",
		"caps-as_seen_on": "WIE GESEHEN BEI\n",
		"become_a_hero": "Werde ein Held",
		"become_an_angel": "Werde ein Engel\n",
		"how_it_works": "Wie es funktioniert\n",
		"for": "zum",
		"caps-heroes": "HELDINNEN",
		"caps-angels": "ENGEL",
	},
};
