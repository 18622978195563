import { Button } from 'react-bootstrap'
import React from 'react'
import CustomInput from '../CustomInput'
import CustomLabel from '../CustomLabel'
import ShowFaqs from '../ShowFaqs';

export default class CreateFaqs extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			question: '',
			answer: ''
		}
	}

	onInputChange = (item) => {
		this.setState({
			[item.target.id]: item.target.value
		})
	}

	createFaq = () => {
		const { question, answer } = this.state
		if (!question.length || !answer.length) {
			return alert("Please type both, question and an answer for the FAQ")
		}
		this.props.pushFaq(question, answer)
		this.setState({ question: '', answer: '' })
	}

	render() {
		const { question, answer } = this.state
		return (
			<div className='border border-primary p-1 mb-2'>
				<ShowFaqs
					faqs={this.props.faqs}
					popFaq={this.props.popFaq}
				/> <br />
				<h5>Create FAQs</h5>
				<CustomLabel text="Question" />
				<CustomInput id='question'
					onChange={this.onInputChange}
					value={question} />
				<CustomLabel text="Answer" />
				<CustomInput id='answer'
					onChange={this.onInputChange}
					value={answer}
					type='textarea' />
				<Button className="mt-1" onClick={this.createFaq}>Create</Button>
				<br />
				*Note: You can skip adding the FAQs
				<br />
			</div>
		)
	}
}

