import { createStore } from "redux";
import { receiveLogin, setDashboardMode, setLanguage } from "./actions";
import rootReducer from "./reducers";
import User from "../DataModels/User";

export default function configureStore(persistedState) {
	const store = createStore(rootReducer, persistedState);
	let user = localStorage.getItem("auth");
	let language = localStorage.getItem("language");
	let dashboardMode = localStorage.getItem("dashboardMode");
	if (user) {
		user = JSON.parse(user);
		store.dispatch(receiveLogin(user));
	}
	if (language) {
		store.dispatch(setLanguage(language));
	}
	if (dashboardMode) {
		store.dispatch(
			setDashboardMode(JSON.parse(dashboardMode).dashboardMode)
		);
	}
	setTimeout(async () => {
		let user = await User.ReloadUser();
		if (user) {
			user = JSON.parse(user);
			store.dispatch(receiveLogin(user));
		}
	}, 1000);
	return store;
}
