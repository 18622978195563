import React from "react";
import "./TextSection.css";
import PropTypes from "prop-types";
import Title from "../Title/Title";

const TextSection = (props) => {
    return (
        <section className="TextSection">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-10 col-lg-8 col-xl-7 mx-auto">
                        <div className="wrap-content">
                            <div className="title-box">
                                <Title
                                    size="md"
                                    color={props.color}
                                    isResponsive={true}
                                >
                                    {props.title}
                                </Title>
                            </div>

                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

TextSection.propTypes = {
    title: PropTypes.string.isRequired,
    color: PropTypes.oneOf(["white", "dark"]).isRequired,
};

export default TextSection;
