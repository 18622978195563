import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import { Map, Marker, GoogleApiWrapper, InfoWindow } from "google-maps-react";
import './MapLocationViewer.css'

function MapLocationViewer(props) {
    // console.log('inner modal', { props })
    const [activeMarker, setMarker] = useState(null);
    const [showingInfoWindow, setShowInfoWindow] = useState(false);
    const [selectedPlace, setSelectedPlace] = useState({});
    const [position, setInfoPosition] = useState(null);

    const { location, toggleModal, show } = props
    if (!location) return null
    const { lat, lng, locationName } = location

    let markers = [{
        name: `${locationName}`,
        lat: parseFloat(lat),
        lng: parseFloat(lng),
        // icon: {
        // 	// url:'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png'
        // 	url: require("../../user/profile/avatar.png"),

        // 	scaledSize: new google.maps.Size(20, 30)
        // }
    }]

    const onMarkerClick = (props, marker) => {
        setMarker(marker)
        setShowInfoWindow(true)
        setSelectedPlace(props)
    }


    const onInfoWindowClose = () => {
        setMarker(null)
        setShowInfoWindow(false)
    }

    const onMapClicked = () => {
        if (showingInfoWindow) {
            setMarker(null)
            setShowInfoWindow(false)
        }
    };

    return (
        <Modal
            show={show}
            onHide={toggleModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered >
            <Modal.Header>
                <h6>Location Details</h6><br />
                <h5>{locationName}</h5>
            </Modal.Header>
            <Modal.Body style={{ height: '400px' }}>
                <Map
                    google={props.google}
                    onClick={onMapClicked}
                    initialCenter={{ lat, lng }}
                    center={{ lat, lng }}
                    className={"map"}
                    zoom={12}
                >
                    {markers.map((marker, index) => <Marker
                        onClick={onMarkerClick}
                        title={marker.title}
                        position={{
                            lat: marker.lat,
                            lng: marker.lng
                        }}
                        key={'checkInMarker' + index}
                        name={marker.name}
                        icon={marker.icon}

                    />)}



                    <InfoWindow
                        marker={activeMarker}
                        position={!activeMarker && position ? position : null}
                        onClose={onInfoWindowClose}
                        visible={showingInfoWindow}>
                        <div>
                            <p>{selectedPlace.name}</p>
                        </div>
                    </InfoWindow>

                </Map>
            </Modal.Body>
        </Modal>
    );
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyDt9GY0qjMwSFvi-ODbrRJFZg3wCwtZofc"
})(MapLocationViewer);
