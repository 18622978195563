import React from "react";
import { Link } from "react-router-dom";


import CreateComment from "./CreateComment";
import { createDateAndTimeFromISO } from "../utils/helpers";

class CampaignCommentReplies extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showReplyBox: false,
        };
    }

    componentDidMount() { }

    renderSingleComment = ({
        createdAt,
        dislikes,
        likes,
        message,
        replies,
        user,
        name,
        _id,
    }) => (
            <div
                style={{
                    marginLeft: "50px",
                    marginBottom: "5px",
                    padding: "10px",
                    backgroundColor: "#d1d1d1",
                }}
                key={_id}>
                <div className='d-flex justify-content-between'>
                    <div>
                        <i>From:</i> <b>{name}</b>
                    </div>
                    <div>
                        <i>Time:</i>{" "}
                        {createDateAndTimeFromISO(createdAt, true, true, false)}
                    </div>
                </div>
                <br />
                {message}
                {/* <i>Likes:</i> <b>{likes}</b> <i>Dislikes:</i> <b>{dislikes}</b> */}
            </div>
        );

    renderReplies = () => {
        const { comments } = this.props;
        return (
            <div>
                {/* {this.renderSingleComment({
					createdAt: new Date().toISOString(),
					message: "This is the reply",
					user: {
						firstName: "Nouman",
						lastName: "khan",
					},
					_id: "aldskfjalsdkfj",
				})} */}
                {comments.map((comment) => this.renderSingleComment(comment))}
            </div>
        );
    };

    setShowReplyBox = (showReplyBox) => {
        this.setState({ showReplyBox });
    };

    renderReplyBox = () => (
        <div style={{ marginLeft: "50px" }}>
            {this.state.showReplyBox ? (
                <CreateComment
                    replyTo={this.props.commentId}
                    reloadComments={this.props.reloadComments}
                />
            ) : null}
            <Link
                className='comment-reply-button'
                onClick={() => this.setShowReplyBox(!this.state.showReplyBox)}>
                {this.state.showReplyBox ? "Cancel" : "Reply"}
            </Link>
        </div>
    );

    render() {
        return (
            <div>
                {this.renderReplies()}
                {this.renderReplyBox()}
            </div>
        );
    }
}

export default CampaignCommentReplies;
