import React, { useRef, useState } from "react";
import "./LanguageBar.css";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { setLanguage } from "../../../store/actions";

const LanguageBar = (props) => {
	const Languages = [
		{
			id: "en-us",
			type: "English",
			imgSrc: "https://www.countryflags.io/us/flat/24.png",
		},
		{
			id: "fr-ca",
			type: "French",
			imgSrc: "https://www.countryflags.io/fr/flat/24.png",
		},
		{
			id: "de-de",
			type: "German",
			imgSrc: "https://www.countryflags.io/de/flat/24.png",
		},
		{
			id: "pt-PT",
			type: "Portuguese",
			imgSrc: "https://www.countryflags.io/pt/flat/24.png",
		},
		{
			id: "es",
			type: "Spanish",
			imgSrc: "https://www.countryflags.io/es/flat/24.png",
		},
	];

	const [selectedLang, setSelectedLang] = useState(props.language);
	const DropdownOptions = useRef(null);

	const toggleDropdownOptions = () => {
		const display = DropdownOptions.current.style.display;

		if (display === "flex") {
			DropdownOptions.current.style.display = "none";
		} else {
			DropdownOptions.current.style.display = "flex";
		}
	};

	const changeLanguageHandler = (languageID) => {
		setSelectedLang(languageID);
		props.setLanguage(languageID);
	};

	return (
		<section id="LanguageBar">
			<div className="LanguageDropdown">
				<div className="DropdownSelected">
					{Languages.map((lang) => {
						if (lang.id === selectedLang) {
							return (
								<div className="flagBox">
									<img alt="language" src={lang.imgSrc} />
									<span>{lang.type}</span>
								</div>
							);
						} else return null;
					})}
					<div className="arrow" onClick={toggleDropdownOptions}>
						<FontAwesomeIcon icon={faSortDown} />
					</div>
				</div>
				<div className="DropdownOptions" ref={DropdownOptions}>
					{Languages.map((lang) => {
						if (lang.id !== selectedLang) {
							return (
								<div
									className="flagBox hoverEffect"
									onClick={() =>
										changeLanguageHandler(lang.id)
									}
								>
									<img alt="language" src={lang.imgSrc} />
									<span>{lang.type}</span>
								</div>
							);
						} else return null;
					})}
				</div>
			</div>
		</section>
	);
};

function mapStateToProps(state) {
	return {
		language: state.language.language,
	};
}

const mapDispatchToProps = (dispatch) => ({
	setLanguage: (language) => {
		localStorage.setItem("language", language);
		dispatch(setLanguage(language));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageBar);
