import React from "react";
import "./TopBanner.css";
import HeroIcon from "../../../assets/images/heroes-icon.png";
import AngelIcon from "../../../assets/images/angels-icon.png";
import { Link } from "react-router-dom";

const TopBanner = (props) => {
    const icons = props.icons ? (
        <div className="wrap-icons">
            <Link className="icon-link" to="#">
                <div className="icon">
                    <div className="wrap-img">
                        <img src={HeroIcon} alt="icon" />
                    </div>
                    <h5 className="text">Be A Hero</h5>
                </div>
            </Link>
            <Link className="icon-link" to="#">
                <div className="icon">
                    <div className="wrap-img">
                        <img src={AngelIcon} alt="icon" />
                    </div>
                    <h5 className="text">Be An Angel</h5>
                </div>
            </Link>
        </div>
    ) : null;

    return (
        <div className="TopBanner">
            <div className="banner-bgImg">
                <img src={props.img} alt="BannerImg" />
            </div>
            <div className="overlay"></div>
            {icons}
        </div>
    );
};

export default TopBanner;
