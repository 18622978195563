import React, { Component } from "react";
import DashboardLayout from "../../../components/layouts/DashboardLayout/DashboardLayout";

class Dashboard extends Component {
	state = {};

	render() {
		return <DashboardLayout></DashboardLayout>;
	}
}

export default Dashboard;
