import React from "react";
import "./BgShape.css";
import PropTypes from "prop-types";

const BgShape = (props) => {
    const classNames = ["BgShape", props.side];
    const stylesheet = {};

    if (props.fromTop) {
        stylesheet.top = `${props.fromTop}px`;
    }

    if (props.fromBottom) {
        stylesheet.bottom = `${props.fromBottom}px`;
    }

    return (
        <div className={classNames.join(" ")} style={stylesheet}>
            <div className="img-wrapper">
                <div className="img"></div>
            </div>
        </div>
    );
};

BgShape.propTypes = {
    side: PropTypes.oneOf(["left", "right"]).isRequired,
    fromTop: PropTypes.number,
    fromBottom: PropTypes.number,
};

export default BgShape;
