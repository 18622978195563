import React from "react";

import { requestHandler } from "../utils/requestHandler";
import CustomInput from "./CustomInput";
import { connect } from "react-redux";

class CreateComment extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			message: "",
		};
	}

	onInputChange = (item) => {
		this.setState({
			[item.target.id]: item.target.value,
		});
	};

	postComment = (e) => {
		e.preventDefault();
		const { replyTo, campaignId, isAuthenticated, user } = this.props;
		const { message } = this.state;
		if (!isAuthenticated) {
			return alert("You need to sign in to post a comment");
		}

		let promise = null;

		if (replyTo) {
			promise = requestHandler(
				"updateComment",
				{
					replies: [
						{
							name: user.firstName + " " + user.lastName,
							message,
							createdAt: new Date().toISOString(),
						},
					],
				},
				replyTo,
			);
		} else if (campaignId) {
			promise = requestHandler("createCampaignComment", {
				campaignId,
				message,
			});
		}

		promise.then((response) => {
			console.log("posted");
			this.setState({ message: "" });
			document.getElementById("reply-form").reset();
			return this.props.reloadComments
				? this.props.reloadComments()
				: null;
		});
	};

	render() {
		const { replyTo } = this.props;
		const { message } = this.state;
		return (
			<div>
				<form id='reply-form' onSubmit={this.postComment}>
					<CustomInput
						placeholder={
							replyTo ? "Type a reply" : "Please type a comment"
						}
						id='message'
						value={message}
						onChange={this.onInputChange}
					/>
				</form>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		user: state.auth.user,
	};
}

export default connect(mapStateToProps)(CreateComment);
