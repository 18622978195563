import React from "react";
import { Route, Redirect } from "react-router-dom";
import EmailWarning from "./EmailWarning";

const ProtectedRoute = ({
	component: Component,
	isAuthenticated,
	isVerifying,
	isAdmin,
	...rest
}) => (
	<Route
		{...rest}
		render={(props) =>
			isVerifying ? (
				<div />
			) : isAuthenticated && !isAdmin ? (
				<React.Fragment>
					<EmailWarning />
					<Component {...props} />
				</React.Fragment>
			) : isAdmin ? (
				<Redirect
					to={{
						pathname: "/admin/dashboard",
						state: { from: props.location },
					}}
				/>
			) : (
				<Redirect
					to={{
						pathname: "/login",
						state: { from: props.location },
					}}
				/>
			)
		}
	/>
);

export default ProtectedRoute;
