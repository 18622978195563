import React, { Fragment } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { receiveLogout } from "../../store/actions";
import ContentBox from "../../components/layouts/ContentBox/ContentBox";
import Navbar from "../../components/layouts/Navbar";
import Sidebar from "../../components/layouts/Sidebar/Sidebar";
import RefundModal from "../../components/Modals/RefundModal";
import { generateCharityDonationReciptPDF } from "../../utils/generatePDF";
import { requestHandler } from "../../utils/requestHandler";
class MyDonations extends React.Component {
	state = {
		donations: [],
	};

	componentDidMount() {
		this.reloadData();
	}

	reloadData = () => {
		requestHandler("getMyDonations", {}).then((result) => {
			result.data.forEach((ele) =>
				ele.charity ? null : console.log("ELEMENT: ", ele)
			);
			if (result.status === 200)
				this.setState({ donations: result.data });
		});
	};

	downloadReceipt = (donation) => {
		const { totalPayment, donationForPlatform, donationAmount } = donation;

		generateCharityDonationReciptPDF(
			donation.charity.title,
			donationAmount,
			donationForPlatform,
			totalPayment
		);
	};

	setRefundModal = (donation) => {
		this.setState({
			showRefundModal: donation ? true : false,
			refundDonation: donation,
		});
	};

	renderTableBody = () => {
		const viewrefund = new URLSearchParams(window.location.search).get(
			"viewrefund"
		);
		const { donations } = this.state;
		const day = 1000 * 60 * 60 * 24
		return (
            <tbody>
				{donations.map((donation) => {
                    const canRefund = ((new Date().getTime() - new Date(donation.createdAt).getTime()) / day) < 16 
                    return (
					<tr>
						<td>{donation.charity.title}</td>
						<td>{donation.charity.goal}</td>
						<td>{"$ " + donation.donationAmount}</td>
						<td>{"$ " + donation.donationForPlatform}</td>
						<td>{"$ " + donation.totalPayment}</td>
						<td>{donation.createdAt.split("T")[0]}</td>
						<td>
							<Link
								to={"/charity/details/" + donation.charity._id}
							>
								View Cause
							</Link>
						</td>
						<td>
							<Button
								onClick={() => this.downloadReceipt(donation)}
							>
								Download Receipt
							</Button>
						</td>
						{viewrefund === "true" ? canRefund ? (                       
							<td>
								{donation.refundRequested ? (
									<h6>Refund reqested</h6>
								) : (
									<Button
										variant="warning"
										onClick={() =>
											this.setRefundModal(donation)
										}
									>
										Request Refund
									</Button>
								)}
							</td>
						) : 'Cannot refund'  : null }
					</tr>
				)})}
			</tbody>
		);
	};

	render() {
		const { showRefundModal, refundDonation } = this.state;
		return (
			<Fragment>
				<Navbar />
				<Sidebar />
				<ContentBox title="Your Donations">
					<RefundModal
						show={showRefundModal}
						donation={refundDonation}
						onClose={this.setRefundModal}
						reloadData={this.reloadData}
					/>
					{/* <Campaigns charities={this.state.donations}/> */}
					<table className="table">
						<thead>
							<tr>
								<td>Cause's Title</td>
								<td>Cause's Goal</td>
								<td>Donation Amount</td>
								<td>Donation for Platform</td>
								<td>Total Payment</td>
								<td>Donation Date</td>
								<td></td>
							</tr>
						</thead>
						{this.renderTableBody()}
					</table>
				</ContentBox>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		user: state.auth.user,
	};
}

const mapDispatchToProps = (dispatch) => ({
	logoutUser: () => dispatch(receiveLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyDonations);
