import React from "react";
import "./FluidCard.css";
import PropTypes from "prop-types";
import Title from "../../UI/Typography/Title/Title";
import PrimaryBtn from "../../UI/Buttons/PrimaryBtn/PrimaryBtn";

const FluidCard = (props) => {
    return (
        <section className="FluidCard">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-11 col-xl-12 mx-auto">
                        <div className="FluidCard-Wrap">
                            <div className="head">
                                <div className="card-bg-img">
                                    <img src={props.img} alt="CardBgImg" />
                                </div>
                                <div className="overlay"></div>
                            </div>
                            <div className="body">
                                {props.headers.map((text) => (
                                    <Title
                                        size="lg"
                                        color="white"
                                        isResponsive={true}
                                    >
                                        {text}
                                    </Title>
                                ))}
                                <div className="btn-box mt-3">
                                    <PrimaryBtn
                                        type="link"
                                        size="md"
                                        color="yellow"
                                        to="/news"
                                    >
                                        {props.btnText}
                                    </PrimaryBtn>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

FluidCard.propTypes = {
    img: PropTypes.any.isRequired,
    headers: PropTypes.arrayOf(PropTypes.string).isRequired,
    btnText: PropTypes.string,
    onBtnClick: PropTypes.func,
};

export default FluidCard;
