import { LOCALES } from "../locales";

export default {
    [LOCALES.PORTUGUESE]: {
        "home": "Casa",
        "packages": "Pacotes",
        "portfolio": "Portfólio",
        "events": "Eventos",
        "blogs": "Blogs",
        "language": "Língua",
        "login": "Conecte-se",
        "caps-investors": "INVESTIDORAS",
        "caps-million_invested": "MILHÕES INVESTIDOS",
        "caps-million_profits": "MILHÕES DE LUCROS",
        "caps-frames_impacted": "FRAMES IMPACTED",
        "caps-as_seen_on": "COMO VISTO EM",
        "become_a_hero": "Torne-se um Herói",
        "become_an_angel": "Se torne um anjo",
        "how_it_works": "Como funciona",
        "for": "para",
        "caps-heroes": "HERÓIS",
        "caps-angels": "ANJOS",
    },
};
