import React, { Fragment, Component } from "react";
import { Redirect } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";

import "../createCharity/CreateCharity.css";

import Navbar from "../../components/layouts/Navbar";
import Sidebar from "../../components/layouts/Sidebar/Sidebar";
import ContentBox from "../../components/layouts/ContentBox/ContentBox";
import { requestHandler } from "../../utils/requestHandler";
import { tinyCloudAPIKey } from "../../utils/constants";
import ProductStageSelector from "../../components/ProductStageSelector";
import CreateFaqs from "../../components/CreateFaqs";
import PlacesAutoCompleteInput from "../../components/UI/Inputs/PlacesAutoCompleteInput";

class CreateEnterprise extends Component {
	state = {
		redirect: null,
		categories: [],
		title: "",
		goal: "",
		lat: null,
		lng: null,
		category: { name: "" },
		location: "",
		story: "",
		duration: "",
		content: [],
		productStage: null,
		faqs: [],
		loading: true,
	};

	componentDidMount() {
		requestHandler("getCategories").then((result) => {
			this.categories = result.data;
			this.loadEnterprise();
			this.setState({
				categories: result.data,
			});
		});
	}

	loadEnterprise = () => {
		let categories = this.categories;
		const { id } = this.props.match.params;

		requestHandler("getEnterprise", { id }).then((result) => {
			// if(charity.creatorId !== this.props.user._id) {
			//     alert("You cannot edit this charity")
			//     return this.setState({ redirect: '/dashboard'})
			// }

			const {
				categoryId,
				content,
				createdAt,
				creatorId,
				duration,
				goal,
				locationName,
				story,
				title,
				productStage,
				faqs,
			} = result.data[0];

			let category = "";
			categories.forEach((_category) => {
				if (_category._id === categoryId) {
					category = _category;
				}
			});

			this.setState({
				categoryId,
				content,
				createdAt,
				creatorId,
				category,
				duration,
				goal,
				story,
				title,
				location: locationName,
				productStage,
				faqs,
				loading: false,
			});
		});
	};

	onChangeProductStageUpdate = (productStage) => {
		const { currentStage } = productStage;
		// Wierd problem check. Needs research and fixing
		// Also fix in creating enterprise
		// App will crash if check removed
		if (
			currentStage === "concept" ||
			currentStage === "prototype" ||
			currentStage === "production" ||
			currentStage === "shipping"
		) {
			this.setState({ productStage });
		}
		return;
	};

	onCategoryChange = (e) => {
		this.setState({
			category: this.state.categories[e.target.value],
		});
	};

	handleEditorChange = (story) => {
		this.setState({ story });
	};

	onInputChange = (item) => {
		this.setState({
			[item.target.id]: item.target.value,
		});
	};

	editEnterprise = () => {
		const { id } = this.props.match.params;
		let {
			title,
			goal,
			category,
			location,
			story,
			duration,
			content,
			productStage,
			faqs,
			lat,
			lng,
		} = this.state;
		if (!location) location = "";
		if (
			!title.length ||
			!goal.length ||
			!location.length ||
			!story.length ||
			!productStage
		) {
			return alert("Please fill all fields");
		}
		let data = {
			title,
			goal,
			story,
			content,
			categoryId: category._id,
			duration,
			locationName: location,
			productStage,
			faqs,
			location: [lat, lng],
		};
		requestHandler("editEnterprise", data, id).then((result) => {
			if (result.status === 200)
				this.setState({ redirect: "/enterprise/details/" + id });
		});
	};

	pushFaq = (question, answer) => {
		const faqs = this.state.faqs ? this.state.faqs : [];
		faqs.push({ question, answer });
		this.setState({
			faqs,
		});
	};

	popFaq = (index) => {
		const faqs = [...this.state.faqs];
		faqs.splice(index, 1);
		this.setState({ faqs });
	};

	setLocatoinAutocomplete = (location, latLng) => {
		this.setState({ location });

		if (latLng) {
			const { lat, lng } = latLng;
			this.setState({ lat, lng });
		}
	};

	render() {
		const {
			categories,
			title,
			goal,
			location,
			story,
			duration,
			category: selectedCategory,
			redirect,
			productStage,
			faqs,
			loading,
			lat,
			lng,
		} = this.state;
		if (redirect) return <Redirect to={redirect} />;
		console.log({ location, lat, lng });
		return (
			<Fragment>
				<Navbar />
				<Sidebar />
				<ContentBox title="Edit Enterprise">
					<div className="CharityWrapper">
						<form
							className="mb-4"
							onSubmit={(e) => e.preventDefault()}
						>
							<div className="form-group">
								<label htmlFor="title">Title</label>
								<input
									type="text"
									className="form-control"
									id="title"
									onChange={this.onInputChange}
									value={title}
								/>
							</div>
							<div className="form-group">
								<label htmlFor="category">Category</label>
								<select
									className="form-control"
									id="category"
									onChange={this.onCategoryChange}
								>
									{categories.map((category, i) => (
										<option
											key={i}
											selected={
												category._id ===
												selectedCategory._id
											}
											value={i}
										>
											{category.name}
										</option>
									))}
								</select>
							</div>
							<div className="form-group">
								<label htmlFor="location">Location</label>
								<PlacesAutoCompleteInput
									setLocatoinAutocomplete={
										this.setLocatoinAutocomplete
									}
									location={location}
								/>
								{/* <input
									type="text"
									className="form-control"
									id="location"
									onChange={this.onInputChange}
									value={location}
								/> */}
							</div>
							<div className="form-group">
								<label htmlFor="duration">
									Campaign Duration
								</label>
								<input
									type="number"
									className="form-control"
									id="duration"
									onChange={this.onInputChange}
									value={duration}
								/>
							</div>
							<ProductStageSelector
								onChangeProductStageUpdate={
									this.onChangeProductStageUpdate
								}
								productStage={productStage}
							/>{" "}
							<br />
							<div className="form-group">
								<label htmlFor="story">Story</label>
								{!loading ? (
									<Editor
										id="story"
										initialValue={
											story.length
												? story
												: "<h2>&nbsp;</h2>"
										}
										apiKey={tinyCloudAPIKey}
										init={{
											height: 500,
											menubar: false,
											plugins: [
												"advlist autolink lists link image charmap print preview anchor",
												"searchreplace visualblocks code fullscreen",
												"insertdatetime media table paste code help wordcount",
											],
											toolbar: `undo redo | formatselect | bold italic | 
                                        alignleft aligncenter alignright alignjustify | 
                                        bullist numlist outdent indent | removeformat | help `,
										}}
										onEditorChange={this.handleEditorChange}
									/>
								) : null}
							</div>
							<div className="form-group">
								<label htmlFor="goal">Goal</label>
								<div className="input-group mb-3">
									<div className="input-group-prepend">
										<span className="input-group-text">
											$
										</span>
									</div>
									<input
										type="number"
										className="form-control"
										id="goal"
										onChange={this.onInputChange}
										value={goal}
									/>
								</div>
							</div>
							<CreateFaqs
								faqs={faqs}
								popFaq={this.popFaq}
								pushFaq={this.pushFaq}
							/>
							<button
								type="submit"
								className="btn btn-primary"
								onClick={this.editEnterprise}
							>
								Submit
							</button>
						</form>
					</div>
				</ContentBox>
			</Fragment>
		);
	}
}

export default CreateEnterprise;
