import React from "react";
import "./Contact.css";
import { Link } from "react-router-dom";
import Title from "../../UI/Typography/Title/Title";
import LabelledInput from "../../UI/Inputs/LabelledInput/LabelledInput";
import PrimaryBtn from "../../UI/Buttons/PrimaryBtn/PrimaryBtn";
import PeopleImg from "../../../assets/images/people.png";

const Contact = (props) => {
	return (
		<section className="Contact">
			<div className="container">
				<div className="row">
					<div className="col col-md-8 col-xl-6">
						<div className="Contact-Wrapper">
							<div className="Head">
								<Title
									size="lg"
									color="dark"
									isResponsive={true}
								>
									Speak to the Elders
								</Title>
								<p className="text text-one">
									If you have questions about your experience
									at Tribe, please check our{" "}
									<span>
										<Link to="" className="custom-link">
											FAQs
										</Link>
									</span>
									.
								</p>
								<p className="text text-second">
									If what you seek isn’t there, you can always
									reach out to the Elders, either in our live
									chat or through the form below.
								</p>
							</div>
							<div className="Body">
								<LabelledInput
									inputType="input"
									label="By what name are you known here?"
									input={{
										type: "text",
										placeholder: "Please enter",
										name: "name",
										value: props.inputs.name,
										onChange: props.inputChangeHandler,
									}}
								/>
								<LabelledInput
									inputType="input"
									label="How should the Elders reach you?"
									input={{
										type: "email",
										placeholder: "Email address",
										name: "email",
										value: props.inputs.email,
										onChange: props.inputChangeHandler,
									}}
								/>
								<LabelledInput
									inputType="input"
									label="Phone Number"
									input={{
										type: "text",
										placeholder: "Phone Number",
										name: "phoneNumber",
										value: props.inputs.phoneNumber,
										onChange: props.inputChangeHandler,
									}}
								/>
								<LabelledInput
									inputType="select"
									label="What is the nature of your question?"
									input={{
										placeholder: "Please write your query",
										name: "category",
										value: props.inputs.category,
										onChange: props.inputChangeHandler,
									}}
								>
									<option value="">Select a question</option>
									<option value="Crowdfunding in general">
										Crowdfunding in general
									</option>
									<option value="Tribe Account">
										Tribe Account
									</option>
									<option value="Tribe World">
										Tribe World
									</option>
									<option value="Sign Up">Sign Up</option>
									<option value="Profile/Dashboard">
										Profile/Dashboard
									</option>
									<option value="Campaign Creation">
										Campaign Creation
									</option>
									<option value="Issue with Payment">
										Issue with Payment
									</option>
									<option value="Donation/Pledge">
										Donation/Pledge
									</option>
									<option value="Fee">Fee</option>
									<option value="Legal Policy">
										Legal Policy
									</option>
									<option value="Withdrawal of Funds">
										Withdrawal of Funds
									</option>
									<option value="Refund">Refund</option>
									<option value="Report Abuse">
										Report Abuse
									</option>
									<option value="Report Spamming">
										Report Spamming
									</option>
									<option value="Rewards">Rewards</option>
									<option value="Other">Other</option>
								</LabelledInput>
								<LabelledInput
									inputType="textarea"
									label="How can the Elders help?"
									input={{
										type: "text",
										placeholder:
											"Let us know how we can help",
										name: "description",
										value: props.inputs.description,
										onChange: props.inputChangeHandler,
									}}
								/>
							</div>
							<div className="Footer">
								<PrimaryBtn
									type="button"
									size="sm"
									color="yellow"
									onClick={props.onSubmitHandler}
								>
									Submit
								</PrimaryBtn>
							</div>
						</div>
					</div>
					<div className="d-none d-lg-block col-lg-4">
						<div className="wrap-peopleImg">
							<img src={PeopleImg} alt="PeopleImg" />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Contact;
