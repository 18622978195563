// import configureStore from "../configureStore";
import { requestHandler } from "../utils/requestHandler";

// const store = configureStore();

class Report {
	static addReport = async (data) => {
		return await requestHandler("addReport", data);
	}

	static fetchReports = async () => {
		return await requestHandler("getReport");
	}
}

export default Report;
