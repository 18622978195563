import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { requestHandler } from "../../utils/requestHandler";
import Navbar from "../../components/layouts/Navbar";
import Sidebar from "../../components/layouts/Sidebar/Sidebar";
import ContentBox from "../../components/layouts/ContentBox/ContentBox";

class TransferRequests extends Component {
	state = { records: [] };

	componentDidMount() {
		this.loadData();
	}

	loadData = () => {
		requestHandler("getTransferRequest")
			.then((res) => {
				if (res.status === 200) {
					this.setState({ records: res.data });
				}
			})
			.catch((err) => console.log(err));
	};

	approveTransfer = (transferId, approved) => {
		requestHandler("approveTransferRequest", { transferId, approved })
			.then((res) => {
				if (res.status === 200) {
					if (approved) alert("Campaign Transfer Accepted");
					else alert("Campaign Transfer Rejected");
				}
			})
			.catch((err) => console.log(err));
	};

	getInitiatedTransfers = () => {
		const { email } = this.props.user;
		return this.state.records.filter((rec) => rec.transferTo !== email);
	};

	getReceivedTransfers = () => {
		const { email } = this.props.user;
		return this.state.records.filter((rec) => rec.transferTo === email);
	};

	initiatedTransfersBody = () => {
		const records = this.getInitiatedTransfers();

		return (
			<tbody>
				{records.map((rec) => (
					<tr>
						<td>{rec.charity.title}</td>
						<td>{rec.transferTo}</td>
						<td>{rec.status}</td>
						<td>{rec.createdAt.split("T")[0]}</td>
					</tr>
				))}
			</tbody>
		);
	};

	receivedTransfersBody = () => {
		const records = this.getReceivedTransfers();

		return (
			<tbody>
				{records.map((rec) => (
					<tr>
						<td>{rec.charity.title}</td>
						<td>{rec.currentOwner.email}</td>
						<td>{rec.status}</td>
						<td>{rec.createdAt.split("T")[0]}</td>
						<td>
							{rec.status === "pending" ? (
								<div>
									<Button
										variant="success"
										onClick={() =>
											this.approveTransfer(rec._id, true)
										}
									>
										Accept
									</Button>
									<div className="mt-3">
										<Button
											variant="danger"
											onClick={() =>
												this.approveTransfer(
													rec._id,
													false
												)
											}
										>
											Reject
										</Button>
									</div>{" "}
								</div>
							) : null}
						</td>
					</tr>
				))}
			</tbody>
		);
	};

	render() {
		return (
			<Fragment>
				<Navbar />
				<Sidebar />
				<div>
					<ContentBox title={"Transfer Requests Initiated"}>
						<table className="table">
							<thead>
								<tr>
									<th>Campaign</th>
									<th>Transfer To</th>
									<th>Transfer To Status</th>
									<th>Date</th>
								</tr>
							</thead>
							{this.initiatedTransfersBody()}
						</table>
					</ContentBox>
				</div>
				<div style={{ marginTop: "100px" }}>
					<ContentBox title={"Transfer Requests Received"}>
						<table className="table">
							<thead>
								<tr>
									<th>Campaign</th>
									<th>Current Owner</th>
									<th>Transfer Status</th>
									<th>Date</th>
									<th>Actions</th>
								</tr>
							</thead>
							{this.receivedTransfersBody()}
						</table>
					</ContentBox>
				</div>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.auth.user,
	};
}

export default connect(mapStateToProps)(TransferRequests);
