import { LOCALES } from "../locales";

export default {
    [LOCALES.SPANISH]: {
        "home": "Hogar",
        "packages": "Paquetes",
        "portfolio": "portafolio",
        "events": "Eventos",
        "blogs": "Blogs",
        "language": "Idioma",
        "login": "Iniciar sesión",
        "caps-investors": "INVERSIONISTAS",
        "caps-million_invested": "MILLONES INVERTIDOS",
        "caps-million_profits": "MILLONES DE BENEFICIOS",
        "caps-frames_impacted": "MARCOS IMPACTADOS",
        "caps-as_seen_on": "COMO SE VE EN",
        "become_a_hero": "Conviértete en un héroe",
        "become_an_angel": "Conviértete en un ángel",
        "how_it_works": "Cómo funciona",
        "for": "para",
        "caps-heroes": "HEROES",
        "caps-angels": "Ángeles",
    },
};
