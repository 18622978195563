import React, { Fragment } from "react";
import { connect } from "react-redux";

import { receiveLogout } from "../../store/actions";

import Navbar from "../../components/layouts/Navbar";
import Sidebar from "../../components/layouts/Sidebar/Sidebar";
import ContentBox from "../../components/layouts/ContentBox/ContentBox";
import Campaigns from "../../components/Campaigns/Campaigns";
import { requestHandler } from "../../utils/requestHandler";

class ManageEnterprise extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            enterprises: [],
        };
    }

    componentDidMount() {
        requestHandler("getEnterprise", { creatorId: this.props.user._id }).then(
            (result) => {
                this.setState({ enterprises: result.data, loading: false });
            }
        );
    }

    render() {
        const { enterprises } = this.state;
        return (
            <Fragment>
                <Navbar />
                <Sidebar />
                <ContentBox title="Your Enterprises">
                    <Campaigns enterprises charities={enterprises} />
                </ContentBox>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        user: state.auth.user,
    };
}

const mapDispatchToProps = (dispatch) => ({
    logoutUser: () => dispatch(receiveLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageEnterprise);
