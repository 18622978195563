import React from "react";
import { Link } from "react-router-dom";

import RewardDetails from "../Modals/RewardDetails";

const Reward = (props) => (
    <div className="card m-2" style={{ width: "18rem" }}>

        <div className="card-header">Reward</div>
        <ul className="list-group list-group-flush">
            <li className="list-group-item">
                Title: {props.title}
            </li>
            <li className="list-group-item">
                Price: {props.price}
            </li>
            <li className="list-group-item">
                Retail Price: {props.retailPrice}
            </li>
            <li className="list-group-item">
                Description: {props.description}
            </li>
            <li className="list-group-item">
                Quantity: {props.quantity}
            </li>
            <li className="list-group-item">
                Estimated Delivery Time (in days): {props.deliveryDays}
            </li>
            <RewardDetails enterpriseId={props.enterpriseId} reward={props.reward} />
            <Link to={"/reward/buy/" + props.enterpriseId + "/" + props.rewardId} >
                <li className="list-group-item btn btn-primary">
                    Purchase
                </li>
            </Link>

        </ul>
    </div>
);

export default Reward;
