import React from "react";
import "./CauseStepOne.css";
import {
	TextField,
	FormControl,
	InputLabel,
	OutlinedInput,
	InputAdornment,
} from "@material-ui/core";
import PlacesAutoCompleteInput from "../../../Inputs/PlacesAutoCompleteInput";

const CauseStepOne = (props) => {
	return (
		<div className="CauseStepOne">
			<div className="input-wrapper">
				<FormControl
					fullWidth
					// className={classes.margin}
					variant="outlined"
				>
					<InputLabel htmlFor="outlined-adornment-amount">
						Title
					</InputLabel>
					<OutlinedInput
						id="outlined-adornment-amount"
						value={0}
						// onChange={handleChange("amount")}
						labelWidth={60}
					/>
				</FormControl>
			</div>
			<div className="input-wrapper">
				<FormControl
					fullWidth
					// className={classes.margin}
					variant="outlined"
				>
					<InputLabel htmlFor="outlined-adornment-amount">
						Amount To Raise
					</InputLabel>
					<OutlinedInput
						id="outlined-adornment-amount"
						value={0}
						// onChange={handleChange("amount")}
						startAdornment={
							<InputAdornment position="start">$</InputAdornment>
						}
						labelWidth={60}
					/>
				</FormControl>
			</div>
			<div>
				<PlacesAutoCompleteInput />
			</div>
			<div className="input-wrapper">
				<TextField
					id="outlined-multiline-static"
					label="Short Description"
					defaultValue="Write a short description..."
					variant="outlined"
					multiline
					rows={4}
					fullWidth
				/>
			</div>
		</div>
	);
};

export default CauseStepOne;
