import React, { Fragment } from 'react'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Campaigns from '../components/Campaigns/Campaigns'
import ContentBox from '../components/layouts/ContentBox/ContentBox'
import Navbar from '../components/layouts/Navbar'
import Sidebar from '../components/layouts/Sidebar/Sidebar'
import ReportModal from '../components/Modals/ReportModal/ReportModal'
import UserAvatar from '../components/UserAvatar'
import Conversation from '../DataModels/Conversation'
import User from '../DataModels/User'
import { requestHandler } from '../utils/requestHandler'

class Profile extends React.Component {
	constructor(p) {
		super(p)
		this.state = {
			user: null,
			redirect: null,
			enterprises: [],
			causes: [],
			reportModalShow: false,
		}
	}

	componentDidMount() {
		this.loadUser()
		this.loadCampaigns()
	}

	loadCampaigns = () => {
		const { id } = this.props.match.params
		this.loadCauses(id)
		this.loadEnterprises(id)
	}

	loadCauses = (creatorId) => {
		requestHandler('getCharity', { creatorId }).then((result) => {
			this.setState({ causes: result.data, loading: false })
		})
	}

	loadEnterprises = (creatorId) => {
		requestHandler('getEnterprise', { creatorId }).then((result) => {
			this.setState({ enterprises: result.data, loading: false })
		})
	}

	loadUser = async () => {
		let { id } = this.props.match.params
		if (!id) {
			const user = User.GetLocalUser()
			if (user) {
				id = user._id
			} else {
				this.setState({ redirect: '/' })
			}
		}
		try {
			const user = await User.GetUserCard(id)
			this.setState({ user })
		} catch (e) {
			console.log(e)
			this.setState({ redirect: '/' })
		}
	}

	toggleReportModal = (value) => {
		this.setState({ reportModalShow: value })
	}

	startConversation = async () => {
		const { user } = this.state
		if (!this.props.user) {
			return alert('Please login to perform this activity!')
		}
		try {
			let conversation = await Conversation.FetchSingleConversation(user._id)
			this.setState({ redirect: '/chat/' + conversation._id })
		} catch (e) {
			alert('Cannot start the conversation at this moment. Please try again later')
		}
	}

	render() {
		const { user, redirect, enterprises, causes } = this.state
		return redirect ? (
			<Redirect to={redirect} />
		) : (
			<Fragment>
				<Navbar />
				<Sidebar />
				<ContentBox title='PROFILE'>
					<div className='row'>
						<div className='col'>
							<div style={{ marginLeft: '50%', marginTop: '7%' }}>
								<UserAvatar
									loading={!user}
									avatar={user ? user.avatar : null}
									style={{ width: '300px', height: '300px' }}
								/>
								<div
									style={{
										marginLeft: '6%',
										marginTop: '20px',
									}}>
									<Button
										disabled={this.props.user && user && this.props.user._id === user._id}
										onClick={this.startConversation}>
										{' '}
										Contact User{' '}
									</Button>
									<Button
										className='ml-2'
										variant='danger'
										onClick={() => this.toggleReportModal(true)}>
										Report User
									</Button>
								</div>
							</div>
						</div>
						<div className='col'>
							<div
								style={{
									alignItems: 'center',
									alignContent: 'center',
									textAlign: 'center',
								}}>
								<div
									style={{
										width: '400px',
										height: '500px',
										paddingTop: '40px',
									}}
									className='bg-light'>
									{user ? (
										<div>
											<h5 className='card-title mt-3'> Name </h5>
											<b>
												<p className='card-text'>
													{user.firstName ? user.firstName + ' ' + user.lastName : null}
												</p>
											</b>

											<h5 className='card-title mt-3'> Joined At </h5>
											<b>
												<p className='card-text'>{user.createdAt.split('T')[0]}</p>
											</b>

											<h5 className='card-title mt-3'> Location </h5>
											<b>
												<p className='card-text'>
													{user.address
														? user.address.city + ', ' + user.address.country
														: 'Not Set'}
												</p>
											</b>
										</div>
									) : (
										<Fragment>Loading...</Fragment>
									)}
								</div>
							</div>
						</div>
					</div>
				</ContentBox>
				<div className='row'>
					<div className='col'>
						<ContentBox title="Hero's causes">
							<Campaigns causes charities={causes} />
						</ContentBox>
					</div>
					<div className='col'>
						<ContentBox title="Hero's Enterprises">
							<Campaigns enterprises charities={enterprises} />
						</ContentBox>
					</div>
				</div>
				<ReportModal
					showModal={this.state.reportModalShow}
					toggleReportModal={this.toggleReportModal}
					reportType='user'
					reportedId={this.props.user._id}
					campaignId={user ? user._id : null}
				/>
			</Fragment>
		)
	}
}

function mapStateToProps(state) {
	return {
		user: state.auth.user,
	}
}

export default connect(mapStateToProps)(Profile)
