import React from "react";
import "./CheckBox.css";
import PropTypes from "prop-types";

const CheckBox = (props) => {
	const className = `customCheckBox ${props.size}`;

	return (
		<div className="CheckBox">
			<input {...props.input} type="checkbox" className={className} />
			<label className={props.color}>{props.label}</label>
		</div>
	);
};

CheckBox.propTypes = {
	color: PropTypes.oneOf(["white", "dark"]).isRequired,
    size: PropTypes.oneOf(["sm", "md"]).isRequired,
};

export default CheckBox;
