import React from "react";
import "./ContentBox.css";

const ContentBox = (props) => (
	<div className="ContentBox">
		{props.title ? (
			<div className="ContentBox-Header">
				<h3>{props.title}</h3>
			</div>
		) : null}
		<div className="Content">{props.children}</div>
	</div>
);

export default ContentBox;
