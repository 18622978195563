import React, { Component, Fragment } from "react";
import Updates from "../../DataModels/Updates";
import Navbar from "../../components/layouts/Navbar";
import AdminSidebar from "../../components/layouts/AdminSidebar/AdminSidebar";
import ContentBox from "../../components/layouts/ContentBox/ContentBox";
import ReactHtmlParser from "react-html-parser";
import { Modal, Button } from "react-bootstrap";

class AdminPendingUpdates extends Component {
	state = {
		show: false,
		records: [],
		selectedHTML: "",
		selectedCampaignId: "",
	};

	componentDidMount() {
		this.getUpdates();
	}

	handleClose = () => {
		this.setState({ show: false });
	};

	getUpdates = () => {
		Updates.getPendingUpdates()
			.then((result) => {
				if (result.status === 200) {
					this.setState({ records: result.data });
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	onViewClick = (record) => {
		this.setState({
			selectedHTML: record.message,
			selectedCampaignId: record._id,
			show: true,
		});
	};

	updateHTMLModel = () => {
		return (
			<Modal show={this.state.show} onHide={this.handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Campaign Update Content</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="m-3">
						{ReactHtmlParser(this.state.selectedHTML)}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="danger"
						onClick={() => this.statusUpdateHandler("accepted")}
					>
						Reject
					</Button>
					<Button
						variant="primary"
						onClick={() => this.statusUpdateHandler("rejected")}
					>
						Accept
					</Button>
				</Modal.Footer>
			</Modal>
		);
	};

	statusUpdateHandler = (status) => {
		const { selectedCampaignId } = this.state;

		Updates.updateCampaignStatus(selectedCampaignId, status)
			.then((result) => {
				if (result.state === 200) {
					this.getUpdates();
					alert(
						`The campaign update has been successfully ${status}`
					);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	render() {
		const { records } = this.state;

		return (
			<Fragment>
				<Navbar />
				<AdminSidebar />
				<ContentBox title="Pending Updates">
					<div className="m-5">
						<table className="table">
							<thead>
								<tr>
									<td>Campaign Name</td>
									<td>Creator Name</td>
									<td>View Update</td>
								</tr>
							</thead>
							<tbody>
								{records.length
									? records.map((record) => (
											<tr>
												<td>
													{/* {record.causeDetails
														? record.causeDetails
																.name
														: record
																.enterpriseDetails
																.name} */}
												</td>
												<td>
													{record.user.firstName +
														" " +
														record.user.lastName}
												</td>
												<td>
													<button
														className="btn btn-primary"
														onClick={() => {
															this.onViewClick(
																record
															);
														}}
													>
														View
													</button>
												</td>
											</tr>
									  ))
									: null}
							</tbody>
						</table>
						{this.updateHTMLModel()}
					</div>
				</ContentBox>
			</Fragment>
		);
	}
}

export default AdminPendingUpdates;
