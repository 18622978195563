import React from "react";
import "./SliderArrow.css";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronLeft,
    faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const SliderArrow = (props) => {
    const sliderClassName = ["SliderArrow", props.direction];
    const { direction, disabled } = props;

    if (disabled) {
        sliderClassName.push("disabled");
    }

    return (
        <div
            className={sliderClassName.join(" ")}
            style={{ "bottom": `${props.fromBottom}%` }}
            onClick={props.onClick}
        >
            <span className="icon">
                <FontAwesomeIcon
                    icon={direction === "left" ? faChevronLeft : faChevronRight}
                />
            </span>
        </div>
    );
};

SliderArrow.propTypes = {
    direction: PropTypes.oneOf(["left", "right"]).isRequired,
    disabled: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    fromBottom: PropTypes.string.isRequired,
};

export default SliderArrow;
