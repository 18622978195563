import { requestHandler } from '../utils/requestHandler'

export default class TransferRequest {
	constructor(_id, charityId, type, transferTo, currentOwner, status) {
		this._id = _id
		this.charityId = charityId
		this.type = type
		this.transferTo = transferTo
		this.currentOwner = currentOwner
		this.status = status
	}

	create() {
		return requestHandler('createTransferRequest', this).then((result) => {
			return result.status === 200 ? true : result.message
		})
	}
}
