import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import Donation from '../../../DataModels/Donation'
export default class RefundModal extends React.Component {
	constructor(p) {
		super(p)
		this.state = {}
	}

	componentDidMount() {
		console.log('hhhhhhhhhhhhhhhhh/', this.props.reloadData)
	}

	onMakeRequest = async () => {
		const { donation } = this.props
		const result = await Donation.refundDonation(donation._id)
		this.props.reloadData()
		this.props.onClose()
	}

	render() {
		const { donation } = this.props
		console.log('the donation: ', donation)
		return !donation ? null : (
			<Modal show={this.props.show} onHide={this.props.onClose}>
				<Modal.Header closeButton>
					<Modal.Title></Modal.Title>
					<Modal.Body>
						<div>
							<b>REFUND REQUEST</b>
							<br />
							<br />
							<br />
							Are you sure you want to make a Refund request?
							<br />
							<br />
							Cause: <b>{donation.charity.title}</b>
							<br />
							Donation date: <b>{donation.createdAt.split('T')[0]}</b>
							<br />
							Donation amount: <b>{donation.donationAmount}</b>
							<br />
							<Button onClick={this.onMakeRequest}> Make Request </Button>
						</div>
					</Modal.Body>
					<Modal.Footer></Modal.Footer>
				</Modal.Header>
			</Modal>
		)
	}
}
