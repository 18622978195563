import React, { useState } from "react";
import "./Header.css";
import Navbar from "../Navbar/Navbar";
import PropTypes from "prop-types";
import Title from "../../UI/Typography/Title/Title";
import PrimaryBtn from "../../UI/Buttons/PrimaryBtn/PrimaryBtn";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import ModalVideo from "react-modal-video";

const Header = (props) => {
    const [videoModal, setVideoModal] = useState(false);
	const [videoId, setVideoId] = useState("Q5NuA91RhAQ");

    const toggleVideoModal = () => {
        setVideoModal(!videoModal);
    };

    if (videoModal) {
        document.body.style.overflow = "hidden";
    } else {
        document.body.style.overflow = "visible";
        document.body.style.overflowX = "hidden";
    }

    return (
        <section className="Header">
            <div className="wrap-header-bg">
                <img src={props.img} alt="header-bg-img" />
            </div>
            <div className="overlay"></div>
            <Navbar mode="white" />
            <div className="container content-container">
                <div className="row">
                    <div className="col">
                        <Title size="lg" color="white" isResponsive={true}>
                            Everyday Heroes
                        </Title>
                        <Title size="lg" color="white" isResponsive={true}>
                            crowdfunding a
                        </Title>
                        <Title size="lg" color="white" isResponsive={true}>
                            better world
                        </Title>
                        <p className="text">Are you ready to join the tribe?</p>
                        <div className="btn-wrapper">
                            <PrimaryBtn
                                type="button"
                                size="md"
                                color="transparent"
                                icon={faArrowRight}
                                effect="hvr-icon-forward"
                                onClick={toggleVideoModal}
                            >
                                Play Video
                            </PrimaryBtn>
                        </div>
                    </div>
                </div>
            </div>

            <div className="Header-VideoModal">
                <ModalVideo
                    channel="youtube"
                    isOpen={videoModal}
                    videoId={videoId}
                    onClose={toggleVideoModal}
                />
            </div>
        </section>
    );
};

Header.propTypes = {
    img: PropTypes.any.isRequired,
};

export default Header;
