import React from "react";
import "./AuthModal.css";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const AuthModal = (props) => {
	return (
		<Modal
			className="AuthModal"
			show={props.showModal}
			onHide={props.toggleModal}
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header>
				{props.headerBtn === "prev" ? (
					<button
						type="button"
						className="iconBtn"
						onClick={props.headerBtnClick}
					>
						<span className="icon">
							<FontAwesomeIcon icon={faChevronLeft} />
						</span>
					</button>
				) : props.toggleModal ? (
					<button
						type="button"
						className="close"
						onClick={props.toggleModal}
					>
						<span aria-hidden="true">&times;</span>
					</button>
				) : null}
				<h6 className="modal-title">{props.title}</h6>
			</Modal.Header>
			<Modal.Body>{props.children}</Modal.Body>
		</Modal>
	);
};

export default AuthModal;
