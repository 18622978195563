import React, { Fragment } from "react";
import { connect } from "react-redux";
import queryString from "query-string";

import { receiveLogout } from "../../store/actions";
import Navbar from "../../components/layouts/Navbar";
import Sidebar from "../../components/layouts/Sidebar/Sidebar";
import ContentBox from "../../components/layouts/ContentBox/ContentBox";
import Campaigns from "../../components/Campaigns/Campaigns";
import { requestHandler } from "../../utils/requestHandler";
import CauseCreatedPopup from "../../components/Modals/CauseCreatedPopup";

class ManageCampaign extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			charities: [],
			popupModal: null,
		};
	}

	componentDidMount() {
		const { id, popup, newaccount } = queryString.parse(
			window.location.search
		);
		if (popup && popup.length) {
			this.setState({
				popupModal: {
					popup,
					id,
					newaccount,
				},
			});
		}
		requestHandler("getCharity", { creatorId: this.props.user._id }).then(
			(result) => {
				this.setState({ charities: result.data, loading: false });
			}
		);
	}

	closeCauseCreatedPopup = () => {
		console.log("here");
		this.setState({ popupModal: null });
	};

	renderPopupModal = () => {
		const { popup, id, newaccount } = this.state.popupModal;
		// return (<MyModel />)
		return (
			<CauseCreatedPopup
				onClose={this.closeCauseCreatedPopup}
				type={popup}
				refId={id}
				newAccount={newaccount === "true" ? true : false}
			/>
		);
	};

	render() {
		const { charities, popupModal } = this.state;
		return (
			<Fragment>
				<Navbar />
				<Sidebar />
				<ContentBox title="Your Causes">
					<Campaigns charities={charities} />
					{popupModal ? this.renderPopupModal() : null}
				</ContentBox>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		user: state.auth.user,
	};
}

const mapDispatchToProps = (dispatch) => ({
	logoutUser: () => dispatch(receiveLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageCampaign);
