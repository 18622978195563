import React, { Component } from "react";
import "./LoginMobile.css";
import $ from "jquery";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { GoogleLogin } from "react-google-login";
import { FACEBOOK_APP_ID, GOOGLE_AUTH_ID } from "../../../../utils/constants";
import { requestHandler } from "../../../../utils/requestHandler";
import { receiveLogin } from "../../../../store/actions";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// Components
import TribePrimaryBtn from "../../../../components/UI/Buttons/TribePrimaryBtn/TribePrimaryBtn";
import HorizontalLine from "../../../../components/UI/HorizontalLine/HorizontalLine";
import PrimaryAuthInput from "../../../../components/UI/Inputs/PrimaryAuthInput/PrimaryAuthInput";
import Title from "../../../../components/UI/Typography/Title/Title";
import sweetalert from "sweetalert";

class LoginMobile extends Component {
	state = {
		email: "",
		password: "",
		errorMessage: "",
		redirect: null,
		failedWithEmail: null,
		failedLoginAttempts: null,
	};

	componentDidMount() {
		if (document.body.clientWidth > 992) {
			this.props.history.push("/");
		}
	}

	onChange = (e) => {
		this.setState({ [e.target.id]: e.target.value });
	};

	signIn = (e) => {
		e.preventDefault();
		const { email, password } = this.state;
		if (email === this.state.failedWithEmail) {
			sweetalert(
				"Error",
				"Too many failed login attempts for this user. Please try again later or refresh the page.",
				"error"
			);
			return;
		}
		requestHandler("login", {
			email: email.toLowerCase(),
			password,
			userType: "other",
		}).then(
			(result) => {
				if (result.status === 200) {
					localStorage.setItem("auth", JSON.stringify(result.data));
					$("#LoginModalId").modal("hide");
					this.props.loginUser(result.data);
					this.setState({ redirect: "/dashboard" });
				} else {
					if (result.failedLoginAttempts > 4) {
						this.setState({
							fauledAttempts: result.failedLoginAttempts,
							failedWithEmail: email,
						});
						sweetalert(
							"Error",
							"Too many failed login attempts for this user. Please try again later or refresh the page.",
							"error"
						);
						return;
					}
					alert(result.message);
				}
			},
			(e) => {
				console.log("ERROR: ", e);
			}
		);
		return;
	};

	socialMediaLogin = (data, socialNetwork) => {
		requestHandler("socialLogin", { data, socialNetwork }).then(
			(result) => {
				if (result.status === 200) {
					localStorage.setItem("auth", JSON.stringify(result.data));
					this.props.loginUser(result.data);
				} else {
					sweetalert("Error", result.message, "error");
				}
			},
			(e) => {
				console.log("ERROR: ", e);
			}
		);
		return;
	};

	responseFacebook = (response) => {
		console.log("Facebook Login: ", response);
		if (!response.accessToken) return console.log("Login not successful");
		let data = {
			firstName: response.name,
			lastName: "",
			email: response.email ? response.email : "",
			socialId: response.userID,
			socialObj: response,
		};
		this.socialMediaLogin(data, "facebook");
	};

	responseGoogle = (response) => {
		console.log("Google Login: ", response);
		if (!response.accessToken) return console.log("Login not successful");
		let data = {
			firstName: response.profileObj.givenName
				? response.profileObj.givenName
				: "",
			lastName: response.profileObj.familyName
				? response.profileObj.familyName
				: "",
			email: response.profileObj.email ? response.profileObj.email : "",
			socialId: response.googleId,
			socialObj: response,
		};
		this.socialMediaLogin(data, "google");
	};

	render() {
		const { email, password, redirect } = this.state;

		if (redirect) {
			return <Redirect to={redirect} />;
		}

		return (
			<main className="LoginMobile">
				<section className="LoginMobile-Section">
					<div className="container">
						<div className="row">
							<div className="col-10 col-sm-8 col-md-7 col-lg-6 col-xl-5 mx-auto">
								<div className="LoginMobile-Body">
									<div className="header">
										<div className="header-title">
											<Title size="md" color="white">
												Sign into tribe
											</Title>
										</div>
										<Link to="/">
											<div className="header-times">
												&times;
											</div>
										</Link>
									</div>
									<div className="form">
										<div className="wrap-input">
											<label htmlFor="email">Email</label>
											<PrimaryAuthInput
												id="email"
												value={email}
												onChange={this.onChange}
												borderStyle="rounded"
												type="email"
												placeholder="Email"
											/>
										</div>
										<div className="wrap-input">
											<label htmlFor="password">
												Password
											</label>
											<PrimaryAuthInput
												id="password"
												value={password}
												onChange={this.onChange}
												borderStyle="rounded"
												type="password"
												placeholder="Password"
											/>
										</div>
										<div className="other-options">
											<Link
												to="/resetpassword"
												className="forgotLink"
											>
												Forgot Password
											</Link>
											<div className="login-checkBox">
												<input
													id="rememberMeCheckbox"
													type="checkbox"
												/>
												<label htmlFor="rememberMeCheckbox">
													Remember me
												</label>
											</div>
										</div>
										<div className="group-buttons">
											<TribePrimaryBtn
												type="hero"
												onClick={this.signIn}
											>
												SIGN IN
											</TribePrimaryBtn>

											<HorizontalLine color="white" />

											<div className="mt-3">
												<FacebookLogin
													appId={FACEBOOK_APP_ID}
													fields="name,email,picture"
													callback={
														this.responseFacebook
													}
													render={(renderProps) => (
														<TribePrimaryBtn
															onClick={
																renderProps.onClick
															}
															type="facebook"
														>
															SIGN IN WITH
															FACEBOOK
														</TribePrimaryBtn>
													)}
												/>
											</div>

											<div className="mt-3">
												<GoogleLogin
													clientId={GOOGLE_AUTH_ID}
													render={(renderProps) => (
														<TribePrimaryBtn
															onClick={
																renderProps.onClick
															}
															disabled={
																renderProps.disabled
															}
															type="google"
														>
															SIGN IN WITH GOOGLE
														</TribePrimaryBtn>
													)}
													buttonText="Login"
													onSuccess={
														this.responseGoogle
													}
													onFailure={
														this.responseGoogle
													}
													cookiePolicy={
														"single_host_origin"
													}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
		);
	}
}

function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		isVerifying: state.auth.isVerifying,
		user: state.auth.user,
		language: state.language.language,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		loginUser: (user) => dispatch(receiveLogin(user)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginMobile);
