import React from "react";
import "./NavbarWithBackdrop.css";
import PropTypes from "prop-types";
import Navbar from "../../Sections/Navbar/Navbar";
import Backdrop from "../../layouts/Backdrop/Backdrop";

const NavbarWithBackdrop = (props) => {
    return (
        <div className="NavbarWithBackdrop">
            <Navbar mode={props.navbarMode} />
            <Backdrop
                img={props.backdropImg}
                text={props.text}
                textColor={props.textColor}
            />
        </div>
    );
};

NavbarWithBackdrop.propTypes = {
    navbarMode: PropTypes.oneOf(["white", "dark"]).isRequired,
    backdropImg: PropTypes.any.isRequired,
    text: PropTypes.string.isRequired,
    textColor: PropTypes.oneOf(["white", "dark"]).isRequired,
};

export default NavbarWithBackdrop;
