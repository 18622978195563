import React, { Component } from "react";
import "./HowItWorks.css";
import NavbarWithBackdrop from "../../../components/Sections/NavbarWithBackdrop/NavbarWithBackdrop";
import Footer from "../../../components/Sections/Footer/Footer";
import CollapseCard from "../../../components/UI/Cards/CollapseCard/CollapseCard";
import Title from "../../../components/UI/Typography/Title/Title";

// Images
import BackdropImg from "../../../assets/images/backdropTest.png";

class HowItWorks extends Component {
	constructor(props) {
		super(props);
		this.state = {
			options: ["general", "hero", "angel"],
			activeOption: "general",
		};

		this.generalRef = React.createRef();
		this.heroRef = React.createRef();
		this.angelRef = React.createRef();
		this.optionsEl = React.createRef();

		window.onscroll = this.onScrollHandler;
	}

	onScrollHandler = () => {
		if (this.optionsEl.current) {
			if (window.scrollY >= 425) {
				this.optionsEl.current.style.position = "sticky";
				this.optionsEl.current.style.top = "1rem";
			} else {
				this.optionsEl.current.style.position = "static";
			}
		}
	};

	selectOptionHandler = (selectedOpt) => {
		const { options } = this.state;
		const doesExist = options.find((opt) => opt === selectedOpt);

		if (doesExist) {
			this.setState({ activeOption: selectedOpt });

			switch (selectedOpt) {
				case "general":
					this.generalRef.current.scrollIntoView();
					break;
				case "hero":
					this.heroRef.current.scrollIntoView();
					break;
				case "angel":
					this.angelRef.current.scrollIntoView();
					break;
				default:
					return null;
			}
		} else {
			return;
		}
	};

	render() {
		return (
			<main className="HowItWorks">
				<NavbarWithBackdrop
					navbarMode="white"
					backdropImg={BackdropImg}
					text="How It Works"
					textColor="white"
				/>

				{/* <div style={{ height: "100vh" }}></div> */}

				<section className="HowItWorks-Body">
					<div className="container">
						<div className="row">
							<div className="col col-xl-8 mx-auto">
								<div className="Faq-Section General-Faq">
									<div className="section-title">
										<Title
											size="md"
											color="dark"
											isResponsive={true}
										>
											Strapline
										</Title>
									</div>
									<CollapseCard
										title="What is Tribe, Why it
											Exists"
									>
										<p>
											Welcome to Tribe, the crowdfunding
											platform that lets ordinary people
											create extraordinary change. Where
											Heroes and Angels come together to
											build a better world. Where even a
											small contribution can make a big
											difference.
										</p>
										<p>
											Tribe was founded to help everyday
											Heroes find the support and
											resources they need to realise their
											dreams and make real, positive
											changes in their community. We also
											pride ourselves on providing our
											Angels with a straightforward and
											transparent way to support exciting
											new ideas and innovative projects.
											So, let’s break it down.
										</p>
									</CollapseCard>
								</div>

								<div className="Faq-Section General-Faq">
									<div className="section-title">
										<Title
											size="md"
											color="dark"
											isResponsive={true}
										>
											Heroes
										</Title>
									</div>
									<CollapseCard title="How it Works for Heroes">
										<p>
											Tribe is a powerful tool for
											bringing your ideas to life. We can
											help you find funding, mentoring and
											a community who are as invested in
											your project as you are. All it
											takes is three simple steps:
										</p>
									</CollapseCard>
									<CollapseCard title="Register an Account">
										<p>
											Sign up to Tribe as a Hero and
											register your details. You’ll be
											able to create an avatar, upload
											pictures and outline where in the
											world you are and what you’re
											interested in so we can help match
											you with like minded Angels and
											Heroes.
										</p>
									</CollapseCard>
									<CollapseCard title="Create your Campaign">
										<p>
											Following our step by step guidance,
											create a campaign page for your
											project. All the information you
											need will be explained on the pages
											as you move through the creation
											process, but if you want any extra
											help, we have an extensive library
											of guides and documents, and our
											Elders are always on hand to answer
											any questions.
										</p>
									</CollapseCard>
									<CollapseCard title="Spread the Word">
										<p>
											Share your campaign page with the
											world through email, social media
											and on Tribe itself. Again, we have
											extensive guidance on how best to
											get your campaign seen, and our
											Elders are available any time you
											need to talk.
										</p>
									</CollapseCard>
								</div>

								<div className="Faq-Section General-Faq">
									<div className="section-title">
										<Title
											size="md"
											color="dark"
											isResponsive={true}
										>
											Angels
										</Title>
									</div>
									<CollapseCard title="How it Works for Angels">
										<p>
											Tribe gives you exclusive access to
											some of the most exciting and
											important new projects and startups
											in the world. With total
											transparency, you can see exactly
											how your money is making a positive
											change in the world with just three
											simple steps
										</p>
									</CollapseCard>
									<CollapseCard title="Register an Account">
										<p>
											Sign up to Tribe as an Angel and
											create your profile. Create an
											avatar, upload pictures and be sure
											to let us know where in the world
											you are and where your interests lie
											so we can help you find the project
											that’s right for you.
										</p>
									</CollapseCard>
									<CollapseCard title="Find a Project">
										<p>
											Use Tribe’s search function or
											browse our featured projects until
											you find something that inspires
											you.
										</p>
									</CollapseCard>
									<CollapseCard title="Change the World">
										<p>
											When you’ve settled on a worthy Hero
											to support, making a donation is
											simple. Just click on the Donate Now
											button, follow the instructions, and
											help build a better world.
										</p>
									</CollapseCard>
								</div>

								<div className="Faq-Section General-Faq">
									<div className="section-title">
										<Title
											size="md"
											color="dark"
											isResponsive={true}
										>
											Let's join hands to make a change
										</Title>
									</div>
									<CollapseCard title="So What Are You Waiting For?">
										<p>
											That’s it - that’s everything you
											need to know to start building a
											better world through Tribe. Only one
											question remains: Are you ready to
											join the Tribe?
										</p>
									</CollapseCard>
								</div>
							</div>
						</div>
					</div>
				</section>

				<Footer />
			</main>
		);
	}
}

export default HowItWorks;
