import React from 'react'


const CustomLabel = (props) => (
    <input
        className="form-control"
        {...props}
    />
)

export default CustomLabel