import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import User from '../../DataModels/User'
import MapLocationViewer from '../Modals/MapLocationViewer'
import UserAvatar from '../UserAvatar'

export default class UserCard extends React.Component {
	constructor(p) {
		super(p)
		this.state = {
			name: '',
			noOfCauses: '',
			noOfEnterprises: '',
			location: '',
			refuntPopup: false,
			showMapLocation: false,
		}
	}

	componentDidMount() {
		this.loadData()
	}

	loadData = async () => {
		const user = await User.GetUserCard(this.props.userId)
		console.log({ user })
        user.address = user.address || {}
		this.setState({
			name: `${user.firstName} ${user.lastName}`,
			noOfCauses: user.charities,
			noOfEnterprises: user.enterprises,
			location: `${user.address.city}, ${user.address.country}`,
			locationName: user.address.location,
			lat: user.address.lat,
			lng: user.address.lng,
			avatar: user.avatar,
		})
	}

	toggleShowMapModal = () => {
		this.setState({ showMapLocation: !this.state.showMapLocation })
	}

	render() {
		const {
			name,
			noOfCauses,
			noOfEnterprises,
			location,
			locationName,
			lat,
			lng,
			showMapLocation,
			avatar,
		} = this.state

		return (
			<div style={{}}>
				{showMapLocation ? (
					<MapLocationViewer
						show={true}
						toggleModal={this.toggleShowMapModal}
						location={{
							locationName,
							lat,
							lng,
						}}
					/>
				) : null}
				<div className={'mx-auto bg-light'} style={{ borderStyle: 'ridge' }}>
					<div className='row'>
						<div className='col'>
							<div
								style={{
									alignContent: 'center',
									alignItems: 'center',
									alignSelf: 'center',
									margin: '20%',
								}}>
								<UserAvatar avatar={avatar} style={{ width: '150px', height: '150px' }} />
							</div>
						</div>
						<div className='col' style={{ marginTop: '5%' }}>
							<div className='head'>
								<p className='text'>Name: {name}</p>
								<p className='text'>Hero to {noOfCauses} Causes </p>
								<p className='text'>Hero to {noOfEnterprises} Enterprises </p>
								<p className='text'>Location: {location}</p>
								<Link to={'/profile/' + this.props.userId}>View Profile</Link>
								<br />
								<Button onClick={this.toggleShowMapModal}>View Location</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
