import React from 'react'
import { Redirect } from 'react-router-dom'
import User from '../../DataModels/User'

class ReloadUser extends React.Component {
	state = {
		loading: true,
		redirect: null
	}
	componentDidMount() {
		this.reloadUser()
	}

	reloadUser = async () => {
		console.log('loading - user')
		await User.ReloadUser()
		console.log('user loaded ')
		this.setState({ redirect: '/dashboard', loading: false })
	}

	render() {
		const { loading, redirect } = this.state
		return loading ? <div></div> : <Redirect to={redirect} />
	}
}

export default ReloadUser
