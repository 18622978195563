import React from "react";
import "./ForgotPassword.css";

import BannerImg from "../../../../assets/images/ForgotPasswordBannerImg.jpg";
import TribeHeaderLogo from "../../../../components/Sections/TribeHeaderLogo/TribeHeaderLogo";
import TopBanner from "../../../../components/layouts/TopBanner/TopBanner";
import ForgotPasswordEmailRequest from "./ForgotPasswordEmailRequest";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

const ForgotPassword = () => {
	return (
		<main>
			<section id="ForgotPassword">
				<TribeHeaderLogo />
				<TopBanner img={BannerImg} />
				<ForgotPasswordEmailRequest redirect="/" />
			</section>
			<div style={{ marginBottom: "100px" }}>
				<div className="container">
					<div className="d-flex align-items-center">
						<div style={{ marginLeft: "41%" }}>
							<Link to="/login"><Button>Sign In</Button></Link>
						</div>
						<div style={{ marginLeft: "20px" }}>
							<Link to="/register"><Button>Register</Button></Link>
						</div>
					</div>
				</div>

			</div>
		</main >
	);
}

export default ForgotPassword;
