import configureStore from "../store/configureStore";
import { receiveLogin as loginUser } from "../store/actions";
const store = configureStore();
const { requestHandler } = require("../utils/requestHandler");

class User {
	static template = {
		_id: "",
		firstName: "",
		lastName: "",
		email: "",
		password: "",
		avatar: "",
		phone: "",
		userType: "",
		address: "",
		shippingAddresses: "",
		terms: "",
		promotionalEmails: "",
		reset_token: "",
		socialId: "",
		socialObj: "",
		createdAt: "",
		updatedAt: "",
	};

	static registerUser = async (userData) => {
		let registeringData = {
			...userData,
			service: true,
		};

		return await requestHandler("register", registeringData);
	};

	static FetchAllUsers = async () => {
		const allUsers = await requestHandler("getAllUsers");
		return allUsers.data;
	};

	static ResendVarificationEmail = async () => {
		let response = await requestHandler("resendVarificationEmail", {});
		console.log(response);
		if (response.status === 200) {
			alert("Varification email sent");
		} else {
			alert("Something went wrong");
		}
	};

	static GetUserCard = async (id) => {
		let response = await requestHandler("getUserCard", { id });
		if (response.data && response.data[0]) {
			let user = response.data[0];
			return user;
		} else return null;
	};

	static GetAllUserCard = async (id) => {
		return await requestHandler("getUserCard");
	};

	static GetLocalUser = () => {
		const user = localStorage.getItem("auth");
		if (!user) {
			return;
		}
		return JSON.parse(user);
	};

	static ReloadUser = async () => {
		let user = localStorage.getItem("auth");

		if (!user) {
			return;
		}
		user = JSON.parse(user);
		const token = user.token;
		let response = await requestHandler("getUser", { id: user._id });
		if (response.data && response.data[0]) {
			let newUser = response.data[0];
			newUser.token = token;
			localStorage.setItem("auth", JSON.stringify(newUser));
			return newUser;
		} else {
			// logout out here. JWT expired
			localStorage.removeItem("auth");
			window.location = "/";
		}
	};

	static signIn = async (email, password) => {
		try {
			let result = await requestHandler("login", {
				email: email.toLowerCase(),
				password,
				userType: "other",
			})
			console.log({ result })
			if (result.status === 200) {
				localStorage.setItem("auth", JSON.stringify(result.data));
				store.dispatch(loginUser(result.data));
			} else {
				alert(result.message);
			}
		} catch (e) {
			console.log("ERROR: ", e);
			throw e
		}
		// .then(
		// 	(result) => {
		// 		if (result.status === 200) {
		// 			localStorage.setItem("auth", JSON.stringify(result.data));
		// 			store.dispatch(loginUser(result.data));
		// 		} else {
		// 			alert(result.message);
		// 		}
		// 	},
		// 	(e) => {
		// 		console.log("ERROR: ", e);
		// 	}
		// );
	};

	static varifyEmail = (token) => {
		return requestHandler("varifyEmail", { token });
	};

	static socialMediaLogin = (data, socialNetwork) => {
		requestHandler("socialLogin", { data, socialNetwork }).then(
			(result) => {
				if (result.status === 200) {
					localStorage.setItem("auth", JSON.stringify(result.data));
					store.dispatch(loginUser(result.data));
				} else {
					alert(result.message);
				}
			},
			(e) => {
				console.log("ERROR: ", e);
			}
		);
		return;
	};

	static forgotPasswordEmailRequest = (email) => {
		console.log({ email });
		return requestHandler("resetPasswordRequest", { email });
	};
}

export default User;
