import React, { Component } from 'react'
import { connect } from 'react-redux'
import UserAvatar from '../../components/UserAvatar'
import User from '../../DataModels/User'

class ChatThreadCard extends Component {
	constructor(props) {
		super(props)
		this.state = {
			user: null
		}
	}

	componentDidMount() {
		this.loadUser()
	}

	loadUser = async () => {
		const {
			// _id,
			userA,
			userB
			// userAUnread,
			// userBUnread,
			// createdAt
		} = this.props.conversation
		const toGetUser = userA === this.props.user._id ? userB : userA
		const user = await User.GetUserCard(toGetUser)
		this.setState({ user })
	}

	render() {
		const { user } = this.state
		return (
			<div onClick={this.props.loadChat}>
				<div
					className={
						this.props.selected ? 'row bg-info' : 'row bg-info'
					}
					style={{
						borderStyle: 'outset',
						marginLeft: '3px',
						marginRight: '3px',
						marginTop: '5px'
					}}>
					<div className='col'>
						<UserAvatar style={{ width: '60px', height: '60px' }} />
					</div>
					<div className='col mt-4 pr-5'>
						<b>{user ? user.firstName : null}</b>
					</div>
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		user: state.auth.user
	}
}

export default connect(mapStateToProps)(ChatThreadCard)
