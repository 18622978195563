import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Editor } from "@tinymce/tinymce-react";
import $ from "jquery";
import queryString from "query-string";
import React, { Fragment } from "react";
import { Button, Modal } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { connect } from "react-redux";
import { Link, Redirect, withRouter } from "react-router-dom";
import img from "../../assets/images/Card.jpg";
import CampaignComments from "../../components/CampaignComments";
import CampaignUpdates from "../../components/CampaignUpdates";
import ContentBox from "../../components/layouts/ContentBox/ContentBox";
import Navbar from "../../components/layouts/Navbar";
import Sidebar from "../../components/layouts/Sidebar/Sidebar";
import MapLocationViewer from "../../components/Modals/MapLocationViewer";
import ReportModal from "../../components/Modals/ReportModal/ReportModal";
import ShowFaqs from "../../components/ShowFaqs";
import SocialMediaShare from "../../components/SocialMediaShare";
import UserCard from "../../components/UserCard";
import Conversation from "../../DataModels/Conversation";
import TransferRequest from "../../DataModels/TransferRequest";
import User from "../../DataModels/User";
import { receiveLogout } from "../../store/actions";
import { tinyCloudAPIKey } from "../../utils/constants";
import { requestHandler } from "../../utils/requestHandler";
import "./CharityDetails.css";

class CharityDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
            transferTo: '',
			showTransferModal: false,
			redirect: null,
			title: "",
			goal: "",
			shortDesc: "",
			story: "",
			loading: true,
			location: "",
			donations: [],
			achieved: "",
			charityInCreation: props.charityInCreation,
			customUrl: "",
			content: [],
			faqs: [],
			showMapLocation: false,
			modal: {
				currentSlide: 1,
				totalSlies: 3,
			},
			creator: null,
			reportModalShow: false,
		};
	}

	toggleTransferModal = () => {
		this.setState({ showTransferModal: !this.state.showTransferModal });
	};

	toggleShowMapModal = () => {
		this.setState({ showMapLocation: !this.state.showMapLocation });
	};

	componentDidMount() {
		// Getting ID from URL
		if (!this.state.charityInCreation) {
			const { id } = this.props.match.params;
			this.setState({ id }, this.loadData);
		} else {
			$("#uploadPicturesModal").modal({
				backdrop: "static",
				keyboard: false,
			});
			$("#uploadPicturesModal").modal("show");
		}
	}

	loadData = () => {
		this.loadCharity();
	};

	startConversation = async () => {
		const { user } = this.state;
		if (!this.props.user) {
			return alert("Please login to perform this activity!");
		}
		try {
			let conversation = await Conversation.FetchSingleConversation(
				user._id
			);
			this.setState({ redirect: "/chat/" + conversation._id });
		} catch (e) {
			alert(
				"Please login to start a conversation"
			);
		}
	};

	loadCharity = () => {
		const { id } = this.state;
		requestHandler("getCharity", { id }).then(
			(result) => {
				const charity = result.data[0];
				if (!charity) {
					return this.props.history.push("/404-not-found");
				}
				console.log({ charity });
				this.loadCreator(charity.creatorId);
				this.setState(
					{
						id: charity._id,
						creatorId: charity.creatorId,
						goal: charity.goal,
						title: charity.title,
						story: charity.story,
						status: charity.status,
						shortDesc: charity.shortDescription,
						content: charity.content,
						faqs: charity.faqs,
						locationName: charity.locationName,
						latLng: charity.location.coordinates,
					},
					this.loadDonations
				);
			},
			(err) => {
				console.log("ERROR: ", err);
			}
		);
	};

	loadCreator = async (userId) => {
		try {
			const creator = await User.GetUserCard(userId);
			this.setState({
				creator,
			});
		} catch (e) {
			console.log(e);
		}
	};

	toggleReportModal = (value) => {
		this.setState({ reportModalShow: value });
	};

	calculateAchieved = (donations) => {
		let achieved = 0;
		donations.forEach((donation) => {
			achieved += donation.donationAmount;
		});
		this.setState({ achieved });
	};

	loadDonations = () => {
		const { id } = this.state;
		requestHandler("getDonationsForCharity", { id }).then(
			(response) => {
				const donations = response.data;
				this.setState({
					donations,
				});
				this.calculateAchieved(donations);
			},
			(err) => {
				console.log("ERROR: ", err);
			}
		);
	};

	onFileInputChange = (event) => {
		this.setState({ content: event.target.files });
	};

	onInputChange = (item) => {
		this.setState({
			[item.target.id]: item.target.value,
		});
	};

	renderTableBody = () => {
		const { donations } = this.state;
		return (
			<tbody>
				{donations.map((donation, index) => {
					if (!donation.user) {
						donation.user = {
							firstName: "User Deleted",
							lastName: "User Deleted",
							email: "User Deleted",
						};
					}
					return (
						<tr key={index}>
							<td>
								{!donation.donateAnonymously
									? donation.user.firstName +
									  " " +
									  donation.user.lastName
									: "Anonymous"}
							</td>
							{/* <td>{ !donation.donateAnonymously ? donation.user.email : 'Anonymous'}</td> */}
							<td>{donation.donationAmount}</td>
							<td>{donation.createdAt.split("T")[0]}</td>
							<td>
								{donation.message.length ? (
									donation.message
								) : (
									<>NO MESSAGE</>
								)}
							</td>
							<td>
								<Link to={"mailto:" + donation.user.email}>
									Send Email to {donation.user.email}
								</Link>
							</td>
						</tr>
					);
				})}
			</tbody>
		);
	};

	renderDonationsTable = () => (
		<ContentBox title="Donations for this Cause">
			<table className="table">
				<thead>
					<tr>
						<td>Donar Name</td>
						<td>Donation Amount</td>
						<td>Donation Date</td>
						<td>Message</td>
						<td>Contact Agnel</td>
					</tr>
				</thead>
				{this.renderTableBody()}
			</table>
		</ContentBox>
	);
	handleEditorChange = (story) => {
		this.setState({ story });
	};

	changeCauseStatus = (data) => {
		requestHandler("changeCauseStatus", data).then((response) => {
			this.loadCharity();
			this.loadDonations();
		});
	};

	modalSlideChangeHandler = (type, event) => {
		if (event) {
			event.preventDefault();
		}

		const { currentSlide, totalSlies } = this.state.modal;

		if (type === "inc" && currentSlide < totalSlies) {
			this.setState((prevState, props) => {
				return {
					modal: {
						currentSlide: prevState.modal.currentSlide + 1,
						totalSlies: prevState.modal.totalSlies,
					},
				};
			});
		} else if (type === "dec" && currentSlide !== 1) {
			this.setState((prevState, props) => {
				return {
					modal: {
						currentSlide: prevState.modal.currentSlide - 1,
						totalSlies: prevState.modal.totalSlies,
					},
				};
			});
		}
	};

	createCharityHandler = (e) => {
		e.preventDefault();
		const { story, customUrl, content } = this.state;
		console.log("PROPS: ", this.props);
		const {
			title,
			goal,
			shortDesc,
			categoryId,
			creatorId,
			locationName: location,
			faqs,
			latLng,
		} = this.props;
		console.log({
			title,
			goal: Number(goal).toString(),
			location,
			story,
		});
		if (!title.length || !goal.length || !location.length || !story.length)
			return alert("Please fill all fields");
		if (customUrl.length && customUrl.length < 10)
			return alert("Custom URL should be at least 10 characters");

		const formData = new FormData();
		formData.append("title", title);
		formData.append("goal", goal);
		formData.append("story", story);
		formData.append("locationName", location);
		formData.append("shortDescription", shortDesc);
		formData.append("categoryId", categoryId);
		formData.append("creatorId", creatorId);
		formData.append("customUrl", customUrl);
		formData.append("location", [latLng[1], latLng[0]]);
		formData.append("faqs", JSON.stringify(faqs));
		for (let file of content) {
			formData.append("content", file);
		}

		requestHandler("createCharity", formData).then((result) => {
			if (result.status === 200) {
				this.setState({
					redirect:
						"/charity/manage?" +
						queryString.stringify({
							newaccount: false,
							id: result.data[0]._id,
							popup: "causecreated",
						}),
				});
			}
		});

		$("#uploadPicturesModal").modal("hide");
	};

    makeTransferRequest = async () => {
        const { transferTo, id, creatorId } = this.state
        const transferRequest = new TransferRequest(null, id, 'cause', transferTo, creatorId, 'pending')
        transferRequest.create()
        console.log({transferRequest})
        this.toggleTransferModal()
    }

	render() {
		const { user } = this.props;
		let id;
		if (this.props.match && this.props.match.params)
			id = this.props.match.params.id;
		const {
			title,
			goal,
			story,
			creatorId,
			redirect,
			achieved,
			content,
			status,
			shortDesc,
			donations,
			locationName,
			showMapLocation,
			faqs,
			latLng,
            transferTo
		} = this.state;
		const { currentSlide, totalSlies } = this.state.modal;
		if (redirect) return <Redirect to={redirect} />;
		const modal = (
			<div className="modal fade" role="dialog" id="uploadPicturesModal">
				<div
					className="modal-dialog modal-dialog-centered"
					role="document"
				>
					<div className="modal-content">
						<div className="modal-header CharityModalHeader">
							<div className="slideCounter">
								<span
									className="mr-2"
									onClick={() =>
										this.modalSlideChangeHandler("dec")
									}
								>
									<FontAwesomeIcon icon={faArrowLeft} />
								</span>
								<span>
									{currentSlide}/{totalSlies}
								</span>
								<span
									className="ml-2"
									onClick={() =>
										this.modalSlideChangeHandler("inc")
									}
								>
									<FontAwesomeIcon icon={faArrowRight} />
								</span>
							</div>
							<h3 className="text-center mt-3">
								{currentSlide === 1
									? "Add a Cover Photo"
									: currentSlide === 2
									? "Write a Story"
									: "Edit your page link"}
							</h3>
						</div>
						<div className="modal-body">
							{currentSlide === 1 ? (
								<div className="bodyUploadPictures">
									<form
										onSubmit={(e) =>
											this.modalSlideChangeHandler(
												"inc",
												e
											)
										}
									>
										<input
											type="file"
											className="form-control"
											id="content"
											onChange={this.onFileInputChange}
											multiple
										/>
										<button
											type="submit"
											className="btn btn-primary my-3"
										>
											Save and continue
										</button>
									</form>
								</div>
							) : currentSlide === 2 ? (
								<div className="bodyStory">
									<form
										onSubmit={(e) =>
											this.modalSlideChangeHandler(
												"inc",
												e
											)
										}
									>
										<Editor
											id="story"
											initialValue={story}
											apiKey={tinyCloudAPIKey}
											init={{
												height: 250,
												menubar: false,
												plugins: [
													"advlist autolink lists link image charmap print preview anchor",
													"searchreplace visualblocks code fullscreen",
													"insertdatetime media table paste code help wordcount",
												],
												toolbar: `undo redo | formatselect | bold italic | 
                                                    alignleft aligncenter alignright alignjustify | 
                                                    bullist numlist outdent indent | removeformat | help `,
											}}
											onEditorChange={
												this.handleEditorChange
											}
										/>
										<button
											type="submit"
											className="btn btn-primary my-3"
										>
											Save and continue
										</button>
									</form>
								</div>
							) : (
								<div className="bodyLinkEdit">
									<form onSubmit={this.createCharityHandler}>
										<input
											type="text"
											className="form-control"
											value="tribeworld.com/crowdfunding/"
											disabled
										/>
										<input
											type="text"
											className="form-control"
											placeholder="Your page link here..."
											id="customUrl"
											value={this.state.customUrl}
											onChange={this.onInputChange}
											required
										/>
										<button
											type="submit"
											className="btn btn-primary my-3"
										>
											Save
										</button>
									</form>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		);
		return (
			<Fragment>
				{showMapLocation ? (
					<MapLocationViewer
						show={true}
						toggleModal={this.toggleShowMapModal}
						location={{
							locationName,
							lat: latLng[1],
							lng: latLng[0],
						}}
					/>
				) : null}
				<Navbar />
				{this.props.isAuthenticated ? <Sidebar /> : null}

				<ContentBox title="">
					<div className="row mb-4" style={{ marginLeft: "20%" }}>
						<div className="CharityWrapper">
							<div className="row">
								<div className="card custom-card">
									<img
										className="main-image card-img-top"
										src={content.length ? content[0] : img}
										alt="main-img"
									/>
									<br />
									<SocialMediaShare
										title={title}
										goal={goal}
									/>
								</div>
								<div className="card custom-card">
									<div className="card-body">
										<h2>{title}</h2>
										<div className="shortDesc">
											&nbsp;{shortDesc}
										</div>
										<h5 className="card-title mt-3">
											Hero Responsible
										</h5>
										{creatorId && creatorId.length ? (
											<UserCard userId={creatorId} />
										) : null}
										<h5 className="card-title mt-3">
											Goal
										</h5>
										<p className="card-text">
											&nbsp;{" $ " + goal}
										</p>
										<h5 className="card-title">Achieved</h5>
										<p className="card-text">
											&nbsp;{" $ " + achieved}
										</p>
										<h5 className="card-title">Location</h5>
										<p className="card-text">
											{locationName}
										</p>
										{/* <Button onClick={() => this.toggleShowMapModal(true)}>Show Map</Button> */}
										<br />
										<ShowFaqs faqs={faqs} />
										<Button
											onClick={() =>
												this.toggleShowMapModal(true)
											}
										>
											Show Map
										</Button>
										{user.userType === "admin" &&
										status === "active" ? (
											<div>
												Admin connot donate to a charity
											</div>
										) : null}
										{status === "active" &&
										user.userType !== "admin" ? (
											<div>
												<Link
													to={"/charity/donate/" + id}
													className="btn btn-primary"
												>
													Donate Now
												</Link>
											</div>
										) : null}
										{status === "rejected" ? (
											<div>
												The charity has been rejected by
												the Admin
											</div>
										) : null}
										{status === "pending" ? (
											<div>
												The charity is pending, to be
												accepted Admin
											</div>
										) : null}
										<br />
										{user.userType === "admin" ? (
											<div>
												{status === "rejected" ||
												status === "pending" ? (
													<Button
														onClick={() =>
															this.changeCauseStatus(
																{
																	causeId: id,
																	status: "active",
																}
															)
														}
													>
														Activate
													</Button>
												) : null}
												&nbsp;
												{status === "pending" ? (
													<Button
														onClick={() =>
															this.changeCauseStatus(
																{
																	causeId: id,
																	status: "rejected",
																}
															)
														}
													>
														Reject
													</Button>
												) : null}
											</div>
										) : null}
										{creatorId === this.props.user._id &&
										status !== "active" ? (
											<Link to={"/charity/edit/" + id}>
												Edit Cause
											</Link>
										) : null}
										&nbsp;&nbsp;
										{creatorId === this.props.user._id ? (
											<Link
												to={"/campaign/update/" + id}
												className="btn btn-primary"
											>
												Post Update
											</Link>
										) : null}
										<br /> <br />
										{creatorId === this.props.user._id ? (
											<Button
												variant="primary"
												onClick={
													this.toggleTransferModal
												}
											>
												Transfer Campaign
											</Button>
										) : null}
										&nbsp;&nbsp;
										{this.props.user &&
										this.state.creator ? (
											this.props.user._id !==
												this.state.creator._id &&
											user.userType !== "admin" ? (
												<Button
													variant="danger"
													onClick={() =>
														this.toggleReportModal(
															true
														)
													}
												>
													Report Campaign
												</Button>
											) : null
										) : null}
									</div>
								</div>
							</div>
						</div>
					</div>

					{/*  */}
					{/* <div className='row' style={{marginLeft: '15%', marginRight: '20%'}}>
                        {status === 'active' ? this.renderDonationsTable() : null}
                    </div> */}

					<div className="container-fluid my-3 mx-2">
						<div className="row">
							<div className="col-8">
								<ul
									className="nav nav-tabs"
									id="myTab"
									role="tablist"
								>
									<li
										className="nav-item"
										role="presentation"
									>
										<a
											className="nav-link active"
											id="story-tab"
											data-toggle="tab"
											href="#story"
											role="tab"
											aria-controls="story"
											aria-selected="true"
										>
											Story
										</a>
									</li>
									<li
										className="nav-item"
										role="presentation"
									>
										<a
											className="nav-link"
											id="updates-tab"
											data-toggle="tab"
											href="#updates"
											role="tab"
											aria-controls="updates"
											aria-selected="false"
										>
											Updates
										</a>
									</li>
									<li
										className="nav-item"
										role="presentation"
									>
										<a
											className="nav-link"
											id="comments-tab"
											data-toggle="tab"
											href="#comments"
											role="tab"
											aria-controls="comments"
											aria-selected="false"
										>
											Comments
										</a>
									</li>
								</ul>

								<div
									className="tab-content mt-2 mx-4"
									id="myTabContent"
								>
									<div
										className="tab-pane fade show active"
										id="story"
										role="tabpanel"
										aria-labelledby="story-tab"
									>
										{ReactHtmlParser(story)}
									</div>

									<div
										className="tab-pane fade"
										id="updates"
										role="tabpanel"
										aria-labelledby="updates-tab"
									>
										<CampaignUpdates
											charity={true}
											campaignId={id}
										/>
									</div>

									<div
										className="tab-pane fade"
										id="comments"
										role="tabpanel"
										aria-labelledby="comments-tab"
									>
										<CampaignComments
											charity={true}
											campaignId={id}
										/>
									</div>
								</div>
							</div>

							<div className="col-4">
								{donations.map((donation, index) => {
									if (!donation.user) {
										donation.user = {
											firstName: "User Deleted",
											lastName: "User Deleted",
											email: "User Deleted",
										};
									}
									return (
										<div className="card mb-3" key={index}>
											<div className="card-body">
												<h5 className="card-title">
													{!donation.donateAnonymously
														? donation.user
																.firstName +
														  " " +
														  donation.user.lastName
														: "Anonymous"}
												</h5>
												<h6 className="card-subtitle mb-3">
													Amount: $
													{donation.donationAmount}
												</h6>
												<p className="card-text">
													{donation.message.length ? (
														donation.message
													) : (
														<>NO MESSAGE</>
													)}
												</p>
												<h6 className="card-subtitle mb-2 text-muted">
													Time:{" "}
													{
														donation.createdAt.split(
															"T"
														)[0]
													}
												</h6>
												{!donation.donateAnonymously ? (
													<Link
														to={
															"/profile/" +
															donation.user._id
														}
													>
														View Profile
													</Link>
												) : null}
												{/* {donation.donateAnonymously ? null : <a href={"mailto:" + donation.user.email}>Send Email to {donation.user.email}</a>} */}
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
					{this.state.charityInCreation ? modal : null}
					<ReportModal
						showModal={this.state.reportModalShow}
						toggleReportModal={this.toggleReportModal}
						reportType="campaign"
						reportedId={this.props.match.params.id}
						campaignId={this.state.id}
					/>

					{creatorId === this.props.user._id ? (
						<Modal
							show={this.state.showTransferModal}
							onHide={this.toggleTransferModal}
							centered
						>
							<Modal.Header closeButton>
								<Modal.Title>Transfer Campaign</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<div className="input-group mb-3">
									<div className="input-group-prepend">
										<span
											className="input-group-text"
											id="basic-addon1"
										>
											Email
										</span>
									</div>
									<input
                                        onChange={(e) => this.setState({transferTo: e.target.value})}
										type="email"
										className="form-control"
										aria-label="Email"
										aria-describedby="basic-addon1"
									/>
								</div>
							</Modal.Body>
							<Modal.Footer>
								<Button
									variant="secondary"
									onClick={this.toggleTransferModal}
								>
									Close
								</Button>
								<Button
									variant="primary"
									onClick={this.makeTransferRequest}
								>
									Submit Request
								</Button>
							</Modal.Footer>
						</Modal>
					) : null}
				</ContentBox>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		user: state.auth.user,
	};
}

const mapDispatchToProps = (dispatch) => ({
	logoutUser: () => dispatch(receiveLogout()),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(CharityDetails));
