import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NameShorten from 'name-shorten'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import UserAvatar from '../../../UserAvatar'
import Title from '../../Typography/Title/Title'
import './UserCard.css'

const UserCard = (props) => {
	let name = props.user ? NameShorten(props.user.firstName, props.user.lastName) : 'Undefined'

	if (name.length > 14) {
		name = `${name.substring(0, 10)}...`
	}

	const charities = props.user ? props.user.charities : 0
	const enterprises = props.user ? props.user.enterprises : 0

	return (
		<div className='UserCard'>
			<div className='UserCard-body'>
				<div className='user-img'>
					<UserAvatar avatar={props.user.avatar} defaultAvatar={props.user.avatar ? false : true} />
				</div>
				<div className='content'>
					<div className='mt-2'>
						<Title size='sm' color='dark' isResponsive={true}>
							{name}
						</Title>
					</div>
					<p className='location'>
						{props.user.address
							? props.user.address.location
								? props.user.address.location
								: `${props.user.address.city || 'No'}, ${props.user.address.country || 'Location'} `
							: 'No Location'}
					</p>
					<div className='divider'></div>
					<div className='contributions-list'>
						<div className='contributions-list-item'>
							<p className='key'>causes</p>
							<p className='value'>{charities}</p>
						</div>
						<div className='contributions-list-item'>
							<p className='key'>enterprises</p>
							<p className='value'>{enterprises}</p>
						</div>
					</div>
				</div>
			</div>
			<div className='UserCard-footer'>
				<Link className='footer-btn' to={'/profile/' + props.user._id}>
					View Profile
					<span className='icon'>
						<FontAwesomeIcon icon={faChevronRight} />
					</span>
				</Link>
			</div>
		</div>
	)
}

UserCard.propTypes = {
	user: PropTypes.object.isRequired,
}

export default UserCard
