import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { receiveLogout, setDashboardMode } from "../../store/actions";
// import Logo from "../../assets/images/logo_login.pnactionsg";
import UserAvatar from "../UserAvatar";

const logout = (props) => {
	console.log(props);
	localStorage.removeItem("auth");
	localStorage.removeItem("dashboardMode");
	props.logoutUser();
	props.history.push("/");
};

const Navbar = (props) => {
	const { user } = props;
	let name = user.firstName ? user.firstName : "";
	name += " ";
	name += user.lastName ? user.lastName : "";
	const { isAuthenticated, dashboardMode } = props;
	const isAdmin = user.userType === "admin" ? true : false;
	return (
		<section id="navigation">
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="wrapper">
							<div className="wrap-logo">
								<Link to="/">
									{/* <img src={Logo} alt="logo"></img> */}
								</Link>
							</div>

							<div className="wrap-list">
								<div className="bars d-block d-md-none">
									<FontAwesomeIcon icon={faBars} size="lg" />
								</div>
								<div className="list d-none d-md-block">
									<ul className="custom-navbar">
										<li className="navbar-item">
											{isAuthenticated ? (
												<div className="d-flex align-items-center">
													<div
														style={{
															width: "75px",
															height: "75px",
														}}
													>
														<UserAvatar />
													</div>
													<Link
														to=""
													></Link>
													{isAdmin ? null : (
														<Link
															onClick={() => {
																props.setDashboardMode(
																	dashboardMode ===
																		"hero"
																		? "angel"
																		: "hero"
																);
															}}
															to={"/dashboard"}
															className="navbar-link "
														>
															{dashboardMode +
																" dashboard"}
														</Link>
													)}
													<Link
														to={
															"/profile/" +
															user._id
														}
														className="navbar-link "
														style={
															isAdmin
																? {
																		color: "green",
																  }
																: {}
														}
													>
														{isAdmin
															? "Admin: " + name
															: name}
													</Link>
												</div>
											) : null}
										</li>
										<li className="navbar-item">
											<Link
												to={
													isAuthenticated
														? "/dashboard"
														: "/?loginpopup=true"
												}
												onClick={
													isAuthenticated
														? () => logout(props)
														: null
												}
												className="navbar-link"
											>
												{isAuthenticated
													? "Logout"
													: "Login"}
											</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="mobile-navbar d-md-none">
							<ul className="custom-navbar">
								<li className="navbar-item">
									<a
										href="/profile"
										className="navbar-link active"
									>
										Profile
									</a>
								</li>
								<li className="navbar-item">
									<a href="explore" className="navbar-link">
										Explorer
									</a>
								</li>
								<li className="navbar-item">
									<a href="/" className="navbar-link">
										Logout
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		user: state.auth.user,
		dashboardMode: state.dashboard.mode,
	};
}

const mapDispatchToProps = (dispatch) => ({
	logoutUser: () => dispatch(receiveLogout()),
	setDashboardMode: (dashboardMode) => {
		localStorage.setItem(
			"dashboardMode",
			JSON.stringify({ dashboardMode })
		);
		dispatch(setDashboardMode(dashboardMode));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar));
