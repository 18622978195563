import { Redirect } from "react-router-dom";
import React, { Fragment, Component } from "react";

import "../createCharity/CreateCharity.css";
import { requestHandler } from "../../utils/requestHandler";

import Navbar from "../../components/layouts/Navbar";
import Sidebar from "../../components/layouts/Sidebar/Sidebar";
import ContentBox from "../../components/layouts/ContentBox/ContentBox";
import EnterpriseRewardItems from '../../components/EnterpriseRewardItems';

class AddReward extends Component {
	state = {
		redirect: null,
		title: "",
		price: "",
		retailPrice: "",
		desc: "",
		quantity: "",
		deliveryDays: "",
		selectedItems: null,
	};

	componentDidMount() {
		const { id: enterpriseId } = this.props.match.params;
		if (!enterpriseId) {
			return this.setState({ redirect: '/enterprise/manage' })
		}
	}

	onInputChange = (item) => {
		this.setState({
			[item.target.id]: item.target.value,
		});
	};

	createReward = () => {
		const { id: enterpriseId } = this.props.match.params;
		const {
			title,
			price,
			retailPrice,
			desc: description,
			quantity,
			deliveryDays,
			selectedItems,
		} = this.state;

		if (!title.length || !description.length || price <= 0 || retailPrice <= 0 || quantity <= 0)
			return alert("Please fill all fields");
		const items = []
		for (let k in selectedItems) {
			items.push({ _id: k, quantity: selectedItems[k].quantity })
		}

		let data = {
			title,
			price,
			retailPrice,
			description,
			quantity,
			deliveryDays,
			enterpriseId,
			items
		};
		requestHandler("createReward", data).then((result) => {
			console.log("RESULT: ", result);
			if (result.status === 200)
				this.setState({ redirect: "/enterprise/details/" + enterpriseId });
		});
	};

	render() {
		const { id: enterpriseId } = this.props.match.params;
		const {
			title,
			price,
			retailPrice,
			desc,
			quantity,
			deliveryDays,
			redirect,
			selectedItems,
		} = this.state;
		console.log({ retailPrice })
		if (redirect) return (<Redirect to={redirect} />)
		return (
			<Fragment>
				<Navbar />
				<Sidebar />
				<ContentBox title="Add Reward">
					<div className="CharityWrapper">
						<form
							className="mb-4"
							onSubmit={(e) => e.preventDefault()}
						>
							<div className="form-group">
								<label htmlFor="title">Title</label>
								<input
									type="text"
									className="form-control"
									id="title"
									onChange={this.onInputChange}
									value={title}
								/>
							</div>

							<div className="form-group">
								<label htmlFor="price">Price</label>
								<input
									number="number"
									className="form-control hello"
									id="price"
									onChange={this.onInputChange}
									value={price}
								/>
							</div>

							<div>
								<label htmlFor="price">Select Item for the perk</label>
								<EnterpriseRewardItems
									showAddItem={true}
									enterpriseId={enterpriseId}
									setSelectedItems={(selectedItems, retailPrice) => this.setState({ selectedItems, retailPrice })}
									selection={selectedItems}
								/>
							</div>
							<br />
							<div className="form-group">
								<label htmlFor="retailPrice">Retail Price</label>
								<input
									disabled={true}
									className="form-control"
									id="retailPrice"
									onChange={this.onInputChange}
									value={retailPrice}
								/>
							</div>

							<div className="form-group">
								<label htmlFor="desc">Description</label>
								<textarea
									className="form-control"
									id="desc"
									rows="3"
									onChange={this.onInputChange}
									value={desc}
								></textarea>
							</div>

							<div className="form-group">
								<label htmlFor="quantity">
									Quantity
                                </label>
								<input
									type="number"
									className="form-control"
									id="quantity"
									onChange={this.onInputChange}
									value={quantity}
								/>
							</div>

							<div className="form-group">
								<label htmlFor="deliveryDays">
									Estimated Delivery Time (in days)
                                </label>
								<input
									type="text"
									className="form-control"
									id="deliveryDays"
									onChange={this.onInputChange}
									value={deliveryDays}
								/>
							</div>

							<button
								type="submit"
								className="btn btn-primary"
								onClick={this.createReward}
							>
								Submit
                            </button>
						</form>
					</div>
				</ContentBox>
			</Fragment>
		);
	}
}

export default AddReward;
