import React, { Fragment } from "react";
import { connect } from 'react-redux';

import { receiveLogout } from "../../store/actions";

import Navbar from '../../components/layouts/Navbar'
import AdminSidebar from "../../components/layouts/AdminSidebar/AdminSidebar";
import ContentBox from "../../components/layouts/ContentBox/ContentBox";

import { Redirect, Link } from "react-router-dom";
import { requestHandler } from "../../utils/requestHandler";
import { Button } from "react-bootstrap";


class Navigation extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            causes: [],
            loading: true,
            redirect: null,
        }
    }

    componentDidMount() {
        this.reloadData()
    }

    componentWillReceiveProps(props) {
        const { causestatus } = props.match.params
        const { lastQuery } = this.state
        if(lastQuery !== causestatus)
            this.setState({users: []}, this.reloadData)
    }

    reloadData = () => {
        const { causestatus } = this.props.match.params
        const query = {}
        console.log("QUERYING USER")
        if(causestatus === 'active' || causestatus === 'pending' || causestatus === 'rejected') {
            query.status = causestatus
        } else if(!causestatus) {}
        else {
            return this.setState({ redirect: '/error' })
        }
        
        requestHandler('getCharity', query)
        .then( response => {
            console.log({response})
            if(response.status === 200) {
                this.setState({ causes: response.data, lastQuery: causestatus })
            } 
        })
    }

    changeCauseStatus = (data) => {
        requestHandler('changeCauseStatus', data)
        .then( response => {
            console.log({response})
            // if(response === 200) {

                this.reloadData()
            // }

        })
    }

    renderChangeCauseStatusButton = (cause) => {
        if(cause.status === 'active')
        return (
            <div>Active Campaign</div>
        )
        else if(cause.status === 'pending')
        return (
            <div>
                <Button onClick={() => this.changeCauseStatus({causeId: cause._id, status: 'active'})}>Activate</Button>&nbsp;
                <Button onClick={() => this.changeCauseStatus({causeId: cause._id, status: 'rejected'})}>Reject</Button>
            </div>
        )
        else if(cause.status === 'rejected') 
        return (
            <Button onClick={() => this.changeCauseStatus({causeId: cause._id, status: 'active'})}>Activate</Button>
        )
    }

    renderTableBody = () => {
        const { causes } = this.state
        return (
			<tbody>
				{causes.map((cause) => (
					<tr>
						<td>
							<Link to={'/charity/details/' + cause._id}>{cause.title}</Link>
						</td>
						<td>{cause.goal}</td>
						<td>{cause.locationName}</td>
						<td>{cause.createdAt.split('T')[0]}</td>
						<td>
							<Button
								onClick={() => this.props.history.push('/admin/user/' + cause.creatorId)}>
								View Creator
							</Button>
						</td>
						<td>{cause.status.charAt(0).toUpperCase() + cause.status.slice(1)}</td>
						<td>{this.renderChangeCauseStatusButton(cause)}</td>
					</tr>
				))}
			</tbody>
		) 
    }

    render() {  
        const { redirect } = this.state
        const { causestatus } = this.props.match.params
        if(redirect) return ( <Redirect to={redirect} />)
        return (
            <Fragment>
                <Navbar />
				<AdminSidebar />
                <ContentBox title={"Show Causes: " + (causestatus ? causestatus : "all")}> 
                    <table className='table'>
                        <thead>
                            <tr>
                                <td>Title</td>
                                <td>Goal</td>
                                <td>Location Name</td>
                                <td>Created at</td>
                                <td>Created By</td>
                                <td>Status</td>
                                <td>Change Status</td>
                            </tr>

                        </thead>
                        {this.renderTableBody()}
                    </table>                
                </ContentBox>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        user: state.auth.user
    };
}

const mapDispatchToProps = (dispatch) => ({
    logoutUser: () => dispatch(receiveLogout())

});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
