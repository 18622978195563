import React, { Component, Fragment } from "react";
import "./SignupMobile.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { receiveLogin as loginUser } from "../../../../store/actions";
import UserModel from "../../../../DataModels/User";
import sweetalert from "sweetalert";

// Components
import Title from "../../../../components/UI/Typography/Title/Title";
import TribePrimaryBtn from "../../../../components/UI/Buttons/TribePrimaryBtn/TribePrimaryBtn";
import HorizontalLine from "../../../../components/UI/HorizontalLine/HorizontalLine";
import PrimaryAuthInput from "../../../../components/UI/Inputs/PrimaryAuthInput/PrimaryAuthInput";
import CheckBox from "../../../../components/UI/Inputs/CheckBox/CheckBox";
import NationalityDropdown from "../../../../components/UI/Inputs/NationalityDropdown/NationalityDropdown";

// Images
import TribeLogo from "../../../../assets/images/tribe-white-logo.png";

class SignupMobile extends Component {
	state = {
		activeScreen: 1,
		totalScreens: 4,
		firstName: "",
		lastName: "",
		email: "",
		password: "",
		confirmPassword: "",
		username: "",
		nationality: "",
		promotionalEmails: false,
		terms: false,
	};

	onChangeScreen = (toScreen) => {
		const { activeScreen, totalScreens } = this.state;

		if (toScreen === "next" && activeScreen < totalScreens) {
			this.setState({ activeScreen: activeScreen + 1 });
		} else if (toScreen === "prev" && activeScreen > 1) {
			this.setState({ activeScreen: activeScreen - 1 });
		}
	};

	onInputChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	screenOneHTML = () => {
		return (
			<Fragment>
				<p className="screenOne-text">
					Welcome to Tribe. Before you begin your journey, as hero,
					angel or both, your tribe would like to know a little more
					about you:
				</p>
				<TribePrimaryBtn
					type="hero"
					onClick={() => this.onChangeScreen("next")}
				>
					Continue with Email
				</TribePrimaryBtn>
				<HorizontalLine color="white" />
				<div className="mb-3">
					<TribePrimaryBtn type="facebook">
						Continue with Facebook
					</TribePrimaryBtn>
				</div>
				<div className="mb-2">
					<TribePrimaryBtn type="google">
						Continue with Google
					</TribePrimaryBtn>
				</div>
			</Fragment>
		);
	};

	screenTwoHTML = () => {
		const {
			firstName,
			lastName,
			email,
			password,
			confirmPassword,
			promotionalEmails,
			terms,
		} = this.state;

		return (
			<Fragment>
				<div className="wrap-input">
					<label htmlFor="firstName">First Name</label>
					<PrimaryAuthInput
						id="firstName"
						borderStyle="rounded"
						type="text"
						name="firstName"
						value={firstName}
						onChange={this.onInputChange}
					/>
				</div>
				<div className="wrap-input">
					<label htmlFor="lastName">Last Name</label>
					<PrimaryAuthInput
						id="lastName"
						borderStyle="rounded"
						type="text"
						name="lastName"
						value={lastName}
						onChange={this.onInputChange}
					/>
				</div>
				<div className="wrap-input">
					<label htmlFor="email">Email</label>
					<PrimaryAuthInput
						id="email"
						borderStyle="rounded"
						type="email"
						name="email"
						value={email}
						onChange={this.onInputChange}
					/>
				</div>
				<div className="wrap-input">
					<label htmlFor="password">Password</label>
					<PrimaryAuthInput
						id="password"
						borderStyle="rounded"
						type="password"
						name="password"
						value={password}
						onChange={this.onInputChange}
					/>
				</div>
				<div className="wrap-input">
					<label htmlFor="confirmPassword">Confirm Password</label>
					<PrimaryAuthInput
						id="confirmPassword"
						borderStyle="rounded"
						type="password"
						name="confirmPassword"
						value={confirmPassword}
						onChange={this.onInputChange}
					/>
				</div>
				<div className="other-options my-4">
					<CheckBox
						size="sm"
						label="I want to receive newsletters via email"
						color="white"
						input={{
							defaultChecked: promotionalEmails,
							onChange: (e) =>
								this.setState({
									promotionalEmails:
										!this.state.promotionalEmails,
								}),
						}}
					/>
					<CheckBox
						size="sm"
						label="I agree to the Terms of Use and have read the Privacy Policy"
						color="white"
						input={{
							defaultChecked: terms,
							onChange: (e) => {
								this.setState({ terms: !this.state.terms });
							},
						}}
					/>
				</div>
				<div className="parallel-btns">
					<div className="mr-2">
						<TribePrimaryBtn
							type="white"
							onClick={() => this.onChangeScreen("prev")}
						>
							Go Back
						</TribePrimaryBtn>
					</div>
					<div className="ml-2">
						<TribePrimaryBtn
							type="hero"
							onClick={this.validateStepTwoBody}
						>
							Continue
						</TribePrimaryBtn>
					</div>
				</div>
			</Fragment>
		);
	};

	screenThreeHTML = () => {
		const { username, nationality } = this.state;

		return (
			<Fragment>
				<div className="wrap-input">
					<label htmlFor="username">Username</label>
					<PrimaryAuthInput
						id="username"
						borderStyle="rounded"
						type="text"
						name="username"
						value={username}
						onChange={this.onInputChange}
					/>
				</div>
				<div className="wrap-input">
					<label htmlFor="nationality">Nationality</label>
					<NationalityDropdown
						name="nationality"
						value={nationality}
						onChange={this.onInputChange}
					></NationalityDropdown>
				</div>
				<div className="parallel-btns mt-5">
					<div className="mr-2">
						<TribePrimaryBtn
							type="white"
							onClick={() => this.onChangeScreen("prev")}
						>
							Go Back
						</TribePrimaryBtn>
					</div>
					<div className="ml-2">
						<TribePrimaryBtn
							type="hero"
							onClick={this.validateStepThreeBody}
						>
							Continue
						</TribePrimaryBtn>
					</div>
				</div>
			</Fragment>
		);
	};

	screenFourHTML = () => {
		return (
			<Fragment>
				<h4 className="title-screenFour">Welcome to Tribe</h4>
				<div className="center-img">
					<div className="wrap-dummyImg">
						<img src={TribeLogo} alt="TribeLogo" />
					</div>
				</div>
				<div className="mt-4">
					<TribePrimaryBtn type="hero">Become a Hero</TribePrimaryBtn>
				</div>
				<div className="mt-3 mb-2">
					<TribePrimaryBtn type="angel">
						Become an Angel
					</TribePrimaryBtn>
				</div>
			</Fragment>
		);
	};

	renderScreen = () => {
		switch (this.state.activeScreen) {
			case 1:
				return this.screenOneHTML();
			case 2:
				return this.screenTwoHTML();
			case 3:
				return this.screenThreeHTML();
			case 4:
				return this.screenFourHTML();
			default:
				return null;
		}
	};

	validateStepTwoBody = () => {
		const { firstName, lastName, email, password, confirmPassword, terms } =
			this.state;

		if (terms) {
			if (firstName && lastName && email && password && confirmPassword) {
				if (password === confirmPassword) {
					this.onChangeScreen("next");
				} else {
					sweetalert("Error", "Passwords do not match.", "error");
				}
			} else {
				sweetalert(
					"Error",
					"Kindly fill the required fields.",
					"error"
				);
			}
		} else {
			sweetalert(
				"Error",
				"Kindly check the Terms and conditions.",
				"error"
			);
		}
	};

	validateStepThreeBody = () => {
		const { username, nationality } = this.state;

		if (username && nationality) {
			this.onSubmitHandler();
		} else {
			sweetalert("Error", "Invalid Inputs.", "error");
		}
	};

	onSubmitHandler = () => {
		const newUser = {
			firstName: this.state.firstName,
			lastName: this.state.lastName,
			email: this.state.email,
			password: this.state.password,
			username: this.state.username,
			nationality: this.state.nationality,
			terms: this.state.terms,
			promotionalEmails: this.state.promotionalEmails,
		};

		UserModel.registerUser(newUser)
			.then((result) => {
				if (result.status === 200) {
					this.props.loginUser(result.data);
					this.onChangeScreen("next");
				} else {
					sweetalert("Error", result.message, "error");
				}
			})
			.catch((err) => {
				console.log(err);
				sweetalert(
					"Error",
					"Server side error occured while registering user",
					"error"
				);
			});
	};

	render() {
		return (
			<main className="SignupMobile">
				<section className="SignupMobile-Section">
					<div className="container">
						<div className="row">
							<div className="col-10 col-sm-8 col-md-7 col-lg-6 col-xl-5 mx-auto">
								<div className="SignupMobile-Body">
									<div className="header">
										<div className="header-title">
											<Title size="md" color="white">
												JOIN Tribe
											</Title>
										</div>
										<Link to="/">
											<div className="header-times">
												&times;
											</div>
										</Link>
									</div>
									<div className="form">
										{this.renderScreen()}
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		loginUser: (user) => dispatch(loginUser(user)),
	};
}

export default connect(null, mapDispatchToProps)(SignupMobile);
