import React, { Component, Fragment } from "react";
import Navbar from "../../components/layouts/Navbar";
import AdminSidebar from "../../components/layouts/AdminSidebar/AdminSidebar";
import ContentBox from "../../components/layouts/ContentBox/ContentBox";
import { Button } from "react-bootstrap";
import { requestHandler } from "../../utils/requestHandler";

class AdminTransfers extends Component {
	state = { records: [] };

	componentDidMount() {
		this.loadData();
	}

	loadData = () => {
		requestHandler("getTransferRequest")
			.then((res) => {
				if (res.status === 200) {
					this.setState({ records: res.data });
				}
			})
			.catch((err) => console.log(err));
	};

	completeTransfer = (transferId, approved) => {
		requestHandler("completeTransferRequest", { transferId, approved })
			.then((res) => {
				if (res.status === 200) {
					if (approved) alert("Campaign Transfer Accepted");
					else alert("Campaign Transfer Rejected");
				}
			})
			.catch((err) => console.log(err));
	};

	renderTableBody = () => {
		const { records } = this.state;

		return (
			<tbody>
				{records.map((item) => (
					<tr>
						<td>{item.charity.title}</td>
						<td>{item.currentOwner.email}</td>
						<td>{item.transferTo}</td>
						<td>{item.status}</td>
						<td>{item.createdAt.split("T")[0]}</td>
						<td>
							{item.status === "approved" ? (
								<div>
									<Button
										variant="primary"
										onClick={() =>
											this.completeTransfer(
												item._id,
												true
											)
										}
									>
										Accept
									</Button>
									<br />
									<br />
									<Button
										variant="danger"
										onClick={() =>
											this.completeTransfer(
												item._id,
												false
											)
										}
									>
										Reject
									</Button>{" "}
								</div>
							) : null}
						</td>
					</tr>
				))}
			</tbody>
		);
	};

	render() {
		console.log(this.state);

		return (
			<Fragment>
				<Navbar />
				<AdminSidebar />
				<ContentBox title={"Transfer Requests"}>
					<table className="table">
						<thead>
							<tr>
								<th>Campaign</th>
								<th>Owner</th>
								<th>Transfer To</th>
								<th>Status</th>
								<th>Date</th>
								<th>Actions</th>
							</tr>
						</thead>
						{this.renderTableBody()}
					</table>
				</ContentBox>
			</Fragment>
		);
	}
}

export default AdminTransfers;
