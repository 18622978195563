import React from 'react'
import { productStagesMessages } from '../../utils/templates'

export default function ProductStageDetails(props) {
    const currentStage = props.productStage ? props.productStage.currentStage : null
    return (
        <div className="bg-light m-2 border border-secondary p-1">
            <h5 className='card-title'>Product Stage</h5>
            <table>
                <tr>
                    <td>
                        {currentStage !== 'concept' ?
                            (<> Concept &nbsp; </>)
                            : <> <button type="button" disabled={true} className="btn-primary"> Concept </button> &nbsp; </>
                        }

                    </td>
                    <td>
                        {currentStage !== 'prototype' ?
                            (<> Prototype &nbsp; </>)
                            : <> <button type="button" disabled={true} className="btn-primary"> Prototype </button> &nbsp; </>
                        }

                    </td>
                    <td>
                        {currentStage !== 'production' ?
                            (<> Production &nbsp; </>)
                            : <> <button type="button" disabled={true} className="btn-primary"> Production </button> &nbsp; </>
                        }

                    </td>
                    <td>
                        {currentStage !== 'shipping' ?
                            (<> Shipping &nbsp; </>)
                            : <> <button type="button" disabled={true} className="btn-primary"> Shipping </button> &nbsp; </>
                        }

                    </td>
                </tr>
            </table>
            <hr />
            <i>{productStagesMessages[currentStage]}</i>
        </div>
    )
}