import React from 'react'
import GoogleContacts from 'react-google-contacts';

import { GOOGLE_API_KEY } from "../../utils/constants";

export default class InviteFriends extends React.Component {
	constructor(p) {
		super(p)
		this.state = {

		}
	}

	onGoogleContactsResponseApi = (response) => {
		console.log({ response })
	}

	render() {
		return (
			<GoogleContacts
				clientId={GOOGLE_API_KEY}
				onSuccess={this.onGoogleContactsResponseApi}
				onFailure={this.onGoogleContactsResponseApi}
			>
				<span> Import from Gmail</span>
			</GoogleContacts>
		)
	}
}