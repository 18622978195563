import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { receiveLogin } from "../../store/actions";
import { requestHandler } from '../../utils/requestHandler'
import jwt_decode from "jwt-decode";

import Logo from '../../assets/images/logo_login.png'



import './social-login-buttons.css'

class ResetPassword extends Component {

	state = {
		password: '',
		errorMessage: '',
		redirect: null,
	};

	signIn = () => {
		const email = this.email
		const { password } = this.state;
		requestHandler("login", {
			email: email.toLowerCase(),
			password,
			userType: "other",
		}).then(
			(result) => {
				if (result.status === 200) {
					localStorage.setItem("auth", JSON.stringify(result.data));
					this.props.loginUser(result.data);
					this.setState({ redirect: "/dashboard" });
				} else {
					alert(result.message);
				}
			},
			(e) => {
				console.log("ERROR: ", e);
			}
		);
		return;
	};

	componentDidMount() {
		const { token } = this.props.match.params
		const payload = jwt_decode(token)
		this.email = payload._uid
		if (!token) {
			this.setState({ redirect: '/login' })
		}
	}

	onChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};

	updatePassword = () => {
		const { password, confirmPassword } = this.state;
		const { token } = this.props.match.params
		if (password !== confirmPassword) {
			return alert("The passwords do not match!")
		} else if (password.length < 6) {
			return alert("Password should be at least 6 characters.")
		}

		requestHandler('resetPassword', { token, password })
			.then(result => {
				console.log("RESULT: ", result)
				if (result.status === 200) {
					alert("Password has been updated, Please login")
					this.signIn()
					// this.setState({ redirect: '/login' })
				} else {
					alert("The token is invalid or has expired")
				}
			}, e => {
				console.log("ERROR: ", e)
			})
		return;
	}

	render() {
		const { password, confirmPassword, errorMessage, redirect } = this.state;
		const { isAuthenticated } = this.props;

		if (isAuthenticated) {
			return <Redirect to="/dashboard" />;
		} else if (redirect) return (<Redirect to={redirect} />)

		return (
			<section id="login">
				<div className="container">
					<div className="row">
						<div className="col-10 col-md-8 col-lg-5">
							<div className="wrapper">
								<div>
									<div className="wrap-img">
										<img src={Logo} alt="logo"></img>
									</div>
								</div>
								<div className="wrap-form">
									<form onSubmit={this.onSubmit}>
										<div className="form-group">
											<input
												type="password"
												name="password"
												className="input-field form-control"
												placeholder="new password"
												value={password}
												onChange={this.onChange}
											/> <br />

											<input
												type="password"
												name="confirmPassword"
												className="input-field form-control"
												placeholder="confirm new password"
												value={confirmPassword}
												onChange={this.onChange}
											/>
										</div>
										{errorMessage.length ?
											<div>
												<span>
													{errorMessage}
												</span>
											</div>
											: null}
										<button
											type="button"
											className="btn btn-block btn-primary"
											onClick={this.updatePassword}
										>
											Update Password
										</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated
	};
}

const mapDispatchToProps = (dispatch) => ({
	loginUser: user => dispatch(receiveLogin(user))

});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
