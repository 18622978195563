import { combineReducers } from "redux";
import auth from "./auth";
import language from "./language";
import dashboard from "./dashboard";

export default combineReducers({
	auth,
	language,
	dashboard,
});
