import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import Cause from '../../../DataModels/Cause'
import InviteFriends from '../../InviteFriends'
import SocialMediaShare from '../../SocialMediaShare'
import PrimaryInputField from '../../UI/Inputs/PrimaryAuthInput/PrimaryAuthInput'
import UpdateAvatarLink from '../../UpdateAvatarLink'
import UserAvatar from '../../UserAvatar'

const PopupModal = (props) => {
	const [show, setShow] = useState(true)
	const [cause, setCause] = useState(null)
	const [modalStep, setModalStep] = useState(1)
	const { type, refId, newAccount } = props

	useEffect(() => {
		Cause.FetchCause(refId).then((cause) => {
			setCause(cause)
		})
	}, [refId])

	const title = type === 'donationcomplete' ? 'Congratulations Tribe Angel, you have successfully donated to' : null

	let causeTitle = ''
	if (cause && cause.title) causeTitle = cause.title

	const renderBody = (step) => {
		switch (step) {
			case 1:
				return (
					<React.Fragment>
						<Modal.Body>
							<p>Share you donation with your friends.</p>
							<SocialMediaShare title={causeTitle} goal={cause ? (cause.goal ? cause.goal : 0) : 0} />
						</Modal.Body>

						<Modal.Footer>
							{' '}
							{newAccount ? (
								<Button variant='primary' onClick={() => setModalStep(2)}>
									Next
								</Button>
							) : (
								<Button variant='primary' onClick={() => setShow(false)}>
									Close
								</Button>
							)}
						</Modal.Footer>
					</React.Fragment>
				)
			// skipping this step for now
			case 10:
				return (
					<React.Fragment>
						<Modal.Body>
							<p>Invite your friends to doante.</p>
							<PrimaryInputField type='text' placeholder='Enter your email' />
							<InviteFriends />
						</Modal.Body>

						<Modal.Footer>
							<Button variant='primary' onClick={() => setModalStep(1)}>
								Previous
							</Button>
							{newAccount ? (
								<Button variant='primary' onClick={() => setModalStep(3)}>
									Next
								</Button>
							) : (
								<Button variant='primary' onClick={() => setShow(false)}>
									Close
								</Button>
							)}
						</Modal.Footer>
					</React.Fragment>
				)
			case 2:
				return newAccount ? (
					<React.Fragment>
						<Modal.Body>
							<div className='d-flex align-items-center'>
								<UserAvatar />
							</div>
							<UpdateAvatarLink />
						</Modal.Body>

						<Modal.Footer>
							<Button variant='primary' onClick={() => setModalStep(1)}>
								Previous
							</Button>
							<Button variant='primary' onClick={() => setShow(false)}>
								Close
							</Button>
						</Modal.Footer>
					</React.Fragment>
				) : null
			default:
				return null
		}
	}

	return (
		<Modal show={show} onHide={() => setShow(false)}>
			<Modal.Header closeButton>
				<Modal.Title>
					{title + ' '} <b>{causeTitle}</b>
				</Modal.Title>
			</Modal.Header>

			{renderBody(modalStep)}
		</Modal>
	)
}

export default PopupModal
