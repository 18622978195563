import React, { useState, useEffect, useRef } from "react";
import "./Sidebar.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faUserCircle,
	faCog,
	faSignOutAlt,
	faTachometerAlt,
	faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import UserAvatar from "../../UserAvatar";

const Sidebar = (props) => {
	const [isSlider, setIsSlider] = useState(false);
	const sidebarRef = useRef();

	useEffect(() => {
		if (window.screen.width < 1200) {
			setIsSlider(true);
		}
	}, []);

	const closeSlider = () => {
		sidebarRef.current.style.transform = "scaleX(0)";
	};

	const HeroNavigation = () => (
		<div className="navigation">
			<div className="navigation-item">
				<Link to="#" className="navigation-link">
					<div className="item-icon">
						<FontAwesomeIcon icon={faTachometerAlt} />
					</div>
					<div className="item-text">Dashboard</div>
				</Link>
			</div>
			<div className="navigation-item">
				<Link to="#" className="navigation-link">
					<div className="item-icon">
						<FontAwesomeIcon icon={faTachometerAlt} />
					</div>
					<div className="item-text">Start a Fundraiser</div>
				</Link>
			</div>
			<div className="navigation-item">
				<Link to="#" className="navigation-link">
					<div className="item-icon">
						<FontAwesomeIcon icon={faTachometerAlt} />
					</div>
					<div className="item-text">Manage Causes</div>
				</Link>
			</div>
			<div className="navigation-item">
				<Link to="#" className="navigation-link">
					<div className="item-icon">
						<FontAwesomeIcon icon={faTachometerAlt} />
					</div>
					<div className="item-text">Manage Enterpriese</div>
				</Link>
			</div>
			<div className="navigation-item">
				<Link to="#" className="navigation-link">
					<div className="item-icon">
						<FontAwesomeIcon icon={faTachometerAlt} />
					</div>
					<div className="item-text">Tribe World</div>
				</Link>
			</div>
			<div className="navigation-item">
				<Link to="#" className="navigation-link">
					<div className="item-icon">
						<FontAwesomeIcon icon={faTachometerAlt} />
					</div>
					<div className="item-text">Inbox</div>
				</Link>
			</div>
			<div className="navigation-item">
				<Link to="#" className="navigation-link">
					<div className="item-icon">
						<FontAwesomeIcon icon={faTachometerAlt} />
					</div>
					<div className="item-text">Settings</div>
				</Link>
			</div>
		</div>
	);

	const AngelNavigation = () => (
		<div className="navigation">
			<div className="navigation-item">
				<Link to="#" className="navigation-link">
					<div className="item-icon">
						<FontAwesomeIcon icon={faTachometerAlt} />
					</div>
					<div className="item-text">Dashboard</div>
				</Link>
			</div>
			<div className="navigation-item">
				<Link to="/angel-donations" className="navigation-link">
					<div className="item-icon">
						<FontAwesomeIcon icon={faTachometerAlt} />
					</div>
					<div className="item-text">Donations</div>
				</Link>
			</div>
			<div className="navigation-item">
				<Link to="/angel-rewards" className="navigation-link">
					<div className="item-icon">
						<FontAwesomeIcon icon={faTachometerAlt} />
					</div>
					<div className="item-text">Reward Tracking</div>
				</Link>
			</div>
			<div className="navigation-item">
				<Link to="#" className="navigation-link">
					<div className="item-icon">
						<FontAwesomeIcon icon={faTachometerAlt} />
					</div>
					<div className="item-text">Tribe World</div>
				</Link>
			</div>
			<div className="navigation-item">
				<Link to="#" className="navigation-link">
					<div className="item-icon">
						<FontAwesomeIcon icon={faTachometerAlt} />
					</div>
					<div className="item-text">Inbox</div>
				</Link>
			</div>
			<div className="navigation-item">
				<Link to="#" className="navigation-link">
					<div className="item-icon">
						<FontAwesomeIcon icon={faTachometerAlt} />
					</div>
					<div className="item-text">Settings</div>
				</Link>
			</div>
		</div>
	);

	return (
		<div
			id="dashboardSidebar"
			ref={sidebarRef}
			className={`Sidebar ${props.dashboardMode} ${
				isSlider ? "slider" : ""
			}`}
			style={{ width: isSlider ? "100%" : `${props.sidebarWidth}px` }}
		>
			<div className="Sidebar-Mobile d-xl-none">
				<button className="timeCircle-btn" onClick={closeSlider}>
					<FontAwesomeIcon icon={faTimesCircle} />
				</button>
			</div>
			<div className="Sidebar-Head">
				<div className="Head-Avatar">
					<UserAvatar />
				</div>
				<h3 className="name">{`${props.user.firstName} ${props.user.lastName}`}</h3>
				<div className="wrap-options">
					<div className="option">
						<Link to="#" className="option-link">
							<FontAwesomeIcon icon={faUserCircle} />
						</Link>
					</div>
					<div className="option">
						<Link to="#" className="option-link">
							<FontAwesomeIcon icon={faCog} />
						</Link>
					</div>
					<div className="option">
						<Link
							to="#"
							className="option-link"
							onClick={props.logoutUser}
						>
							<FontAwesomeIcon icon={faSignOutAlt} />
						</Link>
					</div>
				</div>
			</div>
			<div className="Sidebar-Body">
				{props.dashboardMode === "hero"
					? HeroNavigation()
					: AngelNavigation()}
			</div>
		</div>
	);
};

export default Sidebar;
