import React from "react";

export default class UserCard extends React.Component {
	state = {};

	toggleShowMapModal = () => {
		this.setState({ showMapLocation: !this.state.showMapLocation });
	};

	render() {
		const { campaign } = this.props;

		return (
			<div style={{}}>
				<div
					className={"mx-auto bg-light"}
					style={{ borderStyle: "ridge" }}
				>
					<div className="row">
						<div className="col" style={{ marginTop: "5%" }}>
							<div className="head">
								<p className="text">
									Title: {campaign ? campaign.title : ""}
								</p>
								<p className="text">
									Short Desc:{" "}
									{campaign ? campaign.shortDescription : ""}
								</p>
								<p className="text">
									Goal: {campaign ? campaign.goal : ""}
								</p>
								<a
									href={`/charity/details/${
										campaign ? campaign._id : ""
									}`}
									target="_blank"
								>
									View Cause
								</a>
								<br />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
