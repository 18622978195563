import React, { useRef, useState, Fragment } from "react";
import "./SliderNavbar.css";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import TribeLogo from "../../../UI/TribeLogo/TribeLogo";
import PrimaryBtn from "../../../UI/Buttons/PrimaryBtn/PrimaryBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFacebookF,
	faTwitter,
	faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const SliderNavbar = (props) => {
	const [showDropdown, setShowDropdown] = useState(false);
	const sliderNavbarRef = useRef();

	const toggleDropdown = () => {
		setShowDropdown(!showDropdown);
	};

	const closeSliderNavbar = () => {
		sliderNavbarRef.current.style.transform = "scaleX(0)";
	};

	return (
		<section id="SliderNavbar" ref={sliderNavbarRef}>
			<div className="container">
				<div className="row">
					<div className="col">
						<div className="wrapper">
							<div className="header-bar">
								<TribeLogo type="white" />
								<div
									className="close-slider"
									onClick={closeSliderNavbar}
								>
									Close
								</div>
							</div>
							<div className="body">
								<div className="menubar">
									<ul>
										<li className="menubar-item">
											<Link
												className="menubar-link"
												to="/findcause"
											>
												Find causes
											</Link>
										</li>
										<li className="menubar-item">
											<Link
												className="menubar-link"
												to="/howitworks"
											>
												How it works
											</Link>
										</li>
										<li
											className="menubar-item dropdown-mobile"
											onClick={toggleDropdown}
										>
											<Link
												className="menubar-link"
												to=""
											>
												About
												<span className="downIcon">
													<FontAwesomeIcon
														icon={faChevronDown}
													/>
												</span>
											</Link>
											<div
												className={`dropdown-container ${showDropdown}`}
											>
												<div className="dropdown-box">
													<Link
														className="dropdown-link"
														to="/about"
													>
														About us
													</Link>
												</div>
												<div className="dropdown-box">
													<Link
														className="dropdown-link"
														to="/news"
													>
														News
													</Link>
												</div>
												<div className="dropdown-box">
													<Link
														className="dropdown-link"
														to="/contact"
													>
														Contact
													</Link>
												</div>
												<div className="dropdown-box">
													<Link
														className="dropdown-link"
														to="/legal"
													>
														Legal
													</Link>
												</div>
												<div className="dropdown-box">
													<Link
														className="dropdown-link"
														to="/faqs"
													>
														FAQS
													</Link>
												</div>
											</div>
										</li>
										{props.isAuthenticated ? (
											<li className="menubar-item btn-item">
												<PrimaryBtn
													type="link"
													size="md"
													color="yellow"
													to="/dashboard"
												>
													Dashboard
												</PrimaryBtn>
											</li>
										) : (
											<Fragment>
												<li className="menubar-item btn-item">
													<PrimaryBtn
														type="link"
														size="md"
														color="yellow"
														to="/join"
													>
														Join us
													</PrimaryBtn>
												</li>
												<li className="menubar-item">
													<PrimaryBtn
														type="link"
														size="md"
														color="transparent"
														to="/login"
													>
														Login
													</PrimaryBtn>
												</li>
											</Fragment>
										)}
									</ul>
								</div>
								<div className="footer">
									<div className="media-icons">
										<a href="/" target="_blank">
											<FontAwesomeIcon
												icon={faFacebookF}
											/>
										</a>
										<a href="/" target="_blank">
											<FontAwesomeIcon icon={faTwitter} />
										</a>
										<a href="/" target="_blank">
											<FontAwesomeIcon
												icon={faInstagram}
											/>
										</a>
									</div>
									<p>
										&copy; Tribe 2020. All rights reserved.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

SliderNavbar.propTypes = {
	isAuthenticated: PropTypes.bool.isRequired,
};

export default SliderNavbar;
