import React, { Component } from "react";
import DashboardLayout from "../../../../components/layouts/DashboardLayout/DashboardLayout";
import CauseForm from "../../../../components/UI/Forms/CauseForm/CauseForm";

class CreateCause extends Component {
	state = {
		title: "",
		goal: "",
		location: "",
		lat: null,
		lng: null,
		categories: [],
		category: { name: "" },
		shortDesc: "",
		story: "",
		customUrl: "",
		faqs: [],
	};

	render() {
		return (
			<DashboardLayout>
				<main className="CreateCause">
					<CauseForm />
				</main>
			</DashboardLayout>
		);
	}
}

export default CreateCause;
