import { requestHandler } from "../utils/requestHandler";

class Angel {
	static fetchDonations = () => {
		return requestHandler("getMyDonations", {}).then((result) => {
			if (result.status === 200) {
				return result.data;
			} else {
				throw "Request status is not 200.";
			}
		});
	};

	static fetchRewards = () => {
		return requestHandler("getMyRewards", {}).then((result) => {
			if (result.status === 200 || result.statusCode === 200) {
				return result.data;
			} else {
				throw "Request status is not 200.";
			}
		});
	};
}

export default Angel;
