import React, { Fragment } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import StripeCheckout from "react-stripe-checkout";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import queryString from "query-string";

import "./Donate.css";

import { generateCharityDonationReciptPDF } from "../../utils/generatePDF";
import {
	receiveLogout,
	receiveLogin,
	setDashboardMode,
} from "../../store/actions";
import Navbar from "../../components/layouts/Navbar";
import Sidebar from "../../components/layouts/Sidebar/Sidebar";
import ContentBox from "../../components/layouts/ContentBox/ContentBox";
import { requestHandler } from "../../utils/requestHandler";
import successImage from "../../assets/images/successImage.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUniversity, faWallet } from "@fortawesome/free-solid-svg-icons";
import CustomLabel from "../../components/CustomLabel";
import CustomInput from "../../components/CustomInput";
import {
	FACEBOOK_APP_ID,
	GOOGLE_AUTH_ID,
	STRIPE_KEY,
} from "../../utils/constants";
import GoogleLogin from "react-google-login";
import User from "../../DataModels/User";
import ForgotPasswordEmailRequest from "../../components/Modals/ForgotPasswordModal";

class CharityDetails extends React.Component {
	constructor(props) {
		super(props);
		this.weekDays = [
			"Sunday",
			"Monday",
			"Tuesday",
			"Wednesday",
			"Thursday",
			"Friday",
			"Saturday",
		];
		this.state = {
			redirect: null,
			step: 1,
			loading: true,
			firstName: "",
			lastName: "",
			email: "",
			message: "",
			lastStep: 3,
			donateAnonymously: false,
			donationAmount: "",
			percentForPlatform: ["15%", "10%", "5%"],
			donationForPlatform: "15%",
			checkingEmailExistenceInSystem: false,
			emailGoodToGo: false,
			loginPassword: "",
			schedualWeekly: false,
			schedualMonthly: false,
			showForgotPasswordModal: false,
		};
	}

	componentDidMount() {
		this.presetData(this.props);
	}

	presetData = (props) => {
		const { user } = props;
		const { id } = props.match.params;
		this.setState({
			charityId: id,
			firstName: user.firstName ? user.firstName : "",
			lastName: user.lastName ? user.lastName : "",
			email: user.email ? user.email : "",
		});
		if (props.isAuthenticated) {
			this.setState({
				checkingEmailExistenceInSystem: false,
				emailGoodToGo: true,
			});
		}
	};

	componentWillReceiveProps(props) {
		this.presetData(props);
	}

	validateFields = () => {
		const { firstName, donationAmount, email } = this.state;
		let errors = [];
		if (!donationAmount.length || Number(donationAmount) <= 0)
			errors.push("Invalid Donation Amount");
		if (!firstName.length) errors.push("First Name");
		// if(!lastName.length) errors.push('Last Name')
		if (!email.length) errors.push("Email Address");

		return errors;
	};

	checkEmailExistenceInSystem = async (email) => {
		let checkingEmailExistenceInSystem = true;
		let emailGoodToGo = false;
		this.setState({
			checkingEmailExistenceInSystem,
			emailGoodToGo,
		});
		try {
			let response = await requestHandler("checkEmailExistance", {
				email,
			});
			console.log({ response });
			checkingEmailExistenceInSystem = false;
			emailGoodToGo = response.email ? false : true;
			this.setState({
				emailGoodToGo,
				checkingEmailExistenceInSystem,
			});
		} catch (e) {
			console.log("ERROR: ", e);
		}
	};

	checkingEmailTimeout = null;
	checkingEmail = null;
	onInputChange = (item) => {
		if (item.target.id === "email") {
			clearTimeout(this.checkingEmailTimeout);
			this.checkingEmail = item.target.value;
			this.checkingEmailTimeout = setTimeout(
				() => this.checkEmailExistenceInSystem(this.checkingEmail),
				500
			);
		}

		this.setState({
			[item.target.id]: item.target.value,
		});
	};

	nextStep = () => this.setState({ step: this.state.step + 1 });
	prevStep = () => this.setState({ step: this.state.step - 1 });

	updateDonationForPlatform = (e) => {
		this.setState({
			donationForPlatform: this.state.percentForPlatform[e.target.value],
		});
	};

	registerUser = async (firstName, lastName, email) => {
		let registeringData = {
			firstName,
			lastName,
			email,
			password: Math.floor(Math.random() * 1000000000).toString(),
			sendPassword: true,
			service: true,
			terms: true,
			promotionalEmails: false,
		};
		let status = false;
		await requestHandler("register", registeringData).then(
			(result) => {
				console.log(result);
				if (result.status === 200) {
					this.setState({ newAccount: true });
					status = true;
					localStorage.setItem("auth", JSON.stringify(result.data));
					this.props.loginUser(result.data);
				} else {
					alert(result.message);
				}
			},
			(e) => {
				console.log("ERROR: ", e);
			}
		);
		return status;
	};

	donateNow = async () => {
		const {
			firstName,
			lastName,
			email,
			charityId,
			donateAnonymously,
			donationAmount,
			donationForPlatform,
			message,
		} = this.state;

		let donationPercentage = donationForPlatform.substring(
			0,
			donationForPlatform.length - 1
		);
		let donationToPlatformAmount = (
			donationAmount *
			(donationPercentage / 100)
		).toFixed(2);
		let totalPayment = (
			parseFloat(donationAmount) + parseFloat(donationToPlatformAmount)
		).toString();

		if (!this.props.isAuthenticated) {
			await this.registerUser(firstName, lastName, email);
		}
		requestHandler("donateToCharity", {
			charityId,
			donationAmount,
			donationForPlatform: donationToPlatformAmount,
			totalPayment,
			donateAnonymously,
			message,
		}).then((result) => {
			if (result.status === 200) {
				this.props.setDashboardMode("angel");
				this.setState({
					step: "donationComplete",
				});
			} else if (result.status === 500) {
				console.log("RESULT: ", result.status);
				alert(result.error.message);
			}
		});
	};

	initPaypalPayment = (totalPayment) => {
		if (!this.validateFields().length) {
			requestHandler("paypalPayment", {
				amount: totalPayment.toString(),
			}).then(
				(response) => {
					if (response.status === 200) {
						window.open(response.approval_url, "_blank");
					}
				},
				(error) => {
					console.log("ERROR: ", error);
				}
			);
		}
	};

	handleStripeToken = async (token) => {
		const {
			firstName,
			lastName,
			email,
			donationAmount,
			donationForPlatform,
		} = this.state;

		if (!this.props.isAuthenticated) {
			await this.registerUser(firstName, lastName, email);
		}

		let donationPercentage = donationForPlatform.substring(
			0,
			donationForPlatform.length - 1
		);
		let donationToPlatformAmount = (
			donationAmount *
			(donationPercentage / 100)
		).toFixed(2);
		let totalPayment = (
			parseFloat(donationAmount) + parseFloat(donationToPlatformAmount)
		).toString();
		totalPayment = totalPayment * 100;
		requestHandler("stripePayment", {
			stripeToken: token.id,
			amount: totalPayment.toString(),
		}).then(
			(response) => {
				if (response.status === 200) {
					this.donateNow();
				}
			},
			(error) => {
				console.log("ERROR: ", error);
			}
		);
	};

	renderSetAmount = () => {
		const { donationAmount, percentForPlatform, donationForPlatform } =
			this.state;
		return (
			<div>
				<h5>Please set Donation Amount</h5>
				<input
					type="number"
					className="form-control"
					placeholder="amount"
					id="donationAmount"
					onChange={this.onInputChange}
					value={donationAmount}
				/>
				<br />
				<div className="form-group">
					<label htmlFor="category">Donate to platform</label>
					<select
						className="form-control"
						id="donationForPlatform"
						onChange={this.updateDonationForPlatform}
					>
						{percentForPlatform.map((percent, i) => (
							<option
								key={i}
								value={i}
								selected={
									percent === donationForPlatform
										? true
										: false
								}
							>
								{percent}
							</option>
						))}
					</select>
				</div>
			</div>
		);
	};

	renderPrepaymentRecipt = () => {
		let { donationAmount, donationForPlatform } = this.state;
		if (
			donationAmount === null ||
			donationAmount === undefined ||
			donationAmount === ""
		)
			donationAmount = 0;
		let donationPercentage = donationForPlatform.substring(
			0,
			donationForPlatform.length - 1
		);
		return (
			<div>
				<span>
					Donation Amount <h5>{donationAmount}</h5>
				</span>
				<span>
					Contribution to Platform{" "}
					<h5>
						{(donationAmount * (donationPercentage / 100)).toFixed(
							2
						)}
					</h5>
				</span>
				<span>
					Total Payment{" "}
					<h5>
						{(
							parseInt(donationAmount) +
							donationAmount * (donationPercentage / 100)
						).toFixed(2)}
					</h5>
				</span>
			</div>
		);
	};

	renderMessageInput = () => {
		const { message, donateAnonymously } = this.state;
		return (
			<div>
				<div className="form-group">
					<label htmlFor="message">Message</label>
					<textarea
						className="form-control"
						id="message"
						rows="3"
						onChange={this.onInputChange}
						value={message}
					/>
				</div>
				<div>
					<input
						type="checkbox"
						onChange={() => {}}
						checked={donateAnonymously}
						onClick={() =>
							this.setState({
								donateAnonymously: !donateAnonymously,
							})
						}
					/>
					&nbsp;&nbsp;
					<label> I would like to donate anonymously </label>
				</div>
			</div>
		);
	};

	renderErrors = (errorsList) => (
		<div>
			Please fill proper data before proceeding with payment
			<br />
			<ul>
				{errorsList.map((ele) => (
					<li>{ele}</li>
				))}
			</ul>
		</div>
	);

	renderStripeButton = (totalPayment) => (
		<div>
			<StripeCheckout
				stripeKey={STRIPE_KEY}
				token={this.handleStripeToken}
				allowRememberMe={false}
				amount={totalPayment * 100}
			/>{" "}
			<br />
		</div>
	);

	responseFacebook = (response) => {
		console.log("Facebook Login: ", response);
		if (!response.accessToken) return console.log("Login not successful");
		let data = {
			firstName: response.name,
			lastName: "",
			email: response.email ? response.email : "",
			socialId: response.userID,
			socialObj: response,
		};
		User.socialMediaLogin(data, "facebook");
	};

	responseGoogle = (response) => {
		console.log("Google Login: ", response);
		if (!response.accessToken) return console.log("Login not successful");
		let data = {
			firstName: response.profileObj.givenName
				? response.profileObj.givenName
				: "",
			lastName: response.profileObj.familyName
				? response.profileObj.familyName
				: "",
			email: response.profileObj.email ? response.profileObj.email : "",
			socialId: response.googleId,
			socialObj: response,
		};
		User.socialMediaLogin(data, "google");
	};

	_generatePaymentRecipt = () => {
		let { donationAmount, donationForPlatform } = this.state;
		if (
			donationAmount === null ||
			donationAmount === undefined ||
			donationAmount === ""
		)
			donationAmount = 0;
		let donationToPlatformPercentage = donationForPlatform.substring(
			0,
			donationForPlatform.length - 1
		);
		let donationToPlatformAmount = (
			donationAmount *
			(donationToPlatformPercentage / 100)
		).toFixed(2);
		let totalPayment =
			parseInt(donationAmount) + parseInt(donationToPlatformAmount);

		generateCharityDonationReciptPDF(
			donationAmount,
			donationToPlatformPercentage,
			donationToPlatformAmount,
			totalPayment
		);
	};

	showForgotPasswordModal = () => {
		this.setState({
			showForgotPasswordModal: !this.state.showForgotPasswordModal,
		});
	};

	renderSelectPayment = (errors) => {
		const {
			donationAmount,
			donationForPlatform,
			loginPassword,
			firstName,
			lastName,
			email,
			checkingEmailExistenceInSystem,
			emailGoodToGo,
			showForgotPasswordModal,
		} = this.state;
		console.log({ checkingEmailExistenceInSystem, emailGoodToGo });
		let donationPercentage = donationForPlatform.substring(
			0,
			donationForPlatform.length - 1
		);
		let donationToPlatformAmount = (
			donationAmount *
			(donationPercentage / 100)
		).toFixed(2);
		let totalPayment = (
			parseFloat(donationAmount) + parseFloat(donationToPlatformAmount)
		).toString();
		return (
			<div>
				{showForgotPasswordModal ? (
					<ForgotPasswordEmailRequest
						onSuccess={this.showForgotPasswordModal}
						onClose={this.showForgotPasswordModal}
					/>
				) : null}
				<div className="form-group">
					<CustomLabel text="First Name " />
					<CustomInput
						id="firstName"
						placeholder=" last name"
						disabled={this.props.isAuthenticated ? true : false}
						onChange={this.onInputChange}
						value={firstName}
					/>
				</div>
				<div className="form-group">
					<CustomLabel text="Last Name " />
					<CustomInput
						id="lastName"
						placeholder=" last name"
						disabled={this.props.isAuthenticated ? true : false}
						onChange={this.onInputChange}
						value={lastName}
					/>
				</div>
				<div className="form-group">
					<CustomLabel text="Email " />
					<CustomInput
						id="email"
						placeholder=" email address"
						disabled={this.props.isAuthenticated ? true : false}
						onChange={this.onInputChange}
						value={email}
					/>
					{checkingEmailExistenceInSystem ? (
						"Checking..."
					) : emailGoodToGo ? (
						""
					) : email.length ? (
						<>
							Email exists. Please Login
							<br />
							<CustomInput
								id="loginPassword"
								placeholder=" password"
								type="password"
								disabled={
									this.props.isAuthenticated ? true : false
								}
								onChange={this.onInputChange}
								value={loginPassword}
							/>
							<br />
							<button
								onClick={this.login}
								className="btn-with-margin-left btn btn-primary"
							>
								{" "}
								Login
							</button>
							<Link onClick={this.showForgotPasswordModal}>
								{" "}
								Forgot your password?{" "}
							</Link>
							<div className="text-center social-btn">
								<FacebookLogin
									appId={FACEBOOK_APP_ID}
									fields="name,email,picture"
									callback={this.responseFacebook}
									render={(renderProps) => (
										<button
											className="btn btn-primary btn-block"
											onClick={renderProps.onClick}
										>
											<i className="fa fa-facebook"></i>{" "}
											Sign in with <b>Facebook</b>
										</button>
									)}
								/>

								<GoogleLogin
									clientId={GOOGLE_AUTH_ID}
									render={(renderProps) => (
										<button
											onClick={renderProps.onClick}
											disabled={renderProps.disabled}
											className="btn btn-danger btn-block"
										>
											<i className="fa fa-google"></i>{" "}
											Sign in with <b>Google</b>
										</button>
									)}
									buttonText="Login"
									onSuccess={this.responseGoogle}
									onFailure={this.responseGoogle}
									cookiePolicy={"single_host_origin"}
								/>
							</div>
						</>
					) : (
						""
					)}
				</div>
				<div>
					{/* <CustomLabel text="Payment Recipt " />
                    <button onClick={this._generatePaymentRecipt} className="btn-with-margin-left btn btn-secondary" > Generate and Download</button> */}
					{/* <div>
						<br />
						<b>Do you want to schedual this Donation</b>
						<br />
						<input type='checkbox' onChange={() => { }} checked={schedualWeekly}
							onClick={() => this.setState({ schedualWeekly: !schedualWeekly, schedualMonthly: false })} />&nbsp;&nbsp;
                    	<label> Weekly </label>
						<input type='checkbox' onChange={() => { }} checked={schedualMonthly}
							onClick={() => this.setState({ schedualMonthly: !schedualMonthly, schedualWeekly: false })} />&nbsp;&nbsp;
                    	<label> Monthly </label>
						<br />
						<b>
							{schedualWeekly ? "On every " + this.weekDays[new Date().getDay()] + " this donation will repeat itself" : null}
							{schedualMonthly ? "On " + new Date().getDate() + " of every month this donation will repeat itself" : null}
						</b>
					</div> */}
					<br />
				</div>
				<div>
					<h5>Select Payment Method</h5>
					{/* {this.renderPrepaymentRecipt()} */}
					{errors.length || !emailGoodToGo ? (
						this.renderErrors(errors)
					) : (
						<ul
							className="nav bg-light nav-pills rounded nav-fill mb-3"
							role="tablist"
						>
							<li className="nav-item li-space">
								{this.renderStripeButton(totalPayment)}
							</li>
							<li className="nav-item li-space">
								<button
									onClick={() =>
										this.initPaypalPayment(totalPayment)
									}
									className="nav-link"
									data-toggle="pill"
									href="#nav-tab-paypal"
								>
									<FontAwesomeIcon icon={faWallet} />
									Paypal
								</button>
							</li>
							<li className="nav-item li-space">
								<button
									onClick={() => {}}
									className="nav-link"
									data-toggle="pill"
									href="#nav-tab-bank"
								>
									<FontAwesomeIcon icon={faUniversity} /> Bank
									Transfer
								</button>
							</li>
						</ul>
					)}
				</div>
			</div>
		);
	};

	login = () => {
		const { email, loginPassword: password } = this.state;
		requestHandler("login", {
			email: email.toLowerCase(),
			password,
			userType: "other",
		}).then(
			(result) => {
				if (result.status === 200) {
					localStorage.setItem("auth", JSON.stringify(result.data));
					this.props.loginUser(result.data);
				} else {
					alert(result.message);
				}
			},
			(e) => {
				console.log("ERROR: ", e);
			}
		);
		return;
	};

	socialMediaLogin = (data, socialNetwork) => {
		requestHandler("socialLogin", { data, socialNetwork }).then(
			(result) => {
				if (result.status === 200) {
					localStorage.setItem("auth", JSON.stringify(result.data));
					this.props.loginUser(result.data);
				} else {
					alert(result.message);
				}
			},
			(e) => {
				console.log("ERROR: ", e);
			}
		);
		return;
	};

	onDonationSuccessful = () => {
		const queryparams = {
			id: this.state.charityId,
			popup: "donationcomplete",
		};
		if (this.state.newAccount) {
			queryparams.newaccount = true;
		}
		this.setState({
			redirect: "/dashboard?" + queryString.stringify(queryparams),
		});
		return (
			<Fragment>
				<Navbar />
				{this.props.isAuthenticated ? <Sidebar /> : null}
				<ContentBox title="Donate Now ">
					<div className="CharityWrapper ">
						<div className="card custom-card center-items">
							<div className="card-body">
								<h5>DONATION COMPLETE</h5>
								<div>
									<img
										className="image-fitting"
										src={successImage}
										alt="success_image"
									/>
								</div>
							</div>
						</div>
					</div>
				</ContentBox>
			</Fragment>
		);
	};

	render() {
		console.log("AUTH: ", this.props.isAuthenticated);
		const { lastStep, step, redirect } = this.state;

		const errors = this.validateFields();

		if (redirect) return <Redirect to={redirect} />;

		if (step === "donationComplete") return this.onDonationSuccessful();

		return (
			<Fragment>
				<Navbar />
				{this.props.isAuthenticated ? <Sidebar /> : null}
				<ContentBox title="Donate Now ">
					<div className="CharityWrapper ">
						<div className="card custom-card center-items">
							<div className="card-body">
								<h6>Step {step + "/" + lastStep}</h6>
								{step === 1 && this.renderSetAmount()}
								{step === 2 && this.renderMessageInput()}
								{step === 3 && this.renderSelectPayment(errors)}

								<button
									onClick={this.prevStep}
									disabled={step === 1 ? true : false}
									className="btn-with-margin-left btn btn-primary"
								>
									{" "}
									Prev{" "}
								</button>

								{step === lastStep ? null : (
									<button
										onClick={this.nextStep}
										className="btn-with-margin-left btn btn-primary"
									>
										{" "}
										Next{" "}
									</button>
								)}
							</div>
						</div>
					</div>
				</ContentBox>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {

	return {
		isAuthenticated: state.auth.isAuthenticated,
		user: state.auth.user,
		dashboardMode: state.dashboard.mode,
	};
}

const mapDispatchToProps = (dispatch) => ({
	logoutUser: () => dispatch(receiveLogout()),
	loginUser: (user) => dispatch(receiveLogin(user)),
	setDashboardMode: (dashboardMode) => {
		localStorage.setItem(
			"dashboardMode",
			JSON.stringify({ dashboardMode })
		);
		dispatch(setDashboardMode(dashboardMode));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(CharityDetails);
