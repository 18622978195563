import React from "react";
import SectionCard from "../../../UI/Cards/SectionCard/SectionCard";
import angelSectionImg from "../../../../assets/images/angelSectionImg.png";

const AboutAngels = () => {
    return (
        <SectionCard
            isReversed={false}
            img={angelSectionImg}
            imgSize="lg"
            title={"Angels"}
            list={[
                "Join Tribe",
                "Find Heroes and Causes to support",
                "Start changing the world",
            ]}
            btnText="Become an Angel"
            btnUrl="/register"
        >
            <p>
                In Tribe, when you support a Cause, you become an Angel, giving
                your resources or expertise to a worthy Hero so they can change
                the world.
            </p>
        </SectionCard>
    );
};

export default AboutAngels;
