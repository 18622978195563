import React from "react";
import "./PrimaryBtn.css";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PrimaryBtn = (props) => {
	const btnClassName = ["PrimaryBtn", `${props.color}`, `${props.effect}`];
	const stylesheet = {};

	switch (props.size) {
		case "sm":
			stylesheet.padding = "10px 0px";
			stylesheet.width = "100px";
			stylesheet.fontSize = "14px";
			break;
		case "md":
			stylesheet.padding = "8px 0px";
			stylesheet.width = "175px";
			stylesheet.fontSize = "14px";
			break;
		case "lg":
			stylesheet.padding = "10px 0px";
			stylesheet.width = "225px";
			break;
		case "cover":
			stylesheet.width = "100%";
			break;
		default:
			stylesheet.width = "175px";
	}

	switch (props.type) {
		case "link":
			return (
				<Link
					style={stylesheet}
					to={props.to}
					className={btnClassName.join(" ")}
				>
					{props.children}
					{props.icon ? (
						<span className="icon">
							<FontAwesomeIcon
								icon={props.icon}
								className="hvr-icon"
							/>
						</span>
					) : null}
				</Link>
			);
		case "button":
			return (
				<button
					style={stylesheet}
					onClick={props.onClick}
					className={btnClassName.join(" ")}
					disabled={props.disabled ? props.disabled : false}
				>
					{props.children}
					{props.icon ? (
						<span className="icon">
							<FontAwesomeIcon
								icon={props.icon}
								className="hvr-icon"
							/>
						</span>
					) : null}
				</button>
			);
		default:
			return null;
	}
};

PrimaryBtn.propTypes = {
	type: PropTypes.oneOf(["link", "button"]).isRequired,
	size: PropTypes.oneOf(["sm", "md", "cover"]).isRequired,
	color: PropTypes.oneOf([
		"yellow",
		"blue",
		"transparentYellow",
		"transparentBlue",
		"transparent",
		"disabled",
	]).isRequired,
	to: PropTypes.string,
	onClick: PropTypes.func,
	icon: PropTypes.any,
	effect: PropTypes.string,
};

export default PrimaryBtn;
