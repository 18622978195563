import React from "react";
import "./MainContent.css";

const MainContent = (props) => {
    const titleClassName =
        props.titleCenter === true
            ? "col-12"
            : "col-12 col-md-6 col-lg-3 mx-auto";
            
    const Styles = {
        title: {
            "text-align": props.titleCenter === true ? "center" : "left",
        },
        content: {
            "text-align": props.contentAlign ? props.contentAlign : "left",
        },
    };

    return (
        <section className="MainContent">
            <div className="container">
                <div className="row">
                    <div className={titleClassName}>
                        <div className="wrap-title" style={Styles.title}>
                            <h1 className="title">{props.topTitle}</h1>
                            <h1 className="title">{props.bottomTitle}</h1>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mx-auto col-content">
                        <div className="wrap-content" style={Styles.content}>
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MainContent;
