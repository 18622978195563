import React, { Fragment } from "react";
import "./styles.css";
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
} from "react-places-autocomplete";
import { FormControl, InputLabel, OutlinedInput } from "@material-ui/core";

class PlacesAutoCompleteInput extends React.Component {
	constructor(props) {
		super(props);
	}

	handleChange = (location) => {
		this.props.setLocatoinAutocomplete(location);
	};

	handleSelect = (location) => {
		geocodeByAddress(location)
			.then((results) => getLatLng(results[0]))
			.then((latLng) =>
				this.props.setLocatoinAutocomplete(location, latLng)
			)
			.catch((error) => console.error("Error", error));
	};

	render() {
		return (
			<PlacesAutocomplete
				value={this.props.location}
				onChange={this.handleChange}
				onSelect={this.handleSelect}
			>
				{({
					getInputProps,
					suggestions,
					getSuggestionItemProps,
					loading,
				}) => (
					<div className="LocationAutoComplete">
						{/* <div className="form-group">
							<input
								type="text"
								{...getInputProps({
									placeholder: "Location",
									className:
										"form-control location-search-input",
								})}
							/>
						</div> */}
						<div className="input-wrapper">
							<FormControl fullWidth variant="outlined">
								<InputLabel htmlFor="outlined-adornment-amount">
									Location
								</InputLabel>
								<OutlinedInput
									id="outlined-adornment-amount"
									value={0}
									// onChange={handleChange("amount")}
									labelWidth={60}
									{...getInputProps({
										className:
											"form-control location-search-input",
									})}
								/>
							</FormControl>
						</div>

						<ul className="LocationAutoComplete-List list-group autocomplete-dropdown-container">
							<Fragment>
								{loading && <Fragment>Loading...</Fragment>}
								{suggestions.map((suggestion) => {
									const className = suggestion.active
										? "suggestion-item--active"
										: "suggestion-item";
									const style = suggestion.active
										? {
												backgroundColor: "#fafafa",
												cursor: "pointer",
										  }
										: {
												backgroundColor: "#ffffff",
												cursor: "pointer",
										  };
									return (
										<Fragment>
											<li
												{...getSuggestionItemProps(
													suggestion,
													{
														className,
														style,
													}
												)}
												className="LocationAutoComplete-ListItem list-group-item"
											>
												{suggestion.description}
											</li>
										</Fragment>
									);
								})}
							</Fragment>
						</ul>
					</div>
				)}
			</PlacesAutocomplete>
		);
	}
}

export default PlacesAutoCompleteInput;
