import React, { Fragment } from "react";
import { connect } from 'react-redux';

import { receiveLogout } from "../../store/actions";

import Navbar from '../../components/layouts/Navbar'
import AdminSidebar from "../../components/layouts/AdminSidebar/AdminSidebar";
import ContentBox from "../../components/layouts/ContentBox/ContentBox";

import { Redirect } from "react-router-dom";
import { requestHandler } from "../../utils/requestHandler";
import { Button } from "react-bootstrap";


class Navigation extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            loading: true,
            redirect: null,
        }
    }

    componentDidMount() {
        this.reloadData()
    }

    componentWillReceiveProps(props) {
        const { userstatus } = props.match.params
        const { lastQuery } = this.state
        if(lastQuery !== userstatus)
            this.setState({users: []}, this.reloadData)
    }

    reloadData = () => {
        const { userstatus } = this.props.match.params
        const query = {}
        console.log("QUERYING USER")
        if(userstatus === 'inactive') {
            query.status = userstatus
        } else if(!userstatus) {}
        else {
            return this.setState({ redirect: '/error' })
        }
        
        requestHandler('getUser', query)
        .then( response => {
            console.log({response})
            if(response.status === 200) {
                this.setState({ users: response.data, lastQuery: userstatus })
            }
        })
    }

    changeUserStatus = (data) => {
        requestHandler('changeUserStatus', data)
        .then( response => {
            console.log("Status change: ", response)
            if(response.status === 200) {
                console.log("Reloading data")
                this.reloadData()
            }
        })
    }

    renderChangeUserStatusButton = (user) => {
        if(user.status === 'active')
        return (
            <Button onClick={() => this.changeUserStatus({userId: user._id, status: 'inactive'})}>Deactivate Account</Button>
        )
        else
        return (
            <Button onClick={() => this.changeUserStatus({userId: user._id, status: 'active'})}>Activate Account</Button>
        )
    }

    renderTableBody = () => {
        const { users } = this.state
        return (
			<tbody>
				{users.map((user) => (
					<tr>
						<td>{user.firstName}</td>
						<td>{user.lastName}</td>
						<td>{user.email}</td>
						<td>{user.promotionalEmails ? 'Yes' : 'No'}</td>
						<td>{user.status === 'active' ? 'Active' : 'Inactive'}</td>
						<td>{user.createdAt.split('T')[0]}</td>
						<td>
							<Button onClick={() => this.props.history.push('/admin/user/' + user._id)}>
								View User
							</Button>
						</td>
						<td>{this.renderChangeUserStatusButton(user)}</td>
					</tr>
				))}
			</tbody>
		) 
    }

    render() {  
        const { redirect } = this.state
        const { userstatus } = this.props.match.params
        if(redirect) return ( <Redirect to={redirect} />)
        return (
            <Fragment>
                <Navbar />
				<AdminSidebar />
                <ContentBox title={"Show users: " + (userstatus ? userstatus : "all")}> 
                    <table className='table'>
                        <thead>
                            <tr>
                                <td>First Name</td>
                                <td>Last Name</td>
                                <td>Email Address</td>
                                <td>Promo Emails</td>
                                <td>Profile Status</td>
                                <td>Joined At</td>
                                <td>Profile</td>
                                <td>Account Status</td>
                            </tr>

                        </thead>
                        {this.renderTableBody()}
                    </table>                
                </ContentBox>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        user: state.auth.user
    };
}

const mapDispatchToProps = (dispatch) => ({
    logoutUser: () => dispatch(receiveLogout())

});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
