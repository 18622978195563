import React, { Fragment } from 'react'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { receiveLogout } from '../../store/actions'
import ContentBox from '../../components/layouts/ContentBox/ContentBox'
import Navbar from '../../components/layouts/Navbar'
import Sidebar from '../../components/layouts/Sidebar/Sidebar'
import Enterprise from '../../DataModels/Enterprise'
import { requestHandler } from '../../utils/requestHandler'

class MyRewards extends React.Component {
	state = {
		purchases: [],
		showTrackingModal: false
	}

	componentDidMount() {
		this.loadMyRewards()
	}

	loadMyRewards = () => {
		requestHandler('getMyRewards', {}).then((result) => {
			if (result.status === 200 || result.statusCode === 200) {
				console.log('REWARDS: ', result.data)
				this.setState({ purchases: result.data })
			}
		})
	}

	renderTableBody = () => {
		const { purchases } = this.state
		console.log({ purchases })
		return (
			<tbody>
				{purchases.map((purchase) => (
					<tr>
						<td> {purchase.enterprise.title} </td>
						<td> {purchase.rewardDetails.title} </td>
						<td> {'$ ' + purchase.rewardDetails.price} </td>
						<td> {purchase.rewardDetails.deliveryDays} </td>
						<td>
							{' '}
							<Button
								onClick={() =>
									this.setShowTrackingModal(
										purchase.enterprise._id
									)
								}>
								Tracking
							</Button>
						</td>
						<td>
							{' '}
							<Link
								to={
									'/enterprise/details/' +
									purchase.enterprise._id
								}>
								{' '}
								View Details{' '}
							</Link>{' '}
						</td>
					</tr>
				))}
			</tbody>
		)
	}

	setShowTrackingModal = (enterpriseId) => {
		if (!enterpriseId) {
			return this.setState({ showTrackingModal: false })
		}
		Enterprise.FetchById(enterpriseId).then((res) => {
			console.log('response: ', res.productStage)
			if (res.status === 200) {
				this.setState({ showTrackingModal: res.productStage.data[0] })
			}
		})
	}

	render() {
		return (
			<Fragment>
				{/* <ProductStageDetails product={this.state.showTrackingModal} toggleModal={() => this.props.setShowTrackingModal} /> */}
				<Navbar />
				<Sidebar />
				<ContentBox title='Your Rewards'>
					{/* <Campaigns charities={this.state.donations}/> */}
					<table className='table'>
						<thead>
							<tr>
								<td>Enterprise Title</td>
								<td>Reward Title</td>
								<td>Reward Price</td>
								<td>Delivery Days</td>
							</tr>
						</thead>
						{this.renderTableBody()}
					</table>
				</ContentBox>
			</Fragment>
		)
	}
}

function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		user: state.auth.user
	}
}

const mapDispatchToProps = (dispatch) => ({
	logoutUser: () => dispatch(receiveLogout())
})

export default connect(mapStateToProps, mapDispatchToProps)(MyRewards)
