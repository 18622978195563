import React from "react";
import "./CampaignCard.css";
import CardImg from "../../../assets/images/Card.jpg";
import { Link } from "react-router-dom";

const CampaignCard = (props) => (
	<div className='CampaignCard'>
		<div className='head'>
			<img
				src={props.content.length ? props.content[0] : CardImg}
				alt='CampaignCardImg'></img>
		</div>
		<div className='body'>
			<h4 className='title'>{props.title}</h4>
			<p className='text'>Goal $ {props.goal}</p>
			<Link
				className='link'
				to={
					props.enterprise
						? "/enterprise/details/" + props._id
						: "/charity/details/" + props._id
				}>
				View
			</Link>
		</div>
	</div>
);

export default CampaignCard;
