import React from "react";
import PropTypes from "prop-types";
import "./Backdrop.css";
import Title from "../../UI/Typography/Title/Title";

const Backdrop = (props) => {
    return (
        <div className="Backdrop">
            <div className="Backdrop-Img">
                <img src={props.img} alt="Backdrop-Img" />
            </div>
            <div className="Backdrop-Title">
                <Title size="lg" color={props.textColor} isResponsive={true}>
                    {props.text}
                </Title>
            </div>
        </div>
    );
};

Backdrop.propTypes = {
    img: PropTypes.any.isRequired,
    text: PropTypes.string.isRequired,
    textColor: PropTypes.oneOf(["white", "dark"]).isRequired,
};

export default Backdrop;
