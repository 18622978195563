import React from "react";
import CampaignCard from "./CampaignCard/CampaignCard";

const Campaigns = (props) => (
    <section className="Campaigns">
        <h5>{props.type ? props.type : ''}</h5>
        <div className="container">
            <div className="row">
                {
                    props.charities.map( charity => (
                        <div key={charity._id} className="col-10 col-md-6 col-lg-4 mx-auto mb-3">
                            <CampaignCard enterprise={props.enterprises} {...charity}/>
                        </div>
                    ))
                }
            </div>
        </div>
    </section>
);

export default Campaigns;
