import React, { Component } from "react";
import Angel from "../../../../DataModels/Angel";
import DashboardLayout from "../../../../components/layouts/DashboardLayout/DashboardLayout";
import ResponsiveTable from "../../../../components/UI/ResponsiveTable/ResponsiveTable";

class RewardTraking extends Component {
	state = {
		headers: [
			"Enterprise Title",
			"Reward Title",
			"Reward Price",
			"Delivery Days",
			"Actions",
		],
		records: [],
	};

	componentDidMount() {
		this.getRewards();
	}

	getRewards = () => {
		Angel.fetchRewards()
			.then((data) => this.setState({ records: data }))
			.catch((err) => console.log(err));
	};

	render() {
		return (
			<DashboardLayout>
				<main className="RewardTraking" style={{ margin: "0px 40px" }}>
					<ResponsiveTable
						for="angelRewards"
						headers={this.state.headers}
						records={this.state.records}
					/>
				</main>
			</DashboardLayout>
		);
	}
}

export default RewardTraking;
