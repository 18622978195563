import React from 'react';
import "./AddressCardsSection.css";
import { connect } from "react-redux";
import { receiveLogout, receiveLogin } from "../../store/actions";
import { requestHandler } from '../../utils/requestHandler';
import AddressCard from '../AddressCard/AddressCard'

class AddressCardsSection extends React.Component {
	state = {
		shippingAddresses: []
	};

	componentDidMount() {
		this.loadData()
	}

	loadData = async () => {
		let response = await requestHandler('getUser', { id: this.props.user._id })
		const shippingAddresses = response.data[0].shippingAddresses ? response.data[0].shippingAddresses : []
		if (response.status === 200) {
			if (shippingAddresses.length) {
				this.setState({ shippingAddresses })
				this.props.setShippingAddress(shippingAddresses[0])
			}

		}
	}

	renderAddresses = () => {
		const { selectedShippingAddress } = this.props
		const { shippingAddresses } = this.state
		return (
			<div className="row">
				{ shippingAddresses.map((address) => {
					return (
						<div className="CampaignCard ml-4" onClick={() => this.props.setShippingAddress(address)} >
							<AddressCard selected={selectedShippingAddress && selectedShippingAddress._id === address._id ? true : false} key={address._id} {...address} />
						</div>
					)
				})}
				<div className="CampaignCard ml-4" onClick={() => this.props.setShippingAddress(null)} >
					Add new adderss
                </div>
			</div>
		)
	}

	render() {
		return (<>
			<div className="card mx-3">
				<div className="card-header">
					<h4>Select one of saved address for shipping</h4>
				</div>
				{this.renderAddresses()}

			</div>
			<button
				className="btn btn-primary my-3"
				type="submit" >
				Buy Reward
            </button> </>
		);
	}
}

function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		user: state.auth.user,
	};
}

const mapDispatchToProps = (dispatch) => ({
	logoutUser: () => dispatch(receiveLogout()),
	loginUser: user => dispatch(receiveLogin(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressCardsSection);

