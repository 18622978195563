import React, { useState, useRef } from "react";
import "./SearchBar.css";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom";

const SearchBar = (props) => {
	const [searchInput, setSearchInput] = useState("");
	const SearchBarRef = useRef(null);
	const classNameList = ["SearchBar", `${props.viewport}`, `${props.show}`];

	const submitHandler = () => {
        props.toggleSearchBar()
		if (searchInput) {
			props.history.push(`/findcause?search=${searchInput}`);
		} else {
			alert("Empty search field...");
		}
	};

	return (
		<div className={classNameList.join(" ")} ref={SearchBarRef}>
			<div className="Wrap-Bar">
				<div className="SearchIconBox">
					<span onClick={submitHandler}>
						<FontAwesomeIcon icon={faSearch} />
					</span>
				</div>
				<div className="InputBox">
					<input
						type="text"
						placeholder="Search..."
						className="InputField"
						value={searchInput}
						onChange={(e) => setSearchInput(e.target.value)}
						onKeyDown={(e) => {
							if (e.keyCode === 13) {
								submitHandler();
							}
						}}
					/>
				</div>
				<div className="TimesIconBox">
					<span onClick={props.toggleSearchBar}>
						<FontAwesomeIcon icon={faTimes} />
					</span>
				</div>
			</div>
			{/* <div className="Wrap-Dropdown">
				<div className="option">Lorem.</div>
				<div className="option">Lorem Ipsum.</div>
				<div className="option">Lorem Ipsum dolar sit amet.</div>
			</div> */}
		</div>
	);
};

SearchBar.propTypes = {
	show: PropTypes.bool.isRequired,
	viewport: PropTypes.oneOf(["mobile", "desktop"]).isRequired,
	toggleSearchBar: PropTypes.func.isRequired,
};

export default withRouter(SearchBar);
