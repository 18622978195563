import React, { Component, Fragment } from "react";
import "./Home.css";
import { connect } from "react-redux";
import sweetalert from "sweetalert";
import bgDummy1 from "../../../assets/images/bgDummy1.png";
import bgDummy2 from "../../../assets/images/bgDummy2.png";
// Images
import HeaderImg from "../../../assets/images/headerImg.png";
import BgShape from "../../../components/layouts/BgShape/BgShape";
import FluidCard from "../../../components/layouts/FluidCard/FluidCard";
import Footer from "../../../components/Sections/Footer/Footer";
// Sections
import Header from "../../../components/Sections/Header/Header";
import AboutAngels from "../../../components/Sections/Home/AboutAngels/AboutAngels";
import AboutHeroes from "../../../components/Sections/Home/AboutHeroes/AboutHeroes";
import TrendingProjects from "../../../components/Sections/Home/TrendingProjects/TrendingProjects";
import UserCardSlider from "../../../components/Sections/Home/UserCardSlider/UserCardSlider";
import UserModel from "../../../DataModels/User";

class Home extends Component {
	state = {
		topHeroes: [],
	};

	componentDidMount() {
		this.getTopHeroes();
	}

	getTopHeroes = () => {
		UserModel.GetAllUserCard()
			.then((apiRes) => {
				if (apiRes.status === 200) {
					this.setState({ topHeroes: apiRes.data });
				}
			})
			.catch((err) => {
				sweetalert(
					"Error",
					"Error occured while fetching heroes.",
					"error"
				);
			});
	};

	render() {
		return (
			<main className="Home">
				<Fragment>
					<Header img={HeaderImg} />
					<AboutHeroes />
					<AboutAngels />
					<TrendingProjects showBgShape={true} />
					<div className="First-FluidCard">
						<FluidCard
							img={bgDummy1}
							headers={["why tribe", "matters more", "then ever"]}
							btnText={"tell me more"}
						/>
					</div>
					<div className="First-UserCardSlider">
						<UserCardSlider
							title="Top Heroes"
							text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci eleifend vitae elit faucibus. Duis at morbi viverra ac consequat augue elementum."
							btnText="View all"
							data={this.state.topHeroes}
						/>
						<BgShape side="left" fromTop={0} />
					</div>
					<div className="Second-UserCardSlider">
						<UserCardSlider
							title="Our Volunteers"
							text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci eleifend vitae elit faucibus. Duis at morbi viverra ac consequat augue elementum."
							btnText="View all"
							data={this.state.topHeroes}
						/>
					</div>
					<div className="Second-FluidCard">
						<FluidCard
							img={bgDummy2}
							headers={["Are you ready to", "join the tribe?"]}
							btnText={"tell me more"}
						/>
					</div>
					<Footer />
				</Fragment>
			</main>
		);
	}
}

function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		user: state.auth.user,
	};
}

export default connect(mapStateToProps)(Home);
