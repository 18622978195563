import React, { Fragment } from "react";
import { connect } from "react-redux";
import queryString from "query-string";

import { receiveLogout } from "../store/actions";

import Navbar from "../components/layouts/Navbar";
import Sidebar from "../components/layouts/Sidebar/Sidebar";
import ContentBox from "../components/layouts/ContentBox/ContentBox";
import { Redirect } from "react-router-dom";
import PopupModal from "../components/Modals/PopupModal/PopupModal";
import MapContainer from "../components/MapContainer/MapContainer";

class Navigation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			data: {},
			redirect: null,
			message: "",
			popupModal: null,
		};
	}

	componentDidMount() {
		const { id, popup, newaccount } = queryString.parse(
			window.location.search
		);
		if (popup && popup.length)
			this.setState({
				popupModal: {
					popup,
					id,
					newaccount,
				},
			});
	}

	renderPopupModal = () => {
		const { popup, id, newaccount } = this.state.popupModal;
		// return (<MyModel />)
		return (
			<PopupModal
				type={popup}
				refId={id}
				newAccount={newaccount === "true" ? true : false}
			/>
		);
	};

	render() {
		const { redirect, popupModal } = this.state;
		const { dashboardMode } = this.props;

		if (redirect) return <Redirect to={redirect} />;
		return (
			<Fragment>
				<Navbar />
				<Sidebar />
				<ContentBox
					title={
						dashboardMode.toUpperCase() +
						" DASHBOARD to be implemented"
					}
				>
					{popupModal ? this.renderPopupModal() : null}
					{/* <MapContainer google={true} /> */}
				</ContentBox>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		user: state.auth.user,
		dashboardMode: state.dashboard.mode,
	};
}

const mapDispatchToProps = (dispatch) => ({
	logoutUser: () => dispatch(receiveLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
