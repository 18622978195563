import React from 'react'
import User from '../../DataModels/User'
import { GENERATE_AVATAR_URL } from '../../utils/constants'

const UpdateAvatarLink = (props) => {
	const user = User.GetLocalUser()
	let linkAppends = ''
	if (user.avatar) {
		linkAppends = new URLSearchParams(user.avatar).toString()
	}
	return <a href={GENERATE_AVATAR_URL + '?' + linkAppends}>Create your Avatar</a>
}

export default UpdateAvatarLink
