import React, { Component } from "react";
import { connect } from 'react-redux';
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin } from "react-google-login";

import { receiveLogin } from "../../store/actions";
import CustomLabel from '../../components/CustomLabel'
import CustomInput from '../../components/CustomInput'
import { requestHandler } from "../../utils/requestHandler";
import ButtonSuccess from "../../components/layouts/ButtonSuccess";
import { Link, Redirect } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FACEBOOK_APP_ID, GOOGLE_AUTH_ID } from "../../utils/constants";


class Register extends Component {

	state = {
		firstName: '',
		lastName: '',
		email: '',
		password: '',
		confirmPassword: '',
		acceptTandC: false,
		acceptPromotionalEmails: true,
		loading: false,
		register: false,
		service: true,
	};

	onChange = e => {
		this.setState({ [e.target.id]: e.target.value });
	};

	onSubmit = e => {
		e.preventDefault();
	};

	registerUser = () => {
		const {
			firstName,
			lastName,
			email,
			password,
			confirmPassword,
			acceptTandC,
			acceptPromotionalEmails,
			service
		} = this.state
		if (!firstName.length || !lastName.length || !email.length || !password.length || !acceptTandC) {
			return alert("Please fill all *medatory fields")
		} else if (password !== confirmPassword) return alert("Passwords do not match")

		let registeringData = { firstName, lastName, email, password, service, terms: acceptTandC, promotionalEmails: acceptPromotionalEmails }
		requestHandler('register', registeringData)
			.then(result => {
				console.log(result)
				if (result.status === 200) {
					localStorage.setItem('auth', JSON.stringify(result.data));
					this.props.loginUser(result.data)
				} else {
					alert(result.message)
				}
			}, e => {
				console.log('ERROR: ', e)
			})

	}
	socialMediaLogin = (data, socialNetwork) => {
		requestHandler("socialLogin", { data, socialNetwork }).then(
			(result) => {
				if (result.status === 200) {
					localStorage.setItem("auth", JSON.stringify(result.data));
					this.props.loginUser(result.data);
				} else {
					alert(result.message);
				}
			},
			(e) => {
				console.log("ERROR: ", e);
			},
		);
		return;
	};

	responseFacebook = (response) => {
		console.log("Facebook Login: ", response);
		if (!response.accessToken) return console.log("Login not successful");
		let data = {
			firstName: response.name,
			lastName: "",
			email: response.email ? response.email : "",
			socialId: response.userID,
			socialObj: response,
		};
		this.socialMediaLogin(data, "facebook");
	};

	responseGoogle = (response) => {
		console.log("Google Login: ", response);
		if (!response.accessToken) return console.log("Login not successful");
		let data = {
			firstName: response.profileObj.givenName
				? response.profileObj.givenName
				: "",
			lastName: response.profileObj.familyName
				? response.profileObj.familyName
				: "",
			email: response.profileObj.email ? response.profileObj.email : "",
			socialId: response.googleId,
			socialObj: response,
		};
		this.socialMediaLogin(data, "google");
	};

	render() {
		const {
			firstName,
			lastName,
			email,
			password,
			confirmPassword,
			acceptTandC,
			acceptPromotionalEmails,
			service,
			// loading,
			// register
		} = this.state;
		const { isAuthenticated } = this.props;

		if (isAuthenticated) {
			return <Redirect to="/dashboard" />;
		}

		if (!service) {
			return (
				<section id="register">
					<div className="container">
						<div className="row">
							<div className="mx-auto">
								<div className="wrapper">
									<form onSubmit={this.onSubmit}>
										<div className="wrap wrap-basic">
											<div className="content">
												<h2 className="title">
													Registration Information
												</h2>
												<br />
												<ButtonSuccess text='Register as Angle' onClick={() => this.setState({ service: 'angle' })} />
												<ButtonSuccess text='Register as Hero' onClick={() => this.setState({ service: 'hero' })} />
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</section>
			)
		}

		return (
			<section id="register">
				<div className="container">
					<div className="row">
						<div className="mx-auto">
							<div className="wrapper">
								<form onSubmit={this.onSubmit}>
									<div className="wrap wrap-basic">
										<div className="content">
											<h2 className="title">
												Registration Information
											</h2>
											<br />
										</div>
										<div className="form-group">
											<CustomLabel text="First Name *" />
											<CustomInput id='firstName' placeholder=' first name' onChange={this.onChange} value={firstName} />
										</div>
										<div className="form-group">
											<CustomLabel text="Last Name *" />
											<CustomInput id='lastName' placeholder=' last name' onChange={this.onChange} value={lastName} />
										</div>

										<div className="form-group">
											<CustomLabel text="Email Address *" />
											<CustomInput id='email' placeholder=' email' onChange={this.onChange} value={email} />
										</div>
										<div className="form-group">
											<CustomLabel text="Password *" />
											<CustomInput id='password' type='password' placeholder=' password' onChange={this.onChange} value={password} />
										</div>
										<div className="form-group">
											<CustomLabel text="Confirm Password *" />
											<CustomInput id='confirmPassword' type='password' placeholder=' re-enter password' onChange={this.onChange} value={confirmPassword} />
										</div>
									</div>
									<div>
										<input type='checkbox' onChange={() => { }} checked={acceptPromotionalEmails} onClick={() => this.setState({ acceptPromotionalEmails: !acceptPromotionalEmails })} />&nbsp;&nbsp;
									<label>I want to receive newsletters via email</label>
									</div>
									<div>
										<input type='checkbox' onChange={() => { }} checked={acceptTandC} onClick={() => this.setState({ acceptTandC: !acceptTandC })} />&nbsp;&nbsp;
									<label>* I agree to the Terms of Use and have read the Privacy Policy</label>
									</div>
									<br />
									<div className="d-flex">
										<ButtonSuccess text='Register' onClick={this.registerUser} />
									</div>
								</form>

								<div className='text-center social-btn'>
									<FacebookLogin
										appId={FACEBOOK_APP_ID}
										fields='name,email,picture'
										callback={this.responseFacebook}
										render={(renderProps) => (
											<button
												className='btn btn-primary btn-block'
												onClick={renderProps.onClick}>
												<i className='fa fa-facebook'></i>{" "}
												Register with <b>Facebook</b>
											</button>
										)}
									/>

									<GoogleLogin
										clientId={GOOGLE_AUTH_ID}
										render={(renderProps) => (
											<button
												onClick={renderProps.onClick}
												disabled={renderProps.disabled}
												className='btn btn-danger btn-block'>
												<i className='fa fa-google'></i>{" "}
												Register with <b>Google</b>
											</button>
										)}
										buttonText='Login'
										onSuccess={this.responseGoogle}
										onFailure={this.responseGoogle}
										cookiePolicy={"single_host_origin"}
									/>

									{/* <button className="btn btn-info btn-block"><i className="fa fa-twitter"></i> Sign in with <b>Twitter</b></button> */}
								</div>
								<div style={{ marginTop: "60px", marginLeft: "10%" }}>

									Already a Member of Tribe? &nbsp;&nbsp;
										<Link to="/login"><Button>Sign In</Button></Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}


function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated
	};
}

const mapDispatchToProps = (dispatch) => ({
	loginUser: user => dispatch(receiveLogin(user))

});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
