import { requestHandler } from "../utils/requestHandler";

class Updates {
	static getPendingUpdates = async () => {
		return await requestHandler("getCampaignUpdates", {
			status: "pending",
		});
	};

	static updateCampaignStatus = async (campaignId, status) => {
		return await requestHandler(
			"updateCampaignStatus",
			{ status },
			campaignId
		);
	};
}

export default Updates;
