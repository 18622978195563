import React from "react";
import "./ProjectCard.css";
import PropTypes from "prop-types";
import { ProgressBar } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import CardImg from "../../../../assets/images/cardImg.png";
import Title from "../../Typography/Title/Title";
import PrimaryBtn from "../../Buttons/PrimaryBtn/PrimaryBtn";

const ProjectCard = (props) => {
	const cardImg = props.charity.content
		? props.charity.content.length !== 0
			? props.charity.content[0]
			: CardImg
		: CardImg;

	const progress = props.charity
		? (props.charity.donationAmount / props.charity.goal) * 100 === 0
			? 0
			: (props.charity.donationAmount / props.charity.goal) * 100 < 10
			? 5
			: (props.charity.donationAmount / props.charity.goal) * 100
		: 0;

	return (
		<div
			className="ProjectCard"
			onClick={() =>
				props.history.push(`/charity/details/${props.charity._id}`)
			}
		>
			<div className="top-img">
				<img src={cardImg} alt="CardImg" />
			</div>
			<div className="content-box">
				<div className="body">
					<div className="wrapper-title">
						<Title size="md" color="dark">
							{props.charity.title || ""}
						</Title>
					</div>
					<p className="text">
						{props.charity.shortDescription || "No description"}
					</p>
					<Title size="sm" color="dark">
						Amount raised of ${props.charity.goal || 0}
					</Title>
					<Title size="md" color="dark" isResponsive={true}>
						${props.charity.donationAmount || 0}
					</Title>
					<ProgressBar variant="warning" now={progress} />
				</div>
				<div className="footer">
					<div className="stat mt-3">
						<Title size="md" color="dark" isResponsive={true}>
							{props.charity.anglesBacked || 0}
						</Title>
						<Title size="sm" color="dark">
							Angels have backed this
						</Title>
					</div>
					<div className="btn-box mt-3">
						<PrimaryBtn
							type="link"
							size="md"
							color="yellow"
							to={`/charity/details/${props.charity._id}` || ""}
						>
							Donate Now
						</PrimaryBtn>
					</div>
				</div>
			</div>
		</div>
	);
};

ProjectCard.propTypes = {
	charity: PropTypes.object.isRequired,
};

export default withRouter(ProjectCard);
