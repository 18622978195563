import jspdf from 'jspdf'

export function generateCharityDonationReciptPDF(title, donationAmount, donationToPlatformAmount, totalPayment) {
	var doc = new jspdf('p', 'pt');
	doc.text(50, 80, title)
	doc.text(50, 100 + 20, 'Donation to Cause: $ ' + donationAmount)
	doc.text(50, 120 + 20, 'Donation to Platform: $ ' + donationToPlatformAmount)
	doc.text(50, 140 + 20, 'Total payment: $ ' + totalPayment)
	doc.save('recipt.pdf')
}

