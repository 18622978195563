import React, { Fragment } from "react";
import { Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';

import "./EditCharity.css";

import { templateCharityUpdate } from '../../utils/templates'
import { receiveLogout } from "../../store/actions";
import Navbar from '../../components/layouts/Navbar'
import Sidebar from '../../components/layouts/Sidebar/Sidebar';
import ContentBox from "../../components/layouts/ContentBox/ContentBox";
import { requestHandler } from "../../utils/requestHandler";
import { tinyCloudAPIKey } from '../../utils/constants';


class CreateCampaignUpdate extends React.Component {

    state = {
        redirect: null,
        message: templateCharityUpdate,
        loading: false,
    };

    componentDidMount() {
        // this.loadData()
    }

    loadData = () => {
        this.loadCharity()
    }

    loadCharity = () => {
        const { enterprise, charity } = this.props
        console.log({ enterprise, charity })
        const { id } = this.props.match.params
        requestHandler(enterprise ? 'getEnterprise' : 'getCharity', { id })
            .then(result => {
                console.log("THE RESULT: ", result)
                const campaign = result.data[0]

                if (result.status !== 200) return;

                if (campaign.creatorId !== this.props.user._id) {
                    alert("You cannot post an update to this charity")
                    if (enterprise)
                        this.setState({ redirect: "/enterprise/details/" + id })
                    else if (charity)
                        this.setState({ redirect: "/charity/details/" + id })

                    return;
                } else {
                    this.setState({ loading: false })
                }
            }, err => {
                console.log("ERROR: ", err)
                alert("Cannot edit the Cause at this moment")
            })
    }

    onInputChange = (item) => {
        this.setState({
            [item.target.id]: item.target.value
        })
    }

    handleEditorChange = (message) => {
        this.setState({ message: message.length ? message : '<h1> </h1>' })
    }

    postUpdate = () => {
        const { message } = this.state
        // const { _id: createdBy } = this.props.user
        const { id: campaignId } = this.props.match.params

        if (!message.length)
            return alert("Please write an update")

        let data = {
            campaignId,
            message,
        }
        requestHandler('createCampaignUpdate', data)
            .then(result => {
                if (result.status === 200) {
                    alert("Update created")
                    this.props.history.goBack()
                }
            })
    }

    render() {
        const { message, redirect, loading } = this.state
        if (redirect) return (<Redirect to={redirect} />)
        if (loading) return (<div>loading...</div>)
        return (
            <Fragment>
                <Navbar />
                <Sidebar />
                <ContentBox title="Create an udpate">
                    <div className="CharityWrapper">
                        <form className="mb-4" onSubmit={(e) => e.preventDefault()}>
                            <div className="form-group">
                                {message.length ? <Editor
                                    id="story"
                                    initialValue={message}
                                    apiKey={tinyCloudAPIKey}
                                    init={{
                                        height: 500,
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                        ],
                                        toolbar:
                                            `undo redo | formatselect | bold italic | 
                                        alignleft aligncenter alignright alignjustify | 
                                        bullist numlist outdent indent | removeformat | help`
                                    }}
                                    onEditorChange={this.handleEditorChange} /> : null}
                            </div>
                            <button type="submit" className="btn btn-primary" onClick={this.postUpdate}>Submit</button>
                        </form>
                    </div>
                </ContentBox>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        user: state.auth.user
    };
}

const mapDispatchToProps = (dispatch) => ({
    logoutUser: () => dispatch(receiveLogout())

});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCampaignUpdate);
