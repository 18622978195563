import React from "react";
import "./FullPageLoader.css";
import ClipLoader from "react-spinners/ClipLoader";

const FullPageLoader = (props) => {
	return (
		<div className="FullPageLoader">
			<ClipLoader color={"#ec972f"} loading={props.loading} size={100} />
		</div>
	);
};

export default FullPageLoader;
