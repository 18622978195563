import React, { Fragment } from "react";
import { connect } from 'react-redux';

import { receiveLogout } from "../../store/actions";

import Navbar from '../../components/layouts/Navbar'
import ContentBox from "../../components/layouts/ContentBox/ContentBox";
import Campaigns from "../../components/Campaigns/Campaigns";
import { requestHandler } from "../../utils/requestHandler";

class AllCampaigns extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            allCharities: [],
            allEnterprises: [],
        }
    }

    componentDidMount() {
        requestHandler('getCharity')
        .then( result => {
            this.setState({ allCharities: result.data, loading: false })
        })

        requestHandler('getEnterprise')
        .then( result => {
            this.setState({ allEnterprises: result.data, loading: false })
        })
    }

    render() {
        const {
            allCharities,
            allEnterprises 
        } = this.state
        return (
            <Fragment>
                <Navbar />
                <ContentBox title="All Campaigns">
                    <Campaigns type="Charities" charities={allCharities} />
                    <Campaigns type="Enterprises" charities={allEnterprises} />
                </ContentBox>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        user: state.auth.user
    };
}

const mapDispatchToProps = (dispatch) => ({
    logoutUser: () => dispatch(receiveLogout())

});

export default connect(mapStateToProps, mapDispatchToProps)(AllCampaigns);
