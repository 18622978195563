import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Item from "../../../DataModels/EnterpriseRewardItem";
import ItemVariationSelector from "../../ItemVariationSelector";


class RewardDetails extends React.Component {
    state = {
        showModal: false,
        allItems: []
    }

    toggleModal = () => {
        this.setState({ showModal: !this.state.showModal })
    }

    componentDidMount() {
        this.loadData()
    }

    loadData = async () => {
        const { enterpriseId } = this.props
        const allItems = await Item.FetchEnterpriseRewardItems(enterpriseId)
        console.log('All Items: ', allItems)
        this.setState({ allItems })
    }

    select = (index, selection) => {
        console.log({ index, selection })
    }

    render() {
        const { deliveryDays, description, price, retailPrice, items } = this.props.reward
        const { allItems } = this.state

        console.log('Rewar Items: ', items)
        return (
            <>
                <Modal
                    show={this.state.showModal}
                    onHide={this.toggleModal} >
                    <Modal.Header>
                        <h4>Reward Details</h4>
                    </Modal.Header>
                    <Modal.Body>
                        Delivery Days: <b>{deliveryDays}</b><br />
						Description: <b>{description}</b><br />
						Price: <b>{price}</b><br />
						Retail Price: <b>{retailPrice}</b><br />
                        <br />
                        {items && items.length ?
                            items.map((item, index) => <ItemVariationSelector
                                item={item}
                                allItems={allItems}
                                select={(selection) => this.select(index, selection)} />
                            ) : null}

                        {this.props.enterpriseId ?
                            <Link to={"/reward/buy/" + this.props.enterpriseId + "/" + this.props.rewardId} >
                                <li className="list-group-item btn btn-primary">
                                    Purchase
								</li>
                            </Link>
                            : null}
                        {/* {JSON.stringify(this.props.reward)} */}
                    </Modal.Body>
                </Modal>
                <Button onClick={this.toggleModal}>Show Details</Button>
            </>
        );
    };
}

export default RewardDetails;
