import React from "react";
import "./TribeLogo.css";
import { Link } from "react-router-dom";
import TribeWhiteLogo from "../../../assets/images/tribe-white-logo.png";
import TribeBlackLogo from "../../../assets/images/tribelogo.png";
import PropTypes from "prop-types";

const TribeLogo = (props) => (
    <Link to="/">
        <div className="TribeLogo">
            <img
                src={props.type === "white" ? TribeWhiteLogo : TribeBlackLogo}
                alt="TribeLogo"
            />
        </div>
    </Link>
);

TribeLogo.propTypes = {
    type: PropTypes.oneOf(["white", "black"]).isRequired,
};

export default TribeLogo;
