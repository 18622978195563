import React, { Fragment } from 'react'

export default class ItemVariationSelector extends React.Component {

    onChangeChange = (e) => {
        console.log(e.target.value)
    };

    renderCustomization = (customization, index) => {
        console.log({ customization, index })
        return <Fragment className="bg-secondary p-3">
            Customization: {customization.description} <br />
            <div style={{ marginLeft: '20px' }}>
                Options: {
                    <select
                        className='form-control'
                        id={index}
                        onChange={this.onChangeChange}>
                        {customization
                            .availability
                            .map((singleOption, i) => (
                                <option
                                    key={i}
                                    value={i}>
                                    {singleOption}
                                </option>
                            ))}
                    </select>
                }
            </div>
        </Fragment>
    }

    render() {
        const { item, allItems } = this.props
        let itemDeatils = null
        for (let iter of allItems) {
            if (item._id === iter._id) {
                itemDeatils = iter
                break;
            }
        }
        if (!itemDeatils) return null
        return (
            <div>
                <hr />
                Item: {itemDeatils.title} <br />
                Quantity: {item.quantity} <br />
                {itemDeatils.customizations ?
                    itemDeatils.customizations.map((customization, index) => this.renderCustomization(customization, index))
                    : null}

            </div>)

    }
}