import { Editor } from "@tinymce/tinymce-react";
import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import CreateFaqs from "../../components/CreateFaqs";
import ContentBox from "../../components/layouts/ContentBox/ContentBox";
import Navbar from "../../components/layouts/Navbar";
import Sidebar from "../../components/layouts/Sidebar/Sidebar";
import ProductStageSelector from "../../components/ProductStageSelector";
import PlacesAutoCompleteInput from "../../components/UI/Inputs/PlacesAutoCompleteInput";
import { tinyCloudAPIKey } from "../../utils/constants";
import { generateDefaultCustomUrlFromTitle } from "../../utils/helpers";
import { requestHandler } from "../../utils/requestHandler";
import { templateStoryEnterprise } from "../../utils/templates";
import "../createCharity/CreateCharity.css";

class CreateEnterprise extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirect: null,
			categories: [],
			title: "",
			shortDescription: "",
			goal: "",
			content: [],
			category: { name: "" },
			location: "",
			lat: "",
			lng: "",
			story: templateStoryEnterprise,
			duration: "",
			step: 0,
			customUrl: "",
			productStage: null,
			faqs: [],
		};
	}

	setLocatoinAutocomplete = (location, latLng) => {
		this.setState({ location });

		if (latLng) {
			const { lat, lng } = latLng;
			this.setState({ lat, lng });
		}
	};

	componentDidMount() {
		requestHandler("getCategories").then((result) => {
			this.setState({
				categories: result.data,
				category: result.data[0] ? result.data[0] : { name: "" },
			});
		});
	}

	onCategoryChange = (e) => {
		this.setState({
			category: this.state.categories[e.target.value],
		});
	};

	onInputChange = (item) => {
		this.setState({
			[item.target.id]: item.target.value,
		});
		if (item.target.id === "title") {
			this.setState({
				customUrl: generateDefaultCustomUrlFromTitle(item.target.value),
			});
		}
	};

	createEnterprise = (e) => {
		e.preventDefault();
		const {
			title,
			goal,
			customUrl,
			category,
			location,
			lat,
			lng,
			story,
			duration,
			content,
			shortDescription,
			productStage,
			faqs,
		} = this.state;

		if (
			!title.length ||
			!goal.length ||
			!location.length ||
			!story.length ||
			!productStage
		) {
			return alert("Please fill all fields");
		}
		if (customUrl.length && customUrl.length < 10) {
			return alert("Custom URL should be at least 10 characters");
		}
		const formData = new FormData();
		formData.append("title", title);
		formData.append("goal", Number(goal).toString());
		formData.append("story", story);
		formData.append("duration", duration);
		formData.append("customUrl", customUrl);
		formData.append("shortDescription", shortDescription);
		formData.append("productStage", productStage);
		formData.append("categoryId", category._id);
		formData.append("locationName", location);
		formData.append("location", [lat, lng]);
		formData.append("faqs", JSON.stringify(faqs));
		formData.append("productStage", JSON.stringify(productStage));

		for (let file of content) {
			formData.append("content", file);
		}

		requestHandler("createEnterprise", formData).then((result) => {
			if (result.status === 200)
				this.setState({
					redirect: "/enterprise/details/" + result.data[0]._id,
				});
		});
	};

	onFileInputChange = (event) => {
		this.setState({ content: event.target.files });
	};

	onInputChange = (item) => {
		this.setState({
			[item.target.id]: item.target.value,
		});
	};

	handleEditorChange = (story) => {
		this.setState({ story });
	};

	nextStep = (e) => {
		if (e && e.preventDefault) e.preventDefault();
		this.setState({ step: this.state.step + 1 });
	};

	prevStep = () => {
		this.setState({ step: this.state.step - 1 });
	};

	pushFaq = (question, answer) => {
		this.setState({
			faqs: [...this.state.faqs, { question, answer }],
		});
	};

	popFaq = (index) => {
		const faqs = [...this.state.faqs];
		faqs.splice(index, 1);
		this.setState({ faqs });
	};

	renderDefineCategory = () => {
		// STEP 0
		const { categories, category } = this.state;
		return (
			<form className="mb-4" onSubmit={this.nextStep}>
				<div className="form-group">
					Please select a category of your Cause
					<select
						className="form-control"
						id="category"
						onChange={this.onCategoryChange}
					>
						{categories.map((singleCategory, i) => (
							<option
								selected={singleCategory._id === category._id}
								key={i}
								value={i}
							>
								{singleCategory.name}
							</option>
						))}
					</select>
				</div>
				<button type="submit" className="btn btn-primary">
					Continue
				</button>
			</form>
		);
	};

	renderDefineGoalAndTitle = () => {
		// STEP 1
		const { title, goal, location, duration, shortDescription } =
			this.state;
		return (
			<form className="mb-4" onSubmit={this.nextStep}>
				<div className="form-group">
					I am looking to make a change in
					<br />
					<PlacesAutoCompleteInput
						setLocatoinAutocomplete={this.setLocatoinAutocomplete}
						location={location}
					/>
				</div>
				<br />
				<div className="form-group">
					I am raising &nbsp;
					<div className="input-group mb-3">
						<div className="input-group-prepend">
							<span className="input-group-text">$</span>
						</div>
						<input
							type="number"
							placeholder="goal"
							className="form-control"
							id="goal"
							required
							onChange={this.onInputChange}
							value={goal}
						/>
					</div>
				</div>

				<div className="form-group">
					To
					<input
						type="text"
						placeholder="title"
						className="form-control"
						id="title"
						required
						onChange={this.onInputChange}
						value={title}
					/>
				</div>

				<div className="form-group">
					Short Description
					<input
						type="text"
						placeholder="short description / tagline"
						className="form-control"
						id="shortDescription"
						required
						onChange={this.onInputChange}
						value={shortDescription}
					/>
				</div>

				<div className="form-group">
					Planning to initiate my idea in
					<input
						type="number"
						className="form-control"
						id="duration"
						placeholder="# of days"
						required
						onChange={this.onInputChange}
						value={duration}
					/>
				</div>

				<span>
					<button
						className="btn btn-secondary"
						onClick={this.prevStep}
					>
						Go back
					</button>
					&nbsp;
					<button type="submit" className="btn btn-primary">
						Continue
					</button>
				</span>
			</form>
		);
	};

	onChangeProductStageUpdate = (productStage) => {
		const { currentStage } = productStage;
		// Wierd problem check. Needs research and fixing
		// Also fix in updating enterprise
		// App will crash if check removed
		if (
			currentStage === "concept" ||
			currentStage === "prototype" ||
			currentStage === "production" ||
			currentStage === "shipping"
		) {
			this.setState({ productStage });
		}
		return;
	};

	renderDefineStory = () => {
		// STEP 2
		const { story, customUrl, productStage } = this.state;
		return (
			<form className="mb-4" onSubmit={this.nextStep}>
				<input
					type="file"
					className="form-control"
					id="content"
					onChange={this.onFileInputChange}
					multiple
				/>
				<div className="form-group">
					Create custom page link
					<br />
					&nbsp;
					<span>
						tribe.com/enterprise/details/
						<input
							type="text"
							placeholder="custom-url"
							id="customUrl"
							onChange={this.onInputChange}
							value={customUrl}
						/>
					</span>
				</div>
				<ProductStageSelector
					onChangeProductStageUpdate={this.onChangeProductStageUpdate}
					productStage={productStage}
				/>
				<div className="form-group">
					<label htmlFor="story">Story</label>
					<Editor
						id="story"
						initialValue={story}
						apiKey={tinyCloudAPIKey}
						init={{
							height: 500,
							menubar: false,
							plugins: [
								"advlist autolink lists link image charmap print preview anchor",
								"searchreplace visualblocks code fullscreen",
								"insertdatetime media table paste code help wordcount",
							],
							toolbar: `undo redo | formatselect | bold italic | 
                            	alignleft aligncenter alignright alignjustify | 
                            	bullist numlist outdent indent | removeformat | help `,
						}}
						onEditorChange={this.handleEditorChange}
					/>
				</div>
				<span>
					<button
						className="btn btn-secondary"
						onClick={this.prevStep}
					>
						Go back
					</button>
					&nbsp;
					<button type="submit" className="btn btn-primary">
						Continue
					</button>
				</span>
			</form>
		);
	};

	renderDefineFaqs = () => {
		// Step 3
		const { faqs } = this.state;
		return (
			<form className="mb-4" onSubmit={this.createEnterprise}>
				<CreateFaqs
					faqs={faqs}
					popFaq={this.popFaq}
					pushFaq={this.pushFaq}
				/>

				<span>
					<button
						className="btn btn-secondary"
						onClick={this.prevStep}
					>
						Go back
					</button>
					&nbsp;
					<button type="submit" className="btn btn-primary">
						Continue
					</button>
				</span>
			</form>
		);
	};

	render() {
		const {
			// categories,
			// title,
			// goal,
			// location,
			// story,
			// duration,
			redirect,
			step,
		} = this.state;
		if (redirect) return <Redirect to={redirect} />;
		return (
			<Fragment>
				<Navbar />
				<Sidebar />
				<ContentBox title="Start your Enterprise">
					<div className="CharityWrapper">
						{step === 0
							? this.renderDefineCategory()
							: step === 1
							? this.renderDefineGoalAndTitle()
							: step === 2
							? this.renderDefineStory()
							: step === 3
							? this.renderDefineFaqs()
							: null}
					</div>
				</ContentBox>
			</Fragment>
		);
	}
}

export default CreateEnterprise;
