import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import TribeLogo from "../../UI/TribeLogo/TribeLogo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebookF,
    faTwitter,
    faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import LanguageBar from "../LanguageBar/LanguageBar";

const Footer = () => (
    <section id="Footer">
        <div className="container-fluid">
            <div className="row">
                <div className="col col-md-10 mx-auto">
                    <div className="Footer-body">
                        <div className="logo">
                            <TribeLogo type="white" />
                            <div className="tribe-contact-info mt-4">
                                <h6>Address: 85 Great Portland Street, Londan, England, W1W 7LT</h6>
                                <h6>Contact: 03300168434</h6>
                            </div>
                        </div>
                        <div className="content">
                            <div className="links-list">
                                <div>
                                    <a className="link" href="/">
                                        Home
                                    </a>
                                </div>
                                <div>
                                    <a className="link" href="/about">
                                        About us
                                    </a>
                                </div>
                                <div>
                                    <a className="link" href="/news">
                                        News
                                    </a>
                                </div>
                                <div>
                                    <a className="link" href="/contact">
                                        Contact us
                                    </a>
                                </div>
                                <div>
                                    <a className="link" href="/legal">
                                        Legal
                                    </a>
                                </div>
                                <div>
                                    <a className="link" href="/faqs">
                                        FAQS
                                    </a>
                                </div>
                            </div>
                            <div className="links-list">
                                <div>
                                    <Link className="link" to="">
                                        Terms & Privacy
                                    </Link>
                                </div>
                                <div>
                                    <Link className="link" to="">
                                        Manage Cookies
                                    </Link>
                                </div>
                            </div>
                            <div className="language">
                                <LanguageBar />
                            </div>
                        </div>
                    </div>
                    <div className="Footer-bottom">
                        <p className="text">
                            © Tribe 2020. All rights reserved.
                        </p>
                        <div className="wrap-media-icons">
                            <a className="media-icon" href="/" target="_blank">
                                <FontAwesomeIcon icon={faFacebookF} />
                            </a>
                            <a className="media-icon" href="/" target="_blank">
                                <FontAwesomeIcon icon={faTwitter} />
                            </a>
                            <a className="media-icon" href="/" target="_blank">
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
);

export default Footer;
