export const productStagesMessages = {
	concept: `This product is in Concept Stage`,
	prototype: `The project team has a working demo, not the final product. 
		Their ability to begin production may be affected by product development or financial challenges.`,
	production: `The project team has begun turning their prototype into the final product. 
		Their ability to ship the products may be affected by product development or financial challenges.`,
	shipping: `This product is in Shipping Stage`,
}

export const socialMediaShareMessage = `Hello,
We're raising {{goal}} to help fund {{title}}.
Please donate to my Tribe Crowdfunding Page and help make it happen:

Thanks for your support
`

export const templateCharityUpdate = `<div>
    <h5>Please type your update here</h5>
</div>`

export const templateStoryCause = `<div>
<h3>Short Summary</h3>
<p>Contributors fund ideas they can be passionate about and to people they trust. Here are some things to do in this section:</p>
<ul>
<li>Introduce yourself and your background.</li>
<li>Briefly describe your campaign and why it's important to you.</li>
<li>Express the magnitude of what contributors will help you achieve.</li>
</ul>
<p>Remember, keep it concise, yet personal. Ask yourself: if someone stopped reading here would they be ready to make a contribution?</p>
</div>
<div>
<h3>What We Need &amp; What You Get</h3>
<p>Break it down for folks in more detail:</p>
<ul>
<li>Explain how much funding you need and where it's going. Be transparent and specific-people need to trust you to want to fund you.a</li>
<li>Tell people about your unique perks. Get them excited!</li>
<li>Describe where the funds go if you don't reach your entire goal.</li>
</ul>
</div>
<div>
<h3>The Impact</h3>
<p>Feel free to explain more about your campaign and let people know the difference their contribution will make:</p>
<ul>
<li>Explain why your project is valuable to the contributor and to the world.</li>
<li>Point out your successful track record with projects like this (if you have one).</li>
<li>Make it real for people and build trust.</li>
</ul>
</div>
<div>
<h3>Risks &amp; Challenges</h3>
<p>People value your transparency. Be open and stand out by providing insight into the risks and obstacles you may face on the way to achieving your goal.</p>
<ul>
<li>Share what qualifies you to overcome these hurdles.</li>
<li>Describe your plan for solving these challenges.</li>
</ul>
</div>
<div>
<h3>Other Ways You Can Help</h3>
<p>Some people just can't contribute, but that doesn't mean they can't help:</p>
<ul>
<li>Ask folks to get the word out and make some noise about your campaign.</li>
<li>Remind them to use the Indiegogo share tools!</li>
</ul>
</div>
<p>And that's all there is to it.</p>`


export const templateStoryEnterprise = `<div>
<h3>Short Summary</h3>
<p>Contributors fund ideas they can be passionate about and to people they trust. Here are some things to do in this section:</p>
<ul>
<li>Introduce yourself and your background.</li>
<li>Briefly describe your campaign and why it's important to you.</li>
<li>Express the magnitude of what contributors will help you achieve.</li>
</ul>
<p>Remember, keep it concise, yet personal. Ask yourself: if someone stopped reading here would they be ready to make a contribution?</p>
</div>
<div>
<h3>What We Need &amp; What You Get</h3>
<p>Break it down for folks in more detail:</p>
<ul>
<li>Explain how much funding you need and where it's going. Be transparent and specific-people need to trust you to want to fund you.a</li>
<li>Tell people about your unique perks. Get them excited!</li>
<li>Describe where the funds go if you don't reach your entire goal.</li>
</ul>
</div>
<div>
<h3>The Impact</h3>
<p>Feel free to explain more about your campaign and let people know the difference their contribution will make:</p>
<ul>
<li>Explain why your project is valuable to the contributor and to the world.</li>
<li>Point out your successful track record with projects like this (if you have one).</li>
<li>Make it real for people and build trust.</li>
</ul>
</div>
<div>
<h3>Risks &amp; Challenges</h3>
<p>People value your transparency. Be open and stand out by providing insight into the risks and obstacles you may face on the way to achieving your goal.</p>
<ul>
<li>Share what qualifies you to overcome these hurdles.</li>
<li>Describe your plan for solving these challenges.</li>
</ul>
</div>
<div>
<h3>Other Ways You Can Help</h3>
<p>Some people just can't contribute, but that doesn't mean they can't help:</p>
<ul>
<li>Ask folks to get the word out and make some noise about your campaign.</li>
<li>Remind them to use the Indiegogo share tools!</li>
</ul>
</div>
<p>And that's all there is to it.</p>`
