import React, { Component, Fragment } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ChatMessage from '../../DataModels/ChatMessage';


import './Chat.css'

class ChatMessages extends Component {

	state = {
		chatMessages: [],
		message: "",
	};

	componentDidMount() {
		this.loadMessages()
	}

	componentDidUpdate(props) {
		if (this.props.conversation._id !== props.conversation._id) {
			this.loadMessages()
		}
	}

	loadMessages = async () => {
		const { conversation } = this.props
		try {
			const chatMessages = await ChatMessage.FetchChatMessages(conversation._id)
			this.setState({ chatMessages })
		} catch (e) {
			console.log(e)
			alert("Could not load messages at this moment")
		}
	}

	updateMessageText = (e) => this.setState({ message: e.target.value });

	sendMessage = () => {
		const { conversation } = this.props
		const { message } = this.state;
		let chatMessage = new ChatMessage(null, conversation._id, null, message, 'text', null)
		chatMessage.postMessage()
			.then(newMessage => {
				this.pushNewMessage(newMessage)
			}, error => {
				console.log({ error })
				alert("Message could not be sent at this moment.")
				this.setState({ message })
			})
		this.setState({ message: "" })
	};

	pushNewMessage = (newMessage) => {
		const { chatMessages } = this.state
		const newChatMessages = [...chatMessages, newMessage]
		this.setState({
			chatMessages: newChatMessages
		})
	}

	renderReceivedMessage = (message) => {
		let time = new Date(message.createdAt);
		time = time.getHours() + ":" + time.getMinutes();
		return (
			<div className='main-sender pt-3 '>
				<div className='shift-comment-sender  pt-2 '>
					<div className='ml-2'>
						<span>{message.content}</span>
					</div>
				</div>
				<div className='time-span'>
					<span className=''>{time}</span>
				</div>
			</div>
		);
	};

	renderSentMessage = (message) => {
		let time = new Date(message.createdAt);
		time = time.getHours() + ":" + time.getMinutes();
		return (
			<div className='main-receiver pt-3'>
				<div className='shift-comment-receiver  pt-2 '>
					<div className='ml-4 '>
						<span>{message.content}</span>
					</div>
				</div>
				<span className='float-right'>{time}</span>
			</div>
		);
	};

	render() {
		const { chatMessages, message } = this.state;
		const { conversation, user } = this.props
		const otherParticipantId = conversation.userA === user._id ? conversation.userB : conversation.userA
		return (
			<div className='col-3 col-md-3 col-lg-3 col-sm-6  pl-3 media-section bg-light'>
				{conversation.blockedByUserA || conversation.blockedByUserB || conversation.blockedByAdmin ? (
					<div className='text-danger'>
						<b>
							{conversation.blockedByAdmin
								? 'Cannot continue this conversation. Because this conversation has been blocked by the Admin'
								: 'Cannot continue this conversation. Becuase it has been blocked by one of the participants'}
						</b>
						<br />
						{(conversation.blockedByUserA && conversation.userA === user._id) ||
						(conversation.blockedByUserB && conversation.userB === user._id) ? (
							<Button className='btn btn-danger' onClick={this.props.unblockConversation}>
								Unblock User
							</Button>
						) : null}
						&nbsp;
						<Link to={'/profile/' + otherParticipantId}>View Profile</Link>
						<br />
					</div>
				) : (
					<div>
						<Button className='btn btn-danger' onClick={this.props.blockConversation}>
							Block User
						</Button>{' '}
						&nbsp;
						<Link to={'/profile/' + otherParticipantId}>View Profile</Link>
						<br />
					</div>
				)}
				{/* <Button
					className='btn btn-secondary'
					onClick={this.props.reportConversation} >
					Report User
					</Button> */}

				{chatMessages.map((message) => {
					if (message.senderId === this.props.user._id) return this.renderSentMessage(message)
					else return this.renderReceivedMessage(message)
				})}
				{!chatMessages.length ? 'No conversation history available' : null}
				<Fragment>
					{conversation.blockedByUserA ||
					conversation.blockedByUserB ||
					conversation.blockedByAdmin ? null : (
						<div className='chat-div'>
							<div className='col chat-subdiv'>
								<div className='row bg-light'>
									<input
										placeholder='Type your message here'
										className='chat-msg-input mb-3 ml-5'
										onKeyDown={(e) => (e.key === 'Enter' ? this.sendMessage() : null)}
										onChange={this.updateMessageText}
										value={message}></input>
								</div>
								<div className='row ml-2 chat-send-svgicon' onClick={this.sendMessage}>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										className='svg-icon-color'
										width='24'
										height='24'
										viewBox='0 0 24 24'>
										<path d='M2.01 21L23 12 2.01 3 2 10l15 2-15 2z' />
									</svg>
								</div>
							</div>
						</div>
					)}
				</Fragment>
			</div>
		)
	}
}


function mapStateToProps(state) {
	return {
		user: state.auth.user,
	};
}

export default connect(mapStateToProps)(ChatMessages);
