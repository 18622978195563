const { requestHandler } = require('../utils/requestHandler')

export default {
	refundDonation: (donationId) => {
		return requestHandler('refundDonation', { donationId }).then((res) => {
			if (res.status === 200) {
				return res.data
			} else throw new Error('Something went wrong')
		})
	},
}
