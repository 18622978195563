import React, { Fragment } from "react";
import { connect } from 'react-redux';
import { Button } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";

import { receiveLogout } from "../../store/actions";
import Navbar from '../../components/layouts/Navbar'
import AdminSidebar from "../../components/layouts/AdminSidebar/AdminSidebar";
import ContentBox from "../../components/layouts/ContentBox/ContentBox";
import ChatDetailsModal from "../../components/Modals/ChatDetailsModal";
import Conversation from "../../DataModels/Conversation";
import User from "../../DataModels/User";


class AdminChats extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			users: {},
			conversations: [],
			loading: true,
			redirect: null,
			showChatModal: false,
			chatToShow: null,
		}
	}

	componentDidMount() {
		this.loadData();
	}

	formatDate = (givenDate) => {
		if (!givenDate) return 'No Updates'
		const [, month, date, year] = new Date(givenDate).toString().split(" ");
		givenDate = `${month} ${date}, ${year}`;
		return givenDate;
	}

	loadParticipants = (conversations) => {
		const { users } = this.state
		conversations.forEach(async conversation => {
			const { userA: userAId, userB: userBId } = conversation
			let promises = []
			if (!users[userAId]) {
				promises.push(User.GetUserCard(userAId))

			}
			if (!users[userBId]) {
				promises.push(User.GetUserCard(userBId))
			}
			let [userA, userB] = await Promise.all(promises)
			if (userA) {
				this.setState({
					users: {
						...this.state.users,
						[userAId]: userA ? userA : this.state.users[userAId],
						[userBId]: userB ? userB : this.state.users[userBId]
					}
				})
			}
		})
	}

	loadData = () => {
		Conversation.FetchAllConversations()
			.then(conversations => {
				this.setState({ conversations }, () => this.loadParticipants(conversations))
			})
	}

	openChatModal = (showChatModal) => {
		this.setState({ showChatModal })
	}

	viewChat = (conversation) => {
		this.setState({ chatToShow: conversation }, () => this.openChatModal(true))
	}

	getUser = (userId) => {
		const { users } = this.state
		if (users[userId]) {
			return users[userId]
		} else return User.template
	}

	renderTableBody = () => {
		const { conversations } = this.state;
		return (
			<tbody>
				{conversations.map(conversation => {
					const userA = this.getUser(conversation.userA)
					const userB = this.getUser(conversation.userB)
					const status = conversation.blockedByUserA || conversation.blockedByUserB || conversation.blockedByAdmin
					return (
						<tr>
							<td>
								<Link to={'/profile/' + userA._id}>
									{userA.firstName + ' ' + userA.lastName}
								</Link>
							</td>
							<td>
								<Link to={'/profile/' + userB._id}>
									{userB.firstName + ' ' + userB.lastName}
								</Link>
							</td>
							<td className={status ? 'text-danger' : ''}>{status ? "Blocked" : "Active"}</td>
							<td>{conversation.updatedAt.split('T')[0]}</td>
							<td>{conversation.createdAt.split('T')[0]}</td>
							<td><Button onClick={() => this.viewChat(conversation)}>View Chat</Button></td>
						</tr>
					)
				})}
			</tbody>
		);
	}

	render() {
		const { redirect, showChatModal, chatToShow, users } = this.state
		if (redirect) return (<Redirect to={redirect} />)
		return (
			<Fragment>
				<Navbar />
				<AdminSidebar />
				<ContentBox title={"Conversations"}>
					{showChatModal ?
						<ChatDetailsModal
							reloadProps={this.loadData}
							showModal={showChatModal}
							toggleChatModal={this.openChatModal}
							chat={chatToShow}
							userA={users[chatToShow.userA]}
							userB={users[chatToShow.userB]}
						/>
						: null
					}
					<table className='table'>
						<thead>
							<tr>
								<td>Participant A</td>
								<td>Participant B</td>
								<td>Started At</td>
								<td>Last update at</td>
							</tr>

						</thead>
						{this.renderTableBody()}
					</table>
				</ContentBox>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		user: state.auth.user
	};
}

const mapDispatchToProps = (dispatch) => ({
	logoutUser: () => dispatch(receiveLogout())

});

export default connect(mapStateToProps, mapDispatchToProps)(AdminChats);
