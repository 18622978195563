import React from "react";
import ReactHtmlParser from "react-html-parser";

import { requestHandler } from "../utils/requestHandler";
import { createDateAndTimeFromISO } from "../utils/helpers";

class CampaignUpdates extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			updates: [],
		};
	}

	componentDidMount() {
		this.loadData();
	}

	loadData = () => {
		this.loadCampaignUpdates();
	};

	loadCampaignUpdates = () => {
		const { campaignId } = this.props;
		if (!campaignId) return;
		requestHandler("getCampaignUpdates", { campaignId }).then(
			(response) => {
				if (response.status === 200) {
					this.setState({ updates: response.data });
				}
			},
		);
	};

	renderUpdate = ({ userId, message, createdAt, _id, user }) => (
		<div
			className='bg-light m-3 bg-gray'
			style={{
				margin: "10px",
				padding: "10px",
				overflowY: "hidden",
				overflowX: "auto",
				border: "1px solid #000",
			}}
			key={_id}>
			<div className='d-flex justify-content-between'>
				<div>
					<i>Update From:</i>{" "}
					<b>{user.firstName + " " + user.lastName}</b> <br />
				</div>
				<div>
					<i>Time:</i>{" "}
					<b>{createDateAndTimeFromISO(createdAt, true)}</b> <br />
				</div>
			</div>
			<hr />
			<div className='update'> {ReactHtmlParser(message)} </div>
		</div>
	);

	renderUpdates = () => {
		const { updates } = this.state;
		return <div>{updates.map((update) => this.renderUpdate(update))}</div>;
	};

	render() {
		return (
			<div>
				<div>{this.renderUpdates()}</div>
			</div>
		);
	}
}

export default CampaignUpdates;
