import { requestHandler } from '../utils/requestHandler'

export default class Item {
    constructor(itemId) {

    }

    static async FetchEnterpriseRewardItems(enterpriseId) {
        const result = await requestHandler("enterpriseRewardsItems", { enterpriseId })
        const items = [...result.data];
        return items
    }

    static async GetRewardItems(rewardId) {

    }

}
