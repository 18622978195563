import React, { useState, useRef, useEffect } from "react";
import "./DashboardLayout.css";
import { connect } from "react-redux";
import { setDashboardMode, receiveLogout } from "../../../store/actions";
import Sidebar from "../../Dashboard/Sidebar/Sidebar";
import Topbar from "../../Dashboard/Topbar/Topbar";

const DashboardLayout = (props) => {
	const [leftClassName, setLeftClassName] = useState("");
	const [sidebarWidth, setSidebarWidth] = useState(0);
	const leftSideRef = useRef(null);

	useEffect(() => {
		if (window.screen.width < 1700) {
			setLeftClassName("col-xl-3");
		} else {
			setLeftClassName("col-xl-2");
		}
	}, []);

	useEffect(() => {
		if (leftSideRef.current) {
			setSidebarWidth(leftSideRef.current.offsetWidth);
		}
	}, [leftSideRef, leftClassName]);

	return (
		<div className="DashboardLayout">
			<div className="container-fluid">
				<div className="row">
					<div
						ref={leftSideRef}
						className={`LeftSide ${leftClassName}`}
					>
						<Sidebar
							sidebarWidth={sidebarWidth}
							user={props.user}
							dashboardMode={props.dashboardMode}
							logoutUser={props.logoutUser}
						/>
					</div>
					<div className="RightSide col">
						<div className="wrap-topbar">
							<Topbar
								dashboardMode={props.dashboardMode}
								setDashboardMode={props.setDashboardMode}
							/>
						</div>
						<div className="wrap-body">{props.children}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

function mapStateToProps(state) {
	return {
		user: state.auth.user,
		dashboardMode: state.dashboard.mode,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		setDashboardMode: (mode) => dispatch(setDashboardMode(mode)),
		logoutUser: () => dispatch(receiveLogout()),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);
