import { SET_DASHBOARD_MODE } from "../actions";

const initialState = {
	mode: "angel",
};

const dashboardReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_DASHBOARD_MODE:
			return {
				...state,
				mode: action.mode,
			};
		default:
			return state;
	}
};

export default dashboardReducer;
