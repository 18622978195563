// import configureStore from "../configureStore";
import { requestHandler } from "../utils/requestHandler";

// const store = configureStore();

class Conversation {
	constructor(
		_id, userA, userB, userAUnread,
		userBUnread, createdAt, updatedAt,
		blockedByUserA, blockedByUserB, blockedByAdmin
	) {
		this._id = _id
		this.userA = userA
		this.userB = userB
		this.userAUnread = userAUnread
		this.userBUnread = userBUnread
		this.createdAt = createdAt
		this.updatedAt = updatedAt
		this.blockedByUserA = blockedByUserA
		this.blockedByUserB = blockedByUserB
		this.blockedByAdmin = blockedByAdmin
	}

	static async FetchAllConversations() {
		const response = await requestHandler('fetchAllConversations', {})
		const conversations = []
		if (response.status === 200 && response.data && response.data.length) {
			response.data.forEach(singleConvoData => {
				conversations.push(new Conversation(
					singleConvoData._id,
					singleConvoData.userA,
					singleConvoData.userB,
					singleConvoData.userAUnread,
					singleConvoData.userBUnread,
					singleConvoData.createdAt,
					singleConvoData.updatedAt,
					singleConvoData.blockedByUserA,
					singleConvoData.blockedByUserB,
					singleConvoData.blockedByAdmin
				))
			})
		}
		return conversations
	}

	static async FetchSingleConversation(userId) {
		let response = await requestHandler("fetchSingleConversation", { user: userId })
		if (response.status === 200) {
			return new Conversation(
				response.data._id, response.data.userA,
				response.data.userB, response.data.userAUnread,
				response.data.userBUnread, response.data.createdAt,
				response.data.updatedAt, response.data.blockedByUserA,
				response.data.blockedByUserB, response.data.blockedByAdmin
			)
		} else throw new Error("Could not start conversation.")
	}

	static async FetchUserConversations() {
		const response = await requestHandler('fetchUserConversations', {})
		const conversations = []
		if (response.status === 200 && response.data && response.data.length) {
			response.data.forEach(singleConvoData => {
				conversations.push(new Conversation(
					singleConvoData._id,
					singleConvoData.userA,
					singleConvoData.userB,
					singleConvoData.userAUnread,
					singleConvoData.userBUnread,
					singleConvoData.createdAt,
					singleConvoData.updatedAt,
					singleConvoData.blockedByUserA,
					singleConvoData.blockedByUserB,
					singleConvoData.blockedByAdmin
				))

			})
		}
		return conversations
	}

	async blockConversation() {
		const response = await requestHandler('blockConversation', null, this._id)
		if (response.status === 200) {
			return true
		} else return false
	}

	async unblockConversation() {
		const response = await requestHandler('unblockConversation', null, this._id)
		console.log({ response })
		if (response.status === 200) {
			return true
		} else {
			return false
		}
	}
}

export default Conversation;
