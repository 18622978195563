
import { requestHandler } from "../utils/requestHandler";

export default class Enterprise {

    static async FetchUserEnterprises(userId) {
        return requestHandler('getEnterprise', { creatorId: userId }).then((result) => {
			return result.data
		})

    }

    static async FetchById(id) {
        console.log('Id to get: ', id)
        let response = await requestHandler("getEnterprise", { id })
        console.log(response)
        if (response.status === 200) {
            return response.data[0]
        } else return false
    }
}