import React from 'react'
import { Modal } from 'react-bootstrap'

const AdminContactUsRespondBack = (props) => {
	const renderBody = () => {
		const contactUsRequest = props.contactUsRequest || {}
		console.log(contactUsRequest)
		return (
			<React.Fragment>
				<Modal.Body>{JSON.stringify(contactUsRequest)}</Modal.Body>
				<Modal.Footer>Attention Required!</Modal.Footer>
			</React.Fragment>
		)
	}

	return (
		<Modal show={props.show} onHide={() => props.onHide(false)}>
			<Modal.Header closeButton>
				<Modal.Title>Perform Action</Modal.Title>
			</Modal.Header>
			{renderBody()}
		</Modal>
	)
}

export default AdminContactUsRespondBack
