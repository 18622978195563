import {
	faCogs,
	faFeatherAlt,
	faFileContract,
	//  faMoneyBillWave,
	//  faCubes,
	faMask,
	faTachometerAlt,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useRef } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import AdminSidebar from '../AdminSidebar/AdminSidebar'
import './Sidebar.css'

function angelSidebarContent() {
	return (
		<ul className='navbar-list'>
			<li className='navbar-item'>
				<span>
					<FontAwesomeIcon icon={faTachometerAlt} />
					<Link to='/dashboard' className='navbar-link'>
						Dashboard
					</Link>
				</span>
			</li>
			<li className='navbar-item'>
				<span>
					<FontAwesomeIcon icon={faFeatherAlt} />
					<Link to='/my-donations' className='navbar-link'>
						List of Donations
					</Link>
				</span>
			</li>
			<li className='navbar-item'>
				<span>
					<FontAwesomeIcon icon={faFeatherAlt} />
					<Link to='/my-rewards' className='navbar-link'>
						Purchased Reward Tracking
					</Link>
				</span>
			</li>
			<li className='navbar-item'>
				<span>
					<FontAwesomeIcon icon={faFeatherAlt} />
					<Link className='navbar-link' to='/contact-heroes'>
						Tribe World
					</Link>
				</span>
			</li>
		</ul>
	)
}

function heroSidebarContent(dropdownToggleHandler, createAFundraiser) {
	return (
		<ul className='navbar-list'>
			<li className='navbar-item'>
				<span>
					<FontAwesomeIcon icon={faTachometerAlt} />
					<Link to='/dashboard' className='navbar-link'>
						Dashboard
					</Link>
				</span>
			</li>
			<li
				className='navbar-dropdown'
				ref={createAFundraiser}
				onClick={() => dropdownToggleHandler(createAFundraiser)}>
				<span>
					<FontAwesomeIcon icon={faMask} />
					<Link className='navbar-link' to='/enterprise/create'>Start a Fundraiser</Link>
				</span>
				<ul className='navbar-list navbar-dropdown-list hide'>
					<li className='navbar-item'>
						<span>
							<Link to='/charity/create' className='navbar-link'>
								Cause
							</Link>
						</span>
					</li>
					<li className='navbar-item'>
						<span>
							<Link to='/enterprise/create' className='navbar-link'>
								Enterprise
							</Link>
						</span>
					</li>
				</ul>
			</li>
			<li className='navbar-item'>
				<span>
					<FontAwesomeIcon icon={faMask} />
					<Link to='/charity/manage' className='navbar-link'>
						Mange Causes
					</Link>
				</span>
			</li>
			<li className='navbar-item'>
				<span>
					<FontAwesomeIcon icon={faMask} />
					<Link to='/enterprise/manage' className='navbar-link'>
						Manage Enterprises
					</Link>
				</span>
			</li>

			<li className='navbar-item'>
				<span>
					<FontAwesomeIcon icon={faFeatherAlt} />
					<Link className='navbar-link' to='/contact-heroes'>
						Tribe World
					</Link>
				</span>
			</li>
		</ul>
	)
}

const Sidebar = (props) => {
	const createAFundraiser = useRef(null)
	// const angelControlles = useRef(null);
	// const heroControlles = useRef(null);
	const isAngel = props.dashboardMode === 'angel'

	const dropdownToggleHandler = (dropdownItem) => {
		const classes = ['navbar-list', 'navbar-dropdown-list']
		if (dropdownItem.current.childNodes[1].classList[2] === 'hide') {
			classes.push('show')
			dropdownItem.current.childNodes[1].className = classes.join(' ')
		} else {
			classes.push('hide')
			dropdownItem.current.childNodes[1].className = classes.join(' ')
		}
	}
	const { user, isAuthenticated } = props
	if (!isAuthenticated) return null
	if (user.userType === 'admin') return <AdminSidebar />
	return (
		<section id='Sidebar'>
			<div className='wrapper'>
				{isAngel
					? angelSidebarContent(dropdownToggleHandler)
					: heroSidebarContent(dropdownToggleHandler, createAFundraiser)}
				<ul className='navbar-list'>
					<li className='navbar-item'>
						<span>
							<FontAwesomeIcon icon={faFileContract} />
							<Link to='/chat' className='navbar-link'>
								Chats
							</Link>
						</span>
					</li>
					<li className='navbar-item'>
						<span>
							<FontAwesomeIcon icon={faCogs} />
							<Link to='/settings' className='navbar-link'>
								Settings
							</Link>
						</span>
					</li>
				</ul>
			</div>
		</section>
	)
}

function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		user: state.auth.user,
		dashboardMode: state.dashboard.mode,
	}
}

export default connect(mapStateToProps)(Sidebar)
