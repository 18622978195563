import React, { Fragment } from 'react'
import DataTable from 'react-data-table-component'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { receiveLogout } from '../../store/actions'
import AdminSidebar from '../../components/layouts/AdminSidebar/AdminSidebar'
import ContentBox from '../../components/layouts/ContentBox/ContentBox'
import Navbar from '../../components/layouts/Navbar'
import RefundDonationRequestDetails from '../../components/Modals/RefundDonationRequestDetails'
import { requestHandler } from '../../utils/requestHandler'

class AdminRefundRequests extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			redirect: null,
			donations: [],
			donars: {},
			totalDonated: '',
			totalDonatedToPlatform: '',
			totalPayment: '',
			structuredDonations: [],
			selectedDonation: null,
		}
	}

	componentDidMount() {
		this.reloadData()
	}

	calculateStats = (donations) => {
		let totalDonated = 0,
			totalDonatedToPlatform = 0,
			totalPayment = 0
		donations.forEach((donation) => {
			totalDonated += donation.donationAmount
			totalDonatedToPlatform += donation.donationForPlatform
			totalPayment += donation.totalPayment
		})
		this.setState({ totalDonated, totalDonatedToPlatform, totalPayment })
	}

	reloadData = () => {
		// const { causestatus } = this.props.match.params;
		const query = {}
		requestHandler('getMyDonations', query).then((response) => {
			const donations = response.data
			console.log({ donations })
			if (response.status === 200) {
				this.setState({ donations })
			}
			this.calculateStats(donations)
			this.setDataForDatatable(donations)
		})
	}

	setDataForDatatable = (rawDonations) => {
		console.log('raw: ', rawDonations)
		const structuredDonations = []
		rawDonations.forEach((donation) => {
			if (!donation.refundRequested) return
			structuredDonations.push({
				id: donation._id,
				title: donation.charity.title,
				donarName: `${donation.donar[0].firstName} ${
					donation.donar[0].lastName ? donation.donar[0].lastName : ''
				}`,
				donationAmount: donation.donationAmount,
				donationForPlatform: donation.donationForPlatform,
				payment: donation.totalPayment,
				donationDate: donation.createdAt.split('T')[0],
			})
		})
		this.setState({ structuredDonations })
	}

	renderTableBody = () => {
		const { donations } = this.state
		return (
			<tbody>
				{donations.map((donation) => (
					<tr>
						<td>{donation.charity.title}</td>
						<td>{`${donation.donar[0].firstName} ${
							donation.donar[0].lastName ? donation.donar[0].lastName : ''
						}`}</td>
						<td>{'$ ' + donation.donationAmount}</td>
						<td>{'$ ' + donation.donationForPlatform}</td>
						<td>{'$ ' + donation.totalPayment}</td>
						<td>{donation.createdAt.split('T')[0]}</td>
					</tr>
				))}
			</tbody>
		)
	}

	setSelectedDonation = (selectedDonation) => this.setState({ selectedDonation })

	renderDataTable = () => {
		const { structuredDonations } = this.state
		const customStyles = {
			rows: {
				style: {
					minHeight: '72px', // override the row height
				},
			},
			headCells: {
				style: {
					paddingLeft: '8px', // override the cell padding for head cells
					paddingRight: '8px',
				},
			},
			cells: {
				style: {
					paddingLeft: '8px', // override the cell padding for data cells
					paddingRight: '8px',
				},
			},
		}
		const columns = [
			{
				name: "Cause's Title",
				selector: 'title',
				sortable: true,
				right: false,
			},
			{
				name: 'Donation By',
				selector: 'donarName',
				sortable: true,
				right: false,
			},
			{
				name: 'Donation Amount',
				selector: 'donationAmount',
				sortable: true,
				right: false,
			},
			{
				name: 'Donation for Platform',
				selector: 'donationForPlatform',
				sortable: true,
				right: false,
			},
			{
				name: 'Payment',
				selector: 'payment',
				sortable: true,
				right: false,
			},
			{
				name: 'Donation Date',
				selector: 'donationDate',
				sortable: true,
				right: false,
			},
		]
		return (
			<DataTable
				highlightOnHover
				striped
				responsive
				onRowClicked={(e) => this.setSelectedDonation(e)}
				noDataComponent={'No record found'}
				columns={columns}
				customStyles={customStyles}
				data={structuredDonations}
			/>
		)
	}

	render() {
		const { redirect, totalDonated, totalDonatedToPlatform, totalPayment } = this.state
		if (redirect) return <Redirect to={redirect} />
		return (
			<Fragment>
				<RefundDonationRequestDetails
					show={this.state.selectedDonation}
					donation={this.state.selectedDonation}
					onHide={() => this.setSelectedDonation(null)}
				/>
				<Navbar />
				<AdminSidebar />
				<ContentBox title={'Refunds Requested'}>
					Total Donation: <b>{totalDonated}</b>
					<br />
					Total to Platform: <b>{totalDonatedToPlatform}</b>
					<br />
					Total Payment: <b>{totalPayment}</b>
					{this.renderDataTable()}
				</ContentBox>
			</Fragment>
		)
	}
}

function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		user: state.auth.user,
	}
}

const mapDispatchToProps = (dispatch) => ({
	logoutUser: () => dispatch(receiveLogout()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminRefundRequests)
