import { LOCALES } from "../locales";

export default {
    [LOCALES.FRENCH]: {
        "home": "Accueil",
        "packages": "Paquets",
        "portfolio": "Portefeuille",
        "events": "Evénements",
        "blogs": "Blogs",
        "language": "Langue",
        "login": "S'Identifier",
        "caps-investors": "INVESTISSEUS/ES",
        "caps-million_invested": "MILLION INVESTIE",
        "caps-million_profits": "MILLION BÉNÉFICES",
        "caps-frames_impacted": "IMPACTÉ",
        "caps-as_seen_on": "COMME VU SUR",
        "become_a_hero": "Devenez un Héros",
        "become_an_angel": "Devenir un Ange",
        "how_it_works": "Comment ça fonctionne",
        "for": "pour les",
        "caps-heroes": "HEROS",
        "caps-angels": "ANGES",
    },
};
