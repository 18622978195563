import React, { Fragment } from "react";
import { connect } from 'react-redux';
import { Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";

import { receiveLogout } from "../../store/actions";
import Navbar from '../../components/layouts/Navbar'
import AdminSidebar from "../../components/layouts/AdminSidebar/AdminSidebar";
import ContentBox from "../../components/layouts/ContentBox/ContentBox";
import Report from "../../DataModels/Report";
import ReportDetailsModal from "../../components/Modals/ReportDetailsModal/ReportDetailsModal";


class Navigation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			reports: [],
			loading: true,
			redirect: null,
			showReportModal: false,
			reportToShow: null,
		}
	}

	componentDidMount() {
		this.loadData();
	}

	formatDate = (givenDate) => {
		if (!givenDate) return 'No Updates'
		const [, month, date, year] = new Date(givenDate).toString().split(" ");
		givenDate = `${month} ${date}, ${year}`;
		return givenDate;
	}

	loadData = () => {

		Report.fetchReports()
			.then((res) => {
				if (res.status === 200) {
					const reports = res.data.map((item) => {
						item.createdAt = this.formatDate(item.createdAt);
						item.updatedAt = this.formatDate(item.updatedAt);
						return item;
					});

					this.setState({ reports: reports });
				}
			})
			.catch((err) => console.log("err", err));
	}

	openReportModal = (showReportModal) => {
		this.setState({ showReportModal })
	}

	viewReportDetails = (report) => {
		console.log("REPORT: ", report)
		this.setState({ reportToShow: report }, () => this.openReportModal(true))
	}

	renderTableBody = () => {
		const { reports } = this.state;

		return (
			<tbody>
				{reports.map(report => (
					<tr>
						<td>{report.reporterEmail}</td>
						{/* <td>{report.reporterMessage}</td> */}
						<td>{report.status}</td>
						{/* <td>{report.statusMessage}</td> */}
						<td>{report.updatedAt}</td>
						<td>{report.createdAt}</td>
						<td><Button onClick={() => this.viewReportDetails(report)}>View Report</Button></td>
					</tr>
				))}
			</tbody>
		);
	}

	render() {
		const { redirect, showReportModal, reportToShow } = this.state
		if (redirect) return (<Redirect to={redirect} />)
		return (
			<Fragment>
				<Navbar />
				<AdminSidebar />
				<ContentBox title={"Reports"}>
					{showReportModal ? <ReportDetailsModal showModal={showReportModal} toggleReportModal={this.openReportModal} report={reportToShow} /> : null}
					<table className='table'>
						<thead>
							<tr>
								<td>Reporter Email</td>
								{/* <td>Reporter Message</td> */}
								<td>Status</td>
								{/* <td>Status Message</td> */}
								<td>Last Update</td>
								<td>Created At</td>
								<td>View Report</td>
							</tr>

						</thead>
						{this.renderTableBody()}
					</table>
				</ContentBox>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		user: state.auth.user
	};
}

const mapDispatchToProps = (dispatch) => ({
	logoutUser: () => dispatch(receiveLogout())

});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
